/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import { Languages } from '../../../../partials/layout/header-menus/Languages'
import { Logout } from '../../../../partials/layout/header-menus/Logout'
import { BackToOpenSooq } from '../../../../partials/layout/header-menus/BackToOpenSooq'
import { isMobile } from 'react-device-detect'
import { showChatInCountry } from '../../../../helpers/commonFunctions'

type Props = {
  comingFrom ?: string
}
const SidebarMenuMain = ({comingFrom} : Props) => {
  const intl = useIntl()

  let comingFromSource = '';
  if(comingFrom && comingFrom != ''){
    comingFromSource = comingFrom
  }
  return (
    <>
      <SidebarMenuItem isMain={true} to='/' icon='/media/icons/duotune/art/art002.svg' title={intl.formatMessage({id: 'myOpenSooq'})} fontIcon='bi-app-indicator'  newIcon={<><svg width="18" height="18" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11.7821 0.310102C11.573 0.110071 11.2957 0 11 0C10.7049 0 10.4271 0.110071 10.2181 0.310102L0.276572 9.83237C-0.0566729 10.1513 -0.0178377 10.4253 0.0349454 10.5553C0.088002 10.6859 0.245668 10.9122 0.707861 10.9122H1.82725V19.3756C1.82725 20.2559 2.59123 21 3.45627 21H8.49569V15.2638H13.3721V21H18.5434C19.4117 21 20.1394 20.2411 20.1394 19.3756V10.9122H21.2922C21.7549 10.9122 21.9122 10.6859 21.9651 10.5553C22.0176 10.4253 22.057 10.1513 21.724 9.83237L11.7821 0.310102Z" fill="black"/></svg></>}/>
      {showChatInCountry() && <SidebarMenuItem isMain={true} to='/chats' icon='/media/icons/duotune/art/art002.svg' title={intl.formatMessage({id: 'chatsTitle'})} newIcon={<><svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 4H17V13H4V15C4 15.55 4.45 16 5 16H16L20 20V5C20 4.45 19.55 4 19 4ZM15 10V1C15 0.45 14.55 0 14 0H1C0.45 0 0 0.45 0 1V15L4 11H14C14.55 11 15 10.55 15 10Z" fill="black"/></svg></>}/>}
      <SidebarMenuItem isMain={true} to='/notifications' hasBullet={false} title={intl.formatMessage({id: 'notifications'})} fontIcon='bi-app-indicator' newIcon={<><svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M8 20C9.1 20 10 19.1 10 18H6C6 19.1 6.89 20 8 20ZM14 14V9C14 5.93 12.36 3.36 9.5 2.68V2C9.5 1.17 8.83 0.5 8 0.5C7.17 0.5 6.5 1.17 6.5 2V2.68C3.63 3.36 2 5.92 2 9V14L0 16V17H16V16L14 14Z" fill="black"></path> </svg></>}/>
      <SidebarMenuItemWithSub parentMenu={'listings'} comingFrom={comingFromSource} isMain={true} to='#' title={intl.formatMessage({id: 'menu.listing'})} fontIcon='bi-archive' icon='/media/icons/duotune/general/gen010.svg' newIcon={<><svg width="23" height="23" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="5.5" y="2.5" width="14" height="19" rx="1.5" stroke="black"/> <rect x="7" y="9" width="11" height="11" rx="1" fill="black"/> <rect x="7" y="4" width="11" height="1" rx="0.5" fill="black"/> <rect x="7" y="6" width="7" height="1" rx="0.5" fill="black"/> </svg></>}>
        <SidebarMenuItem to='/listings/my-listings' title={intl.formatMessage({id: 'menu.listing.myListing'})} hasBullet={true}/>
        <SidebarMenuItem to='/listings/my-listings/draft-listings' title={intl.formatMessage({id: 'menu.listing.drafts'})} hasBullet={true}/>
        <SidebarMenuItem to='/listings/demand/favorite-listings' title={intl.formatMessage({id: 'menu.listing.favorites'})} hasBullet={true}  />
        <SidebarMenuItem to='/listings/demand/recently-viewed' title={intl.formatMessage({id: 'menu.listing.recently'})} hasBullet={true} />
        <SidebarMenuItem to='/listings/demand/following-listings' title={intl.formatMessage({id: 'menu.listing.followings'})} hasBullet={true}  />
        <SidebarMenuItem to='/listings/demand/job-applications' title={intl.formatMessage({id: 'menu.listing.jobApplications'})} hasBullet={true}/>
        <SidebarMenuItem to='/listings/searches/saved-search' title={intl.formatMessage({id: 'menu.listing.savedSearch'})} hasBullet={true}/>
        <SidebarMenuItem to='/listings/searches/recent-search' title={intl.formatMessage({id: 'menu.listing.recentSearch'})} hasBullet={true}/>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub parentMenu={'account'} comingFrom={comingFromSource} isMain={true} to='#' title={intl.formatMessage({id: 'MENU.ACCOUNT'})} fontIcon='bi-archive' icon='/media/icons/duotune/general/gen004.svg' newIcon={<><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M8 6.28585C8 8.65155 10.015 10.5714 12.5001 10.5714C14.9861 10.5714 17 8.65155 17 6.28585C17 3.91751 14.9861 2 12.5001 2C10.015 2 8 3.91751 8 6.28585Z" fill="black"/> <path fillRule="evenodd" clipRule="evenodd" d="M5 18.9318C5 23.0227 20 23.0227 20 18.9318C20 14.8408 16.6416 11.5238 12.4998 11.5238C8.35787 11.5238 5 14.8408 5 18.9318Z" fill="black"/> </svg></>}>
        <SidebarMenuItem to='/account/my-account' title={intl.formatMessage({id : "myAccount"})} hasBullet={true} />
        <SidebarMenuItem to='/account/cv/edit' title={intl.formatMessage({id: 'MENU.JCV'})} hasBullet={true} />
        <SidebarMenuItem to='/account/my-rating' title={intl.formatMessage({id: 'MENU.ACCOUNT.r'})} hasBullet={true} />
        <SidebarMenuItem to='/account/my-wallet' title={intl.formatMessage({id: 'MENU.ACCOUNT.mw'})} hasBullet={true}/>
        <SidebarMenuItem to='/account/followers' title={intl.formatMessage({id: 'menu.account.followers'})} hasBullet={true}/>
        <SidebarMenuItem to='/account/following' title={intl.formatMessage({id: 'menu.account.followings'})} hasBullet={true}/>
        <SidebarMenuItem to='/account/settings'  title={intl.formatMessage({id: 'menu.account.settings'})} hasBullet={true}/>
      </SidebarMenuItemWithSub>
      {/* <SidebarMenuItemWithSub isMain={true} to='#' title={'sss'} fontIcon='bi-archive' icon='/media/icons/duotune/general/gen005.svg' >
        <SidebarMenuItem to='/custom/carfax-report' title={intl.formatMessage({id: 'MENU.SERVICES.cr'})} hasBullet={true} />
        <SidebarMenuItem to='/custom/hide-promotional-ads' title={intl.formatMessage({id: 'MENU.SERVICES.hpa'})} hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      <SidebarMenuItem isMain={true} to='/products' icon='/media/icons/duotune/art/gen005.svg' title={intl.formatMessage({id : "menu.products"})} fontIcon='bi-app-indicator' newIcon={<><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M3 3V11H11V3H3ZM9 9H5V5H9V9ZM3 13V21H11V13H3ZM9 19H5V15H9V19ZM13 3V11H21V3H13ZM19 9H15V5H19V9ZM13 13V21H21V13H13ZM19 19H15V15H19V19Z" fill="black"/> </svg></>}/>
      <SidebarMenuItem isMain={true} to='/invite-friends' icon='/media/icons/duotune/art/art002.svg' title={intl.formatMessage({id: 'inviteFriend'})} fontIcon='bi-app-indicator' newIcon={<><svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 20C9.1 20 10 19.1 10 18H6C6 19.1 6.89 20 8 20ZM14 14V9C14 5.93 12.36 3.36 9.5 2.68V2C9.5 1.17 8.83 0.5 8 0.5C7.17 0.5 6.5 1.17 6.5 2V2.68C3.63 3.36 2 5.92 2 9V14L0 16V17H16V16L14 14Z" fill="black"/></svg></>}/>
      {(isMobile || comingFrom == 'headMenu') ? <><BackToOpenSooq/>
      <Languages/>
      <Logout/></> : ''}
    </>
  )
}

export {SidebarMenuMain}
