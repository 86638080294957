import { useIntl } from "react-intl"
type Props = {
    showFileModal: () => any
}
const CommercialUploadContainer = ({showFileModal} : Props) => {
    const Intl = useIntl();
    return <div className='row'>
        <div className='fv-row mb-2' onClick={showFileModal}>
            <div className='dropzone dz-clickable' >
                <div className='dz-message needsclick'>
                <i className='fas fa-upload fs-1'></i>
                <div className='ms-4'>
                    <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                    {Intl.formatMessage({id: 'myProfile.id.text1'})}
                    </h3>
                    <span className='fs-7 fw-semibold text-gray-400'>
                    {Intl.formatMessage({id: 'myProfile.id.text2'})}
                    </span>
                </div>
                </div>
            </div>
        </div>
    </div>
}

export default CommercialUploadContainer