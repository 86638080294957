import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import random from "../../../libs/jwt/random";
import { useLang } from "../../../app/i18n/OpenSooqi18n";
import { IframeLoader } from "../common/IframeLoader";
import { getCookies, logout } from "../../helpers/commonFunctions";
import { useIntl } from "react-intl";
import { isMobile } from "react-device-detect";

export function CompleteListing() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const draftId = params.get('id') ?? '';
    const iframeContent = useRef<null | HTMLIFrameElement>(null);
    const [loader,setLoader] = useState <boolean>(true)
    const lang = useLang();
    const Intl = useIntl();

    window.addEventListener("message", event => {
        if(event.data === 401){
            logout()
            document.location.reload()
        }else if(event.data == 200){
            setLoader(false);
            if(iframeContent.current){
                iframeContent.current.classList.remove('d-none')
            }
        }
    }, false);
    

    return (
            <>{loader && <IframeLoader/>}
            <iframe src={`${process.env.REACT_APP_ADD_POST}?v=2&source=${getCookies().source}&lang=${lang}&flow=draft&draft_id=${draftId}&from=my-opensooq`} className="d-none w-100" style={{height : "100%",minHeight: isMobile ? '100%' : '90vh',overflowY:"hidden"}} ref={iframeContent}></iframe></>
    )
  }