import generate_jwt from '../../../libs/jwt/jwt'
import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl"
import { MemberInterface } from "../../interfaces/MemberInterface";
import membersApi from "../../../api/members/members.api";
import { MemberLoader } from "../../../app/layout/components/opensooq/MemberLoader";
import { SearchComponent } from "../../../app/layout/components/opensooq/SearchComponent";
import { BlockedMemberInterface } from "../../interfaces/BlockedMemberInterface";
import { getCookies } from "../../helpers/commonFunctions";
import { NotificationSettings } from './NotificationSettings';


let socket:any;

const Settings: FC = () => {
    const Intl = useIntl();
    const [blockedMembers,setBlockedMembers] = useState<BlockedMemberInterface[]>([])
    const [searchData,setSearchData] = useState<BlockedMemberInterface[]>([])
    const [blockedMembersLoader,setBlockedMembersLoader] = useState(true)
    const [searchString, setSearchString] = useState("")
    
    useEffect(() => {
        const  io = require( 'socket.io-client');
        socket = io(process.env.REACT_APP_CHAT_URL);
        fetchToken().then((token) => {
          socket.on('disconnect', function(e : any) {
              setBlockedMembersLoader(false);
          });
          socket.on('connect', function() {
              const data  = {
                  id: getCookies().userInfo.id,
                  token: token,
                  platform: 'web',
                  country: 'jo',
                  lang:'en',
                  name : 'Laith Elhaj',
                  avatar :"#noImage",
              };
              socket.emit('authentication', data, async function(auth_result : any){
                socket.emit('get-blocked',function(data : any) {
                    const ids = data.map((member : MemberInterface) => { return member.id})
                    membersApi(ids.toString()).then((response) => {
                        setBlockedMembersLoader(false);
                        setBlockedMembers(response.data)
                        setSearchData(response.data)
                    }).catch((error) => {
                        setBlockedMembersLoader(false);
                    });
                });
            })
        })

        socket.on('close',function(){
            setBlockedMembersLoader(false);
        })
        }).catch(error => {
        });

        if(!socket.connected ){
            setBlockedMembersLoader(false);
        }

    },[]);

    const onUnblock = (memberId : number ) => {
        socket.emit('unblock-user',memberId);
        setBlockedMembers(prevMemberList =>{
            return prevMemberList.filter(it => it.id != memberId);
        });
        setSearchData(prevMemberList =>{
            return prevMemberList.filter(it => it.id != memberId);
        });
    }

    const MemberCards:React.FC<{searchData:BlockedMemberInterface[]}> = ({searchData}) => {
        return <React.Fragment key={'favoriteListings'}>
            {searchData.map((member  : BlockedMemberInterface,index) => {
                return <div key={index} className="col-lg-4 col-sm-6  col-xs-12 col-md-6 mt-3">
                    <div className={`card  h-100 customShadow  id_${member.id}`} >
                        <div className="card-body d-flex flex-center flex-column py-9 px-5">
                            <div className="symbol symbol-65px symbol-circle mb-5">
                                <img src={member.profile_picture} alt="followers"/>
                            </div>
                            <span className="fs-5 text-gray-800 text-hover-primary fw-bold mb-5 text-center">{member.full_name}</span>
                            <div className='d-flex justify-content-center'>
                                <button className="btn btn-sm btn-light-primary me-2" onClick={() => {onUnblock(member.id)}}>
                                    <span className="indicator-label">{Intl.formatMessage({id: 'blockedMembers.text1'})}</span>
                                </button>
                                <a target="_blank" href={`${process.env.REACT_APP_OPENSOOQ_URL_BASE}${(`mid/${member.id}`)}`} className='btn btn-sm btn-light-primary'>
                                    <span className="svg-icon follow svg-icon-3 d-none">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                            <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                                        </svg>
                                    </span>
                                    <span className="indicator-label">{Intl.formatMessage({id: 'viewMember'})}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            })}
        </React.Fragment>  
    };


    const cookies = getCookies()
    const fetchToken = async () => {
        const token = generate_jwt(Number(getCookies().tgtTimestamp),cookies.tgtTicket,cookies.userInfo.id)
        return token;
    }



    const setSearch = (e: any) => {
        setSearchString(e.target.value);
    };

    useEffect(() => {
        const newList = blockedMembers.filter(member => member.full_name.toLowerCase().includes(searchString.toLowerCase()))
        setSearchData(newList);
    },[searchString]);

    return (
        <>
            <NotificationSettings />
            <div className='card mb-xl-10 ' id='kt_profile_details_view'>
                <div className='card-header  cursor-pointer '>
                    <div className='card-title '>
                        <h3 className='fw-bolder '>{Intl.formatMessage({id: 'blockedUsers'})}</h3>
                    </div>
                    <SearchComponent action={setSearch} placeholder={`${Intl.formatMessage({id: 'blockedMembers.search'})}`}/>
                </div>
                <div className='card-body ps-4 pe-4 pt-1'>
                    {blockedMembersLoader && <>
                        <MemberLoader/>
                    </>}
                    {!blockedMembersLoader && searchData.length == 0 &&
                        <div className="text-center mt-15">
                            <h1>{Intl.formatMessage({id:"blockedMembers.noMembers"})}</h1>
                                <svg className="mt-10" width="176" height="142" viewBox="0 0 176 142" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 29C15.5228 29 20 24.5228 20 19C20 13.4772 15.5228 9 10 9C4.47715 9 0 13.4772 0 19C0 24.5228 4.47715 29 10 29Z" fill="#E7EBF7"/>
                                    <path d="M23.5 131C26.5376 131 29 128.538 29 125.5C29 122.462 26.5376 120 23.5 120C20.4624 120 18 122.462 18 125.5C18 128.538 20.4624 131 23.5 131Z" fill="#E7EBF7"/>
                                    <path d="M170.5 11C173.538 11 176 8.53757 176 5.5C176 2.46243 173.538 0 170.5 0C167.462 0 165 2.46243 165 5.5C165 8.53757 167.462 11 170.5 11Z" fill="#E7EBF7"/>
                                    <path d="M146.786 6.4895H52.8997L46.4225 8.72048L38.676 20.6921V99.1525L45.3427 109.213L54.0492 113.806H59.7567C61.2089 113.806 62.5518 114.271 63.6477 115.058H76.3519L78.8872 118.65V125.658L80.5896 127.07L91.4355 115.845C92.6942 114.542 94.428 113.806 96.2393 113.806H146.787C157.398 113.806 166 105.204 166 94.593V25.7031C166 15.0918 157.398 6.4895 146.786 6.4895Z" fill="#F4F6F9"/>
                                    <path d="M80.9645 120.486C80.9645 116.797 77.9739 113.806 74.2847 113.806H59.7567C63.4459 113.806 66.4365 116.797 66.4365 120.486V134.647C66.4365 137.217 69.5641 138.481 71.3499 136.632L80.9645 126.682V120.486Z" fill="#E9EDF5"/>
                                    <path d="M40.528 94.5927V25.7031C40.528 15.0916 49.1304 6.4895 59.7416 6.4895H45.2136C34.6023 6.4895 26 15.0918 26 25.7031V94.5927C26 105.204 34.6021 113.806 45.2134 113.806H59.7414C49.1298 113.806 40.528 105.204 40.528 94.5927Z" fill="#E9EDF5"/>
                                    <path d="M124.347 20.6118H120.648L111.493 29.8945C111.493 29.8945 109.38 38.7678 109.31 39.1903C109.239 39.6127 111.563 45.8099 111.774 46.0213C111.986 46.2326 117.549 51.3733 117.549 51.3733L120.225 52.6409L122.975 53.4845C130.695 51.8969 136.5 45.064 136.5 36.8748C136.5 29.1789 131.372 22.6845 124.347 20.6118Z" fill="#86E8D0"/>
                                    <path d="M112.195 36.8748C112.195 29.1789 117.323 22.6845 124.347 20.6118C122.823 20.1623 121.212 19.917 119.542 19.917C110.177 19.917 102.584 27.5093 102.584 36.8748C102.584 46.2403 110.177 53.8325 119.542 53.8325C121.212 53.8325 122.823 53.5873 124.347 53.1377C117.323 51.0654 112.195 44.5707 112.195 36.8748Z" fill="#5DE1C4"/>
                                    <path d="M127.04 34.7618H121.655V29.377C121.655 28.21 120.709 27.2644 119.542 27.2644C118.375 27.2644 117.43 28.2102 117.43 29.377V34.7618H112.044C110.878 34.7618 109.932 35.7076 109.932 36.8744C109.932 38.0411 110.878 38.9869 112.044 38.9869H117.43V44.372C117.43 45.5391 118.375 46.4846 119.542 46.4846C120.709 46.4846 121.655 45.5388 121.655 44.372V38.9869H127.04C128.207 38.9869 129.152 38.0411 129.152 36.8744C129.152 35.7076 128.207 34.7618 127.04 34.7618Z" fill="#DBEDFF"/>
                                    <path d="M86.1951 41.2883H82.7744L77.479 48.0636L76.0002 59.1903L78.958 65.8099L85.4079 69.0972C91.0155 66.8832 94.9853 61.4193 94.9853 55.0245C94.9853 48.9334 91.3836 43.6864 86.1951 41.2883Z" fill="#FFCEBE"/>
                                    <path d="M77.4049 55.0247C77.4049 48.9336 81.0066 43.6866 86.1951 41.2886C84.2668 40.3974 82.1209 39.8965 79.8571 39.8965C71.5019 39.8965 64.7289 46.6695 64.7289 55.0247C64.7289 63.3798 71.5019 70.1529 79.8571 70.1529C82.1209 70.1529 84.2668 69.6522 86.1951 68.7608C81.0066 66.3628 77.4049 61.1158 77.4049 55.0247Z" fill="#FFB1A0"/>
                                    <path d="M89.6157 70.1526H78.2164L72.5494 73.1336L67.6199 78.697V93.1336C67.6199 93.1336 69.5238 94.7699 70.9148 95.9733H98.553C101.68 95.9733 104.214 93.4385 104.214 90.312V84.7511C104.214 76.7219 97.6449 70.1526 89.6157 70.1526Z" fill="#8B94AA"/>
                                    <path d="M68.1761 90.3123V84.7511C68.1761 76.7222 74.7455 70.1528 82.7744 70.1528H70.0984C62.0695 70.1528 55.5001 76.7222 55.5001 84.7511V90.3123C55.5001 93.4388 58.0349 95.9735 61.1614 95.9735H73.8374C70.7109 95.9735 68.1761 93.4388 68.1761 90.3123Z" fill="#81899B"/>
                            </svg>
                        </div> }
                    {!blockedMembersLoader && <div className="row g-5 mt-5">
                        <MemberCards searchData={searchData}/>
                    </div>}
                </div>
            </div>
        </>
    )
}


export {Settings}
