import DynamicObject from "../../models/dynamic-object";
import { AxiosResponse} from "axios";
import Api from "../api";

export default (url: string,config?: DynamicObject): Promise<AxiosResponse> => {
    let newUrl = url.replace(/%2C/g,",")
    const fullUrl = newUrl.replace('http:',"https:")
    const path = 'car-fax';
    const apiUrl : string = (url == '') ? `${path}?page=1&per-page=30` : fullUrl
    return Api.getInstance().request.get(apiUrl, path)
}