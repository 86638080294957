import { CategoryInterface } from "../../../interfaces/CategoryInterface";

interface Iprops {
    searchData : CategoryInterface[],
    lang : string
    action :  (category: CategoryInterface) => void
}
const BundleCategoryComponent = ( {searchData, lang,action} : Iprops) => {
    return <>
    { searchData && searchData.map((cat,index) => {
        return <div key={index}  className='col-12 shadow-sm trHoverAction hoverPointer radius-8 my-1 fw-bolder' onClick={() => {action(cat)}}>
            <div className="d-flex  py-1 ">
                <div className="" >
                    <div className="symbol symbol-45px ">
                        <img src={cat.verticalAddPostIconPath.replace("{platform}","android").replace("{size}","xxh")} className="h-50 align-self-center" alt=""/>
                    </div>
                </div>
                <div style={{display:"flex"}} className="p-2 fs-5 align-self-center fw-bolder">{lang === 'ar' ? cat.label_ar : cat.label_en}</div>
                <div style={{display:"flex"}} className="align-self-center ms-auto p-2 ">{lang === 'ar' ? <><i className="fas fa-arrow-left"></i></> : <><i className="fas fa-arrow-right"></i></>}</div>
            </div>      
        </div>
        })}
    </>
}

export {BundleCategoryComponent};