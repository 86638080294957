import {Modal} from 'react-bootstrap'
import { KTSVG } from '../helpers'

type Props = {
  show: boolean
  handleClose: () => void
  children: any
}

const CustomModal = ({show,handleClose,children}: Props) => {
  return <>
        <Modal id='iframe'tabIndex={-1} aria-hidden='true' dialogClassName='modal-dialog modal-dialog-centered' show={show} onHide={handleClose}  >
            <div className='modal-header p-5'>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>
            <div className='modal-body ' >
               {children}
            </div>
        </Modal>
    </>
}

export {CustomModal}