import { FC, Suspense, useEffect, useState} from 'react'
import {Route, Routes, Navigate, useLocation} from 'react-router-dom'
import {MasterLayout} from '../../app/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../assets/ts/_utils'
import {WithChildren} from '../../app/helpers'
import ListingRoutes from './ListingRoutes'
import AccountRoutes from './AccountRoutes'
import SearchesRoutes from './SearchesRoutes'
import { InviteFriends } from '../modules/InviteFriends'
import { Product } from '../modules/products/Product'
import ServicesRoutes from './ServicesRoutes'
import { Notification } from '../modules/Notification'
import {  PageTitle } from '../../app/layout/core'
import { useLang } from '../../app/i18n/OpenSooqi18n'
import { useIntl } from 'react-intl'
import { MyOpenSooq } from '../modules/my-opensooq/MyOpenSooq'
import ChatScreen from '../modules/ChatScreen'
import ChatOpenPage from "../modules/ChatOpenPage";

const history:string[] = [];

const PrivateRoutes = () => {
  const lang = useLang()
  const Intl = useIntl()
  const location = useLocation()
  useEffect(()=>{
    if(history.length == 2)
      history.shift();
    history.push(location.pathname)
  }, [location]);

  return (
    <Routes>
      <Route path="chats/open" element={<ChatOpenPage />} />
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<MyOpenSooq />} />
        {/* Pages */}
        <Route path='index.html' element={<MyOpenSooq />} />
        {/* Lazy Modules */}
         <Route
          path={`/${lang}/`}
          element={
            <SuspensedView>
              <MyOpenSooq />
            </SuspensedView>
          }
        />
        <Route path='/' element={ <> <PageTitle breadcrumbs={[]}>{Intl.formatMessage({id : "myOpenSooq.title"})}</PageTitle> <SuspensedView><MyOpenSooq/></SuspensedView></> } />
        <Route path='notifications' element={ <> <PageTitle breadcrumbs={[]}>{Intl.formatMessage({id : "notifications"})}</PageTitle> <SuspensedView><Notification/></SuspensedView></> } />
        <Route path='chats' element={ <> <PageTitle breadcrumbs={[]}>{Intl.formatMessage({id : "chatsTitle"})}</PageTitle> <SuspensedView><ChatScreen/></SuspensedView></>}/>

        <Route
          path='products'
          element={
            <SuspensedView>
              <Product />
            </SuspensedView>
          }
        />
        <Route
          path='invite-friends'
          element={
            <SuspensedView>
              <InviteFriends />
            </SuspensedView>
          }
        />
       
        <Route
          path='searches/*'
          element={
            <SuspensedView>
              <SearchesRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='listings/*'
          element={
            <SuspensedView>
              <ListingRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='services/*'
          element={
            <SuspensedView>
              <ServicesRoutes />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' state= {{ 'currentPath': window.location.pathname, 'previousPath' : history.length < 1 ? '' : history[history.length - 1] }}/>} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
