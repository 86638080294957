import { useChatFunctions } from "opensooq-chat"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import deleteForeverApi from "../../../../api/listing/deleteForever.api"
import getDeleteReasons from "../../../../api/listing/getDeleteReasons"
import repostListingApi from "../../../../api/listing/repost.listing.api"
import getShortUrlApi from "../../../../api/site/getShortUrl.api"
import DynamicObject from "../../../../models/dynamic-object"
import { ACTIVATE, BUMPUP, BUMPUP_FREE, COPY, DELETE, DELETE_FOR_EVER, EDIT, LISTING_STATISTICS, PROMOTE, SHARE, VIEW_CHATS, VIEW_ON_OPENSOOQ } from "../../../helper/Ctas"
import { NO_IMAGE } from "../../../helper/ImagePaths"
import { TWITTER_SHARE_URL } from "../../../helper/ShareLinks"
import { showError, showSuccess } from "../../../helper/SystemAlert"
import { toAbsoluteUrl } from "../../../helpers"
import {editListing, generateOpenSooqRedirectUrl, getCookies, isDeviceMobile,showChatInCountry } from "../../../helpers/commonFunctions"
import { useLang } from "../../../i18n/OpenSooqi18n"
import { DeactivateListModal } from "../../../layout/components/modals/DeactivateListModal"
import { IframeModal } from "../../../layout/components/modals/IframeModal"
import { CustomModal } from "../../../modales/CustomModal"
import OpenSooqIcons from "../../common/OpenSooqIcons"
import { ShareListings } from "../../common/ShareListing"
import { BundleModal } from "../../payment/BundleModal"
import {getWidgetByType, JOB_POSTER_TYPE, JOB_SEEKER_TYPE, ListingWidgetTypes } from "./helper"
import MobileListingCell from "./MobileListingCell"
import { isMobile } from "react-device-detect"

type ListingProps = {
    searchData : DynamicObject[]
    isCheck :DynamicObject
    deleteListingFromSearchData : (id? : number)=>void
    changeListingData :  (listingId: number,checked : boolean) => any
    sellerObject : DynamicObject
}

const ListingList = ({searchData,isCheck,changeListingData, deleteListingFromSearchData,sellerObject} : ListingProps) => {
    const [listingIntegrationActionType, setListingIntegrationActionType] = useState('')
    const [pickedListing,setPickedListing] = useState <DynamicObject>()
    const [showEditListingModal, setShowEditListingModal] = useState(false)
    const [showDeactivateModal, setShowDeactivateModal] = useState(false)
    const [showCustomModal,setShowCustomModal] = useState<boolean>(false)
    const [pickedListingId,setPickedListingId] = useState<number>(0)
    const [reasons,setReasons] = useState <DynamicObject>({})
    const [showBundle,setShowBundle] = useState (false);
    const [type,setType] = useState<string[]> ([]);
    const [hoveredId,setHoveredId] = useState(0);
    const Intl = useIntl();
    const lang = useLang();
    const {getUnreadPostRooms} = useChatFunctions()
    const [listingUrl, setListingUrl] = useState('')
    const cookies = getCookies()

    const takeAction = (cta : string,listing : DynamicObject) => {
        setPickedListingId(listing.listing_id);
        setPickedListing(listing);
        switch (cta) {
            case PROMOTE:
                setShowBundle(true)
                setType(['Turbo','Premium','Bumpup'])
                break;
            case BUMPUP:
                setShowBundle(true)
                setType(['Bumpup'])
                break;
            case BUMPUP_FREE:
                repost(listing.listing_id);
                break;
            case VIEW_ON_OPENSOOQ:
                let link =  generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',getCookies().ecountry ?? '',lang) + '/search/' + listing?.listing_id + '/' + listing?.title.replace(/\s+/g, '-');
                if(listing?.listing_type == JOB_POSTER_TYPE || listing?.listing_type == JOB_SEEKER_TYPE){
                    link =  generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',getCookies().ecountry ?? '',lang) + '/job-posters/' + listing?.listing_id;
                }
                window.open(link, "_blank");
                break;
            case LISTING_STATISTICS:
                window.open(`/listings/my-listings/listing-stats?id=${listing?.listing_id}`,'_blank')
                break;
            case EDIT:
                if(listing && listing.listing_id){
                    editListing(listing,'edit');
                }else{
                    showError(Intl,lang)
                }
                break;
            case COPY:
                if(listing && listing.listing_id){
                    editListing(listing,'republish');
                }else{
                    showError(Intl,lang)
                }
                break;
            case DELETE:
                getReasons(listing.listing_id,listing.service_name)
                break;
            case SHARE:
                const shareLink =  generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',cookies.ecountry ?? 'jo',lang) + '/search/' + listing?.listing_id + '/' + listing?.title.replace(/\s+/g, '-');
                setListingUrl(shareLink)
                if(isMobile){
                    handleSharing(listing)
                }else{
                    setShowCustomModal(true);
                }
                break;
            case DELETE_FOR_EVER:
                deleteForeverHandler(listing.listing_id, ()=>{deleteListingFromSearchData(listing.listing_id)})
                break;
            case ACTIVATE:
                setListingIntegrationActionType('activate')
                setShowEditListingModal(true)
                break;
            case VIEW_CHATS:
                viewChat(listing.listing_id);
                break;
            default:
                break;
        }
    }

  /*  const deleteListingApiAction= () => {
        const text = Intl.formatMessage({id : "deleteListingPopup"})
        Swal.fire({
            icon: "warning",
            html:
                `<div class="pe-10 ps-10 fw-bolder">${text}</div>`,
            buttonsStyling: false,
            showCancelButton: true,
            cancelButtonText : Intl.formatMessage({id : 'no'}),
            showLoaderOnConfirm: true,
            confirmButtonText: Intl.formatMessage({id : 'yes'}),
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-light-danger"
            },

        })
        }*/

    const getReasons = (listingId : number,service_name:string[]) => {
        getDeleteReasons(listingId).then((response) => {
            setReasons(response.data);
            if(service_name.length) {
                const text = Intl.formatMessage({id : "deleteListingPopup"})

                Swal.fire({
                    icon: "warning",
                    html:
                        `<div class="pe-10 ps-10 fw-bolder">${text}</div>`,
                    buttonsStyling: false,
                    showCancelButton: true,
                    cancelButtonText : Intl.formatMessage({id : 'no'}),
                    showLoaderOnConfirm: true,
                    confirmButtonText: Intl.formatMessage({id : 'yes'}),
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-light-danger"
                    },
                    allowOutsideClick: () => !Swal.isLoading()
                }).then((result) => {
                    if (result.isConfirmed) {
                        setShowDeactivateModal(true);
                    }
                })
            }
            else setShowDeactivateModal(true)
        });

    }

    const repost = (listingId : number) => {
        repostListingApi(listingId).then((response) => {
            showSuccess(Intl,lang);
            setTimeout(() => {
                window.location.reload();
            }, 3000);
            setShowDeactivateModal(false);
        }).catch(() => {
            showError(Intl,lang);
        })
    }

    const deleteForeverHandler = (listingId : number, deleteListing: ()=> void) => {
        const text = Intl.formatMessage({id : "deleteForever",})
        Swal.fire({
            icon: "warning",
            html:
            `<div class="pe-10 ps-10 fw-bolder">${text}</div>`,
            buttonsStyling: false,
            showCancelButton: true,
            cancelButtonText : Intl.formatMessage({id : 'no'}),
            showLoaderOnConfirm: true,
            confirmButtonText: Intl.formatMessage({id : 'yes'}),
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-light-danger"
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                deleteForeverApi(listingId).then((response) => {
                    showSuccess(Intl,lang)
                    setTimeout(() => {
                        deleteListing()
                }, 2000);
                }).catch((error) => {
                    showError(Intl,lang);
                })
            }
        })
    }

    const handleSharing = (listing : DynamicObject) => {
        if (navigator.share) {
            getShortUrlApi('post',listing.listing_id ?? 0).then((response) => {
                const shortestUrl = response.data.shortUrl;
                const downloadApp   = TWITTER_SHARE_URL;
                const text = (`${Intl.formatMessage({id : "checkMyListing"})} \n ${shortestUrl} \n\n ${Intl.formatMessage({id : 'downloadApp'})} ${downloadApp}`)
                navigator.share({
                    title: listing.title,
                    text: text,
                    url: shortestUrl,
                })
                .then(() => {
                    console.log("Successfully shared");
                })
                .catch((error) => {
                    console.error("Something went wrong", error);
                });
            }).catch((err) => {
                showError(Intl,lang)
            })
        }else{
            setShowCustomModal(true);
        }
    };

    const addHover = (id : number) => {
        if(!isMobile){
            const collection = document.getElementsByClassName(`postTr_${id}`) as HTMLCollectionOf<HTMLElement>
            const myElements = Array.from(collection);
            if(id != hoveredId){
                const oldTr= document.getElementsByClassName(`postTr_${hoveredId}`) as HTMLCollectionOf<HTMLElement>
                const oldElementsTr = Array.from(oldTr);
                oldElementsTr.forEach((oldElement) => {
                    oldElement.style.backgroundColor = 'white';
                });
            }
            myElements.forEach((element) => {
                element.style.backgroundColor = '#f5f8fa';
            });
    
            setHoveredId(id);
        }
        
    }

    const deleteHover = (id : number) => {
        if(!isMobile){
            const collection = document.getElementsByClassName(`postTr_${id}`) as HTMLCollectionOf<HTMLElement>
            const myElements = Array.from(collection);
            myElements.forEach((element) => {
                element.style.backgroundColor = 'white';
            });
            setHoveredId(0);
        }
    }

    const redirectToPost = (e: any,url : string) => {
        if(!e.target.classList.contains('action')){
            window.open(url, "_blank");
        }
    }

    const viewChat = (listingId : number) => {
        window.open(`/listings/chats?id=${listingId}`,'_blank');
    }

    const [count,setCount] = useState<DynamicObject> ({})

    useEffect (() => {
        searchData.map((listing) => {
            getUnreadChat(listing.listing_id);
        })
    },[searchData]);

    const getUnreadChat = (listingId : number) => {
        getUnreadPostRooms(listingId).then((count : number) => {
            setCount((currentCount) => {
                return {
                    ...currentCount,
                    [listingId] : count
                }
            })
        })
    }

    return <>{searchData.map((listing: DynamicObject,index) => {
            const widgets = {
                STATUS : getWidgetByType(listing.widgets,ListingWidgetTypes.STATUS),
                PQS : getWidgetByType(listing.widgets,ListingWidgetTypes.PQS),
                INFO : getWidgetByType(listing.widgets,ListingWidgetTypes.INFO),
                VIEWS : getWidgetByType(listing.widgets,ListingWidgetTypes.VIEWS),
            }

            return <React.Fragment key={index}>
                <tr className={`postTr_${listing.listing_id} ${(index%2 === 0) ? "even": "odd"} ${!isMobile ? 'hoverAction trHoverAction trActionChild' : ''} border-bottom-0 even`} data-id={listing.listing_id} onMouseEnter={() => addHover(listing.listing_id)} onMouseLeave={() => deleteHover(listing.listing_id)} onClick={(e) => {redirectToPost(e,`/listings/my-listings/listing-view?id=${listing.listing_id}`)}}>
                    <td className="hideOnMobile">
                        <div className="d-flex align-items-start">
                            <div className={`sc-265a2526-1 jRUVEn postImgCont  position-relative radius-8 overflowHidden marginEnd-16px ixKSmm`} >
                                <img src={`${listing.main_image_uri.indexOf('noImage/no-image') !== -1 ? NO_IMAGE.replace('{lang}',lang): (listing.main_image_uri.indexOf('{platform}') !== -1) ? listing.main_image_uri.replace("{platform}","android").replace("{size}","xh") : listing.main_image_uri}`} width="272" height="204"  className="width-100  customImg height-100 "  alt={listing.title} loading="eager"/>
                            </div>
                            <div className="d-flex justify-content-start flex-column " style={{overflowWrap : "anywhere"}}>
                                <span className="customPostTitleText fw-bolder ">{listing.title}</span>
                                <span className="fw-semibold d-block fs-7 ">{listing.sub_title}</span>
                                <span className="fw-semibold d-block fs-7 ">
                                    {listing.category} {Intl.formatMessage({id : "in"})} {listing.city}
                                </span>
                                <span className="fw-semibold d-block fs-7 ">
                                    {(listing.has_price && listing.has_price == 0) ? Intl.formatMessage({id : "noPrice"}): (listing.price) ? <span className="" dir="rtl">{listing.price.toLocaleString() + ' ' + listing.price_currency }</span> : ''}
                                </span>
                            </div>
                        </div>
                    </td>
                    <td className="text-start pe-0 hideOnMobile">
                        <div className="d-flex justify-content-start flex-column " style={{overflowWrap : "anywhere"}}>
                            <span className={`customPostTitleText fw-bolder ${(widgets.STATUS && widgets.STATUS.color == 'GREEN') ? 'text-success' : 'text-danger'} `}>
                                {widgets.STATUS.text}
                            </span>
                            <div className="d-flex flex-column bd-highlight mb-3">
                                {listing.service_name.map((service : any,index : number) => {
                                    return <div key={index} className="d-flex justify-content-start">
                                        <span><OpenSooqIcons className="me-1 fw-semibold d-block fs-7" width={20} height={20} icon={service.icon.toLowerCase()} /></span>
                                        <span>{service.label}</span>
                                    </div>
                                })}
                            </div>
                        </div>
                    </td>
                    <td className="text-start pe-0 hideOnMobile">
                        <div className="d-flex justify-content-start flex-column " style={{overflowWrap : "anywhere"}}>
                            {widgets.PQS && <div className="d-flex justify-content-between w-100 mt-auto ">
                                <span className="customPostTitleText fw-bolder">{widgets.PQS.text}</span>
                            </div>}
                            {listing.ctas && listing.ctas.toString().includes('edit') && <span className="d-block fw-semibold d-block fs-7 action"><a href="share">{Intl.formatMessage({id:"myListings.text22"})}</a></span>}
                            <span className="customPostTitleText fw-bolder">{!widgets.PQS && Intl.formatMessage({id : "common.noScore"})} </span>
                        </div>
                    </td>
                    <td className="pe-0 text-start hideOnMobile">
                        <div className=" d-flex justify-content-start flex-column" style={{overflowWrap : "anywhere"}}>
                            <span className="customPostTitleText fw-bolder">
                                {(widgets.VIEWS) ? widgets.VIEWS.text : 0}
                            </span>
                            {listing.ctas && listing.ctas.toString().includes('promote') && <span className="d-block fw-semibold d-block fs-7 action"><a href="share">{Intl.formatMessage({id:"myListings.text23"})}</a></span>}
                        </div>
                    </td>
                    <td className="text-start pe-0 hideOnMobile">
                        <div className="d-flex justify-content-start flex-column" style={{overflowWrap : "anywhere"}}>
                            <span className="fw-semibold d-block fs-7 customPostTitleText fw-bolder">{Intl.formatMessage({id : "myListings.text42"})}: {listing.date_of_expiry}</span>
                            <span className="fw-semibold d-block fs-7 customPostTitleText">{Intl.formatMessage({id : "myListings.text39"})}: {listing.date_created}</span>
                        </div>
                    </td>
                    <td className="text-start pe-0 hideOnMobile">
                        <div className="d-flex justify-content-start flex-column" style={{overflowWrap : "anywhere"}}>
                            <span className="customPostTitleText fw-bolder">{listing.listing_id}</span>
                            {listing.edits_left && <div className="d-flex fw-semibold d-block fs-7 justify-content-start flex-column" style={{overflowWrap : "anywhere"}}>
                                <span className="">{Intl.formatMessage({id : "myListings.text43"})} <span className="fw-bold">{(listing.edits_left == -1) ? Intl.formatMessage({id : "myOpenSooq.unlimited"}): listing.edits_left} </span></span>
                            </div>}
                        </div>
                    </td>
                </tr>
                <tr id={`id-${listing.listing_id}`} data-id={listing.listing_id} className={`postTr_${listing.listing_id} ${(index%2 === 0) ? "even": "odd"}  collapse  show border-top-0 ${!isMobile ? 'hoverAction trHoverAction trActionChild' : ''} `}   onMouseEnter={() => addHover(listing.listing_id)} onMouseLeave={() => deleteHover(listing.listing_id)} onClick={(e) => {redirectToPost(e,`/listings/my-listings/listing-view?id=${listing.listing_id}`)}}>
                    {isDeviceMobile() && <td colSpan={10} className="shadow1 border-top-0 d-block hideOnDesktop">
                        <MobileListingCell count={count} listing={listing} action={takeAction} sellerObject={sellerObject}/>
                    </td>}
                    <td colSpan={10} className="shadow1 border-top-0 p-1 hideOnMobile">
                        <div className="sc-265a2526-2 iRqeKu numOfCont flex bigContainer alignItems radius-8 whiteColor fs-13 " >
                            <span className="flex alignItems radius-8 bigContainer me-1">
                                <svg viewBox="0 0 12 12" width="12" height="12" className="me-2 bigContainer" data-name="iconCamera2">
                                    <path fill="#fff" d="M12 10.6667V1.33333C12 0.6 11.4 0 10.6667 0H1.33333C0.6 0 0 0.6 0 1.33333V10.6667C0 11.4 0.6 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667ZM3.66667 7L5.33333 9.00667L7.66667 6L10.6667 10H1.33333L3.66667 7Z"></path>
                                </svg>{listing.medias_count.images}
                            </span>
                            <span className="flex alignItems me-1 radius-8 bigContainer">
                                <OpenSooqIcons className="me-1 fw-semibold d-block fs-7 text-white" width={20} height={15} icon={'video'} /> {listing.medias_count.videos}
                            </span>
                            <span className="flex alignItems radius-8 bigContainer">
                                <OpenSooqIcons className="me-1 fw-semibold d-block fs-7 text-white" width={20} height={15} icon={'360'} /> {listing.medias_count[360]}
                            </span>
                        </div>
                        {listing.job_applicant_count > 0 && <div className="border border-dashed border-gray-300  grayHoverBg w-25 rounded my-3 p-2 me-2"  >
                            <div className="d-flex align-items-center hoverPointer">
                                <div className="form-check form-check-custom form-check-solid">{Intl.formatMessage({id : "common.numberOfApplicants"})}</div>
                                <div className="flex-grow-1">
                                    <a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6"></a>
                                </div>
                                <span className="badge badge-light fs-12 fw-bold">{listing.job_applicant_count}</span>
                            </div>
                        </div>}
                        {listing.widgets && listing.widgets.map((widget : DynamicObject,index : number) => {
                            return (widget.type == ListingWidgetTypes.INFO ) ? <div key={index} className=" notice d-flex bg-light-warning rounded border-warning border border-dashed p-2  mt-3">
                                    <div className=" d-flex flex-stack flex-grow-1">
                                    <div className=" fw-semibold">
                                        <div className=" fs-6 text-gray-900 ltrDir">
                                            <span className="ltrDir">{widget.title} {widget.subTitle}</span>
                                        </div>
                                    </div>
                                </div>
                            </div> : ''
                        })}
                        <div className="d-flex justify-content-between mb-2  mt-2">
                            <div className="text-start">
                                {listing && listing.ctas.map((cta : DynamicObject,index : number) => {
                                    const label = (cta.cta == VIEW_CHATS)  ? `${cta.label + ' ' + '(' + count[listing.listing_id] ?? 0})` : (cta.label);
                                    return (cta.cta == VIEW_CHATS && showChatInCountry()) ? <button disabled={cta.cta.enabled} onClick={(e) => {takeAction(cta.cta,listing)}} style={{backgroundColor:"#eff2f5"}} key={index} className={"btn btn-sm border-custom-dark  btn-light  me-3 mt-1 action ltrDir"}>{label}</button> : (cta.cta != VIEW_CHATS) ? <button disabled={cta.cta.enabled} onClick={(e) => {takeAction(cta.cta,listing)}} style={{backgroundColor:"#eff2f5"}} key={index} className={"btn btn-sm border-custom-dark  btn-light  me-3 mt-1 action ltrDir"}>{label}</button> : ''
                                })}
                            </div>
                        </div>
                    </td>
                </tr>
            </React.Fragment>
        })}
        <BundleModal  show={showBundle}  type={type} handleClose={() => {setShowBundle(false)}} id={pickedListingId} pos={'myListings'}/>
        <IframeModal show={showEditListingModal} id={pickedListingId} type={listingIntegrationActionType} handleClose={() => {setShowEditListingModal(false)}} />
        {pickedListing && <CustomModal show={showCustomModal} handleClose={() => {setShowCustomModal(false)}} children={<><ShareListings opened={showCustomModal} comeFrom={'myListings'} listingUrl={listingUrl} listing={pickedListing}/></>}/>}
        <DeactivateListModal  reasons={reasons}  show={showDeactivateModal}  id={pickedListingId}  type="edit" handleClose={() => {setShowDeactivateModal(false)}} deleteListingFromSearchData = {deleteListingFromSearchData}/>

    </>
}

export {ListingList}