import { useIntl } from "react-intl"
import Skeleton from "react-loading-skeleton";
import { SellerInterface } from "../../interfaces/SellerInterface";

type Props = {
    seller ?: SellerInterface
    navigateTo : (param : any) => void
}

const Seller = (props : Props) => {
    const Intl = useIntl();

return  <div className="card h-100 card-xl-stretch mb-5 mb-xl-8">
        <div className="card-header border-0">
            <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-dark">{Intl.formatMessage({id : "myOpenSooq.seller"})}</span>
                <span className="text-muted mt-1 fw-semibold fs-7">{Intl.formatMessage({id : "myOpenSooq.sellerDescription"})} </span>
            </h3>
        </div>
        <div className="card-body pt-2">
            <div className="border border-dashed border-gray-300  grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer"  onClick={() => {props.navigateTo('/listings/my-listings?status=promoted')}}>
                <div className="d-flex align-items-center hoverPointer">
                    <span className="bullet bullet-vertical h-30px bg-success"></span>
                    <div className="form-check form-check-custom form-check-solid mx-5"></div>
                    <div className="flex-grow-1">
                        <a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6">{Intl.formatMessage({id : "status.promoted"})}</a>
                    </div>
                    {!props.seller && <Skeleton className="w-30px" />} {props.seller && <span className="badge badge-light-success fs-12 fw-bold text-dark">{props.seller.listing_by_status.Live_Promoted.toLocaleString()}</span>}
                </div>
            </div>
            <div className="border border-dashed border-gray-300  grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer" onClick={() => {props.navigateTo('/listings/my-listings?status=regular')}}>
                <div className="d-flex align-items-center  hoverPointer" >
                    <span className="bullet bullet-vertical h-30px bg-success"></span>
                    <div className="form-check form-check-custom form-check-solid mx-5"></div>
                    <div className="flex-grow-1">
                        <a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6">{Intl.formatMessage({id : "status.regular"})}</a>
                    </div>
                    {!props.seller && <Skeleton className="w-30px" />} {props.seller && <span className="badge badge-light-success fs-12 fw-bold text-dark"><span style={{color :"black"}}>{props.seller.listing_by_status.Live_Regular.toLocaleString()}</span></span>}
                </div>
            </div>
            <div className="border border-dashed border-gray-300  grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer" onClick={() => {props.navigateTo('/listings/my-listings?status=pending_payment')}}>
                <div className="d-flex align-items-center  hoverPointer" >
                    <span className="bullet bullet-vertical h-30px bg-warning"></span>
                    <div className="form-check form-check-custom form-check-solid mx-5"></div>
                        <div className="flex-grow-1"><a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6">{Intl.formatMessage({id : "status.pendingPayment"})}</a>
                    </div>
                    {!props.seller && <Skeleton className="w-30px" />} {props.seller && <span className="badge badge-light-warning fs-12 fw-bold text-dark">{props.seller.listing_by_status.Pending_Payment.toLocaleString()}</span>}
                </div>
            </div>
            <div className="border border-dashed border-gray-300  grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer" onClick={() => {props.navigateTo('/listings/my-listings?status=pending_review')}}>
                <div className="d-flex align-items-center  hoverPointer" >
                    <span className="bullet bullet-vertical h-30px bg-warning"></span>
                    <div className="form-check form-check-custom form-check-solid mx-5"></div>
                        <div className="flex-grow-1"><a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6">{Intl.formatMessage({id : "status.pendingReview"})}</a>
                    </div>
                    {!props.seller && <Skeleton className="w-30px" />} {props.seller && <span className="badge badge-light-warning fs-12 fw-bold text-dark">{props.seller.listing_by_status.Pending_Review.toLocaleString()}</span>}
                </div>
            </div>
            <div className="border border-dashed border-gray-300  grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer" onClick={() => {props.navigateTo('/listings/my-listings?status=rejected')}}>
                <div className="d-flex align-items-center  hoverPointer">
                    <span className="bullet bullet-vertical h-30px bg-danger"></span>
                    <div className="form-check form-check-custom form-check-solid mx-5"></div>
                    <div className="flex-grow-1">
                        <a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6">{Intl.formatMessage({id : "status.rejected"})}</a>
                    </div>
                    {!props.seller && <Skeleton className="w-30px" />} {props.seller && <span className="badge badge-light-danger fs-12 fw-bold text-dark">{props.seller.listing_by_status.Rejected.toLocaleString()}</span>}
                </div>
            </div>
            <div className="border border-dashed border-gray-300  grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer"  onClick={() => {props.navigateTo('/listings/my-listings?status=deleted,expired')}}>
                <div className="d-flex align-items-center  hoverPointer">
                    <span className="bullet bullet-vertical h-30px bg-danger"></span>
                    <div className="form-check form-check-custom form-check-solid mx-5"></div>
                    <div className="flex-grow-1">
                        <a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6">{Intl.formatMessage({id : "status.deletedOrExpired"})}</a>
                    </div>
                    {!props.seller && <Skeleton className="w-30px" />} {props.seller && <span className="badge badge-light-danger fs-12 fw-bold text-dark">{props.seller.listing_by_status.Deleted_Expired_Sold.toLocaleString()}</span>}
                </div>
            </div>
            <div className="border border-dashed border-gray-300  grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer" onClick={() => {props.navigateTo('/listings/my-listings/draft-listings')}}>
                <div className="d-flex align-items-center  hoverPointer">
                    <span className="bullet bullet-vertical h-30px bg-primary">  </span>
                    <div className="form-check form-check-custom form-check-solid mx-5"></div>
                    <div className="flex-grow-1">
                        <a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6">{Intl.formatMessage({id : "common.drafts"})}</a>
                    </div>
                    {!props.seller && <Skeleton className="w-30px" />} {props.seller && <span className="badge badge-light-primary fs-12 fw-bold text-dark">{props.seller.listing_by_status.Drafts.toLocaleString()}</span>}
                </div>
            </div>
        </div>
    </div>
}

export default Seller