import {useState, useRef} from 'react'
import {Modal} from 'react-bootstrap'
import { useIntl } from "react-intl"
import { Button } from '../opensooq/Button'


type Props = {
    show: boolean
    handleClose     : () => void
    handleAction    : () => void
    setRechargeCode : (e : any) => void
    error           : boolean
    isDisabled      : boolean
}

const RechargeModal = ({show,handleClose,handleAction,setRechargeCode,error,isDisabled} : Props) => {
  const Intl = useIntl()
  return <>
        <Modal id='kt_modal_create_app' tabIndex={-1} aria-hidden='true' dialogClassName='modal-dialog modal-dialog-centered ' show={show} >
            <div className="modal-body scroll-y px-10 px-lg-15 pt-15 pb-15">
                <div className="mb-10 text-center">
                    <h1 className="mb-3">{Intl.formatMessage({id: 'myWallet.text10'})}</h1>
                    <div className="text-muted fw-semibold fs-5">{Intl.formatMessage({id: 'myWallet.text11'})}</div>
                </div>
                <div className="d-flex flex-column  fv-row fv-plugins-icon-container">
                    <input type="text" className="form-control form-control-solid" dir='ltr' onKeyUp={setRechargeCode} placeholder="xxxxxxxxxxxxxxxx" name="bid_amount"/>
                    <div className="fv-plugins-message-container invalid-feedback"></div>
                </div>
                {error && <div className="d-flex flex-column  text-danger fv-row fv-plugins-icon-container">
                    {Intl.formatMessage({id: 'myWallet.text13'})}
                </div>}
                <div className="text-center mt-10">
                    <Button disabled={isDisabled} className={'btn-primary me-3'} action={handleAction} text={<><span className="indicator-label">{Intl.formatMessage({id : 'apply'})} {isDisabled && <span className="spinner-border spinner-border-sm align-middle ms-2"></span>}</span></>}/>
                    <Button className={'btn btn-light '} action={handleClose} text={Intl.formatMessage({id : 'cancel'})}/>
                </div>
            </div>
        </Modal>
    </>
}

export {RechargeModal}