type IContactUs = {
    [code : string] : {
        "countryCode" : string,
        "whatsapp": string,
        "callCenterNumber": string,
        'internationalWhatsNumber' : string,
        "sales": boolean
    }
}

const contactUs:IContactUs = {
    "jo": {
        "countryCode" : "jo",
        "whatsapp": "962791000913",
        "callCenterNumber": "0790180000",
        'internationalWhatsNumber' : "+962 7 9100 0913",
        "sales": true
    },
    "om": {
        "countryCode" : "om",
        "whatsapp": "962791000913",
        "callCenterNumber": "94273548",
        'internationalWhatsNumber' : "+962 7 9100 0913",
        "sales": true
    },
    "kw": {
        "countryCode" : "kw",
        "whatsapp": "962791000913",
        "callCenterNumber": "+96522260016",
        'internationalWhatsNumber' : "",
        "sales": true
    },
    "iq": {
        "countryCode" : "iq",
        "whatsapp": "962791000913",
        'internationalWhatsNumber' : "",
        "callCenterNumber": "",
        "sales": true
    },
    "sa": {
        "countryCode" : "sa",
        "whatsapp": "962791000913",
        "sales": false,
        'internationalWhatsNumber' : "962 7 9100 0913",
        "callCenterNumber": ""
    },
    "ae": {
        "countryCode" : "ae",
        "whatsapp": "962791000913",
        'internationalWhatsNumber' : "",
        "sales": false,
        "callCenterNumber": "", 
     },
    "bh": {
        "countryCode" : "bh",
        "whatsapp": "962791000913",
        'internationalWhatsNumber' : "",
        "sales": false,
        "callCenterNumber": ""
    },
    "dz": {
        "countryCode" : "dz",
        "whatsapp": "962791000913",
        'internationalWhatsNumber' : "",
        "sales": false,
        "callCenterNumber": "",    
    },
    "eg": {
        "countryCode" : "eg",
        "whatsapp": "962791000913",
        'internationalWhatsNumber' : "",
        "sales": false,
        "callCenterNumber": "",
    },
    "lb": {
        "countryCode" : "lb",
        "whatsapp": "962791000913",
        'internationalWhatsNumber' : "",
        "sales": false,
        "callCenterNumber": "",
    },
    "ly": {
        "countryCode" : "ly",
        "whatsapp": "962791000913",
        'internationalWhatsNumber' : "",
        "sales": false,
        "callCenterNumber": "",
    },
    "ma": {
        "countryCode" : "ma",
        "whatsapp": "962791000913",
        'internationalWhatsNumber' : "",
        "sales": false,
        "callCenterNumber": "",
    },
    "mr": {
        "countryCode" : "mr",
        "whatsapp": "962791000913",
        'internationalWhatsNumber' : "",
        "sales": false,
        "callCenterNumber": "",
    },
    "ps": {
        "countryCode" : "ps",
        "whatsapp": "962791000913",
        'internationalWhatsNumber' : "",
        "sales": false,
        "callCenterNumber": "",
    },
    "qa": {
        "countryCode" : "qa",
        "whatsapp": "962791000913",
        'internationalWhatsNumber' : "",
        "sales": false,
        "callCenterNumber": "",
    },
    "sd": {
        "countryCode" : "sd",
        "whatsapp": "962791000913",
        'internationalWhatsNumber' : "",
        "sales": false,
        "callCenterNumber": "",
    },
    "sy": {
        "countryCode" : "sy",
        "whatsapp": "962791000913",
        'internationalWhatsNumber' : "",
        "sales": false,
        "callCenterNumber": "",
    },
    "tn": {
        "countryCode" : "tn",
        "whatsapp": "962791000913",
        'internationalWhatsNumber' : "",
        "sales": false,
        "callCenterNumber": "",
    },
    "ye": {
        "countryCode" : "ye",
        "whatsapp": "962791000913",
        'internationalWhatsNumber' : "",
        "sales": false,
        "callCenterNumber": "",
    },
    "tr": {
        "countryCode" : "tr",
        "whatsapp": "962791000913",
        'internationalWhatsNumber' : "",
        "sales": false,
        "callCenterNumber": "",
    },
    "default": {
        "countryCode" : "jo",
        "whatsapp": "962791000913",
        "callCenterNumber": "0790180000",
        'internationalWhatsNumber' : "+962 7 9100 0913",
        "sales": true
    },
}

type ContactUsKey = keyof typeof contactUs
type ContactUsValue = typeof contactUs[ContactUsKey]

export { contactUs }
export type { IContactUs, ContactUsKey, ContactUsValue }
