import Skeleton from "react-loading-skeleton"

const ListingQualityLoader = () => {
    return <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
        <div className="card-header">
            <div className="card-title align-items-start flex-column">
                <h2><Skeleton className="w-150px"/></h2>
            </div>
        </div>
        <div className="card-body pt-0">
            <Skeleton className="w-25"/>
            <Skeleton className="w-50"/>
            <Skeleton className="w-75"/>
            <Skeleton className="w-100"/>
        </div>
    </div>
        
}

export {ListingQualityLoader}