/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Languages} from './Languages'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { SHOP_MEMBER_TYPE } from '../../../../app/helper/MemberTypes'
import {  getCookies, logout } from '../../../helpers/commonFunctions'
import { useLang } from '../../../i18n/OpenSooqi18n'
import { SidebarMenu } from '../../../layout/components/sidebar/sidebar-menu/SidebarMenu'
import { toAbsoluteUrl } from '../../../helpers'
import { social } from '../../../helper/social'


const HeaderUserMenu: FC = () => {
  const cookiesData = getCookies()
  const member = cookiesData.userInfo
  const Intl = useIntl();
  const lang = useLang();
  const defaultProfileImage = "https://opensooqui-new.s3.amazonaws.com/api/v/icons/misc/profile_avatar.png";
  const defaultImage = (member && member.profile_picture) ? (member.profile_picture.includes('no_profile') ? defaultProfileImage : member.profile_picture) : defaultProfileImage
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-350px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={defaultImage} />
          </div>

          <div className=''>
            <div className='fw-bolder align-items-center fs-5'>
              {member?.full_name}
            </div>
            {member && member.phone_number && <span  className='fw-bold text-muted text-hover-primary fs-7'>
              {member.phone_number}
            </span>}
            <div >
              <div className="badge badge-light-success  fw-bold fs-8 px-2 py-1 ">{(member.member_type || 0) == SHOP_MEMBER_TYPE ? Intl.formatMessage({id : "shop"}) : Intl.formatMessage({id : "free"})}</div>
            </div>
          </div>
        </div>
      </div>
      <div className='separator'></div>
      <SidebarMenu height='100%' comingFrom='headMenu'/>
      <div className='separator my-2'></div>
      <div className="flex-column-fluid ps-7 pe-7 border-1">
        <strong className="d-block">
          {Intl.formatMessage({id : "downloadOpenSooq"})}
        </strong>
        <div className="flex mt-4">
          <a target="_blank" href={social.headerMenu.googlePlayStore}  aria-label="Download App" > 
            <img src={toAbsoluteUrl("/media/custom/googlePlay.png")}  className="w-100px  me-1" height="33px" alt="Google Play" loading="lazy" /> 
          </a>
          <a target="_blank" href={social.headerMenu.appleStore}  aria-label="Download App" > 
            <img src={toAbsoluteUrl("/media/custom/appStore.png")} className="w-100px  me-1" height="33px" alt="App Store" loading="lazy" /> 
          </a>
          <a target="_blank" href={social.headerMenu.huaweiStore}  aria-label="Download App" >
              <img src={toAbsoluteUrl("/media/custom/appGallery.png")}  className="w-100px " height="33px" alt="App Gallery" loading="lazy" /> 
          </a>
        </div>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
