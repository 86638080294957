import {Suspense, useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import Style from './assets/js/Style'
import {I18nProvider} from '../app/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../app/layout/core'
import {MasterInit} from '../app/layout/MasterInit'
import {NotificationProvider} from 'opensooq-notification'
import {useLang} from '../app/i18n/OpenSooqi18n'
import {ChatProvider} from 'opensooq-chat'
import {isMobile} from 'react-device-detect';
import {generateOpenSooqRedirectUrl, getCookies} from "./helpers/commonFunctions";
import {showError} from "./helper/SystemAlert";
import {useIntl} from "react-intl"
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "opensooq-chat/dist/esm/css"
const App = () => {
    console.log('v.1.0.74')
    useEffect(() => {
        if (isMobile) {
            document.body.classList.add("mobileSite")
        } else {
            document.body.classList.add("desktopSite")
        }
    }, [])
    
    if(process.env.REACT_APP_SENTRY_URL && process.env.REACT_APP_SENTRY_URL !== ''){
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_URL,
            integrations: [new BrowserTracing()],
            tracesSampleRate: 1.0,
        });
    }
    
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Style>
                <I18nProvider>
                    <LayoutProvider>
                        <Bundles>
                            <Outlet/>
                            <MasterInit/>
                        </Bundles>
                    </LayoutProvider>
                </I18nProvider>
            </Style>
        </Suspense>
    )
}

const Bundles = ({children}: { children: any }) => {
    const cookies = getCookies();
    const lang = useLang();
    const Intl = useIntl();
    const authenticationData = {tgtTicket : cookies.tgtTicket,tgtTimestamp :cookies.tgtTimestamp,userInfo: cookies.userInfo,source:cookies.source,audience: cookies.audience,country : cookies.ecountry};
    const postViewUri = `${generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE,cookies.ecountry,lang)}/search/{post_id}`;
    return <ChatProvider lang={lang} authenticationData={authenticationData} publicUrl={window.location.origin} urls={{ arachnaApi: process.env.REACT_APP_OPENSOOQ_API_URL_BASE_V2 ?? '', arbok: process.env.REACT_APP_ARBOK_URL_BASE ?? '', chat: process.env.REACT_APP_CHAT_URL ?? '', postView: postViewUri }} actions={{ onCallFailed: () => { showError(Intl, lang, { message: Intl.formatMessage({id: "message.call-failed"}) }) }, }}>
        <NotificationProvider authenticationData={authenticationData} urls={{gulpin2: process.env.REACT_APP_GULPIN_2_URL ?? ''}}
                             lang={lang}
                            publicUrl={window.location.origin} >
            {
                children
            }
        </NotificationProvider>
    </ChatProvider>
}

export {App}
