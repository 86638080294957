import { useIntl } from "react-intl"
import { MyWalletTableLoader } from "../../../components/loaders/MyWalletTableLoader"

type Props = {
    transactionsList :  () => any
    transactionsLoader : boolean
}

const WalletTransactions = ({transactionsList,transactionsLoader} : Props) => {
    const Intl = useIntl();
    return <div className="card" >
        <div className="card-header">
            <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">{Intl.formatMessage({id:"myWallet.text6"})}</span>
                <span className="text-gray-800 mt-1 fw-semibold fs-6">{Intl.formatMessage({id:"myWallet.text7"})}</span>
            </h3>
        </div>
        <div className="card-body pt-2" >
            {transactionsLoader && <MyWalletTableLoader/>}
            <div id="kt_table_widget_4_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                <div className="table-responsive l">
                    <table className="table table-hover align-middle table-row-dashed fs-6 gy-3 dataTable no-footer" id="kt_table_widget_4_table">
                        <thead className=" fs-5 fw-semibold ">
                            <tr className="">
                                <th className=" fw-bolder ">{Intl.formatMessage({id: 'description'})}</th>
                                <th className=" fw-bold hideOnMobile">{Intl.formatMessage({id: 'date'})}</th>
                                <th className=" fw-bold hideOnMobile">{Intl.formatMessage({id: 'transactionId'})}</th>
                                <th className=" fw-bold hideOnMobile">{Intl.formatMessage({id: 'amount'})} ({Intl.formatMessage({id: 'credits'})})</th>
                            </tr>
                        </thead>
                        <tbody className=" ">
                            {transactionsList()}
                        </tbody>
                    </table>
                </div>
                <div className="row">
                    <div
                        className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                    </div>
                    <div
                        className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default WalletTransactions