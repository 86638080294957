import ContentLoader from "react-content-loader"
import Skeleton from "react-loading-skeleton";
import { useIntl } from "react-intl";
import { useLang } from "../../../i18n/OpenSooqi18n";

interface loaderProps{
  counter ?: number
  className ?: string
}

const RatingLoader = (props : loaderProps) => {
    const lang = useLang();
    const Intl = useIntl();
    const rtl = (lang == 'ar') ? true : false;
    return (
        <>
            <div className='card '>
                <div className="card-header">
                    <div className='card-title'>
                        <h3>{Intl.formatMessage({id :'MENU.ACCOUNT.r'})}</h3>
                    </div>
                </div>
                <div className='card-body p-5 '>
                    <div className='row'>
                        <div className='col-lg-4 col-3 text-center p-5' style={{margin : "auto"}}>
                            <div className="d-flex flex-column bd-highlight mb-3 p-5">
                                <div className=" "><span style={{fontSize : "40px",fontWeight : "700",lineHeight : "47px"}}><Skeleton width={100}/></span></div>
                                <div className='mt-2' style={{fontSize : "22px",lineHeight : "45px"}}><span><Skeleton width={100}/></span></div>
                            </div>
                        </div>
                        <div className='col-lg-8 col-9  p-5'>
                            <div className="d-flex flex-column bd-highlight mb-3 p-5">
                                <Skeleton   className='me-1 w-100'/>
                                <Skeleton   className='me-1 w-100'/>
                                <Skeleton   className='me-1 w-100'/>
                                <Skeleton   className='me-1 w-100'/>
                                <Skeleton   className='me-1 w-100'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>        
            <div className='card mt-5'>
                <div className='card-header'>
                    <div className='card-title'>
                        <h3>{Intl.formatMessage({id: 'ratingText1'})}</h3>
                    </div>
                </div>
                <div className='card-body p-5 '>
                    <div className='row pb-5'>
                        <div className='col-lg-3 col-6 mt-3  col-md-4'>
                            <Skeleton className='me-1 w-100 h-35px'/>
                        </div>
                        <div className='col-lg-3 col-6 mt-3  col-md-4'>
                            <Skeleton className='me-1 w-100 h-35px'/>
                        </div>
                        <div className='col-lg-3 col-6 mt-3  col-md-4'>
                            <Skeleton className='me-1 w-100 h-35px'/>
                        </div>
                        <div className='col-lg-3 col-6 mt-3  col-md-4'>
                            <Skeleton className='me-1 w-100 h-35px'/>
                        </div>
                        <div className='col-lg-3 col-6 mt-3  col-md-4'>
                            <Skeleton className='me-1 w-100 h-35px'/>
                        </div>
                        <div className='col-lg-3 col-6 mt-3  col-md-4'>
                            <Skeleton className='me-1 w-100 h-35px'/>
                        </div>
                        <div className='col-lg-3 col-6 mt-3  col-md-4'>
                            <Skeleton className='me-1 w-100 h-35px'/>
                        </div>
                        <div className='col-lg-3 col-6 mt-3  col-md-4'>
                            <Skeleton className='me-1 w-100 h-35px'/>
                        </div>
                        <div className='col-lg-3 col-6 mt-3  col-md-4'>
                            <Skeleton className='me-1 w-100 h-35px'/>
                        </div>
                        <div className='col-lg-3 col-6 mt-3  col-md-4'>
                            <Skeleton className='me-1 w-100 h-35px'/>
                        </div>
                        <div className='col-lg-3 col-6 mt-3  col-md-4'>
                            <Skeleton className='me-1 w-100 h-35px'/>
                        </div>
                        <div className='col-lg-3 col-6 mt-3  col-md-4'>
                            <Skeleton className='me-1 w-100 h-35px'/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card mt-5'>
                <div className='card-header'>
                    <div className='card-title'>
                        <h3>{Intl.formatMessage({id: 'rate'})}</h3>
                    </div>
                </div>
                <div className='card-body p-0 '>
                    <div className='row p-5'>
                        <div className='col-12 mt-5'>
                            <div className="card card-flush ">
                                <ContentLoader 
                                    rtl={rtl}
                                    speed={3}
                                    style={{width:"100%"}}
                                    height={160}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"
                                    {...props}
                                >
                                    <rect x="48" y="8" rx="3" ry="3" width="100" height="6" /> 
                                    <rect x="48" y="26" rx="3" ry="3" width="150" height="6" /> 
                                    <rect x="0" y="56" rx="3" ry="3" width="800" height="6" /> 
                                    <rect x="0" y="72" rx="3" ry="3" width="800" height="6" /> 
                                    <rect x="0" y="88" rx="3" ry="3" width="800" height="6" /> 
                                    <circle cx="20" cy="20" r="20" />
                                </ContentLoader>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  );
};

export {RatingLoader}