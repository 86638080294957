import { useIntl } from "react-intl"
import Skeleton from "react-loading-skeleton";
import { MyAccountFeatures } from "../../../interfaces/MyAccountFeatures";

type Props = {
    accountFeature ?: MyAccountFeatures
}

const MyListingsAccountOverView = ({accountFeature} : Props) => {
    const Intl = useIntl();

    let bundles = 0;
    if(accountFeature && !isNaN(accountFeature.wallet_promotion_bundles.avalable_units / accountFeature.wallet_promotion_bundles.avalable_units * 2)){
        bundles = (accountFeature.wallet_promotion_bundles.avalable_units / (accountFeature.wallet_promotion_bundles.avalable_units * 2) * 100)
    }

    let liveLimitsListings = "0%";
    if(accountFeature){
        let liveListings =   accountFeature.live_listings.total;
        let maxQuota     =   accountFeature.account_quota.total;
        liveLimitsListings = ((liveListings / maxQuota) >= 1) ? "100%" : ((liveListings / maxQuota) * 100).toFixed() + '%';
    }

    return <div className="card h-100">
        <div className="card-header">
            <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">{Intl.formatMessage({id : "myListings.text1"})}</span>
                <span className="text-gray-900 mt-1 fw-semibold fs-6">{Intl.formatMessage({id : "myListings.text2"})}</span>
            </h3>
        </div>
        <div className="card-body  pb-0">
            <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
                <div className="flex-grow-1">
                    <div className="d-flex flex-wrap justify-content-start">
                        <div className="d-flex w-100 flex-column mt-3 ">
                                <div className="d-flex w-100 flex-column mt-3 pb-5">
                                    <div className="py-1">
                                        <span className="text-gray-800 text-hover-primary fw-bold fs-6">{!accountFeature && <Skeleton className="w-25" />}{accountFeature && <span>{accountFeature && accountFeature.live_listings.total}/{accountFeature.account_quota.total + accountFeature.account_quota.listing_above_quota} </span>}</span>
                                        {accountFeature && <span className="fw-semibold text-gray-800 fs-7 ms-2">{accountFeature && Intl.formatMessage({id : "myListings.text3"})}</span>}
                                    </div>
                                    <div className="h-5px  w-100 bg-light mb-3">
                                        <div className="bg-success rounded h-5px"  style={{width: `${liveLimitsListings}`}}></div>
                                    </div>
                                </div>
                        </div>
                        <div className="d-flex w-100 flex-column mt-3 ">
                                <div className="d-flex w-100 flex-column mt-3 pb-5">
                                    <div className="py-1">
                                        <span className="text-gray-800 text-hover-primary fw-bold fs-6">{!accountFeature && <Skeleton className="w-25" />}{accountFeature && accountFeature.live_listings.avg_pqs && accountFeature.live_listings.avg_pqs.toFixed() + '%'}</span>
                                        {accountFeature && <span className="fw-semibold text-gray-800 ms-2 fs-7">{Intl.formatMessage({id : "myListings.text4"})}</span>}
                                    </div>
                                    <div className="h-5px  w-100 bg-light mb-3">
                                        <div className="bg-success rounded h-5px"  style={{width: `${accountFeature && accountFeature.live_listings.avg_pqs && accountFeature.live_listings.avg_pqs}%`}}></div>
                                    </div>
                                </div>
                        </div>
                        <div className="d-flex w-100 flex-column mt-3 ">
                            <div className="d-flex w-100 flex-column mt-3 pb-5">
                                <div className="py-1">
                                    <span className="text-gray-800 text-hover-primary fw-bold fs-6">{!accountFeature && <Skeleton className="w-25" />}{accountFeature && accountFeature.wallet_promotion_bundles.avalable_units.toFixed()}</span>
                                    <span className="fw-semibold text-gray-800 fs-7 ms-2">{Intl.formatMessage({id : "myListings.text5"})}</span>
                                </div>
                                <div className="h-5px  w-100 bg-light mb-3">
                                    <div className="bg-success rounded h-5px"  style={{width: `${bundles}%`}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default MyListingsAccountOverView


