import { AxiosResponse} from "axios";
import Api from "../api";

interface UnFollowRequest{
    action       : string
    expand       : null
    following_id : number
}

const MemberFollowAction =(body: UnFollowRequest,action : boolean): Promise<AxiosResponse> => {
    const path = 'followings';
    return Api.getInstance().request.post(`${path}/${(action) ? 'unfollow' : 'follow'}`, body, path).then(r =>r.data)
}

export default MemberFollowAction;