import React from "react";
import { useLang } from "../../i18n/OpenSooqi18n";
//import ltrCss from "../css/style.ltr.css"
//  import "../css/style.ltr.css"
//  import "../sass/style.scss"
// import "../sass/plugins.scss"

const Style = (props: {children: React.ReactNode})=> {
    const lang = useLang();
  
    return (<>
        {props.children}
    </>)
}

export default Style;