import {ReactTooltipContainer} from "../common/ReactTooltipContainer";
import {ListingStatistics} from "../common/ListingStatistics";
import {useNavigate} from "react-router-dom";
import 'react-tooltip/dist/react-tooltip.css'
import {useEffect, useState} from "react";
import {useIntl} from "react-intl"
import {ListingInfo} from "../common/ListingInfo";
import {ShareListings} from "../common/ShareListing";
import {IframeModal} from "../../../app/layout/components/modals/IframeModal";
import {ListingQualityLoader} from "./ListingQualityLoader";
import {CustomPopup} from "../../../app/layout/components/modals/CustomPopup";
import getAdStatsApi from "../../../api/getAdStats.api";
import getListingApi from "../../../api/listing/get.listing.api";
import {ListingFeatures} from "./ListingFeatures";
import {MyListingViewInterface} from "../../interfaces/MyListingViewInterface";
import {ListingQuality} from "./ListingQuality";
import DynamicObject from "../../../models/dynamic-object";
import {EDIT, LISTING_STATISTICS, PROMOTE} from "../../helper/Ctas";
import {ManageListing} from "./ManageListing";
import {showError} from "../../helper/SystemAlert";
import {useLang} from "../../i18n/OpenSooqi18n";
import {useChatFunctions} from "opensooq-chat";
import { generateOpenSooqRedirectUrl, getCookies, isDeviceMobile } from "../../helpers/commonFunctions";

const MyListingView = () => {
    const {getUnreadPostRooms} = useChatFunctions()
    const cookies = getCookies();
    let navigate = useNavigate();
    const Intl = useIntl();
    const lang = useLang();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const adId = parseInt(params.get('id') ?? '');
    const action = params.get('action') ?? '';
    const [listing, setListing] = useState<DynamicObject>();
    const [showEditListingModal, setShowEditListingModal] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openCustomPopup, setOpenCustomerPopup] = useState(false)
    const [viewsCountTotal, setViewsCountTotal] = useState<number>(0)
    const [callCountTotal, setCallCountTotal] = useState<number>(0)
    const [roomCountTotal, setCvCountTotal] = useState<number>(0)
    const [favoriteCountTotal, setFavoriteCountTotal] = useState<number>(0)
    const [statsLoader, setStatsLoader] = useState(true)
    const [listingUrl, setListingUrl] = useState('')
    const [count, setCount] = useState(0)
    useEffect(() => {
        getListing();
    }, []);


    const getListing = () => {
        getListingApi(adId).then((response) => {
            setListing(response.data);
            if(action == EDIT && response.data.ctas.find((cta: DynamicObject) => {return cta.cta == EDIT}) && response.data.ctas.find((cta: DynamicObject) => {return cta.cta == EDIT}).cta){
                setOpenEditModal(true)
            }
            const shareLink =  generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',cookies.ecountry ?? 'jo',lang) + '/search/' + response.data.listing_id + '/' + response.data.title.replace(/\s+/g, '-');
            setListingUrl(shareLink)
        }).catch(error => {
            if(error.response && error.response.status){
                navigate('/error/404')
            }
        })
        
        getAdStatsApi(adId).then(response => {
            setFavoriteCountTotal(response.data.bookmarks.total)
            setViewsCountTotal(response.data.views.total);
            setCallCountTotal(response.data.calls.total);
            setCvCountTotal(response.data.rooms.total);
            setStatsLoader(false);
        }).catch(error => {
            setStatsLoader(false);
        });
    }

    function navigateTo(url: string) {
        navigate(url);
    }

    const setOpenModal = () => {
        setShowEditListingModal(true);
    }

    const refresh = () => {
        getListing();
    }

    useEffect(() => {
        getUnreadPostRooms(adId)
            .then((value: number) => {
                console.log(value)
                if (count !== value)
                    setCount(value)
            })
    }, [getUnreadPostRooms])

    return (
        <>
            <div className="row ">
                <div className="col-3">
                    {listing && listing.job_applicant_count > 0 && <div className="border border-dashed border-gray-300  grayHoverBg  rounded my-3 p-2 me-2 hoverPointer" onClick={() => { navigateTo(`/listings/my-listings/job-applicants?id=${listing.listing_id}`) }}>
                        <div className="d-flex align-items-center h-30px hoverPointer">
                            <div className="form-check form-check-custom form-check-solid mx-5">{Intl.formatMessage({id: "common.numberOfApplicants"})}</div>
                            <div className="flex-grow-1">
                                <a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6"></a>
                            </div>
                            <span className="badge badge-light fs-12 fw-bold">{listing.job_applicant_count}</span>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-lg-12 ps-4 pe-4 pt-2">
                    <ListingInfo count={count} refresh={refresh} listing={listing}/>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-lg-12  pt-2 pb-6">
                    <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
                        <div className="d-flex flex-l-row flex-column gap-7  w-lg-350px">
                            <ManageListing count={count} listing={listing} refresh={refresh}/>
                            {/* {listing && !isDeviceMobile() && listing.ctas.find((cta: DynamicObject) => {
                                return cta.cta == EDIT
                            }) && <ShareListings comeFrom={'myListingView'} listingUrl={listingUrl} listing={listing}/>} */}
                        </div>
                        <div className="d-flex flex-column w-100 gap-7 gap-lg-10">
                            <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
                                {!listing && <ListingQualityLoader/>}
                                {listing && <ListingQuality action={setOpenModal} listing={listing}/>}
                            </div>
                            <ListingFeatures listing={listing}/>
                            <ListingStatistics showStatsButton={listing && listing.ctas.find((cta: DynamicObject) => {
                                return cta.cta == LISTING_STATISTICS })} showPromote={listing && listing.ctas.find((cta: DynamicObject) => {
                                return cta.cta == PROMOTE })} stats={{
                                views: viewsCountTotal,
                                favorite: favoriteCountTotal,
                                calls: callCountTotal,
                                rooms: roomCountTotal
                            }} loading={statsLoader}/>
                        </div>
                    </div>
                </div>
            </div>
            <ReactTooltipContainer/>
            <CustomPopup show={openCustomPopup} handleClose={() => {
                setOpenCustomerPopup(false)
            }}/>
            <IframeModal show={showEditListingModal} id={listing?.listing_id} type="edit" handleClose={() => {
                setShowEditListingModal(false)
            }}/>
            <IframeModal show={openEditModal} id={listing?.listing_id} type={EDIT} handleClose={() => {setOpenEditModal(false)}} />
        </>
    )
}

export {MyListingView}