
import Api from '../api'
import { AxiosResponse} from "axios";
import DynamicObject from "../../models/dynamic-object";

export default (serviceName : string,catReportingName : number,config?: DynamicObject): Promise<AxiosResponse> => {
    const path = 'bundle-user/service-details'
    return Api.getInstance().request.get(`${path}?serviceName=${serviceName}&catReportingName=${catReportingName}`, path);
}

