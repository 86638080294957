import {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
import {ScrollTop} from './components/scroll-top'
import {Content} from './components/content'
import {FooterWrapper} from './components/footer'
import {Sidebar} from './components/sidebar'

import {
  ThemeModeProvider,
} from '../partials'
import {PageDataProvider} from './core'
import {reInitMenu, toAbsoluteUrl} from '../helpers'
import { checkIfLogin } from '../helpers/commonFunctions'

const MasterLayout = () => {
  const location = useLocation()
  checkIfLogin();
  useEffect(() => {
    reInitMenu()
  }, [location.key])
  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
          {/* <div className="topHeaderNav text-end">
            <span className='ms-3 fs-3 fw-bolder border-1 border-gray-300'>
              <a href="/contactUs" className='unsetDecoration fs-14'>Contact Us</a>
              <svg width="15" className='ms-1' height="15" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.3333 15.0833C18.875 15.0833 17.475 14.85 16.1683 14.4183C15.76 14.29 15.305 14.3833 14.9783 14.6983L12.4117 17.265C9.11 15.585 6.40333 12.89 4.72333 9.57667L7.29 6.99833C7.61667 6.695 7.71 6.24 7.58167 5.83167C7.15 4.525 6.91667 3.125 6.91667 1.66667C6.91667 1.025 6.39167 0.5 5.75 0.5H1.66667C1.025 0.5 0.5 1.025 0.5 1.66667C0.5 12.6217 9.37833 21.5 20.3333 21.5C20.975 21.5 21.5 20.975 21.5 20.3333V16.25C21.5 15.6083 20.975 15.0833 20.3333 15.0833ZM19.1667 11H21.5C21.5 5.20167 16.7983 0.5 11 0.5V2.83333C15.515 2.83333 19.1667 6.485 19.1667 11ZM14.5 11H16.8333C16.8333 7.78 14.22 5.16667 11 5.16667V7.5C12.9367 7.5 14.5 9.06333 14.5 11Z" fill="#0179FF"/>
              </svg>
              </span>
            <span className='ms-3 fs-3 fw-bolder'>|</span>
            <span key={newLang} onClick={() => { setLanguage(newLang)}} className='hoverPointer ms-3 fs-3 fw-bolder border-1 border-gray-300'><span  className=' fs-14'>{(currentLanguage?.lang == 'ar') ? 'English' : 'عربي'}</span></span>
          </div> */}
          <div className='app-page flex-column flex-column-fluid' style={{height:"100vh"}} id='kt_app_page'>
            <HeaderWrapper />
            <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
              <Sidebar />
              <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>
                  <Content>
                    <Outlet />
                  </Content>
                </div>
                <FooterWrapper />
              </div>
            </div>
          </div>
        </div>
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
