import calculateTotalApi from "../../../../api/buyNow/calculateTotal.api";
import { ServiceDetailsDuration, ServiceDetailsInterface, ServiceDetailsQuantity } from "../../../interfaces/ServiceDetailsInterface";
import { showError } from "../../../helper/SystemAlert";

interface Iprops {
    lang : string
    serviceDetails : ServiceDetailsInterface
    changeTotal : (amount:number) => void
    changeCurrency : (currency:string) => void
    setParentQty : (qty : number) => void
    setParentProductAssignId : (productAssignId : number) => void
    productAssignId : number
    qty : number
    toggleLoading : () => void
    isRedirecting: boolean
}

const BundleServiceDetailsComponent = ( {serviceDetails, lang, changeTotal, changeCurrency, setParentQty, setParentProductAssignId ,toggleLoading, qty, productAssignId, isRedirecting} : Iprops) => {
    const qtyHandler = (qty : number)=>{
        if(isRedirecting)
            return;
        if(qty && productAssignId)
        {   
            toggleLoading()
            calculateTotalApi(productAssignId, qty).then(res=>{
                changeTotal(res.data.amount)
                changeCurrency(res.data.currency)
                toggleLoading();
            }).catch((error)=>{
                if(error.response && error.response.data){
                    showError(Intl, lang, error.response.data)
                }else{
                    showError(Intl, lang)
                }
            });
            setParentQty(qty)
        }
    }
    const durationHandler = (productAssignId : number) => {
        if(isRedirecting)
            return;
        if(qty && productAssignId)
        {   
            toggleLoading()
            calculateTotalApi(productAssignId, qty).then(res=>{
                changeTotal(res.data.amount)
                changeCurrency(res.data.currency)
                toggleLoading()
            }).catch((error)=>{
                if(error.response && error.response.data){
                    showError(Intl, lang, error.response.data)
                }else{
                    showError(Intl, lang)
                }
            });
            setParentProductAssignId(productAssignId)
        }
    }
    return (
    <div className="col-12">
        <div className="row-6 mt-5 mb-15">
            <div className="h1 mb-5">{serviceDetails.header}</div>
            <div className="h3">{serviceDetails.description}</div>
        </div>
        <div className="row mt-5 mb-15">
            <div className="h1 mb-5">{serviceDetails.durationHeader}</div>
            <div className="h3">{serviceDetails.durationDescription}</div>
            <ul className="nav" role="tablist">
                {serviceDetails.durations.map((duration : ServiceDetailsDuration, _index:number) => {
                    return <li className={`nav-item radius-8 shadow m-2 ${ (duration.productAssignId === productAssignId || ( productAssignId === undefined && _index+1 === serviceDetails.durations.length) ) && ' text-white bg-primary'} p-5 hoverPointer text-hover-white bg-hover-primary`} role="presentation" onClick={()=>{ durationHandler(duration.productAssignId)}} key={duration.value}>
                    <a className={`nav-link btn btn-sm fs-5 fw-bold ${ (duration.productAssignId === productAssignId || ( productAssignId === undefined && _index+1 === serviceDetails.durations.length) ) && 'active text-white '}`}  data-bs-toggle="tab" href="#kt_table_widget_4_tab_1" aria-selected="true" role="tab">
                        {duration.value}
                    </a>
                </li>})}
            </ul>
        </div>
        <div className="row mt-5">
            <div className="h1 mb-5">{serviceDetails.postCountHeader}</div>
            <div className="h3">{serviceDetails.postCountDescription}</div>
            <ul className="nav" role="tablist">
                {serviceDetails.qty.map((qt : ServiceDetailsQuantity )=>  {
                    return <li className={`${ ((qty && qty === qt.value) || (qty === undefined && qt.isDefault) ) && 'bg-primary text-white'} p-5 hoverPointer text-hover-white bg-hover-primary nav-item radius-8 shadow m-2 p-5`} role="presentation" onClick={()=>{ qtyHandler(qt.value) }} key={qt.value}>
                    <a className={`nav-link btn btn-sm btn-active fs-5 fw-bold  ${((qty && qty === qt.value) || (qty === undefined && qt.isDefault) ) && 'active text-white'}`} data-bs-toggle="tab" href="#kt_table_widget_4_tab_1" aria-selected="true" role="tab">
                        {qt.value}
                    </a>
                </li>})}
            </ul>
        </div>
    </div>
    )
}
export {BundleServiceDetailsComponent};