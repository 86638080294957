import {useNavigate} from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useEffect, useState } from "react";
import getAdApi from "../../../api/listing/getAd.api";
import { ListingInterface } from "../../interfaces/ListingInterface";
import { CellLoader } from "../../../app/layout/components/opensooq/CellLoader";
import { useLang } from "../../i18n/OpenSooqi18n";
import { IframeModal } from "../../layout/components/modals/IframeModal";
import { MyListingViewInterface } from "../../interfaces/MyListingViewInterface";
import { ListingViewCell } from "../listing/ListingViewCell";
import { editListing,canTakeAction, showChatInCountry } from "../../helpers/commonFunctions";
import { BundleModal } from '../payment/BundleModal';
import { useChatFunctions } from 'opensooq-chat';
import { showError } from '../../helper/SystemAlert';
import { IMAGE_PREVIEW_SM } from '../../helper/ImageSize';
import { ChatFactoryTypes } from '../listing/my-listings/helper';
import DynamicObject from '../../../models/dynamic-object';
import { EDIT, PROMOTE, VIEW_CHATS } from '../../helper/Ctas';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Header from './listingInfoComponents/Header';

type Props = {
    listing ?: DynamicObject
    refresh :  () => any
    count ?: number
}

export function ListingInfo({listing,refresh,count} : Props) {
    const {createRoom} = useChatFunctions()
    const [showEditListingModal, setShowEditListingModal] = useState(false)
    const [showBundle,setShowBundle] = useState (false);
    const Intl = useIntl();
    const navigate = useNavigate();
    const viewChat = (listing : DynamicObject) => {
        navigate('/chats',{state : {
            type : ChatFactoryTypes.LISTING_CHATS,
            data : {
                listing_id : listing.listing_id
            }
        }});
    }

    const handleClose = () => {
        setShowEditListingModal(false)
        refresh()
    }

    return (
        <div className="card">
            <div className="card-header ">
                {listing && <Header listing={listing}/>}
            </div>
            <div className="card-body p-0 ">
                {!listing && <CellLoader counter={1}/>}
                {listing && <ListingViewCell listing={listing}/>}
            </div>
            <div className={`card-footer  ${isMobile ? 'd-flex p-2 ' : 'p-3' }`}>
                {listing && listing.ctas.map((cta : DynamicObject,index : number) => {
                    if(cta.cta == PROMOTE ){
                        return <button key={index} className={`btn mt-1 btn-primary btn-sm ${isMobile ? 'flex-1 p-2' : '' } me-3`} onClick={() => {setShowBundle(true)}}>
                            <svg width="25" height="18" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M3.90662 22.8125L2.12537 21.0312L11.6879 11.5L16.9066 16.7187L23.7191 9.9375H19.8754V7.4375H27.8754V15.4375H25.4379V11.7187L16.8754 20.2812L11.6566 15.0625L3.90662 22.8125Z" fill="white"/> </svg>
                            {Intl.formatMessage({id : "myListingView.text3"})}
                        </button>
                    }
                    if(cta.cta == VIEW_CHATS && showChatInCountry()){
                        return  <button key={index} className={`btn mt-1 btn-primary btn-sm ${isMobile ? 'flex-1 p-2' : '' } me-3`} onClick={() => {viewChat(listing)}}>
                            <svg width="25" height="18" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clipPath="url(#clip0_1622_5823)"> <path fillRule="evenodd" clipRule="evenodd" d="M23.058 10.3214L23.0939 10.9091C23.0995 11.4661 23.0939 11.4157 23.0939 11.9318C23.0939 17.3687 18.6711 21.1364 12.5367 21.1364H10.4252C12.1003 22.7237 14.0491 23.6932 16.7595 23.6932C17.336 23.6932 17.8983 23.6427 18.4452 23.5561L22.39 25.9091V22.0418C24.5289 20.6222 25.9091 18.4186 25.9091 15.9368C25.9091 13.725 24.8112 11.7348 23.058 10.3214ZM10.8728 19.5934C11.4204 19.6793 12.664 19.7727 13.2405 19.7727C18.2939 19.7727 21.6863 16.1297 21.6863 11.8466C21.6863 7.56338 17.4472 4.09091 13.2405 4.09091C8.18008 4.09091 4.09091 7.56342 4.09091 11.8466C4.09091 14.3277 5.0671 16.4345 6.90617 17.9516V21.8181L10.8728 19.5934Z" fill="white"/> </g> <defs> <clipPath id="clip0_1622_5823"> <rect width="30" height="30" fill="white"/> </clipPath> </defs> </svg>
                            <span  className='ltrDir'>{cta.label} ({count})</span>
                        </button>
                    }
                    if(cta.cta == EDIT){
                        return  <button key={index} className={`btn mt-1 btn-primary btn-sm ${isMobile ? 'flex-1 p-2' : '' } me-3`} disabled={cta.cta.enabled} onClick={() => {editListing(listing,'edit')}}>
                            <svg width="25" height="18" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M24.8125 9.18751L20.8125 5.1875L22.125 3.875C22.4792 3.52084 22.9219 3.34896 23.4531 3.35938C23.9844 3.3698 24.4271 3.55209 24.7813 3.90625L26.125 5.25C26.4792 5.60417 26.6563 6.04167 26.6563 6.56251C26.6563 7.08334 26.4792 7.52084 26.125 7.87501L24.8125 9.18751ZM23.5 10.5L7.75 26.25H3.75V22.25L19.5 6.50001L23.5 10.5Z" fill="white"/> </svg>
                            <span className='ltrDir'>{cta.label}</span>
                        </button>
                    }
                })}
            </div>
            <BundleModal  show={showBundle}  type={['Turbo','Premium','Bumpup']} handleClose={() => {setShowBundle(false)}} id={listing?.listing_id} pos={'myListingView'}/>
            <IframeModal show={showEditListingModal} id={listing?.listing_id ?? 0} type="edit" handleClose={() => {handleClose()}}/>
        </div>
    )
}