import { SearchComponent } from '../../../app/layout/components/opensooq/SearchComponent'
import { Button } from '../../../app/layout/components/opensooq/Button'
import savedSearchApi from '../../../api/searches/savedSearch.api'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Swal from 'sweetalert2'
import { LazyLoader } from '../../../app/layout/components/opensooq/LazyLoader'
import { EmptySavedSearch } from '../../../app/layout/components/empty/EmptySavedSearch'
import { CellLoader } from '../../../app/layout/components/opensooq/CellLoader'
import { SavedSearchInterface } from '../../interfaces/SavedSearchInterface'
import deleteSavedSearchApi from '../../../api/searches/delete.savedSearch.api'
import e from 'express'
import { showError } from '../../helper/SystemAlert'
import { useLang } from '../../../app/i18n/OpenSooqi18n'
import { generateOpenSooqRedirectUrl, getCookies } from '../../helpers/commonFunctions'
import { isMobile } from 'react-device-detect'

export function SavedSearch() {
    const [searchData,setSearchData] = useState <SavedSearchInterface[]>([]); 
    const [list,setList] = useState <SavedSearchInterface[]>([]); 
    const [searchString, setSearchString] = useState("")
    const [loader,serLoader] = useState <boolean>(true)
    const [isLastPage,setIsLastPage] = useState(false)
    const [nextPage,setNextPage] = useState('')
    const Intl = useIntl();
    const lang = useLang();
    const deepLinkBase =  generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',getCookies().ecountry ?? '',lang) + '/';

    useEffect(() => {
        getSavedSearch();
    },[]);

    const getSavedSearch = () => {
        savedSearchApi(nextPage).then(response => {
            const results = response.data.items;
            const links = response.data._links;
            if(isLastPage && nextPage == ''){
                setSearchData(results);
                setList(results);
            }else{
                setSearchData(prevAds=> {
                    return [...prevAds, ...results];
                })
                setList(prevAds=> {
                    return [...prevAds, ...results];
                })
            }
            
            setNextPage(links.next?.href ?? '')
            if(links.next?.href == undefined){
                setNextPage('');
                setIsLastPage(true)
            }

            serLoader(false);
        }).catch(() => {
            serLoader(false);
            const results : any = [];
            setSearchData(prevAds=> {
                return [...prevAds, ...results];
            })
            setList(prevAds=> {
                return [...prevAds, ...results];
            })
            setIsLastPage(true)
            showError(Intl,lang);
        });
    }

    const setSearch = (e: any) => {
        if(e.target.value && e.target.value.length > 0){
            setIsLastPage(true);
        }else{
            setIsLastPage(false);
        }
        setSearchString(e.target.value);
    };

    useEffect(() => {
        const newList = list.filter(item => {
            if(item.term.toLowerCase().includes(searchString.toLowerCase())){
                return true
            }else{
                if(item.tags.toString().toLowerCase().includes(searchString.toLowerCase())){
                    return true;
                }
            }
        })
        setSearchData(newList);
    },[searchString]);
    
    const deleteSavedSearch = (savedSearchMd5 : string) => {
        const data = {
            md5 : savedSearchMd5,
            status : '0'
        }  

        Swal.fire({
            icon: "warning",
            html:
            `<div class="pe-10 ps-10 fw-bolder">${Intl.formatMessage({id : "alerts.savedSearch"})}</div>`,
            buttonsStyling: false,
            showCancelButton: true,
            cancelButtonText : Intl.formatMessage({id : 'no'}),
            showLoaderOnConfirm: true,
            confirmButtonText: Intl.formatMessage({id : 'yes'}),
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-light-danger"
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                deleteSavedSearchApi(data);
                setList(prevMemberList =>{
                    return prevMemberList.filter(it => it.md5 != savedSearchMd5);
                });
                setSearchData(prevMemberList =>{
                    return prevMemberList.filter(it => it.md5 != savedSearchMd5);
                });
            }
        })

    };

    const paginate = () => {
        getSavedSearch();
    };

    const List:React.FC<{searchData:SavedSearchInterface[]}> = ({searchData}) => {
        return <React.Fragment key={'savedSearch'}>
            <table className="table table-hover align-middle table-row-dashed fs-6 gy-3 dataTable no-footer" id="kt_table_widget_4_table">
                <tbody>
                {searchData.map((item  : SavedSearchInterface,index) => {
                    return <>
                        <tr key={index} className={(index%2 === 0) ? "even": "odd" } >
                        <td className="w-25px">
                            <div className="symbol symbol-45px me-5">
                                <img  src={item.category_image} alt=""/>
                            </div>
                        </td>
                        <td className="">
                            { item.tags.map((item,index) => {
                                return  <span key={index} className="badge badge-light me-3 mt-1">{item}</span>
                            })}
                        </td>
                        <td className="">
                            <span className="text-dark fw-bold text-hover-primary fs-3">{item.term}</span>
                        </td>
                        <td className='hideOnMobile text-end'>
                            <a target="_blank" href={`${deepLinkBase}site/dl?requestUri=${item.deeplink}`} className="btn btn-primary btn-sm me-4">{Intl.formatMessage({id: 'viewListings'})}</a>
                            <Button className={' btn-sm me-4 btn-light-danger me-4'} action={() => deleteSavedSearch(item.md5)} text={<><i className="fs-2 fas fa-trash-alt"></i></>}/>
                        </td>
                        <td className="hideOnDesktop text-end hideOnDesktop">
                            <button type="button" className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px" data-kt-table-widget-4="expand_row"  data-bs-toggle="collapse" data-bs-target={`#id-${item.id}`}>
                                <span className="svg-icon svg-icon-3 m-0 toggle-off">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1"
                                            transform="rotate(-90 11 18)" fill="currentColor"></rect>
                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"></rect>
                                    </svg>
                                </span>
                                <span className="svg-icon svg-icon-3 m-0 toggle-on">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"></rect>
                                    </svg>
                                </span>
                            </button>
                        </td>
                    </tr>
                     <tr id={`id-${item.id}`} className="hideOnDesktop collapse" data-bs-parent="#kt_accordion_tr-1"  >
                        <td colSpan={6} className="shadow1">
                            <dl className="row mb-0">
                                <dd className="col-12">
                                    <a target="_blank" href={`${deepLinkBase}site/dl?requestUri=${item.deeplink}`} className="btn btn-primary btn-sm me-4">{Intl.formatMessage({id: 'viewListings'})}</a>
                                    <Button className={' btn-sm me-4 btn-light-danger me-4'} action={() => deleteSavedSearch(item.md5)} text={<><i className="fs-2 fas fa-trash-alt"></i></>}/>
                                </dd>
                            </dl>
                        </td>
                    </tr>
                </>
                })}
                </tbody>
            </table>
        </React.Fragment>  
    };

    
    return (
        <>
        <div className="card">
            <div className="card-header">
                <SearchComponent action={setSearch}  placeholder={`${Intl.formatMessage({id: 'searchIn'})} ${Intl.formatMessage({id : "menu.listing.savedSearch"})}`} />
            </div>
            <div className="card-body">
                {loader && <>
                    <CellLoader />
                </>}
                {!loader && <div className="row g-5">
                    <LazyLoader list={<List searchData={searchData}/>} data={searchData} endMessage={<EmptySavedSearch/>} loader={<CellLoader/>} paginate={paginate} isLastPage={isLastPage}/>
                </div>}
            </div>
        </div>
    </>
  )
}
