import { CountriesInterface } from "../interfaces/MyProfileInterface"

type ComponentsProps = {
  countriesList : CountriesInterface[]
  show : boolean
  changeCountry :  (params: any) => any
}

export function CountriesSelect(props : ComponentsProps) {
    return (
      <div id="countriesList" className={`menu menu-sub menu-sub-dropdown w-100 h-150px overflow-scroll ${(props.show) ? 'd-block' : ''}`}>
        {props.countriesList.map((country,index) => {
            return <div key={index} onClick={() => {props.changeCountry(country)}}>
              <div  className="d-flex bd-highlight ps-2 pe-2 w-100 grayHoverBg hoverPointer" >
                <div className="p-2 bd-highlight"><img className="w-20px" src={country.icon}/></div>
                <div className="p-2 bd-highlight">{country.name}</div>
                <div className="ms-auto p-2 bd-highlight">+{country.phone_code}</div>
              </div>
              <div className='separator border-gray-200'></div>
            </div>
        })}
      </div>
    )
  }
  