/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {useLang, setLanguage} from '../../../i18n/OpenSooqi18n'
import { useIntl } from 'react-intl'
import { generateOpenSooqRedirectUrl, getCookies} from '../../../helpers/commonFunctions'

const BackToOpenSooq: FC = () => {
  const lang = useLang()
  const Intl = useIntl();
  return (
    <div  className='menu-item'  >
      <a href={generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',getCookies().ecountry ?? '',lang)}  target="_blank" className='menu-link'>
        <span className='menu-icon'>
            <img src={'https://opensooqui2.os-cdn.com/os_web/desktop/favicon.png'} className="w-100"/>
        </span>
        <span className='menu-title position-relative fs-16'>
          {Intl.formatMessage({id: 'backToOpenSooqWebsite'})}
        </span>
      </a>
    </div>
  )
}

export {BackToOpenSooq}
