import "react-toastify/dist/ReactToastify.css";
import { toast, ToastOptions } from "react-toastify";

const TOAST_MESSAGE_TIMEOUT = 4000;

type TypeSeverity = "error" | "success" | "warning";

interface IProps {
  severity: TypeSeverity;
  text?: string;
  title?: any;
  description?: any;
  open?: boolean;
  locale?: string;
  lang?:string;
  autoClose?: number;
  onClose?: () => void;
}

const Content= (props : any) => {
  return (
    <>
    <div className="ms-3" dir={(props.locale == 'ar') ? 'rtl' : 'ltr'}>
    <div className="d-flex flexSpaceBetween fw-bold fs-1">{props.title}</div>
    <ul style={{listStyle: ''}}>
    {props.description && (Array.isArray(props.description) ? props.description.map((desc:any, _index:number) => <li className = "mt-1 fs-3" key={_index}>{desc}</li>) : <p className="mt-1 fs-3">{props.description}</p>) }
    </ul>
  </div>
    </>
);
};

export const toastError = (err : any,lang : string) => {
  toastify({
    severity: "error",
    title: lang === "ar" ? "حدث خطأ. يرجى المحاولة مرة أخرى أو الاتصال بنا للمساعدة." : "Something went wrong. Please try again or contact us for help.",
    locale : lang
  });
};

export const toastify = (props: IProps) => {
  let {
    autoClose = TOAST_MESSAGE_TIMEOUT,
    title,
    description,
    severity,
    locale,
    onClose = null,
  } = props;
  onClose = onClose || function () {};

  const alertConfig: ToastOptions = {
    theme: "dark",
    position: toast.POSITION.TOP_CENTER,
    autoClose,
    onClose,
    rtl: locale === "ar",
  };

  const defaultText = title;
  switch (severity) {
    case "success":
      return toast.success(
        <Content title={defaultText} description={description} locale={locale}/>,
        alertConfig
      );
    case "error":
      return toast.error(
        <Content title={defaultText} description={description} locale={locale}/>,
        alertConfig
      );
    case "warning":
      return toast.warning(
        <Content title={defaultText} description={description} locale={locale}/>,
        alertConfig
      );
  }
};