import logApi from "../../../../api/logging/log.api";
import {toAbsoluteUrl} from "../../../helpers";
import {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Button} from "./Button";
import Moment from 'moment';
import {ListingInterface} from "../../../../app/interfaces/ListingInterface";
import getListingPhoneApi from "../../../../api/listing/getListingPhone.api";
import {generateOpenSooqRedirectUrl, getCookies, showChatInCountry} from "../../../helpers/commonFunctions";
import Cookies from "js-cookie";
import {MEMBER_COUNTRY_COOKIE, MEMBER_INFO_COOKIE} from "../../../helpers/CookiesVariables";
import {useLang} from "../../../i18n/OpenSooqi18n";
import {useChatFunctions} from "opensooq-chat";
import {showError} from "../../../helper/SystemAlert";
import {Navigate, useNavigate} from "react-router-dom";
import {ChatFactoryTypes, JOB_POSTER_TYPE, JOB_SEEKER_TYPE} from "../../../modules/listing/my-listings/helper";
import {ListingList} from "../../../modules/listing/my-listings/ListingList";
import {BrowserView, MobileView, isBrowser, isMobile} from 'react-device-detect';
import ChatPopup from "../../../modules/ChatPopup";
import DynamicObject from "../../../../models/dynamic-object";
import Swal from "sweetalert2";


interface cellProps {
    ad: ListingInterface
    action: (params: any) => any
    showButton: boolean
    hideNumber?: boolean | false
    hideChat?: boolean | false
    screen?: string | ''
    comeFrom?: string | 'listing'
}

const Cell = (props: cellProps) => {
    const memberInfo = getCookies().userInfo
    const [previewImageSize, setPreviewImageSize] = useState('640x480')
    const [phone, setPhone] = useState(props.ad.masked_local_phone);
    const [showButton, setShowButton] = useState(false);
    const [postDate, setPostDate] = useState('')
    const [price, setPrice] = useState('');
    const Intl = useIntl();
    const lang = useLang();
    const {createRoom} = useChatFunctions()

    useEffect(() => {
        Moment.locale('en');
        const date = parseInt(props.ad.record_insert_date);
        const formateDate = Moment.unix(date).format("DD-MM-YYYY");
        // const price = props.ad.price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
        const price = props.ad.price_list[props.ad.price_currency]?.price ?? Intl.formatMessage({id: 'noPrice'})
        setPostDate(formateDate);
        setPrice(price);
        setShowButton(props.showButton)

    }, []);

    const showPhone = (listingId: number) => {
        if(props.ad && props.ad.hide_user_contact_details == 0 && phone != 'XX' && phone){
            logApi('call', 'post', props.ad.id, props.screen).then(response => {
                getListingPhoneApi(listingId).then(phoneData => {
                    setPhone(phoneData.data.local_phone);
                    window.location.href = `tel:${phoneData.data.local_phone}`;
                })
            })
        }else{
            Swal.fire({
                icon: 'warning',
                html: `<div class="fw-bolder">${Intl.formatMessage({id : "memberHidesHisPhone"})} </div>`,
                buttonsStyling: false,
                showCancelButton: false,
                showLoaderOnConfirm: false,
                cancelButtonText: Intl.formatMessage({id: 'no'}),
                confirmButtonText: Intl.formatMessage({id: 'ok'}),
                customClass: {
                    confirmButton: 'btn btn-info',
                    cancelButton: 'btn btn-light-danger',
                },
                allowOutsideClick: () => !Swal.isLoading(),
            })
        }
        
    }

    const customParamsList = () => {
        return props.ad.custom_param_array.map((customParam: string, index: number) => {
            const arrayCounter = props.ad.custom_param_array.length;
            const counterTocCheck = (arrayCounter < 3) ? arrayCounter : 3;
            return index < counterTocCheck && <span key={index} className="bigContainer">{customParam}{(index < counterTocCheck - 1) ? ' , ' : ''}</span>
        });
    }

    const cookies = getCookies();
    const redirectToPost = (e: any, post: ListingInterface) => {
        if (e.target.classList.contains('bigContainer')) {
            let link =  generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',cookies.ecountry ?? '','') + post.postUrl.replace("/en", "en").replace("/ar", "ar");
            if(post.view_type == JOB_POSTER_TYPE || post.view_type == JOB_SEEKER_TYPE){
                link =  generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',cookies.ecountry ?? '',lang) + '/job-posters/' + post.id;
            }
            window.open(link, "_blank");
        }
    }

    let navigate = useNavigate();
    const viewChat = (listing: ListingInterface) => {
        console.log(listing.searchMember.profile_picture)

        const data = {
            postId: listing.id,
            ownerId: listing.members_id,
            userId: memberInfo.id,
            post_country: listing.country_abbr,
            post_img: listing.first_image_thumb,
            post_title: listing.title,
            room: {
                avatar: listing.searchMember.profile_picture,
                name: listing.searchMember.full_name
            }
        }
        createRoom(data).then((chat: any) => {
            navigate('/chats', {
                state: {
                    type: ChatFactoryTypes.NEW_CHAT,
                    data: chat
                }
            });
        }).catch((error) => {
            showError(Intl, lang)
        })
    }


    const ChatButton = (buttonProps: { onCreateRoom: (data: DynamicObject) => void }) : JSX.Element => {

        console.log('s', buttonProps.onCreateRoom)
        const clickEventHandler = () => {
            const listing = props.ad
            const data = {
                postId: listing.id,
                ownerId: listing.members_id,
                userId: memberInfo.id,
                post_country: listing.country_abbr,
                post_img: listing.first_image_thumb,
                post_title: listing.title,
                room: {
                    avatar: listing.searchMember.profile_picture,
                    name: listing.searchMember.full_name
                }
            }
            buttonProps.onCreateRoom(data)
        }
        return <Button action={clickEventHandler} className={`btn btn-sm whiteBtn fs-6 ${isMobile ? 'p-3' : ''} `}
                       text={<><i
                           className="fas fa-message openSooqBlueColor"></i>{Intl.formatMessage({id: 'chat'})}</>}/>

    }


    return (
        <div key="postCell"
             className={`mb-32  position-relative  ${(props.comeFrom == 'postView') ? '' : 'customShadow grayHoverBg hoverPointer'} bigContainer  mt-1`}
             onClick={(e) => {
                 redirectToPost(e, props.ad)
             }}>
            <div className="flex flexNoWrap ps-4 pe-4 pt-4 pb-2 blackColor radius-8 bigContainer  ripple boxShadow2 relative">
                <div className={`sc-265a2526-1 jRUVEn postImgCont bigContainer position-relative radius-8 overflowHidden marginEnd-16px ixKSmm`}>
                    <img src={props.ad.first_image_thumb} width="272" height="204"  className="width-100 bigContainer customImg height-100" alt={props.ad.title} loading="eager"/>
                </div>
                <div className="sc-265a2526-3 zoIKj postDet bigContainer flex-1 flex flexSpaceBetween flexDirectionColumn">
                    <div className="flex flexSpaceBetween">
                        <span  className={` ${isMobile ? 'fs-5' : 'fs-2'} fw-bold  breakWord bigContainer overflowHidden  `}>{props.ad.title}</span>
                    </div>
                    <div className={`${isMobile ? 'fs-5' : 'fs-3'}  bigContainer`}>{customParamsList()}</div>
                    <div className="flex alignItems bigContainer ">
                        <div className={`${isMobile ? 'fs-5' : 'fs-3'}  category`}>{(props.ad.category) ? props.ad.category.label + ` ${Intl.formatMessage({id: 'in'})}` : ''} {props.ad.neighborhood && <> <span className="text-dark fw-bold"><b>{props.ad.neighborhood.name}</b></span></>}</div>
                    </div>
                </div>
            </div>
            <div className="flex alignItems ps-4 pe-4 pt-0 pb-1">
                <div className="pe-1  bd-highlight">
                    <div  className="sc-265a2526-2 iRqeKu numOfCont flex bigContainer  alignItems radius-8 whiteColor fs-13 gap-5 ">
                        <span className="flex alignItems radius-8 bigContainer">
                            <svg viewBox="0 0 12 12" width="12" height="12" className="me-2 bigContainer" data-name="iconCamera2">
                                <path fill="#fff"  d="M12 10.6667V1.33333C12 0.6 11.4 0 10.6667 0H1.33333C0.6 0 0 0.6 0 1.33333V10.6667C0 11.4 0.6 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667ZM3.66667 7L5.33333 9.00667L7.66667 6L10.6667 10H1.33333L3.66667 7Z"></path>
                            </svg>
                            {props.ad.imagesCount}
                        </span>
                    </div>
                </div>
                <div className="ms-1 bd-highlight">
                    <div className="d-flex justify-content-start">
                        {props.ad.services.map((service, index) => {
                            return <img key={index} className="bigContainer me-1" width="25" height="25" src={toAbsoluteUrl(`/media/custom/${service}-service.svg`)}/>
                        })}
                    </div>
                </div>
                <div className="postPrice ms-auto bold alignSelfCenter fs-3">{price != '' && <><span
                    className="fw-bolder"> {price} </span></>}</div>
            </div>
            <div className="flex alignItems ps-4 pe-4 pt-0 pb-2" style={{paddingTop: "5px"}}>
                {!props.hideNumber && props.ad && (props.ad.view_type != JOB_POSTER_TYPE && props.ad.view_type != JOB_SEEKER_TYPE) &&
                    <div className="pe-1  bd-highlight">
                        <button onClick={() => {
                            showPhone(props.ad.id)
                        }} style={{minWidth: "145px"}}
                                className={`btn blueBtn fs-6 text-hover-white ${isMobile ? 'flex-1' : ''}`}><i
                            className="fas fa-phone text-white"></i> {(phone != 'XX' && phone != '') ? phone : ''}
                        </button>
                    </div>}
                {!props.hideChat && showChatInCountry() && props.ad.members_id != memberInfo.id && (props.ad.view_type != JOB_POSTER_TYPE && props.ad.view_type != JOB_SEEKER_TYPE) &&
                    <div className="ms-1 bd-highlight">
                        <ChatPopup button={ChatButton} />
                    </div>}
                {showButton && <div className="  bd-highlight customShadow ms-2 fs-6 ">
                    <button disabled={false} className={'btn btn-bg-white btn-sm border-1'} onClick={() => {props.action(props.ad.id)}}>
                        {(props.ad.bookmarkStatus) ?
                            <svg data-id={props.ad.id} viewBox="0 0 32 32" width="25" height="25"
                                 data-name="iconFavouriteFill">
                                <path fill="#ee5555" stroke="#ee5555" strokeWidth="3"
                                      d="M16 28.467l-1.933-1.76c-6.867-6.227-11.4-10.333-11.4-15.373 0-4.107 3.227-7.333 7.333-7.333 2.32 0 4.547 1.080 6 2.787 1.453-1.707 3.68-2.787 6-2.787 4.107 0 7.333 3.227 7.333 7.333 0 5.040-4.533 9.147-11.4 15.387l-1.633 1.747z"></path>
                            </svg> : <svg viewBox="0 0 32 32" width="25" height="25" data-name="iconFavouriteFill">
                                <path fill="#fff" stroke="#ee5555" strokeWidth="3"
                                      d="M16 28.467l-1.933-1.76c-6.867-6.227-11.4-10.333-11.4-15.373 0-4.107 3.227-7.333 7.333-7.333 2.32 0 4.547 1.080 6 2.787 1.453-1.707 3.68-2.787 6-2.787 4.107 0 7.333 3.227 7.333 7.333 0 5.040-4.533 9.147-11.4 15.387l-1.633 1.747z"></path>
                            </svg>}
                    </button>
                </div>}
            </div>
        </div>
    );
};

export {Cell}