import Cookies from "js-cookie";
import {ContactUsValue, contactUs} from "../helper/ContactUsInfo";
import Moment from 'moment';
import DynamicObject from "../../models/dynamic-object";
import { PROMOTE, VIEW_CHATS } from "../helper/Ctas";
import {SocialUrlsValue, socialUrls} from "../helper/SocialUrlsInfo";
import { trackEvent } from "../../gtm/trackEvent";
import Api from "../../api/api";
import shortUrlApi from "../../api/site/shortUrl.api";
import { MyListingInterface } from "../interfaces/MyListingInterface";
import { showError } from "../helper/SystemAlert";

const getDomainWithoutSubdomain = (url : string) => {
    const urlParts = new URL(url).hostname.split('.')
    return urlParts.slice(0).slice(-(urlParts.length === 4 ? 3 : 2)).join('.')
}

const logout = () => {
  window.location.href = process.env.REACT_APP_OPENSOOQ_LOGOUT_URL?.replace("{country}",getCookies().ecountry ?? 'jo').replace("{lang}",getCookies().NEXT_LOCALE) ?? '';
}

const getSocialUrlsByCountry = (country:string): SocialUrlsValue => {
  return country in socialUrls ? socialUrls[country] : socialUrls['default'];
}

const getContactUsByCountry = (country : string) : ContactUsValue => {
  return country in contactUs ? contactUs[country]: contactUs['default'];
}

const redirectOut = (url : string) => {
  window.open(url, '_blank');
}

const generateOpenSooqRedirectUrl = (url ?: string,country ?: string,lang ?: string) => {
  return url?.replace("{country}", country ?? '').replace("{lang}", lang ?? '');
}

const formateDate = (date : string) => {
  return Moment.unix(parseInt(date)).format("DD-MM-YYYY hh:mm")
}

const canTakeAction = (ctas : DynamicObject,action : string) => {
  return ctas?.find((cta : DynamicObject) => {
    return cta.cta === action
  });
}

const takeAction = (cta : string,listingId : number) => {
  if(cta === PROMOTE){
  }
}

export type LiteUserInfo = {
  id: number;
  full_name: string;
  profile_picture: string;
  phone_number: string;
  member_type:number
};

export type AuthTicketType = {
  t: number;
  k: string;
};
  
export const jsonSafeGet = <T>(jsonString : any, defaultData = {}): T => {
  let output = defaultData as T;
  try {
    output = JSON.parse(jsonString);
  } catch (e) {
    // do nothing.
  }
  return output;
};

type CookiesDataTypes = {
  userInfo : LiteUserInfo
  tgtTimestamp : any
  tgtTicket : any
  ecountry :string
  source:string
  device_uuid:string
  NEXT_LOCALE:string
  VIEWED_LISTING_COOKIE:string
  audience : string
}

export const isDeviceMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent || ""
  );
}

const getCookies = () => {
  const data : CookiesDataTypes  =  {
      userInfo: jsonSafeGet<LiteUserInfo>(Cookies.get('userInfo')),
      tgtTimestamp: jsonSafeGet<AuthTicketType>(Cookies.get('auth')).t || Cookies.get('tgt_timestamp'),
      tgtTicket: jsonSafeGet<AuthTicketType>(Cookies.get('auth')).k || Cookies.get('tgt_ticket'),
      ecountry: Cookies.get('ecountry') ?? 'jo',
      source : Cookies.get('source') || Cookies.get('source') || (isDeviceMobile() ? 'mobile' : 'desktop'),
      device_uuid: Cookies.get('device_uuid') ?? '',
      NEXT_LOCALE:Cookies.get('NEXT_LOCALE') ?? 'ar',
      VIEWED_LISTING_COOKIE:Cookies.get('recentlyViewed') ?? 'recentlyViewed',
      audience:  Cookies.get('source') || (isDeviceMobile() ? 'mobile' : 'desktop'),
  }
  return data;
}

const checkIfLogin = () => {
  const appCookies : CookiesDataTypes = getCookies();
  if(!appCookies.userInfo || !appCookies.ecountry || !appCookies.tgtTicket || !appCookies.tgtTimestamp ){
   const url = process.env.REACT_APP_OPENSOOQ_DOMAIN ?? 'https://opensooq.com'
   window.location.href = url;
  }
}

const defaultOptions = {
  expires: process.env.REACT_APP_DEFAULT_COOKIES_EXPIRED_IN_MINUTES,
  path: "/",
  ...(typeof window !== "undefined"
    ? window.location.hostname.includes("localhost")
      ? { domain: window.location.hostname }
      : {
          domain:
            "." +
            window.location.hostname.split(".").reverse()[1] +
            "." +
            window.location.hostname.split(".").reverse()[0],
        }
    : {}),
};

const setCookie = (name : string , value : string, options : DynamicObject)  => {
  // Default values for options:
  let { expires = "" , path = "/", domain = "" } = options;

  // If expires is a number, convert it to minutes and create a Date object:
  if (typeof expires === "number") {
    const minutes = expires;
    const now = new Date();
    now.setTime(now.getTime() + minutes * 60 * 1000);
    expires = now;
  }

  // Construct the cookie string:
  let cookieString = `${name}=${value}`;

  if (expires instanceof Date) {
    cookieString += `;expires=${expires.toUTCString()}`;
  }

  if (path) {
    cookieString += `;path=${path}`;
  }

  if (domain) {
    cookieString += `;domain=${domain}`;
  }

  // Set the cookie:
  document.cookie = cookieString;
}

const removeEmptyValues = (object :DynamicObject) => {
  for (var key in object) {
      if (object.hasOwnProperty(key)) {
          var value = object[key];
          if (value === null || value === undefined || value === '') {
              delete object[key];
          }
      }
  }
}

const deleteCookie = ( name : string) =>  {
  document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`
}

const logError = (error : any,url : string, path:string ) => {
  const errorCode = (error.response && error.response.status ) ? error.response.status : 0
  if(errorCode != 0){
      const link = document.createElement('a');
      link.href = url;
      trackEvent(errorCode,'Error',path,url);
  }
}

const log404Error = (msg : string,errorType : string) => {
  return Api.getInstance().request.post(`logging/tech-log`,{
    level : errorType,
    category : "My OS 404 Error",
    msg : msg
  })
}

const shorten = async (data : DynamicObject) => {
  return shortUrlApi(data)
}

const getAddPostIntegrationUrl = (lang : string,type : string , id : number,draftId : number) => {
      return `${process.env.REACT_APP_ADD_POST}?v=2&source=${getCookies().source}&lang=${lang}&flow=${type}&draft_id=${draftId}&post_id=${id}&from=my-opensooq&return=${window.location.href}`;
}

const editListing = (listing : DynamicObject,type : string) => {
    setCookie("addPostRef",encodeURIComponent(window.location.href), defaultOptions);
    window.location.href = getAddPostIntegrationUrl(getCookies().NEXT_LOCALE,type,listing.listing_id ?? 0,listing.draft_id ?? 0);
}

const showChatInCountry = () : boolean =>{
  return true;
}

const isCountryIncluded = ()=>{
  const ecountry = getCookies().ecountry
  const excludedCountriesString = process.env.REACT_APP_EXCLUDE_CHAT_FROM_COUNTRIES
  let excludedCountries : string[] = []
  let index = -1
  if(excludedCountriesString){
    excludedCountries = excludedCountriesString.split(',')
    index = excludedCountries.findIndex((country => country.toLowerCase() === ecountry.toLowerCase()))
  }
  return (index < 0) ? true : false;
}


const isDomainIncluded = () : boolean => {
  let currentDomain = window.location.hostname;
  let domainParts = currentDomain.split('.'); // Split the domain into parts
  if (domainParts.length > 2) {
    currentDomain = domainParts.slice(1).join('.');
  }
  console.log(currentDomain);
  const excludedDomainsString = process.env.REACT_APP_EXCLUDE_CHAT_FROM_DOMAINS
  let excludedDomains : string[] = []
  let index = -1
  if(excludedDomainsString){
    excludedDomains = excludedDomainsString.split(',')
    index = excludedDomains.findIndex((country => country.toLowerCase() === currentDomain.toLowerCase()))
  }
  return index < 0 ? true : false;
}
export {editListing,getAddPostIntegrationUrl,shorten,log404Error,logError,deleteCookie,removeEmptyValues,generateOpenSooqRedirectUrl,getDomainWithoutSubdomain,logout,getContactUsByCountry,getSocialUrlsByCountry,redirectOut,formateDate,canTakeAction,takeAction,checkIfLogin,getCookies,defaultOptions,setCookie,showChatInCountry}
