import Cookies from "js-cookie";
import { isMobile } from "react-device-detect";
import DynamicObject from "../../../../models/dynamic-object";
import { MEMBER_COUNTRY_COOKIE, MEMBER_INFO_COOKIE, TGT_TICKET_COOKIE, TGT_TIMESTAMP_COOKIE, VIEWED_LISTING_COOKIE } from "../../../helpers/CookiesVariables";
import { getCookies } from "../../../helpers/commonFunctions";

const JOB_POSTER_TYPE = 'JobPoster'
const JOB_SEEKER_TYPE = 'JobSeeker'

const formatNumber = (n : number) => {
    var parts = n.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
}

const checkIfToday = (date : number) => {
    const  inputDate = new Date(date);
    const  today = new Date();
    return (today.toDateString() == inputDate.toDateString());
}

const checkIfWeek = (date : number) => {
    const  inputDate = new Date(date);
    const  day = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000)
    return (inputDate.toDateString() > day.toDateString());
}

const checkIfMonth = (date : number) => {
    const  inputDate = new Date(date);
    const  today = new Date()
    return (today.getMonth() == inputDate.getMonth());
}

const checkYesterday = (date : number) => {
    const  inputDate = new Date(date).getDate();
    const   today = new Date().getDate()
    const   yesterday = today - 1
    const  diffDays = inputDate - yesterday;
    return (diffDays == 1);
}

enum ChatFactoryTypes {
    NEW_CHAT = 'NEW_CHAT',
    LISTING_CHATS = 'LISTING_CHATS',
    UNREAD_ROOMS = 'UNREAD_ROOMS',
}

enum ListingWidgetTypes {
    STATUS = 'STATUS',
    PQS = 'PQS',
    VIEWS = 'VIEWS',
    INFO = 'INFO',
    APPLICANTS = 'APPLICANTS'
}

const getRecentlyViewed = () => {
    const cookies = getCookies();
    const countryCode = cookies.ecountry;
    const recentViewedString     =   localStorage.getItem(`${countryCode}_${VIEWED_LISTING_COOKIE}`) || localStorage.getItem(`_${VIEWED_LISTING_COOKIE}` || "");
    let viewedAdsList:string[] = recentViewedString?.replace(/"/g, "").split(",").filter(Boolean) ?? [];
    return viewedAdsList;
}

const getWidgetByType = (widgets : DynamicObject,type : string) => {
    const data =  widgets.filter((widget : DynamicObject) => widget.type == type);
    if(data[0]){
        return data[0];
    }
    return null;
}

export {getWidgetByType,ListingWidgetTypes,formatNumber,checkIfToday,checkIfWeek,checkYesterday,checkIfMonth,ChatFactoryTypes,JOB_POSTER_TYPE,getRecentlyViewed,JOB_SEEKER_TYPE}