import { useIntl } from "react-intl"
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import DynamicObject from "../../../models/dynamic-object";
import { EDIT } from "../../helper/Ctas";
import { VAS_BUMPUP, VAS_PREMIUM, VAS_TURBO, VAS_VIP } from "../../helper/VasServicrs";
import { canTakeAction, editListing, formateDate } from "../../helpers/commonFunctions";
import { MyListingViewInterface } from "../../interfaces/MyListingViewInterface"
import { Button } from "../../layout/components/opensooq/Button";

type Props = {
    listing ?: DynamicObject
}

export function ListingFeatures({listing} : Props) {
    const Intl = useIntl();
    let navigate = useNavigate(); 
    const hasBumpupWarning : DynamicObject = listing ? listing.vas_count.find((warning : any) => { return warning.service === VAS_BUMPUP }) ?? {} : {};
    const hasPremiumWarning : DynamicObject= listing ? listing.vas_count.find((warning : any) => { return warning.service === VAS_PREMIUM })  ?? {} : {};
    const hasTurboWarning  : DynamicObject= listing ? listing.vas_count.find((warning : any) => { return warning.service === VAS_TURBO })  ?? {} : {};
    const hasVipWarning  : DynamicObject= listing ? listing.vas_count.find((warning : any) => { return warning.service === VAS_VIP })  ?? {} : {};

    const canEdit = () => {
        let canEdit = false;
        if(listing){
            listing.ctas.map((cta : DynamicObject,index : number) => {
                if(cta.cta == EDIT){
                    canEdit = true;
                }
            });
        }
        return canEdit
    }

    const isEnabled = () => {
        let isEnabled = false;
        if(listing){
            listing.ctas.map((cta : DynamicObject,index : number) => {
                if(cta.cta == EDIT && cta.cta.enabled){
                    isEnabled = true;
                }
            });
        }
        return isEnabled
    }

    const editName = () => {
        let editName = '';
        if(listing){
            listing.ctas.map((cta : DynamicObject,index : number) => {
                if(cta.cta == EDIT){
                    editName = cta.label
                }
            });
        }

        return editName
    }

    return <div className="card card-flush py-4 ">
        <div className="card-header">
            <div className="card-title align-items-start flex-column">
                <h2>{Intl.formatMessage({id : "myListingView.text14"})}</h2>
                <div className="d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400">{Intl.formatMessage({id : "myListingView.text15"})}</div>
            </div>
        </div>
        <div className="card-body pt-0">
            <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-auto">
                <tbody className="fw-semibold text-gray-600">
                    <tr>
                        <td className="text-muted">
                            <div className="d-flex align-items-center">
                                <span className="svg-icon svg-icon-2 me-2">
                                    <i className="fas fa-calendar-plus fs-4"></i>
                                </span>
                                {Intl.formatMessage({id : "myListingView.text20"})}
                                <i id="dateCreated" className="fas fa-exclamation-circle ms-1 fs-7" ></i>
                            </div>
                        </td>
                        <td className="fw-bold text-end ltrDir">{listing ? listing.date_created : <Skeleton className="w-100px"/>}</td>
                    </tr>
                    <tr>
                        <td className="text-muted">
                            <div className="d-flex align-items-center">
                                <span className="svg-icon svg-icon-2 me-2">
                                    <i className="fas fa-calendar-plus fs-4"></i>
                                </span>
                                {Intl.formatMessage({id : "myListingView.text21"})}
                                <i id="datePublished" className="fas fa-exclamation-circle ms-1 fs-7" ></i>
                            </div>
                        </td>
                        <td className="fw-bold text-end ltrDir">{listing ? listing.date_created : <Skeleton className="w-100px"/>}</td>
                    </tr>
                    <tr>
                        <td className="text-muted">
                            <div className="d-flex align-items-center">
                                <span className="svg-icon svg-icon-2 me-2">
                                    <i className="fas fa-calendar-minus fs-4"></i>
                                </span>
                                {Intl.formatMessage({id : "myListingView.text22"})}
                                <i id="dateOfExpiry" className="fas fa-exclamation-circle ms-1 fs-7" ></i>
                            </div>
                        </td>
                        <td className="fw-bold text-end ltrDir">{listing ? listing.date_of_expiry : <Skeleton className="w-100px"/>}</td>
                    </tr>
                    {listing && listing.edits_left && <tr>
                        <td className="text-muted">
                            <div className="d-flex align-items-center">
                                <span className="svg-icon svg-icon-2 me-2">
                                    <i className="fa-solid fa-pen-to-square"></i>
                                </span>
                                {Intl.formatMessage({id : "myListingView.text23"})}
                                <i id="editsLeft" className="fas fa-exclamation-circle ms-1 fs-7" ></i>
                            </div>
                        </td>
                        <td className="fw-bold text-end ltrDir">{listing ? (listing.edits_left > -1 ) ? listing.edits_left : Intl.formatMessage({id : 'myOpenSooq.unlimited'}) : <Skeleton className="w-100px"/>}</td>
                    </tr>}
                    <tr className={(listing && listing.vas_count.hasOwnProperty(VAS_BUMPUP)) ? `border-bottom-0 p-0` : ''}>
                        <td className="text-muted hoverPointer">
                            <div className="d-flex align-items-center">
                                <span className="svg-icon svg-icon-2 me-2">
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M9.27083 9.375H20.7292V12.75L25.3125 8.25L20.7292 3.75V7.125H6.97917V13.875H9.27083V9.375ZM20.7292 20.625H9.27083V17.25L4.6875 21.75L9.27083 26.25V22.875H23.0208V16.125H20.7292V20.625Z" fill="#0E91F9"/> </svg>
                                </span>
                                <span className="text-primary"> {Intl.formatMessage({id : "myListingView.text60"})}</span>
                                <i id="PromotedBumpup" className="fas fa-exclamation-circle ms-1 fs-7" ></i>
                            </div>
                        </td>
                        <td className="fw-bold text-end">{listing ? listing.vas_count.find((vas : DynamicObject) => { return vas.service === VAS_BUMPUP })?.count ?? 0 : <Skeleton className="w-20px"/>}</td>
                    </tr>
                    {listing && hasBumpupWarning.icon && <tr className={(listing && listing.vas_count.hasOwnProperty(VAS_BUMPUP)) ? `border-top-0 p-0` : ''}>
                        <td colSpan={2} className="p-0 pb-4 pt-0">
                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-2 ">
                                <div className="d-flex flex-stack flex-grow-1">
                                    <div className="fw-semibold">
                                        <div className="fs-6 text-gray-700 ltrDir">
                                            <span>{hasBumpupWarning.text}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>}
                    <tr className={(listing && listing.vas_count.hasOwnProperty(VAS_PREMIUM)) ? `border-bottom-0 p-0` : ''}>
                        <td className="text-muted hoverPointer">
                            <div className="d-flex align-items-center">
                                <span className="svg-icon svg-icon-2 me-2">
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M23.5861 12.2539C23.5861 17.1231 19.745 21.0693 15 21.0693C10.2551 21.0693 6.41398 17.1231 6.41398 12.2539C6.41398 7.38466 10.2551 3.46159 15 3.46159C19.745 3.46159 23.5861 7.38466 23.5861 12.2539ZM6.34619 23.7462L10.0518 22.8L11.2493 26.5385L13.7347 21.9231C11.769 21.6462 10.0066 20.7693 8.62828 19.477L6.34619 23.7462ZM21.3718 19.477C19.9709 20.7693 18.2085 21.6462 16.2654 21.9231L18.7508 26.5385L19.9483 22.8L23.6539 23.7462L21.3718 19.477Z" fill="#F5A623"/> </svg>
                                </span>
                                <span className="text-primary"> {Intl.formatMessage({id : "myListingView.text24"})}</span>
                                <i id="PromotedPremium" className="fas fa-exclamation-circle ms-1 fs-7" ></i>
                            </div>
                        </td>
                        <td className="fw-bold text-end">{listing ? listing.vas_count.find((vas : DynamicObject) => { return vas.service === VAS_PREMIUM })?.count ?? 0 : <Skeleton className="w-20px"/>}</td>
                    </tr>
                    {listing && hasPremiumWarning.icon && <tr className={(listing && listing.vas_count.hasOwnProperty(VAS_PREMIUM)) ? `border-top-0 p-0` : ''}>
                        <td colSpan={2} className="p-0 pb-4 pt-0">
                                <div  className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-2 ">
                                    <div className="d-flex flex-stack flex-grow-1">
                                    <div className="fw-semibold">
                                        <div className="fs-6 text-gray-700">
                                            <span>{hasPremiumWarning.text}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>}
                    <tr className={(listing && listing.vas_count.hasOwnProperty(VAS_TURBO)) ? `border-bottom-0 p-0` : ''}>
                        <td className="text-muted hoverPointer">
                            <div className="d-flex align-items-center">
                                <span className="svg-icon svg-icon-2 me-2">
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M16.7612 7.7035C21.2819 3.21089 26.2263 3.77246 26.2263 3.77246C26.2263 3.77246 26.7914 8.82665 22.412 13.3193C20.293 15.4252 17.3263 17.2503 14.9247 18.6542L15.6311 22.1641L13.3708 24.4104L11.393 20.4794C10.5454 20.7601 9.98032 21.0409 9.98032 21.0409L8.85017 19.9178C8.85017 19.9178 8.99144 19.3562 9.41525 18.5138L5.45971 16.5483L7.72002 14.302L11.2517 15.1444C12.6644 12.6173 14.5009 9.80941 16.7612 7.7035ZM19.4454 10.5114C20.1517 11.073 21.1406 11.073 21.7057 10.5114C22.2708 9.80941 22.2708 8.82665 21.7057 8.26507C20.9993 7.7035 20.0104 7.7035 19.4454 8.26507C18.8803 8.96704 18.8803 9.9498 19.4454 10.5114ZM5.60092 24.2705C5.60092 24.2705 8.14376 24.832 9.27392 23.7089C10.4041 22.5858 10.6866 21.603 10.6866 21.603C10.6866 21.603 11.1104 23.4281 9.83899 24.6916C7.57869 26.9379 3.90568 26.0955 3.90568 26.0955C3.90568 26.0955 3.05807 22.4454 5.31838 20.1991C6.44853 18.9356 8.28503 19.3568 8.28503 19.3568C8.28503 19.3568 7.29615 19.4972 6.16599 20.6203C5.03584 21.7434 5.60092 24.2705 5.60092 24.2705Z" fill="#F93852"/>
                                    </svg>
                                </span>
                                <span className="text-primary">{Intl.formatMessage({id : "myListingView.text25"})}</span>
                                <i id="PromotedTurbo" className="fas fa-exclamation-circle ms-1 fs-7" ></i>
                            </div>
                        </td>
                        <td className="fw-bold text-end">{listing ? listing.vas_count.find((vas : DynamicObject) => { return vas.service === VAS_TURBO })?.count ?? 0 : <Skeleton className="w-20px"/>}</td>
                    </tr>
                    {listing && hasTurboWarning.icon && <tr className={(listing && listing.vas_count.hasOwnProperty(VAS_TURBO)) ? `border-top-0 p-0` : ''}>
                        <td colSpan={2} className="p-0 pb-4 pt-0">
                            <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-2  ">
                                <div className="d-flex flex-stack flex-grow-1">
                                    <div className="fw-semibold">
                                        <div className="fs-6 text-gray-700">
                                            <span>{hasTurboWarning.text}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>}
                    <tr className={(listing && listing.vas_count.hasOwnProperty(VAS_VIP)) ? `border-bottom-0 p-0` : ''}>
                        <td className="text-muted hoverPointer">
                            <div className="d-flex align-items-center">
                                <span className="svg-icon svg-icon-2 me-2">
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M4.87372 9L7.4578 17.4378H7.48951L10.1053 9H12.7369L8.8846 21H6.01515L2.25 9H4.87372Z" fill="#F5A623"/> <path d="M16.2722 9V21H13.7198V9H16.2722Z" fill="#F5A623"/> <path d="M23.8184 9C25.0232 9 25.9797 9.35294 26.6878 10.0588C27.3959 10.7592 27.75 11.6895 27.75 12.8495C27.75 14.0041 27.3959 14.9343 26.6878 15.6402C25.9797 16.3461 25.0232 16.699 23.8184 16.699H21.0599V21H18.5075V9H23.8184ZM23.1129 14.6471C23.8475 14.6471 24.3917 14.5048 24.7458 14.2202C25.0999 13.9357 25.2769 13.4788 25.2769 12.8495C25.2769 12.2367 25.1051 11.7852 24.7617 11.4952C24.4182 11.1997 23.8712 11.052 23.1208 11.052H21.0599V14.6471H23.1129Z" fill="#F5A623"/> </svg>
                                </span>
                                <span className="text-primary"> {Intl.formatMessage({id : "myListingView.text26"})}</span>
                                <i id="PromotedVip" className="fas fa-exclamation-circle ms-1 fs-7" ></i>
                            </div>
                        </td>
                        <td className="fw-bold text-end">{listing ? listing.vas_count.find((vas : DynamicObject) => { return vas.service === VAS_VIP })?.count ?? 0 : <Skeleton className="w-20px"/>}</td>
                    </tr>
                    {listing && hasVipWarning.icon && <tr className={(listing && listing.vas_count.hasOwnProperty(VAS_VIP)) ? `border-top-0 p-0` : ''}>
                        <td colSpan={2} className="p-0 pb-4 pt-0">
                            <div  className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-2">
                                <div className="d-flex flex-stack flex-grow-1">
                                    <div className="fw-semibold">
                                        <div className="fs-6 text-gray-700">
                                            <span>{hasVipWarning.text}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>}
                </tbody>
            </table>
            {listing && canEdit() && <div className="card-footer d-flex mt-2 p-0 justify-content-end">
                <button  className="btn btn-primary btn-sm mt-3" disabled={isEnabled()} onClick={() => {editListing(listing,'edit')}}>{editName()}</button>
            </div>}
        </div>
    </div>
}

export default (ListingFeatures)