import { useEffect, useState } from 'react'
import {Modal} from 'react-bootstrap'
import { useIntl } from 'react-intl'
import editPhoneApi from '../../api/profile/editPhone.api'
import verifyViaSmsApi from '../../api/profile/verifyViaSms.api'
import verifyViaWhatsAppApi from '../../api/profile/verifyViaWhatsApp.api'
import { KTSVG } from '../../app/helpers'
import { useLang } from '../../app/i18n/OpenSooqi18n'
import { showError, showSuccess } from '../helper/SystemAlert'
import { CountriesInterface } from '../interfaces/MyProfileInterface'
import { CountriesSelect } from './CountriesSelect'

type ComponentsProps = {
    show : boolean
    countriesList : CountriesInterface[]
    phone : string
    currentCountry : any
    handleCloseEditPhone : () => void
    changeEditStep : () => void
    step : number
}

const ChangePhone = (props : ComponentsProps) => {
    const [showCountriesList,setShowCountriesList] = useState<boolean>(false)
    const [country,setCountry] = useState<CountriesInterface>(props.currentCountry)
    const [errorMessage,setErrorMessage] = useState<string>('')
    const [phone,setPhone] = useState<string>(props.phone)
    const [showPopup,setShowPopup] = useState<boolean>(false)
    const lang = useLang();
    const Intl = useIntl()
    window.addEventListener("click", (e : any) => {
        if(e.target.id != 'countriesList' && !e.target.classList.contains('showCountriesList')){
            setShowCountriesList(false);
        }
    })

    useEffect(() => {
        setShowPopup(props.show)
        if(props.currentCountry){
            setCountry(props.currentCountry);
        }
    },[props])

    const changeCountry = (country : CountriesInterface) => {
        setCountry(country);
    }

    const editMobile = () => {
        setErrorMessage('')
        const phoneData = {
            phone_number : phone,
            country_code : country.phone_code
        }

        editPhoneApi(phoneData).then(() => {
            props.changeEditStep();
        }).catch((e) => {
            setErrorMessage(e.response.data.message)
        });
    }

    const changePhone = (e : any) => {

        if (e.target.value === "" || !isNaN(+e.target.value)) {
            if(phone.length <= 6 && e.target.value !== "")
            {
                setErrorMessage(Intl.formatMessage({id : "myProfile.profileDetails.text20"}));
            }else{
                setErrorMessage("");
            }
          setPhone(e.target.value);
        }else {
            e.preventDefault();
        }
    }

    const verifyViaWhats = () => {
        const smsData = {
            phone_number : phone,
            country_code : country.phone_code,
            flow_type : 'ACTION_EDIT_MOBILE'
        }
        verifyViaWhatsAppApi(smsData).then((response) => {
            setShowPopup(false)
            showSuccess(Intl,lang,Intl.formatMessage({id : "myProfile.profileDetails.text24"}))
        }).catch((error) => {
            showError(Intl,lang);
        })
    }

    const verifyViaSms = () => {
        const smsData = {
            phone_number : phone,
            country_code : country.phone_code,
            flow_type : 'ACTION_EDIT_MOBILE'
        }

        verifyViaSmsApi(smsData).then((response) => {
            showSuccess(Intl,lang,Intl.formatMessage({id : "myProfile.profileDetails.text24"}))
            setShowPopup(false)
        }).catch(() => {
            showError(Intl,lang);
        })
    }

    return <>
        <Modal tabIndex={-1} aria-hidden='true' dialogClassName='c-dialog modal-dialog-centered w-400px'  onHide={props.handleCloseEditPhone}  show={showPopup}>
            <div className="card">
                <div className="card-header d-flex align-items-center">
                    <h3 className="card-title align-items-start flex-column">
                        <span  className="card-label fw-bold text-dark">{Intl.formatMessage({id : "myProfile.profileDetails.text17"})}</span> 
                        <span className="text-muted mt-1 fw-semibold fs-7">{Intl.formatMessage({id : "myProfile.profileDetails.text18"})}</span>
                    </h3>
                    <div onClick={props.handleCloseEditPhone} className="btn btn-icon btn-sm btn-active-light-primary ms-2"  data-bs-dismiss="modal"  aria-label="Close" >
                        <KTSVG path="/media/icons/duotune/arrows/arr061.svg"  className="svg-icon svg-icon-2x"  />
                    </div>
                </div>
                <div className='card-body'>
                    {props.step == 1 && <><div className="input-group select-group mb-4">
                            <div className="input-group ltrDir">
                                <span className="input-group-text b-r-0 border-start-dashed hoverPointer showCountriesList ltrDir radius-0" id="basic-addon1 "  onClick={() => {setShowCountriesList(true)}}>
                                    <img className="showCountriesList w-25px pe-2" src={country?.icon}/> <span className='showCountriesList '>+{country?.phone_code} <i className="fas fa-arrow-down"></i></span>
                                </span>
                                <input type="text" value={phone} pattern="[0-9]*" className="form-control b-r-0" placeholder={Intl.formatMessage({id : "myProfile.profileDetails.text5"})} onChange={(e) => {changePhone(e)}}/>
                            </div>
                            {errorMessage != '' && <span className='text-danger' >{errorMessage}</span>}
                        </div>
                        {!showCountriesList && <button disabled={ phone == null || phone.length == 0} className='btn btn-primary w-100' onClick={editMobile}>{Intl.formatMessage({id : "myProfile.profileDetails.text19"})}</button>}
                        <CountriesSelect countriesList={props.countriesList} changeCountry={(country) => {changeCountry(country)}} show={showCountriesList}/>
                        </>}
                        {props.step == 2 && <>
                            <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-4 p-6">
                                <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                                        <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
                                        <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
                                    </svg>
                                </span>
                                <div className="d-flex flex-stack flex-grow-1">
                                    <div className="fw-semibold">
                                        <h4 className="text-gray-900 fw-bold">{Intl.formatMessage({id : "myProfile.profileDetails.text20"})}</h4>
                                        <span>{Intl.formatMessage({id : "myProfile.profileDetails.text21"})} {phone}</span> 
                                    </div>
                                </div>
                            </div>
                            <div onClick={verifyViaWhats} className="border border-dashed border-gray-300 text-center  grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer"  >
                                <div className="d-flex fs-6 fw-semibold align-items-center h-30px ">
                                    <div className="flex-grow-1">
                                        <div className="text-gray-800 text-hover-primary fw-bold fs-6">
                                            <svg  width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M20.224 3.72825C18.0423 1.53492 15.1373 0.333252 12.0456 0.333252C5.67562 0.333252 0.483959 5.52492 0.483959 11.8949C0.483959 13.9366 1.02063 15.9199 2.02396 17.6699L0.390625 23.6666L6.51563 22.0566C8.20729 22.9783 10.109 23.4683 12.0456 23.4683C18.4156 23.4683 23.6073 18.2766 23.6073 11.9066C23.6073 8.81492 22.4056 5.90992 20.224 3.72825ZM12.0456 21.5083C10.319 21.5083 8.62729 21.0416 7.14563 20.1666L6.79563 19.9566L3.15563 20.9133L4.12396 17.3666L3.89063 17.0049C2.93396 15.4766 2.42063 13.7033 2.42063 11.8949C2.42063 6.59825 6.73729 2.28159 12.034 2.28159C14.6006 2.28159 17.0156 3.28492 18.824 5.10492C20.644 6.92492 21.6356 9.33992 21.6356 11.9066C21.659 17.2033 17.3423 21.5083 12.0456 21.5083ZM17.319 14.3216C17.0273 14.1816 15.604 13.4816 15.3473 13.3766C15.079 13.2833 14.8923 13.2366 14.694 13.5166C14.4956 13.8083 13.9473 14.4616 13.784 14.6483C13.6206 14.8466 13.4456 14.8699 13.154 14.7183C12.8623 14.5783 11.929 14.2633 10.8323 13.2833C9.96896 12.5133 9.39729 11.5683 9.22229 11.2766C9.05896 10.9849 9.19896 10.8333 9.35063 10.6816C9.47896 10.5533 9.64229 10.3433 9.78229 10.1799C9.92229 10.0166 9.98063 9.88825 10.074 9.70159C10.1673 9.50325 10.1206 9.33992 10.0506 9.19992C9.98063 9.05992 9.39729 7.63659 9.16396 7.05325C8.93062 6.49325 8.68563 6.56325 8.51063 6.55159C8.33563 6.55159 8.14896 6.55159 7.95062 6.55159C7.75229 6.55159 7.44896 6.62159 7.18062 6.91325C6.92396 7.20492 6.17729 7.90492 6.17729 9.32825C6.17729 10.7516 7.21563 12.1283 7.35563 12.3149C7.49563 12.5133 9.39729 15.4299 12.2906 16.6783C12.979 16.9816 13.5156 17.1566 13.9356 17.2849C14.624 17.5066 15.254 17.4716 15.7556 17.4016C16.3156 17.3199 17.4706 16.7016 17.704 16.0249C17.949 15.3483 17.949 14.7766 17.8673 14.6483C17.7856 14.5199 17.6106 14.4616 17.319 14.3216Z" fill="#25D366" />
                                            </svg>
                                            <span className='ms-2' >{Intl.formatMessage({id : "myProfile.profileDetails.text22"})}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div onClick={verifyViaSms} className="border border-dashed border-gray-300 text-center  grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer"  >
                                <div className="d-flex fs-6 fw-semibold align-items-center h-30px ">
                                    <div className="flex-grow-1">
                                        <div className="text-gray-800 text-hover-primary fw-bold fs-6">
                                            <i className="fs-2 text-primary fas fa-sms"></i>
                                            <span className='ms-2 '>{Intl.formatMessage({id : "myProfile.profileDetails.text23"})}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}
                </div>
            </div>
            
        </Modal>
    </>
}

export {ChangePhone};