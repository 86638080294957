import Skeleton from "react-loading-skeleton"

const TrLoader = () => {
    return <tr className="" >
    <td >
        <Skeleton className="w-100"/>
    </td>
</tr>
}

export {TrLoader}