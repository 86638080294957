import { useState } from "react";
import { useIntl } from "react-intl"
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import DynamicObject from "../../../models/dynamic-object";
import { EDIT } from "../../helper/Ctas";
import { VAS_BUMPUP, VAS_PREMIUM, VAS_TURBO, VAS_VIP } from "../../helper/VasServicrs";
import { canTakeAction, editListing, formateDate } from "../../helpers/commonFunctions";
import { MyListingViewInterface } from "../../interfaces/MyListingViewInterface"
import { IframeModal } from "../../layout/components/modals/IframeModal";
import { Button } from "../../layout/components/opensooq/Button";

type Props = {
    listing ?: DynamicObject
    action :  () => any
}

export function ListingQuality({listing,action} : Props) {
    const [showEditListingModal, setShowEditListingModal] = useState(false)
    const Intl = useIntl();

    const canEdit = () => {
        let canEdit = false;
        if(listing){
            listing.ctas.map((cta : DynamicObject,index : number) => {
                if(cta.cta == EDIT){
                    canEdit = true;
                }
            });
        }
        return canEdit
    }

    const isEnabled = () => {
        let isEnabled = false;
        if(listing){
            listing.ctas.map((cta : DynamicObject,index : number) => {
                if(cta.cta == EDIT && cta.cta.enabled){
                    isEnabled = true;
                }
            });
        }
        return isEnabled
    }

    const editName = () => {
        let editName = '';
        if(listing){
            listing.ctas.map((cta : DynamicObject,index : number) => {
                if(cta.cta == EDIT){
                    editName = cta.label
                }
            });
        }

        return editName
    }

    return  <>{listing &&  <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
        <div className="card-header">
            <div className="card-title align-items-start flex-column">
                <h2>{Intl.formatMessage({id : "myListingView.text6"})}</h2>
                <div className="d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400">{Intl.formatMessage({id : "myListingView.text7"})}</div>
            </div>
        </div>
        <div className="card-body pt-0">
            <div className="notice  rounded  border border-dashed p-6">
                <div className="d-flex   flex-column mt-3 pb-5">
                    <div className="py-1">
                        <span className="text-dark fs-1 fw-bold me-2">{listing.pqs.value}%</span>
                        <span className="fw-semibold text-gray-800 fs-7">{Intl.formatMessage({id : "myListingView.text8"})} </span>
                    </div>
                    <div className="h-5px  w-100 bg-light mb-3">
                        <div className="bg-success rounded h-5px"  style={{width: `${listing.pqs.value}%`}} ></div>
                    </div>
                </div>
            </div>
           {canEdit() && <table className="table align-middle table-row-bordered mb-0 fs-6 min-w-300px">
                <tbody className="fw-semibold text-gray-600">
                    {listing.pqs.missing_items &&  listing.pqs.missing_items.map((item : any,index : number) => {
                        return <tr key={index}>
                        <td className="text-muted">
                            <div className="d-flex align-items-center">
                                {item}
                            </div>
                        </td>
                        <td className="fw-bold text-end">
                            <span className={'text-primary hoverPointer'} onClick={() => {editListing(listing,'edit')}}>{Intl.formatMessage({id : "myListingView.text10"})}</span>
                        </td>
                    </tr>
                    })}
                </tbody>
            </table>}
            {canEdit() && <div className="card-footer d-flex mt-2 p-0 justify-content-end">
                <button className="btn btn-sm btn-primary mt-3" disabled={isEnabled()} onClick={() => {editListing(listing,'edit')}}>{editName()}</button>
            </div>}
            <IframeModal show={showEditListingModal} id={listing?.listing_id} type={'edit'} handleClose={() => {setShowEditListingModal(false)}} />
        </div>
    </div>}</>
}