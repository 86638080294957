import b64_safe from './b64_safe';
import random from './random';
import hmac from './hmac';
import { getCookies } from '../../app/helpers/commonFunctions';

export const generate_short_token_secret = async ({aud, rnd, at0}, tgt) => {
    const audience_secret = "";
    return await hmac({key: tgt, msg: `${aud}.${audience_secret}.${rnd}.${at0}`});
};

const getDeltaTimeServer = () => {
    return 0;
    if (!window.myStorage || !window.myStorage.serverDeltaTime) {
        return 0;
    }
    return parseInt(window.myStorage.serverDeltaTime, 10);
};

export default async function generate_jwt(tgt_timestamp, tgt, member_id) {
    const header_json = '{"alg":"HS256","typ":"JWT"}';
    const header_b64 = b64_safe(header_json);
    const cookiesData = getCookies();
    const memberId = member_id.toString();
    if(memberId){
        const payload = {
            sub: parseInt(memberId),
            aud: cookiesData.audience,
            rnd: random(),
            at0: parseInt(tgt_timestamp, 10),
            exp: parseInt((Date.now() / 1000) + getDeltaTimeServer(), 10) + 300
        };
        const payload_json = JSON.stringify(payload);
        const payload_b64 = b64_safe(payload_json);
        const data = `${header_b64}.${payload_b64}`;
        const secret = await generate_short_token_secret(payload, tgt);
        const signature = await hmac({key: secret, msg: data});
        return `${data}.${signature}`;
    }

    return ''
    
}