interface Props{
    ratingNumber    ?: number
    percentage      ?: number
}

const RatingComponent = (props: Props) => {
    return (
        <>
            <div className="w-100">
                <div className="d-flex align-items-center w-100 ">
                    <span className="fs-1"> {props.ratingNumber}</span>
                    <i className="fas fa-star ps-1 pe-1 fs-2 " style={{color: "#FFAD0F"}}></i>
                    <div className="progress h-6px w-100 me-2 bg-light-warning">
                        <div className="progress-bar " role="progressbar" style={{backgroundColor: "#FFAD0F",width: `${props.percentage}%`}} aria-valuenow={props.percentage} aria-valuemin={0} aria-valuemax={100}></div>
                    </div>
                    <span className="text-gray-400 fw-semibold">{props.percentage}%</span>
                </div>
            </div>
        </>
    )
}

export {RatingComponent}