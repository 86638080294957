import DynamicObject from "../../models/dynamic-object";
import { AxiosResponse} from "axios";
import Api from "../api";

export default (url : string,config?: DynamicObject): Promise<AxiosResponse> => {
    let newUrl = url.replace(/,/g,",")
    const fullUrl = newUrl.replace('http:',"https:")
    const path = 'vertical/forms/drafts'
    const apiUrl : string = (url == '') ?   `${path}?limit=2` : fullUrl
    return Api.getInstance(true,true).request.get(apiUrl, path)
}

