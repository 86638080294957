/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import { Link } from 'react-router-dom'
import { useIntl } from "react-intl"
import { useThemeMode } from '../../../partials'
import { ListingStatsInterface } from '../../../interfaces/ListingStatsInterface'
import Moment from 'moment';
import { Tooltip } from 'react-tooltip'
import { ReactTooltipContainer } from '../../../../app/modules/common/ReactTooltipContainer'

type Props = {
  adId        : string
  className   : string
  chartType   : ListingStatsInterface
  title       : string
  description : string
  buttonText  ?: string
  total       : number,
  target      : string 
  extraText   ?: string
  chartName : string
}

const AccountChart: React.FC<Props> = ({className,chartType,title,description,buttonText,total,target,adId,extraText,chartName}) => {
    
    const min = new Date(chartType.min * 1000)
    const max = new Date(chartType.max * 1000);
    const employee : any = {
        ...chartType.free,
        ...chartType.premium
    };

    const res = Object.entries(employee).reduce((acc,[k,v])=>{
      acc[k] = (acc[k] || 0) + v;
      return acc;
    }, {...employee});


    const categoriesFreeKeys = Object.keys(res);
    const categoriesFreeValues = Object.values(res);

    for (const key in categoriesFreeKeys) {
      categoriesFreeKeys[key] = Moment.unix(parseInt(categoriesFreeKeys[key])).format("YYYY-MM-DD");
    }


    function toTimestamp(strDate : Date){
      var datum = Date.parse(strDate.toString());
      return datum/1000;
   }

    
    const chartRef = useRef<HTMLDivElement | null>(null)
    const {mode} = useThemeMode()
    const Intl = useIntl();

    useEffect(() => {
        const chart = refreshChart(categoriesFreeKeys,categoriesFreeValues)
        return () => {
            if (chart) {
              chart.destroy()
            }
        }
    }, [chartRef, mode])

    const refreshChart = (categoriesFreeKeys : {},categoriesFreeValues : {}) => {
        if (!chartRef.current) {
            return
        }

        const height = parseInt(getCSS(chartRef.current, 'height'))

        const chart = new ApexCharts(chartRef.current, getChartOptions(Intl,height,categoriesFreeKeys,categoriesFreeValues,min,max))
        if (chart) {
            chart.render()
        }
        return chart
    }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 '>
        <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
            <span className='text-muted fw-semibold fs-7'>{description}</span>
            {extraText && <span className='text-muted fw-semibold fs-7'>{extraText}</span>}
        </h3>
        <div className='card-toolbar'>
            <div className="d-flex flex-column mt-2">
                <div className="bd-highlight fs-3 fw-bolder">{total.toLocaleString()}</div>
                {/* <div className="bd-highlight fs-3 mt-1">
                    <span className='text-success fw-bolder'>+100% </span>{Intl.formatMessage({id: 'sinceLastWeek'})}
                </div> */}
                {buttonText && <div className="bd-highlight mt-1 fs-3">
                    <Link to={`/listings/my-listings/listing-lead?type=${target}&id=${adId}`} className='btn btn-primary btn-sm'>{buttonText}</Link>
                </div>}
            </div>
        </div>
      </div>

      <div className='card-body'>
        {total == 0 && <div className='p-15 text-center'><h1>{chartName}</h1>  </div>}
        {total > 0 && <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '350px'}} />}
      </div>
      <ReactTooltipContainer/>
    </div>

  )
}

export {AccountChart}

function getChartOptions(Intl : any,height: number,categoriesFreeKeys : {},categoriesFreeValues : any,min : Date,max : Date): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseColor = getCSSVariableValue('--kt-primary')
  const secondaryColor = getCSSVariableValue('--kt-gray-300')

  return {
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    series: [
      {
        name: Intl.formatMessage({id : "adViews.text1"}),
        data: categoriesFreeValues,
      },
    ],
    colors: ['#0179FF'],
    xaxis: {
      type: 'datetime',
      min : min.getTime(),
      max : max.getTime(),
      categories: categoriesFreeKeys,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
      },
    },
    dataLabels: {
      enabled: false,
      dropShadow: {
        enabled: true
      }
    },
  }
}
