import DynamicObject from "../../models/dynamic-object";
import { AxiosResponse} from "axios";
import Api from "../api";
export default (logType : string ,logFrom : string,postId : number,extra ?: string): Promise<AxiosResponse> => {
    const path = 'logging'
    return Api.getInstance().request.post(`${path}/${logType}/${logFrom}`,{
        "Logging" : {
            post_id : postId,
            data : extra
        }
    }, path)
}