import { Button } from "../../../app/layout/components/opensooq/Button";
import { ProfileInterface } from "../../interfaces/ProfileInterface";
import Skeleton from "react-loading-skeleton";
import { useIntl } from 'react-intl'
import { BundleModal } from "../payment/BundleModal";
import { useState } from "react";

interface Props{
    profile ?: ProfileInterface
}

export function AccountOverview({profile}: Props) {
    const [showBundle,setShowBundle] = useState (false);
    const Intl = useIntl();
    return (
        <div className="card  mb-5 mb-xl-8">
            <div className="card-header p-5">
                <h3 className="card-title align-items-start flex-column">
                    <span  className="card-label fw-bold text-dark">{Intl.formatMessage({id: 'myAccount.accountOverviewTitle'})}</span> 
                    <span className="text-muted mt-1 fw-semibold fs-7">{Intl.formatMessage({id: 'myAccount.accountOverviewDesc'})}</span>
                </h3>
            </div>
            <div className="card-body p-5">
                <table className="table align-middle table-row-dashed fs-6  dataTable"  id="kt_table_widget_4_table">
                    <tbody className=" text-gray-600">
                        <tr className="even">
                            <td>
                                <span className="fw-bold text-gray-800 ">{Intl.formatMessage({id: 'myAccount.accountType'})}</span>
                            </td>
                            <td className="text-end">
                                <span className="fw-bold text-gray-800 ">{profile && profile.membership_type} {!profile && <Skeleton className="w-50"/>}</span>
                            </td>
                        </tr>
                        <tr className="odd">
                            <td>
                                <span className="fw-bold text-gray-800 ">{Intl.formatMessage({id: 'myAccount.limitLiveListings'})}</span>
                            </td>
                            <td className="text-end">
                                <span className="fw-bold text-gray-800 ">{profile && profile.limitAccountReport?.liveAdsLimit} {!profile && <Skeleton className="w-50"/>}</span>
                            </td>
                        </tr>
                        <tr className="even">
                            <td>
                                <span className="ms-5 text-gray-800 ">{Intl.formatMessage({id: 'myAccount.liveListings'})}</span>
                            </td>
                            <td className="text-end">
                                <span className="text-gray-800 ">{profile && profile.limitAccountReport?.numberOfPosts} {!profile && <Skeleton className="w-50"/>}</span>
                            </td >
                        </tr>
                        <tr className="odd">
                            <td>
                                <span className="ms-5 text-gray-800 ">{Intl.formatMessage({id: 'myAccount.remainingListings'})}</span>
                            </td>
                            <td className="text-end">
                                <span className="text-gray-800 ">{profile && profile.limitAccountReport?.number_of_remaining} {!profile && <Skeleton className="w-50"/>}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {profile && !profile.isShop && <div className="card-footer p-3">
                <button onClick={() => {setShowBundle(true)}} className="btn btn-primary btn-sm" >{Intl.formatMessage({id: 'myAccount.upgradeAccount'})}</button>
            </div>}
            {profile && <BundleModal  show={showBundle}  type={['MemberShip']} handleClose={() => {setShowBundle(false)}} id={profile.id} pos={'myAccount'}/>}
        </div>
    )
}