import getCarFaxReportApi from '../../../../api/servicrs/get.carFax.report.api'
import { IframeLoader } from '../../../../app/modules/common/IframeLoader'
import {useState, useRef, useEffect} from 'react'
import { KTSVG } from '../../../helpers'
import {Modal} from 'react-bootstrap'

type Props = {
  show: boolean
  handleClose: () => void
  id: number
}

const CarFaxReportView = ({show,handleClose,id}: Props) => {
    const carFaxReport = useRef<null | HTMLIFrameElement>(null);
    const [report,setReport] = useState<string>('');
    const [loader,setLoader] = useState<boolean>(true)
   
    useEffect(() => {
        getCarFaxReportApi(id).then((carFaxReport) => {
            setReport(carFaxReport.data.html)
            setLoader(false)
        }).catch((error) => {
            handleClose();
        })
    },[]);

    return <Modal   aria-hidden='true' dialogClassName='modal-dialog modal-dialog-centered mw-1000px' show={show} onHide={handleClose}  >
        <div className='modal-header p-5'>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
            </div>
        </div>
        <div className='modal-body pt-10 mb-15' style={{height:"1000px",overflowY : "scroll"}}>
            {loader && <IframeLoader/>}
            {!loader && <div className="carFaxReport" ref={carFaxReport} dangerouslySetInnerHTML={{__html: report}}></div>}
        </div>
    </Modal>
}

export {CarFaxReportView}