import { useIntl } from 'react-intl'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { useNavigate } from 'react-router-dom';

export function ReactTooltipContainer() {
    const Intl = useIntl();
    return (
        <>
            <ReactTooltip clickable={true} anchorId="chart-views"  className='w-200px'     place="bottom" content="Here Text Can Be Added" />
            <ReactTooltip clickable={true} anchorId="chart-msgs"   className='w-200px'    place="bottom" content="Here Text Can Be Added" />
            <ReactTooltip clickable={true} anchorId="chart-bookmarks"    className='w-200px'   place="bottom" content="Here Text Can Be Added" />
            <ReactTooltip clickable={true} anchorId="chart-calls"   className='w-200px'    place="bottom" content="Here Text Can Be Added" />
            <ReactTooltip clickable={true} anchorId="chart-impressions"    className='w-200px'   place="bottom" content="Here Text Can Be Added" />
            <ReactTooltip clickable={true} anchorId="avgQuality"     className='w-200px'  place="bottom" content="Here Text Can Be Added" />
            <ReactTooltip clickable={true} anchorId="listingViews"  className='w-200px'   place="bottom" content="Here Text Can Be Added" />
            <ReactTooltip clickable={true} anchorId="listingCalls"  className='w-200px'   place="bottom" content="Here Text Can Be Added" />
            <ReactTooltip clickable={true} anchorId="listingChat"   className='w-200px'   place="bottom" content="Here Text Can Be Added" />
            <ReactTooltip clickable={true} anchorId="listingFavorite" className='w-200px' place="bottom" content="Here Text Can Be Added" />
            <ReactTooltip clickable={true} anchorId="listingShares"   className='w-200px' place="bottom" content="Here Text Can Be Added" />
            <ReactTooltip clickable={true} anchorId="listingStatisticsView"         className="listingStatisticsView w-200px"    place="bottom" content="Here Text Can Be Added" />
            <ReactTooltip clickable={true} anchorId="listingStatisticsCalls"      className='w-200px'  place="bottom" content="Here Text Can Be Added" />
            <ReactTooltip clickable={true} anchorId="listingStatisticsChats"      className='w-200px'  place="bottom" content="Here Text Can Be Added" />
            <ReactTooltip clickable={true} anchorId="listingStatisticsChatsUnread" className="w-200px" place="bottom" content="Here Text Can Be Added" />
            <ReactTooltip clickable={true} anchorId="listingStatisticsFavorites"   className='w-200px' place="bottom" content="Here Text Can Be Added" />
            <ReactTooltip clickable={true} anchorId="listingStatisticsShares"      className='w-200px' place="bottom" content="Here Text Can Be Added" />
            <ReactTooltip clickable={true} anchorId="daysLive"      className='w-200px'    place="bottom" content="The number of days the listing remained live. Note: The number is rounded to next calendar days where the listing is live for any minute in that day is counted as a day. " />
            <ReactTooltip clickable={true} anchorId="daysToExpire"   className='w-200px'   place="bottom" content="The number of days left for the listing to expire. "  />
            <ReactTooltip clickable={true} anchorId="datePublished"   className='w-200px'    place="bottom" content={Intl.formatMessage({id : "tooltip.datePublished"})}   />
            <ReactTooltip clickable={true} anchorId="dateCreated"   className='w-200px'    place="bottom" content={Intl.formatMessage({id :  "tooltip.dateCreated"})}    />
            <ReactTooltip clickable={true} anchorId="editsLeft"   className='w-200px'    place="bottom" content={Intl.formatMessage({id :  "tooltip.editsLeft"})}    />
            <ReactTooltip clickable={true} anchorId="dateOfExpiry"   className='w-200px'    place="bottom" content={Intl.formatMessage({id : "tooltip.dateOfExpiry"})}   />
            <ReactTooltip clickable={true} anchorId="PromotedBumpup" className='w-200px'    place="bottom" content={Intl.formatMessage({id : "tooltip.PromotedBumpup"})} />
            <ReactTooltip clickable={true} anchorId="PromotedPremium" className='w-200px'   place="bottom" content={Intl.formatMessage({id : "tooltip.PromotedPremium"})}/>
            <ReactTooltip clickable={true} anchorId="PromotedTurbo"   className='w-200px'   place="bottom" content={Intl.formatMessage({id : "tooltip.PromotedTurbo"})}  />
            <ReactTooltip clickable={true} anchorId="PromotedVip"     className='w-200px'   place="bottom" content={Intl.formatMessage({id : "tooltip.PromotedVip"})}    />
            <ReactTooltip clickable={true} anchorId="phone"          className='w-200px'   place="right" content="Please ensure its a valid mobile phone number so you can verify it." />
        </>
    )
}