import {FC} from 'react'
import { useIntl } from 'react-intl'
import {Link, useLocation} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../app/helpers'
import { log404Error, logError } from '../../../helpers/commonFunctions'
import DynamicObject from 'opensooq-notification/dist/esm/dynamic-object'

const Error404: FC = () => {
  const Intl = useIntl()
  const location:DynamicObject = useLocation();
  const msg = {
    previousPath : (location.state && location.state.previousPath) ? location.state.previousPath  :  '' ,
    targetPath : (location.state && location.state.currentPath) ? location.state.currentPath :  '',
    refresh : (location.state && location.state.previousPath && location.state.previousPath == '') ? true : false,
    error : 404
  }
  log404Error(JSON.stringify(msg),'error');
  return (
    <div className="text-center">
    <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>{Intl.formatMessage({id : 'pageNotFound'})}</h1>
          <div className='mb-3'>
            <img src={toAbsoluteUrl('/media/auth/404-error.png')} className='mw-100 mh-300px theme-light-show' alt=''
            />
            <img  src={toAbsoluteUrl('/media/auth/404-error-dark.png')} className='mw-100 mh-300px theme-dark-show'  alt='' />
          </div>
          <div className='mb-0'>
            <Link to='/' className='btn btn-sm btn-primary'>
              {Intl.formatMessage({id : 'backToHome'})}
            </Link>
          </div>
    </div>
  )
}

export {Error404}
