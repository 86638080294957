import React, {useEffect, useState} from "react";
import {PopupChat, useChatFunctions, types} from 'opensooq-chat'
import {Modal} from "react-bootstrap";
import {KTSVG} from "../helpers";
import DynamicObject from "../../models/dynamic-object";
import './ChatPopup.css'
import getListingApi from "../../api/listing/get.listing.api";
import getAdApi from "../../api/listing/getAd.api";
import { useNavigate } from "react-router-dom";
import { showChatInCountry } from "../helpers/commonFunctions";

interface ChatOpenPageProps {
    children?: React.ReactNode;
}

const DEFAULT_ROOM = undefined
const ChatOpenPage = (props : ChatOpenPageProps): JSX.Element => {
    const [room, setRoom] = useState<any>(DEFAULT_ROOM)
    const {createRoom} = useChatFunctions()
    const roomId : string = new URLSearchParams(window.location.search).get('room_id') ?? '';

    const closeModalHandler = () => {
        setRoom(DEFAULT_ROOM)
    }

    const createRoomHandler = (data: DynamicObject) => {
        createRoom(data as any).then((room) => {
            setRoom(room)
        })
    }
    const navigate = useNavigate();
    useEffect(() => {
        if(!showChatInCountry()){
            navigate('/');
        }
        const [postId, ownerId, userId] = roomId.split("-", 3)
        getAdApi(parseInt(postId)).then((response) => {
            const listing = response.data
            const data = {
                postId: listing.id,
                ownerId: listing.members_id,
                userId: userId,
                post_country: listing.country_abbr,
                post_img: listing.first_image_thumb,
                post_title: listing.title,
                room: {
                    avatar: listing.searchMember.profile_picture,
                    name: listing.searchMember.full_name
                }
            }
            createRoomHandler(data)
        })
    }, [])


    return (
        <div style={{
            height: "100vh",
        }}>
            {room && <PopupChat newChat={room} onClear={closeModalHandler}/> }
        </div>
    )
}

export default ChatOpenPage