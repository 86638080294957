const ACTIVATE = 'activate';
const PROMOTE = 'promote';
const BUMPUP_FREE = 'bumpup_free';
const BUMPUP = 'bumpup';
const LISTING_STATISTICS = 'listing_statistics';
const VIEW_ON_OPENSOOQ = 'view_on_opensooq';
const SHARE = 'share';
const COPY = 'copy';
const VIEW_CHATS = 'view_chats';
const EDIT = 'edit';
const DELETE = 'delete';
const DELETE_FOR_EVER = 'delete_for_ever';

export {ACTIVATE,PROMOTE,BUMPUP_FREE,BUMPUP,LISTING_STATISTICS,VIEW_ON_OPENSOOQ,SHARE,COPY,VIEW_CHATS,EDIT,DELETE,DELETE_FOR_EVER}