import { ACTIVATE, BUMPUP, BUMPUP_FREE, COPY, DELETE, DELETE_FOR_EVER, EDIT, LISTING_STATISTICS, PROMOTE, SHARE, VIEW_CHATS, VIEW_ON_OPENSOOQ } from "../../helper/Ctas";
import { DeactivateListModal } from "../../layout/components/modals/DeactivateListModal";
import { MyListingViewInterface } from "../../interfaces/MyListingViewInterface"
import { editListing, generateOpenSooqRedirectUrl, getCookies, isDeviceMobile, shorten ,showChatInCountry} from "../../helpers/commonFunctions";
import { IframeModal } from "../../layout/components/modals/IframeModal";
import getDeleteReasons from "../../../api/listing/getDeleteReasons";
import getShortUrlApi from "../../../api/site/getShortUrl.api";
import DynamicObject from "../../../models/dynamic-object";
import { TWITTER_SHARE_URL } from '../../helper/ShareLinks';
import { WantMoreViews } from "../common/WantMoreViews";
import { BundleModal } from "../payment/BundleModal";
import { useLang } from "../../i18n/OpenSooqi18n";
import { CheckCtasIcon } from "../CheckCtasIcon";
import { useNavigate } from "react-router-dom";
import { TrLoader } from "./TrLoader";
import { useIntl } from "react-intl"
import { useState } from "react";
import { showError, showSuccess } from "../../helper/SystemAlert";
import activateListingApi from "../../../api/listing/activate.listing.api";
import repostListingApi from "../../../api/listing/repost.listing.api";
import { ChatFactoryTypes, getWidgetByType, JOB_POSTER_TYPE, JOB_SEEKER_TYPE, ListingWidgetTypes } from "./my-listings/helper";
import Swal from "sweetalert2";
import deleteForeverApi from "../../../api/listing/deleteForever.api";
import { CustomModal } from "../../modales/CustomModal";
import { ShareListings } from "../common/ShareListing";

type Props = {
    listing ?: DynamicObject
    refresh :  () => any
    count ?: number
}

export function ManageListing({listing,refresh,count} : Props) {
    const targetPage = (window.location.pathname == '/listings/my-listings') ? 'listing_my_listings' : 'listing_my_listing_view'
    const [listingIntegrationActionType, setListingIntegrationActionType] = useState('')
    const [showEditListingModal, setShowEditListingModal] = useState(false)
    const [showDeactivateModal, setShowDeactivateModal] = useState(false)
    const [listingUrl,setListingUrl] = useState('')
    const [showCustomModal,setShowCustomModal] = useState<boolean>(false)
    const [pickedListing,setPickedListing] = useState <DynamicObject>();
    const [pickedListingId,setPickedListingId] = useState<number>(0)
    const [reasons,setReasons] = useState <DynamicObject>({})
    const [showBundle,setShowBundle] = useState (false);
    const [type,setType] = useState<string[]> ([]);
    const navigate = useNavigate();
    const Intl = useIntl();
    const lang = useLang();
    let statusType = null;
    if(listing){
        statusType = getWidgetByType(listing.widgets,ListingWidgetTypes.STATUS);
    }

    const takeAction = (cta : string) => {
        setPickedListingId(listing?.listing_id);
        setPickedListing(listing);
        let link =  generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',getCookies().ecountry ?? '',lang) + '/search/' + listing?.listing_id + '/' + listing?.title.replace(/\s+/g, '-');
        if(listing?.listing_type == JOB_POSTER_TYPE || listing?.listing_type == JOB_SEEKER_TYPE){
            link =  generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',getCookies().ecountry ?? '',lang) + '/job-posters/' + listing?.listing_id;
        }
        switch (cta) {
            case PROMOTE:
                setShowBundle(true)
                setType(['Turbo','Premium','Bumpup'])
                break;
            case BUMPUP:
                setShowBundle(true)
                setType(['Bumpup'])
                break;
            case BUMPUP_FREE:
                repost(listing?.listing_id ?? 0);
                break;
            case VIEW_ON_OPENSOOQ:
                window.open(link, "_blank");
                break;
            case VIEW_CHATS:
                viewChat(listing?.listing_id ?? 0);
                break;
            case LISTING_STATISTICS:
                navigate(`/listings/my-listings/listing-stats?id=${listing?.listing_id}`)
                break;
            case EDIT:
                if(listing && listing.listing_id){
                    editListing(listing,'edit');
                }else{
                    showError(Intl,lang)
                }
                break;
            case DELETE:
                getReasons();
                break;
            case DELETE_FOR_EVER:
                deleteForeverHandler(listing?.listing_id ?? 0)
                break;
            case SHARE:
                if(isDeviceMobile()){
                    handleSharing(link + 'utm_source=opensooq&utm_medium=share&utm_campaign=listing_my_listing_view_standard_from_myopensooq')
                }else{
                    setShowCustomModal(true);
                }
                break;
            case COPY:
                if(listing && listing.listing_id){
                    editListing(listing,'republish');
                }else{
                    showError(Intl,lang)
                }
                break;
            case ACTIVATE:
                setListingIntegrationActionType('activate')
                setShowEditListingModal(true)
                break;
            default:
                break;
        }
    } 

    const deleteForeverHandler = (listingId : number) => {
        const text = Intl.formatMessage({id : "deleteForever",})
        Swal.fire({
            icon: "warning",
            html:
            `<div class="pe-10 ps-10 fw-bolder">${text}</div>`,
            buttonsStyling: false,
            showCancelButton: true,
            cancelButtonText : Intl.formatMessage({id : 'no'}),
            showLoaderOnConfirm: true,
            confirmButtonText: Intl.formatMessage({id : 'yes'}),
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-light-danger"
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                deleteForeverApi(listingId).then((response) => {
                    showSuccess(Intl,lang)
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }).catch((error) => {
                    showError(Intl,lang);
                })
            }
        })
    }

    const viewChat = (listingId : number) => {
        navigate('/chats',{state : {
            type : ChatFactoryTypes.LISTING_CHATS,
            data : {
                listing_id : listingId
            }
        }});
    }

    const getReasons = () => {
        getDeleteReasons(listing?.listing_id ?? 0).then((response) => {
            setReasons(response.data);
            setShowDeactivateModal(true);
        });
    }

    const repost = (listingId : number) => {
        repostListingApi(listingId).then((response) => {
            showSuccess(Intl,lang);
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }).catch(() => {
            showError(Intl,lang);
        })
    }

    const handleSharing = (link : string) => {
        if (navigator.share) {
            const data = {links : {link}}
            shorten(data).then(response => {
                if(response.data && response.data.links && response.data.links.link){
                    const downloadApp   = TWITTER_SHARE_URL;
                    const text = (`${Intl.formatMessage({id : "checkMyListing"})} \n ${response.data.links.link} \n\n ${Intl.formatMessage({id : 'downloadApp'})} ${downloadApp}`)
                    navigator.share({
                        title: listing?.title,
                        text: text,
                        url: response.data.links.link,
                    })
                    .then(() => {
                        console.log("Successfully shared");
                    })
                    .catch((error) => {
                        console.error("Something went wrong", error);
                    });
                }else{
                    showError(Intl,lang);
                }
            }).catch(err => {
                showError(Intl,lang);
            })
        }
    };

    const handleClose = () => {
        setShowEditListingModal(false)
        refresh()
    }
    
    const HtmlComponent = ({cta} : {cta : DynamicObject}) => {
        return <tr key={cta.cta} className="hoverPointer grayHoverBg" onClick={(e) => {takeAction(cta.cta)}}>
            <td className="label" >
                <div className="d-flex align-items-center">
                    <span className="svg-icon svg-icon-2 me-2">
                        <CheckCtasIcon cta={cta.cta}/>
                    </span>
                    <span className="ltrDir">{cta.label.replace("{{unread_messages}}",` (${count}) `)}</span>
                </div>
            </td>
        </tr>
    }
    
    return  <>
        <div className="card card-flush py-4 ">
            {listing && <div className="card-header">
                <div className="card-title align-items-start flex-column">
                    <h2>{Intl.formatMessage({id : "myListingView.text57"})}</h2>
                    {statusType && <span className={`customPostTitleText fw-bolder mt-2 fs-4`}>
                        <span className={`${(statusType && statusType.color == 'GREEN') ? 'text-success' : 'text-danger'}`}>{statusType.text}</span>
                    </span>}
                </div>
                <div className="card-toolbar">
                    {listing && <div className=" customPostTitleText fw-bolder align-items-start flex-column">
                        <span className="card-label fw-bold text-gray-800">{Intl.formatMessage({id : "myListingView.text1"})} : {listing.listing_id}</span>
                    </div>}
                </div>
            </div>}
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-250px">
                        <tbody className="fw-semibold ">
                            {!listing && <TrLoader/>}
                            {!listing && <TrLoader/>}
                            {!listing && <TrLoader/>}
                            {!listing && <TrLoader/>}
                            {!listing && <TrLoader/>}
                            {!listing && <TrLoader/>}
                            {!listing && <TrLoader/>}
                            {!listing && <TrLoader/>}
                            {!listing && <TrLoader/>}
                            {listing && listing.ctas.map((cta : DynamicObject,index : number) => {
                                return (cta.cta == VIEW_CHATS && showChatInCountry()) ? <HtmlComponent key={index} cta={cta}/> : cta.cta != VIEW_CHATS ? <HtmlComponent key={index} cta={cta}/> : ''
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            {listing && listing.ctas.find((cta :DynamicObject) => {return cta.cta == PROMOTE}) && <div className="card-footer py-6 px-9">
                <WantMoreViews showPromote={listing && listing.ctas.find((cta :DynamicObject) => {return cta.cta == PROMOTE})} listingId={listing.listing_id}/>
            </div>}
        </div>
        <BundleModal  show={showBundle}  type={type} handleClose={() => {setShowBundle(false)}} id={listing?.listing_id} pos={'listingView'}/>
        <IframeModal show={showEditListingModal} id={listing?.listing_id} type={listingIntegrationActionType} handleClose={() => {handleClose()}} />
        {pickedListing && <CustomModal show={showCustomModal} handleClose={() => {setShowCustomModal(false)}} children={<><ShareListings opened={showCustomModal} comeFrom={'myListingView'} listingUrl={listingUrl} listing={pickedListing}/></>}/>}
        {listing && <DeactivateListModal reasons={reasons} show={showDeactivateModal} id={listing?.listing_id} type="edit" handleClose={() => {setShowDeactivateModal(false)}}/>}
    </>
}