import React, {useState} from "react";
import {PopupChat, useChatFunctions, types} from 'opensooq-chat'
import {Modal} from "react-bootstrap";
import {KTSVG} from "../helpers";
import DynamicObject from "../../models/dynamic-object";
import './ChatPopup.css'

interface ChatPopupProps {
    children?: React.ReactNode;
    button:  any
}

const DEFAULT_ROOM = undefined
const ChatPopup = ({button: Button}: ChatPopupProps): JSX.Element => {
    const [room, setRoom] = useState<any>(DEFAULT_ROOM)
    const {createRoom} = useChatFunctions()

    const closeModalHandler = () => {
        setRoom(DEFAULT_ROOM)
    }

    const createRoomHandler = (data: DynamicObject) => {
        createRoom(data as any).then((room) => {
            setRoom(room)
        })
    }

    console.log('yes')

    return (
        <>
            <Button onCreateRoom={createRoomHandler}/>
            <Modal id='iframe' tabIndex={-1} aria-hidden='true'
                   dialogClassName='modal-dialog modal-dialog-centered modal-fullscreen' show={!!room}>
                <div className='modal-header p-5'>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={closeModalHandler}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg'/>
                    </div>
                </div>
                <div className='modal-body popup' style={{overflowY: "hidden"}}>
                    {room && <PopupChat newChat={room} onClear={closeModalHandler}/> }
                </div>
            </Modal>
        </>
    )
}

export default ChatPopup