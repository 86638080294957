import { MEMBER_INFO_COOKIE } from "../../app/helpers/CookiesVariables";
import DynamicObject from "../../models/dynamic-object";
import { AxiosResponse} from "axios";
import Cookies from "js-cookie";
import Api from "../api";
import { getCookies } from "../../app/helpers/commonFunctions";

export default (config?: DynamicObject): Promise<AxiosResponse> => {
    const memberId = getCookies().userInfo.id;
    const path = 'members'
    return Api.getInstance().request.get(`${path}/${memberId}?expand=rating,views,followersCount,followingsCount,profile_picture_large,wallet_amount,bundle_count,membership_type,isShopProfileCompleted,dateOfFirstPost,cv,subscription,limitAccountReport,ticket`, path)
}