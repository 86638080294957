import { ChatPage, types } from "opensooq-chat";
import { useEffect } from "react"
import { useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom";
import DynamicObject from "../../models/dynamic-object";
import { PageTitle } from "../layout/core"
import { ChatFactoryTypes } from "./listing/my-listings/helper";
import { showChatInCountry } from "../helpers/commonFunctions";


export interface FactoryInterface { 
    type : ChatFactoryTypes,
    data : any
} 

const ChatScreen = () => {
    const Intl = useIntl();
    const {state} : {state : any} = useLocation()
    let attributes : DynamicObject = {
    }
    

    const newChatHandler = (data : any) => {
        return { newChat :  data}
    }

    const listingChatsHandler = (data : any) => {
        return { filter :  (chat : any) => { return chat.pid == data.listing_id}}
    }

    const unReadListingChatsHandler = (data : any) => {
        return { filter :  (chat : any) => {  return chat.pid == data.listing_id},tap : types.FilterTapsEnum.UNREAD}
    }

    const factoryChat = (state : FactoryInterface) => {
        switch (state.type) {
            case ChatFactoryTypes.NEW_CHAT:
                return newChatHandler(state.data)
                break;
            case ChatFactoryTypes.LISTING_CHATS:
                return listingChatsHandler(state.data)
                break;
            case ChatFactoryTypes.UNREAD_ROOMS:
                return unReadListingChatsHandler(state.data)
                break;
            default:
                return {}
                break;
        }
    }
    
    if(state && state?.type){
        attributes = {...attributes,...factoryChat(state as FactoryInterface)}
    }

    const navigate = useNavigate();

    useEffect(() => {
        if(!showChatInCountry()){
            navigate('/');
        }
        if(state){
            window.history.replaceState({}, document.title)
        }
    },[]);
    return  <>
        <PageTitle breadcrumbs={[]}>{Intl.formatMessage({id : "chatsTitle"})}</PageTitle> <ChatPage {...attributes}/>
    </>
}

export default ChatScreen