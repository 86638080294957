import { ListingInterface } from "../../interfaces/ListingInterface";
import getAdApi from "../../../api/listing/getAd.api";
import { WantMoreViews } from "./WantMoreViews";
import {useNavigate} from 'react-router-dom'
import { useEffect, useState } from "react";
import { useIntl } from 'react-intl'
import DynamicObject from "../../../models/dynamic-object";
import Skeleton from "react-loading-skeleton";

type Props = {
    stats : DynamicObject
    loading : boolean
    showPromote ?: boolean 
    showStatsButton?: boolean
}

export function ListingStatistics(props : Props) {
    const navigate = useNavigate(); 
    const listingId : number | string  = new URLSearchParams(window.location.search).get('id') ?? '';
    const Intl = useIntl();
    return (
        <div className="card card-flush py-4 ">
            <div className="card-header">
                <div className="card-title align-items-start flex-column">
                    <h2>{Intl.formatMessage({id : "myListingView.text41"})}</h2>
                    <div className="d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400">{Intl.formatMessage({id : "myListingView.text28"})}</div>
                </div>
            </div>
            <div className="card-body pt-0">
                <div className="row p-0 mb-5 px-9">
                    <div className="col " >
                        <div  className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                            <span onClick={() => { navigate(`/listings/my-listings/listing-stats?id=${listingId}`)}} className="hoverPointer fs-2hx fw-bold text-gray-900 counted" >{props.loading && <Skeleton className="w-50px h-20px"/>} {!props.loading && props.stats.views.toLocaleString()}</span>
                            <span id="listingStatisticsView" className="fs-4 fw-semibold text-muted d-block">{Intl.formatMessage({id : "myListingView.text29"})} </span>
                        </div>
                    </div>
                    <div className="col ">
                        <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                            <span onClick={() => { navigate(`/listings/my-listings/listing-stats?id=${listingId}`)}} className="hoverPointer fs-2hx fw-bold text-gray-900 counted" >{props.loading && <Skeleton className="w-50px h-20px"/>} {!props.loading && props.stats.calls.toLocaleString()}</span>
                            <span id="listingStatisticsCalls" className="fs-4 fw-semibold text-muted d-block">{Intl.formatMessage({id : "myListingView.text30"})} </span>
                        </div>
                    </div>
                    <div className="col " >
                        <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                            <span onClick={() => { navigate(`/listings/my-listings/listing-stats?id=${listingId}`)}} className="hoverPointer fs-2hx fw-bold text-gray-900 counted">{props.loading && <Skeleton className="w-50px h-20px"/>} {!props.loading && props.stats.rooms.toLocaleString()}</span>
                            <span id="listingStatisticsChats" className="fs-4 fw-semibold text-muted d-block">{Intl.formatMessage({id : "myListingView.text31"})} </span>
                        </div>
                    </div>
                    {/* <div className="col ">
                        <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                            <span onClick={() => { navigate(`/listings/my-listings/listing-stats?id=${listingId}`)}} className="hoverPointer fs-2hx fw-bold text-gray-900 counted" data-kt-countup="true" data-kt-countup-value="9">9</span>
                            <span id="listingStatisticsChatsUnread" className="fs-4 fw-semibold text-muted d-block">Chats (Unread) </span>
                        </div>
                    </div> */}
                    <div className="col " >
                        <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                            <span onClick={() => { navigate(`/listings/my-listings/listing-stats?id=${listingId}`)}} className="hoverPointer fs-2hx fw-bold text-gray-900 counted" >{props.loading && <Skeleton className="w-50px h-20px"/>} {!props.loading && props.stats.favorite.toLocaleString()}</span>
                            <span id="listingStatisticsFavorites" className="fs-4 fw-semibold text-muted d-block">{Intl.formatMessage({id : "myListingView.text33"})} </span>
                        </div>
                    </div>
                    {/* <div className="col " >
                        <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                            <span onClick={() => { navigate(`/listings/my-listings/listing-stats?id=${listingId}`)}} className="hoverPointer fs-2hx fw-bold text-gray-900 counted" data-kt-countup="true"  data-kt-countup-value="291">291</span>
                            <span id="listingStatisticsShares" className="fs-4 fw-semibold text-muted d-block">Shares <i className="fas fa-exclamation-circle ms-1 fs-7"></i></span>
                        </div>
                    </div> */}
                </div>
                {listingId && <div className="card-footer">
                    <WantMoreViews listingId={parseInt(listingId)} showPromote={props.showPromote ?? false} extraButton={props.showStatsButton ? Intl.formatMessage({id : "myListingView.text37"}) : ''}/>
                </div>}
            </div>
        </div>
    )
}