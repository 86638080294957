import  {FC} from 'react'
import { useIntl } from 'react-intl'
import {Routes} from 'react-router-dom'
import { PageLink, PageTitle } from '../../app/layout/core';

const SearchesRoutes: FC = () => {
    const Intl = useIntl();
    const searches: Array<PageLink> = [
        {
            title: Intl.formatMessage({id: 'searches'}),
            path: '',
            isSeparator: false,
            isActive: false,
        },
    ]
    return (
        <Routes>
        </Routes>
    )
}

export default SearchesRoutes
