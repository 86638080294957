import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { StarComponent } from "./StarComponent"
import { Rating as RatingStars} from 'react-simple-star-rating'

interface cardProps{
    avatar  :   string
    text    :   string
    name    :   string
    date    :   string
    rating  :   number
}


const ReviewComponent = (props : cardProps) => {
    const Intl = useIntl()
    const rating = props.rating;
    return (
        <>
          <div className="card card-flush ">
                <div className="card-header ">
                    <div className="d-flex align-items-center">
                        <div className="symbol symbol-50px me-5">
                            <img src={props.avatar} className="" alt=""/>
                        </div>
                        <div className="flex-grow-1">
                            <a href="#" className="text-gray-800 text-hover-primary fs-4 fw-bold">{props.name}</a>
                            <span className="text-gray-400 fw-semibold d-block">{props.date}</span>
                        </div>
                    </div>
                    <div className="card-toolbar">
                        <div className="m-0">
                            <div className="card-toolbar">
                                <div className="m-0">
                                    <RatingStars readonly={true} iconsCount={5} initialValue={rating} allowFraction={true} fillStyle={{color:"rgb(255, 173, 15)"}}/>
                                    <b className='ps-1 fs-5'>{rating}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body" style={{padding: "1rem 2.25rem"}}>
                    <div className="fs-6 fw-normal text-gray-700">{props.text}</div>
                </div>
                <div className='card-footer' style={{padding:"0rem 2.25rem 0px"}}>
                    <div className="separator separator-solid "></div>
                </div>
            </div>
        </>
    )
}

export {ReviewComponent}
