import DynamicObject from "../../../../models/dynamic-object";
import { generateOpenSooqRedirectUrl, getCookies } from "../../../helpers/commonFunctions";
import { BuyNowServiceInterface } from "../../../interfaces/BuyNowServiceInterface";

interface Iprops {
    services : BuyNowServiceInterface[],
    lang : string
    action :  (params: any) => any
}
const BundleServiceComponent = ( {services, lang,action} : Iprops) => {
    const ICON_IMAGE_SIZE = 'xh';
    const takeAction = (service: BuyNowServiceInterface) => {
        if(service.isDeepLink){
            const deepLinkBase =  generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',getCookies().ecountry,lang) + '/';
            const url = `${deepLinkBase}site/dl?requestUri=${service.deepLink}`;
            window.open(url,'_blank');
        }else{
            action(service);
        }
    }
    return <>{services && services.map((service : BuyNowServiceInterface,index : number) => {
        return <div key={index}  className='col-12 shadow-sm trHoverAction hoverPointer radius-8 my-1 fw-bolder' onClick={() => {takeAction(service)}}>
            <div className="d-flex  py-1 ">
                <div className="">
                    <div className="symbol symbol-45px ">
                        <img src={`https://opensooqui2.os-cdn.com/api/apiV/android/${ICON_IMAGE_SIZE}${service.icon}`} className="h-50 align-self-center" alt=""/>
                    </div>
                </div>
                <div style={{display:"flex"}} className="p-2 fs-5 align-self-center fw-bolder">{service.text}</div>
                <div style={{display:"flex"}} className="align-self-center ms-auto p-2 ">{lang === 'ar' ? <><i className="fas fa-arrow-left"></i></> : <><i className="fas fa-arrow-right"></i></>}</div>
            </div>      
        </div>
    })}</>
}

export {BundleServiceComponent};