import { useIntl } from "react-intl"
import Skeleton from "react-loading-skeleton";
import { toAbsoluteUrl } from "../../helpers";
import { BuyerInterface } from "../../interfaces/BuyerInterface";
import { getRecentlyViewed } from "../listing/my-listings/helper";

type Props = {
    buyer ?: BuyerInterface
    navigateTo : (param : any) => void

}

const Buyer = (props : Props) => {
    const Intl = useIntl();
    const recentlyViewed = getRecentlyViewed()

    return  <div className="card h-100 card-xl-stretch mb-xl-8">
        <div className="card-header border-0">
            <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-dark">
                {Intl.formatMessage({id : "myOpenSooq.buyer"})}
                </span>
                <span className="text-muted mt-1 fw-semibold fs-7">{Intl.formatMessage({id : "myOpenSooq.buyerDescription"})}</span>
            </h3>
        </div>
        <div className="card-body pt-2">
            <div className="border border-dashed border-gray-300 grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer" onClick={() => {props.navigateTo('/listings/demand/favorite-listings')}}>
                <div className="d-flex align-items-sm-center h-30px hoverPointer" >
                <svg className=" fs-1" viewBox="0 0 32 32" width="25" height="25" data-name="iconFavouriteFill"><path fill="#ee5555" stroke="#ee5555" strokeWidth="3" d="M16 28.467l-1.933-1.76c-6.867-6.227-11.4-10.333-11.4-15.373 0-4.107 3.227-7.333 7.333-7.333 2.32 0 4.547 1.080 6 2.787 1.453-1.707 3.68-2.787 6-2.787 4.107 0 7.333 3.227 7.333 7.333 0 5.040-4.533 9.147-11.4 15.387l-1.633 1.747z"></path></svg>
                    <div className="d-flex align-items-center flex-row-fluid ms-3 flex-wrap">
                        <div className="flex-grow-1 me-2">
                            <a href="#" className="text-gray-800 text-hover-primary fs-6 fw-bold">{Intl.formatMessage({id : "myOpenSooq.favoriteListing"})}</a>
                        </div>
                        {!props.buyer && <Skeleton className="w-30px" />} {props.buyer && <span className="badge badge-light fs-12 fw-bold"><span style={{color :"black"}}>{props.buyer.favorite_listings.total.toLocaleString()}</span></span>}
                    </div>
                </div>
            </div>
            <div className="border border-dashed border-gray-300 grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer" onClick={() => {props.navigateTo('/listings/demand/recently-viewed')}}>
                <div className="d-flex align-items-sm-center h-30px hoverPointer" >
                    <i className='fas fa-low-vision fs-3 text-primary'></i>
                    <div className="d-flex align-items-center flex-row-fluid flex-wrap ms-3 ">
                        <div className="flex-grow-1 me-2"><a href="#" className="text-gray-800 text-hover-primary fs-6 fw-bold">{Intl.formatMessage({id : "myOpenSooq.recentlyViewed"})}</a>
                        </div><span className="badge badge-light fw-bold my-2">{recentlyViewed.length}</span>
                    </div>
                </div>
            </div>
            <div className="border border-dashed border-gray-300 grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer" onClick={() => {props.navigateTo('/listings/demand/following-listings')}}>
                <div className="d-flex align-items-sm-center h-30px hoverPointer" >
                    <i className='fas fa-user-plus fs-3 text-primary'></i>
                    <div className="d-flex align-items-center flex-row-fluid flex-wrap ms-3">
                        <div className="flex-grow-1 me-2">
                            <a href="#" className="text-gray-800 text-hover-primary fs-6 fw-bold">{Intl.formatMessage({id : "myOpenSooq.followingListing"})}</a>
                        </div>
                        {!props.buyer && <Skeleton className="w-30px" />} {props.buyer && <span className="badge badge-light fs-12 fw-bold"><span style={{color :"black"}}>{props.buyer.followings_listings.total.toLocaleString()}</span></span>}
                    </div>
                </div>
            </div>
            <div className="border border-dashed border-gray-300 grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer" onClick={() => {props.navigateTo('/listings/demand/job-applications')}}>
                <div className="d-flex align-items-sm-center h-30px hoverPointer" >
                    <i className='fas fa-user-md fs-3 text-primary'></i>
                    <div className="d-flex align-items-center flex-row-fluid flex-wrap ms-3">
                        <div className="flex-grow-1 me-2">
                            <a href="#"  className="text-gray-800 text-hover-primary fs-6 fw-bold">{Intl.formatMessage({id : "myOpenSooq.jobApplication"})}</a>
                        </div>
                        {!props.buyer && <Skeleton className="w-30px" />} {props.buyer && <span className="badge badge-light fs-12 fw-bold"><span style={{color :"black"}}>{props.buyer.job_applications.total.toLocaleString()}</span></span>}
                    </div>
                </div>
            </div>
            <div className="border border-dashed border-gray-300 grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer" onClick={() => {props.navigateTo('/listings/searches/saved-search')}}>
                <div onClick={() => {props.navigateTo('/listings/searches/saved-search')}} className="d-flex align-items-center rounded  hoverPointer">
                    <img className="fs-3" width="20" height="20" src={toAbsoluteUrl('/media/custom/saved-search.svg')} alt="feature"/>
                    <div className="flex-grow-1 me-2 ms-2 ">
                        <span  className="fw-bold text-gray-800 text-hover-primary fs-6">{Intl.formatMessage({id : "myOpenSooq.savedSearch"})}</span>
                    </div>
                    {!props.buyer && <Skeleton className="w-30px" />} {props.buyer && <span className="badge badge-light fs-12 fw-bold"><span style={{color :"black"}}>{props.buyer.recent_saved_search.total_saved.toLocaleString()}</span></span>}
                </div>
            </div>
            <div className="border border-dashed grayHoverBg border-gray-300 w-100 rounded my-3 p-2 me-2 hoverPointer" onClick={() => {props.navigateTo('/listings/searches/recent-search')}}>
                <div onClick={() => {props.navigateTo('/listings/searches/recent-search')}} className="d-flex align-items-center rounded  hoverPointer">
                    <i className='fas fa-search  text-primary fs-2 pe-2'></i>
                    <div className="flex-grow-1 me-2 ms-1 ">
                        <span  className="fw-bold text-gray-800 text-hover-primary fs-6">{Intl.formatMessage({id : "myOpenSooq.recentSearch"})}</span>
                    </div>
                    {!props.buyer && <Skeleton className="w-30px" />} {props.buyer && <span className="badge badge-light fs-12 fw-bold"><span style={{color :"black"}}>{props.buyer.recent_saved_search.total_recent.toLocaleString()}</span></span>}
                </div>
            </div>
        </div>
    </div>
}

export default Buyer