import { AxiosResponse} from "axios";
import DynamicObject from "../../models/dynamic-object";
import Api from "../api";

export default (url : string): Promise<AxiosResponse> => {
    let newUrl = url.replace(/%2C/g,",")
    const fullUrl = newUrl.replace('http:',"https:")
    const path = 'members/0/bookmarks'
    const apiUrl : string = (url == '') ? `${path}?per-page=30&supportCurrency=true&filter=all&page=1&expand=images,category,dynamicAttributes,bookmarkStatus,custom_param_string,city,neighborhood,postUrl,searchMember,totalComments,imagesCount,note,price_list,chat_enabled,custom_param_array` : fullUrl
    return Api.getInstance().request.get(apiUrl, path)
}
