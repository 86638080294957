import React from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  newIcon ?: any
  isMain ?: boolean
  parentMenu? : string
  comingFrom ?: string
}

const SidebarMenuItemWithSub: React.FC<Props & WithChildren> = ({
  parentMenu,
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  newIcon,
  isMain,
  comingFrom
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config
  pathname.indexOf('1');
  pathname.toLowerCase();
  var mainPath = pathname.split("/")[1];
  return (
    <div className={clsx(`menu-item ${(comingFrom != 'headMenu' && ((pathname == '/' && title == 'Listings') || (mainPath == parentMenu))) ? 'show' : ''}`, {'here show': isActive }, 'menu-accordion')} data-kt-menu-trigger='click' >
      <span className='menu-link'>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {!newIcon && icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        {newIcon && (
          <span className='menu-icon'>
            {newIcon}
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        <span className={`menu-title ${(isMain) ? 'fs-16' : ''}`}>{title}</span>
        <span className='menu-arrow'></span>
      </span>
      <div  aria-expanded={true}  className={clsx(`menu-sub menu-sub-accordion `, {'menu-active-bg': isActive})}>
        {children}
      </div>
    </div>
  )
}

export {SidebarMenuItemWithSub}
