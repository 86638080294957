const days = [
  {
    day_en: 'Sunday',
    day_ar: 'الأحد',
    dow: '0',
  },
  {
    day_en: 'Monday',
    day_ar: 'الاثنين',
    dow: '1',
  },
  {
    day_en: 'Tuesday',
    day_ar: 'الثلاثاء',
    dow: '2',
  },
  {
    day_en: 'Wednesday',
    day_ar: 'الأربعاء',
    dow: '3',
  },
  {
    day_en: 'Thursday',
    day_ar: 'الخميس',
    dow: '4',
  },
  {
    day_en: 'Friday',
    day_ar: 'الجمعة',
    dow: '5',
  },
  {
    day_en: 'Saturday',
    day_ar: 'السبت',
    dow: '6',
  },
]

const hours = [
  {
    standard_format: '01:00',
    time_of_day: 'AM',
    view: '1:00',
  },
  {
    standard_format: '02:00',
    time_of_day: 'AM',
    view: '2:00',
  },

  {
    standard_format: '03:00',
    time_of_day: 'AM',
    view: '3:00',
  },

  {
    standard_format: '04:00',
    time_of_day: 'AM',
    view: '4:00',
  },

  {
    standard_format: '05:00',
    time_of_day: 'AM',
    view: '5:00',
  },

  {
    standard_format: '06:00',
    time_of_day: 'AM',
    view: '6:00',
  },

  {
    standard_format: '07:00',
    time_of_day: 'AM',
    view: '7:00',
  },

  {
    standard_format: '08:00',
    time_of_day: 'AM',
    view: '8:00',
  },

  {
    standard_format: '09:00',
    time_of_day: 'AM',
    view: '9:00',
  },

  {
    standard_format: '10:00',
    time_of_day: 'AM',
    view: '10:00',
  },

  {
    standard_format: '11:00',
    time_of_day: 'AM',
    view: '11:00',
  },

  {
    standard_format: '12:00',
    time_of_day: 'pm',
    view: '12:00',
  },

  {
    standard_format: '13:00',
    time_of_day: 'PM',
    view: '1:00',
  },

  {
    standard_format: '14:00',
    time_of_day: 'PM',
    view: '2:00',
  },

  {
    standard_format: '15:00',
    time_of_day: 'PM',
    view: '3:00',
  },

  {
    standard_format: '16:00',
    time_of_day: 'PM',
    view: '4:00',
  },

  {
    standard_format: '17:00',
    time_of_day: 'PM',
    view: '5:00',
  },

  {
    standard_format: '18:00',
    time_of_day: 'PM',
    view: '6:00',
  },

  {
    standard_format: '19:00',
    time_of_day: 'PM',
    view: '7:00',
  },

  {
    standard_format: '20:00',
    time_of_day: 'PM',
    view: '8:00',
  },

  {
    standard_format: '21:00',
    time_of_day: 'PM',
    view: '9:00',
  },

  {
    standard_format: '22:00',
    time_of_day: 'PM',
    view: '10:00',
  },

  {
    standard_format: '23:00',
    time_of_day: 'PM',
    view: '11:00',
  },
  {
    standard_format: '00:00',
    time_of_day: 'AM',
    view: '00:00',
  },
]

const openHours = [
  {
    dow: '0',
    open_time: '01:00',
    close_time: '01:00',
  },
  {
    dow: '1',
    open_time: '01:00',
    close_time: '01:00',
  },
  {
    dow: '2',
    open_time: '01:00',
    close_time: '01:00',
  },
  {
    dow: '3',
    open_time: '01:00',
    close_time: '01:00',
  },
  {
    dow: '4',
    open_time: '01:00',
    close_time: '01:00',
  },
  {
    dow: '5',
    open_time: '01:00',
    close_time: '01:00',
  },
  {
    dow: '6',
    open_time: '01:00',
    close_time: '01:00',
  },
]

export {days, hours, openHours}
