import InfiniteScroll from "react-infinite-scroll-component";

interface cellProps{
    list        : any,
    data        : any,
    paginate    :  () => any,
    isLastPage  : boolean,
    loader      : any
    endMessage  : any
}

const LazyLoader = (props : cellProps) => {
    return (
        <>
        <InfiniteScroll
            style={{width : "100%",overflow : "inherit"}}
            dataLength={props.data.length} 
            next={props.paginate}
            hasMore={!props.isLastPage}
            loader={
                <>
                    {props.loader}
                </>
            }
            refreshFunction={undefined}
            endMessage={ <>{props.data.length == 0 && props.endMessage}</> }
            pullDownToRefresh={false}
            pullDownToRefreshThreshold={100}
            pullDownToRefreshContent={null}
            releaseToRefreshContent={null}
            >
            {props.list}
            </InfiniteScroll>
        </>
    )
}

export {LazyLoader}
