import { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../helpers";

interface ButtonProps{
    action      ?:    (params?: any) => any
    className   ?:    string
    text        ?:    any
    disabled    ?:    boolean
    setData     ?:    number
}

const Button = (props : ButtonProps) => {
  const [isDisabledBtn,setIsDisabledBtn] = useState(false)
  useEffect(() => {
    setIsDisabledBtn(props && props.disabled ? props.disabled : false);
  },[props.disabled]);
  return (
    <>
        <button data-id={props.setData} disabled={isDisabledBtn} className={`btn ${props.className}`} onClick={props.action}>{props.text}</button>
    </>
  );
};

export {Button}