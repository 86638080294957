import { useIntl } from "react-intl";
import { Button } from "../../../layout/components/opensooq/Button";

type Props = {
    action :  () => any
}
export function NotificationDropdown(props  : Props) {
    const Intl = useIntl();
    return (
      <div className='dropdown-menu ' aria-labelledby="dropdownMenuButton1">
        <Button action={() => {props.action()}} className={'form-control w-150px fs-7 btn btn-primary  ms-1'}  text={Intl.formatMessage({id : "notification.card.header.markAllAsRead"})}/>
      </div>
    )
  }
  