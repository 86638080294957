import Skeleton from "react-loading-skeleton";
import { useIntl } from "react-intl";


const MyWalletTableLoader = () => {
  const Intl = useIntl();
  return (
    <>
        <div className="table-responsive">
            <table className="table table-hover align-middle table-row-dashed fs-6 gy-3 dataTable no-footer" id="kt_table_widget_4_table">
                <thead>
                    <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                        <th className="text-start min-w-100px sorting_disabled " rowSpan={1} colSpan={1} > <Skeleton   className='me-1 w-50'/></th>
                        <th className="text-start min-w-100px sorting_disabled " rowSpan={1} colSpan={1} > <Skeleton   className='me-1 w-50'/></th>
                        <th className="text-start min-w-100px sorting_disabled " rowSpan={1} colSpan={1} > <Skeleton   className='me-1 w-50'/></th>
                        <th className="text-start min-w-100px sorting_disabled " rowSpan={1} colSpan={1} > <Skeleton   className='me-1 w-50'/></th>
                        <th className="text-start min-w-100px sorting_disabled " rowSpan={1} colSpan={1} > <Skeleton   className='me-1 w-50'/></th>
                        <th className="text-start min-w-100px sorting_disabled " rowSpan={1} colSpan={1} > <Skeleton   className='me-1 w-50'/></th>
                    </tr>
                </thead>
                <tbody className="">
                    <tr>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                    </tr>
                    <tr>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                    </tr>
                    <tr>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                    </tr>
                    <tr>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                    </tr>
                    <tr>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                    </tr>
                    <tr>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                    </tr>
                    <tr>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                    </tr>
                    <tr>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                        <td><Skeleton   className='me-1 w-50'/></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </>
  )
}

export {MyWalletTableLoader}
