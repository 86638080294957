
// @ts-nocheck

import axios from "axios";
import generate_jwt from "../libs/jwt/jwt";
import { getCookies, logError, logout } from "../app/helpers/commonFunctions";

interface IRequest {
    get: (url: string, path?:string) => Promise < any > 
    delete: (url: string, path?:string) => Promise < any > 
    post: (url: string, body: any, path?:string) => Promise < any >
    put: (url: string, body: any, path?:string) => Promise < any >
}

export default class Api {
    static getInstance(otherSource : boolean = false,isBase ?: boolean = false,customUrl = '',passCountry = true):{request:IRequest} {
        let baseUrl = (isBase) ? process.env.REACT_APP_OPENSOOQ_API_URL_BASE : !otherSource ? process.env.REACT_APP_OPENSOOQ_API_URL : process.env.REACT_APP_OPENSOOQ_API_URL_VERTICAL
        
        if(customUrl != ''){
            baseUrl = customUrl
        }

        const originalAxios = axios.create({
            baseURL: baseUrl,
            timeout: Number(process.env.REACT_APP_API_TIMEOUT || '5000')
        });

        let lang = 'ar';
        if(localStorage.getItem('i18nConfig')){
            lang =  localStorage.getItem('i18nConfig').indexOf('ar') > -1 ? 'ar' : 'en'
        }
        const cookiesData = getCookies();
        const defaultHeaders = {
            'source':  cookiesData.source,
            'accept-language': lang,
            'session-id' : cookiesData.userInfo.id,
            'country': passCountry ? cookiesData.ecountry : '',
            'x-tracking-uuid':cookiesData.device_uuid,
            'Content-Type' : 'application/json',

        };

        const fetchToken = async () => {
            const tgt_ticket = cookiesData.tgtTicket;
            const tgt_timestamp =  cookiesData.tgtTimestamp;
            const memberId = cookiesData.userInfo.id
            if (!tgt_ticket) return null;
            const token = generate_jwt(Number(tgt_timestamp),tgt_ticket,memberId.toString())
            defaultHeaders["x-tracking-uuid"] = cookiesData.device_uuid;
            return token;
        }
        const request: IRequest = {
            get: async function (url: string, path?:string = "") {
                const token = await fetchToken();
                const headers = {
                    ...(token ? {
                        'Authorization': 'Bearer ' + token
                    } : {}),
                    ...defaultHeaders
                };
                return originalAxios.get(url, {
                    headers
                }).catch(error => {
                    logError(error,url, path)
                    if(error.response && error.response.status === 401){
                        return logout()  
                    }
                    throw error;
                });
            },
            delete: async function (url: string, path?:string = "") {
                const token = await fetchToken();
                const headers = {
                    ...(token ? {
                        'Authorization': 'Bearer ' + token
                    } : {}),
                    ...defaultHeaders
                };
                return originalAxios.delete(url, {
                    headers
                }).catch(error => {
                    logError(error,url, path)
                    if(error.response && error.response.status === 401){
                        return logout()  
                    } 
                    throw error;
                });
            },
            post: async function (url: string, data:any, path?: string = "") {
                const token = await fetchToken();
                const headers = {
                    ...(token ? {
                        'Authorization': 'Bearer ' + token
                    } : {}),
                    ...defaultHeaders
                };
                const response = await originalAxios.post(url, data,{
                    headers: headers
                }).catch(error => {
                    logError(error, url, path)
                    if(error.response && error.response.status === 401){
                        return logout()  
                    } 
                    throw error;
                });

                return response;
            },
            put: async function (url: string, data: any, path? :string ="") {
                const token = await fetchToken();
                const headers = {
                    ...(token ? {
                        'Authorization': 'Bearer ' + token
                    } : {}),
                    ...defaultHeaders
                };
                const response = await originalAxios.put(url, data,{
                    headers: headers
                }).catch(error => {
                    logError(error,url, path)
                    if(error.response && error.response.status === 401){
                        return logout()  
                    } 
                    throw error;
                });

                return response;
            },
        }

        

        return {
            request
        }
    }


}