import { useRef, useState } from "react";
import { getCookies, logout } from "../../helpers/commonFunctions";
import { useLang } from "../../i18n/OpenSooqi18n";
import { IframeLoader } from "../common/IframeLoader";

export function MyCv() {
  const iframeContent = useRef<null | HTMLIFrameElement>(null);
  const [loader,setLoader] = useState <boolean>(true)
  const lang = useLang();
  window.addEventListener("message", event => {
    if(event.data === 401){
        logout()
    }else if(event.data == 200){
        setLoader(false);
        if(iframeContent.current){
            iframeContent.current.classList.remove('d-none')
        }
    }
}, false);

return   <div className="row justify-content-center">
        <div className="col-lg-8 col-12">
            <div className="card">
                <div className="card-body">
                    <div className='text-center' >
                        {loader && <IframeLoader/>}
                        <iframe src={`${process.env.REACT_APP_ADD_POST}?v=2&source=${getCookies().source}&lang=${lang}&flow=my-cv&from=my-opensooq`} className="d-none w-100" style={{height : "100vh",overflowY:"hidden"}} ref={iframeContent}></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
}