type ISocialUrls = {
    [code : string] : {
        "countryCode" : string,
        "twitter" : string,
        "facebook" : string,
        "instagram" : string
    }
}

const socialUrls:ISocialUrls = {
    "jo" :
    {   
        "countryCode" : "jo",
        "twitter": "https://twitter.com/OpenSooqJordan",
        "facebook": "https://facebook.com/OpenSooq.Jordan",
        "instagram": "http://instagram.com/opensooq.jordan"
    },
    "sa" :
    {   
        "countryCode" : "sa",
        "twitter": "https://twitter.com/OpenSooqKSA",
        "facebook": "https://facebook.com/OpenSooq.KSA",
        "instagram": "http://instagram.com/opensooqksa"
    },
    "ly" :
    {   
        "countryCode" : "ly",
        "twitter": "https://twitter.com/OpenSooqLibya",
        "facebook": "https://facebook.com/OpenSooq.libya",
        "instagram": "https://www.instagram.com/opensooqlibya/"
    },
    "ae" :
    {   
        "countryCode" : "ae",
        "twitter": "https://twitter.com/OpenSooqUAE",
        "facebook": "https://facebook.com/OpenSooq.uae",
        "instagram": "http://instagram.com/opensooqae"
    },
    "qa" :
    {   
        "countryCode" : "qa",
        "twitter": "https://twitter.com/OpenSooqQatar",
        "facebook": "https://facebook.com/OpenSooq.qatar",
        "instagram": "http://instagram.com/opensooq/"
    },
    "om" :
    {   
        "countryCode" : "om",
        "twitter": "https://twitter.com/OpenSooqOm",
        "facebook": "https://facebook.com/OpenSooq.oman",
        "instagram": "http://instagram.com/opensooqoman"
    },
    "bh" :
    {   
        "countryCode" : "bh",
        "twitter": "https://twitter.com/OpenSooqBahrain",
        "facebook": "https://facebook.com/OpenSooq.bahrain",
        "instagram": "https://www.instagram.com/opensooqbahrain/"
    },
    "kw" :
    {   
        "countryCode" : "kw",
        "twitter": "https://twitter.com/OpenSooqKuwait",
        "facebook": "https://facebook.com/OpenSooq.kuwait",
        "instagram": "http://instagram.com/opensooqkuwait"
    },
    "iq" :
    {   
        "countryCode" : "iq",
        "twitter": "https://twitter.com/OpenSooqIraq",
        "facebook": "https://facebook.com/OpenSooq.Iraq",
        "instagram": "https://www.instagram.com/opensooqiraq/"
    },
    "lb" :
    {   
        "countryCode" : "lb",
        "twitter": "https://twitter.com/OpenSooqLebanon",
        "facebook": "https://www.facebook.com/opensooq.Lebanon/",
        "instagram": "http://instagram.com/opensooq/"
    },
    "sy" :
    {   
        "countryCode" : "sy",
        "twitter": "https://twitter.com/OpenSooqSyria",
        "facebook": "https://facebook.com/OpenSooq.syria",
        "instagram": "https://www.instagram.com/opensooqsyria/"
    },
    "ps" :
    {   
        "countryCode" : "ps",
        "twitter": "https://twitter.com/OpenSooqPal",
        "facebook": "https://facebook.com/OpenSooq.palestine",
        "instagram": "http://instagram.com/opensooq/"
    },
    "dz" :
    {   
        "countryCode" : "dz",
        "twitter": "https://twitter.com/OpenSooqAlgerie",
        "facebook": "https://facebook.com/opensooq",
        "instagram": "http://instagram.com/opensooq/"
    },
    "ma" :
    {   
        "countryCode" : "ma",
        "twitter": "https://twitter.com/OpenSooqMaroc",
        "facebook": "https://facebook.com/OpenSooq.maroc",
        "instagram": "http://instagram.com/opensooq/"
    },
    "tn" :
    {   
        "countryCode" : "tn",
        "twitter": "https://twitter.com/OpenSooqTunisie",
        "facebook": "https://facebook.com/OpenSooq.tunisie",
        "instagram": "http://instagram.com/opensooq/"
    },
    "sd" :
    {   
        "countryCode" : "sd",
        "twitter": "https://twitter.com/OpenSooqSudan",
        "facebook": "https://facebook.com/OpenSooq.sudan",
        "instagram": "https://www.instagram.com/opensooqsudan",
    },
    "mr" :
    {   
        "countryCode" : "mr",
        "twitter": "https://twitter.com/OpenSooqMr",
        "facebook": "https://facebook.com/opensooq",
        "instagram": "http://instagram.com/opensooq/",
    },
    "ye" :
    {   
        "countryCode" : "ye",
        "twitter": "https://twitter.com/OpenSooqYemen",
        "facebook": "https://facebook.com/OpenSooq.Yemen",
        "instagram": "http://instagram.com/opensooq/",
    },
    "default" :
    {   
        "countryCode" : "default",
        "twitter": "https://twitter.com/opensooq",
        "facebook": "https://facebook.com/opensooq",
        "instagram": "http://instagram.com/opensooq/",
    }
}

type SocialUrlsKey = keyof typeof socialUrls
type SocialUrlsValue = typeof socialUrls[SocialUrlsKey]

export { socialUrls }
export type { ISocialUrls, SocialUrlsKey, SocialUrlsValue }
