const Loader = () => {
return (
    <>
        <div className="row p-5 centerElement">
            <div className="col-lg-12 text-center">
                <div className="spinner-border text-success" role="status">
                    <span className="visually-hidden"></span>
                </div>
            </div>
        </div>
    </>
  );
};
export {Loader}