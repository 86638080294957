import React from 'react';
import { useIntl } from 'react-intl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toastError, toastify } from '../../tostify/snackbarAlert';
import { useLang } from '../../app/i18n/OpenSooqi18n';
import DynamicObject from '../../models/dynamic-object';


const showSuccess = (Intl : any,lang : string,customBody ?: string) => {
    const description : string= customBody ? customBody : Intl.formatMessage({id : 'successMsg'})
    toastify({severity : 'success', title :Intl.formatMessage({id : 'success'}),description : description,locale : lang})
}

const showError = (Intl : any,lang : string, errorMessages? : DynamicObject[] | DynamicObject) => {
    if(errorMessages)
    {   
        let messages:any[] = [];
        if(Array.isArray(errorMessages))
        {
            errorMessages?.map((e : any) => messages.push(e.message));    
        }
        else {
            messages.push(errorMessages.message);
        }
        toastify({severity: 'error' ,title : Intl.formatMessage({id: 'error'}), description: messages, locale: lang});
    }else {

        toastify({severity: 'error' ,title : Intl.formatMessage({id: 'error'}), description: "", locale: lang});
    }}


export {showSuccess,showError}