import { Button } from "../../../app/layout/components/opensooq/Button";
import { ProfileInterface } from "../../interfaces/ProfileInterface";
import {useNavigate} from 'react-router-dom'
import { useIntl } from 'react-intl'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import Skeleton from "react-loading-skeleton";
import { MyAccountStatesInterface } from "../../interfaces/MyAccountStatesInterface";
import { useEffect, useState } from "react";
import getAccountStatsApi from "../../../api/account/getAccountStats.api";

interface Props{
    profile ?: ProfileInterface
}

export function AccountStatistics({profile}: Props) {
    const [accountState,setAccountState] = useState <MyAccountStatesInterface>(); 
    const navigate = useNavigate(); 
    const Intl = useIntl();

    const redirectToStats = (e : any) =>{
        if (!e.target.classList.contains('toolChecker')) {
            navigate(`/account/my-account/account-stats`)
        }
    }

    useEffect(() => {
        getAccountStatsApi().then((response) => {
            setAccountState(response.data)
        })
    },[])

    return (
        <>
            <div className="card  mb-5 mb-xl-8">
                <div className="card-header p-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span  className="card-label fw-bold text-dark">{Intl.formatMessage({id: 'accountStatistics.title'})}</span> 
                        <span className="text-muted mt-1 fw-semibold fs-7">{Intl.formatMessage({id: 'accountStatistics.desc'})}</span>
                    </h3>
                </div>
                <div className="card-body p-5">
                    <div className="row ">
                        <div className="col hoverPointer" onClick={(e) => { redirectToStats(e)}}>
                            <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                <span className="fs-2hx fw-bold text-gray-900 counted" data-kt-countup="true">{!accountState && <Skeleton className="w-30px" />}{accountState && accountState.postCountTotal.toLocaleString()}</span>
                                <span id="listingView" className="toolChecker fs-7 fw-semibold text-muted d-block">{Intl.formatMessage({id: 'accountStatistics.totalListingsViews'})} <i   className="toolChecker fas fa-info-circle"></i></span>
                            </div>
                        </div>
                        <div className="col hoverPointer" onClick={(e) => { redirectToStats(e)}}>
                            <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                <span className="fs-2hx fw-bold text-gray-900 counted" data-kt-countup="true">{!accountState && <Skeleton className="w-30px" />}{accountState && accountState.viewsCountTotal.toLocaleString()}</span>
                                <span id="totalAccountView" className="toolChecker fs-7 fw-semibold text-muted d-block">
                                    {Intl.formatMessage({id: 'accountStatistics.totalAccountViews'})} <i  className="toolChecker fas fa-info-circle"></i>
                                </span>
                            </div>
                        </div>
                        <div className="col hoverPointer" onClick={(e) => { redirectToStats(e)}}>
                            <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                <span className="fs-2hx fw-bold text-gray-900 counted" data-kt-countup="true">{!accountState && <Skeleton className="w-30px" />}{accountState && (accountState.cvCountTotal) ? accountState.cvCountTotal.toLocaleString() : 0}</span>
                                <span id="totalCvView" className="toolChecker fs-7 fw-semibold text-muted d-block">
                                    {Intl.formatMessage({id: 'accountStatistics.totalCvViews'})} <i  className="toolChecker fas fa-info-circle"></i>
                                </span>
                            </div>
                        </div>
                        {/* <div className="col hoverPointer" onClick={(e) => { redirectToStats(e)}}>
                            <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                <span  className="fs-2hx fw-bold text-gray-900 counted" >291</span>
                                <span id="totalFavorite" className="toolChecker fs-7 fw-semibold text-muted d-block">
                                    {Intl.formatMessage({id: 'accountStatsText20'})} <i  className="toolChecker ms-1 fas fa-info-circle"></i>
                                </span>
                            </div>
                        </div>
                        <div className="col hoverPointer" onClick={(e) => { redirectToStats(e)}}>
                            <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                <span  className="fs-2hx fw-bold text-gray-900 counted" >291</span>
                                <span id="totalShares" className="toolChecker fs-7 fw-semibold text-muted d-block">
                                    {Intl.formatMessage({id: 'accountStatsText21'})} <i  className="toolChecker ms-1 fas fa-info-circle"></i>
                                </span>
                            </div>
                        </div> */}
                        {/* <div className="col hoverPointer" onClick={(e) => { redirectToStats(e)}}>
                            <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                <span  className="fs-2hx fw-bold text-gray-900 counted" >291</span>
                                <span id="totalShares" className="toolChecker fs-7 fw-semibold text-muted d-block">
                                    Total Listing Calls<i  className="toolChecker ms-1 fas fa-info-circle"></i>
                                </span>
                            </div>
                        </div>
                        <div className="col hoverPointer" onClick={(e) => { redirectToStats(e)}}>
                            <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                <span  className="fs-2hx fw-bold text-gray-900 counted" >291</span>
                                <span id="totalShares" className="toolChecker fs-7 fw-semibold text-muted d-block">
                                    Total Listing Chats<i  className="toolChecker ms-1 fas fa-info-circle"></i>
                                </span>
                            </div>
                        </div>
                        <div className="col hoverPointer" onClick={(e) => { redirectToStats(e)}}>
                            <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                <span  className="fs-2hx fw-bold text-gray-900 counted" >291</span>
                                <span id="totalShares" className="toolChecker fs-7 fw-semibold text-muted d-block">
                                    Total Account Calls<i  className="toolChecker ms-1 fas fa-info-circle"></i>
                                </span>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="card-footer p-3">
                    <Button className="btn-primary btn-sm" text={Intl.formatMessage({id: 'accountStatistics.viewStatistics'})} action={() => { navigate(`/account/my-account/account-stats`)}}/>
                </div>
            </div>
            <ReactTooltip clickable={true} anchorId="listingView" className="w-200px" place="bottom" content={Intl.formatMessage({id : "accountStatisticsListingsViewsI"})} />
            <ReactTooltip clickable={true} anchorId="totalAccountView" className="w-200px" place="bottom" content={Intl.formatMessage({id : "accountStatisticsAccountViewsI"})} />
            <ReactTooltip clickable={true} anchorId="totalCvView" className="w-200px" place="bottom" content={Intl.formatMessage({id : "accountStatisticsAccountCVI"})} />
        </>
    )
}