import { SubCategoryInterface } from "../../../interfaces/SubCategoryInterface"

interface Iprops {
    lang : string,
    searchData : SubCategoryInterface[]
    action :  (params: SubCategoryInterface) => void
}
const BundleSubCategoryComponent = ( {searchData, lang, action} : Iprops) => {
    return <>{ searchData && searchData.map((sub : SubCategoryInterface,index : number) => {
        return <div key={index}  className='col-12 shadow-sm trHoverAction hoverPointer radius-8 my-1 fw-bolder' onClick={() => {action(sub)}}>
            <div className="d-flex  py-1 ">
                <div className="" >
                    <div className="symbol symbol-45px ">
                        {/* <img src={sub.verticalAddPostIconPath.replace("{platform}","android").replace("{size}","xh")} className="h-50 align-self-center" alt=""/> */}
                    </div>
                </div>
                <div style={{display:"flex"}} className="p-2 fs-5 align-self-center fw-bolder">{lang === 'ar' ? sub.label_ar : sub.label_en}</div>
                <div style={{display:"flex"}} className="align-self-center ms-auto p-2 ">{lang === 'ar' ? <><i className="fas fa-arrow-left"></i></> : <><i className="fas fa-arrow-right"></i></>}</div>
            </div>      
        </div>
    })}</>
}

export {BundleSubCategoryComponent};