import CommercialNotice from "../CommercialNotice"
import CommercialUploadContainer from "../CommercialUploadContainer"
import Header from "../Header"
import ViewDocuments from "../ViewDocuments"

type Props = {
    showFileModal: () => any
    docsImagesList: () => any
}
const Commercial = ({showFileModal,docsImagesList} : Props) => {
    return <div className='card mb-5 mb-xl-10'>
        <div className='card-header'>
            <Header/>
        </div>
        <div className='card-body border-top p-9'>
            <CommercialNotice showFileModal={showFileModal}/>
            <CommercialUploadContainer showFileModal={showFileModal}/>
            <ViewDocuments docsImagesList={docsImagesList}/>
        </div>
    </div>
}

export default Commercial