import React from "react";
import { ACTIVATE, BUMPUP, BUMPUP_FREE, COPY, DELETE, DELETE_FOR_EVER, EDIT, LISTING_STATISTICS, PROMOTE, SHARE, VIEW_CHATS, VIEW_ON_OPENSOOQ } from "../helper/Ctas";
import {toAbsoluteUrl} from "../helpers";

type Props = {
  cta : string
}

const CheckCtasIcon = ({cta} : Props) => {
  return <>
    {(cta == ACTIVATE) ? <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M10 23.6562V6.15625L23.75 14.9062L10 23.6562Z" fill="black"/> </svg> : ''}
    {(cta == PROMOTE) ? <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M3.90662 22.8125L2.12537 21.0312L11.6879 11.5L16.9066 16.7187L23.7191 9.9375H19.8754V7.4375H27.8754V15.4375H25.4379V11.7187L16.8754 20.2812L11.6566 15.0625L3.90662 22.8125Z" fill="black"/> </svg>: ''}
    {(cta == BUMPUP_FREE) ? <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M9.27083 9.375H20.7292V12.75L25.3125 8.25L20.7292 3.75V7.125H6.97917V13.875H9.27083V9.375ZM20.7292 20.625H9.27083V17.25L4.6875 21.75L9.27083 26.25V22.875H23.0208V16.125H20.7292V20.625Z" fill="black"/> </svg> :''}
    {(cta == BUMPUP) ?<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M9.27083 9.375H20.7292V12.75L25.3125 8.25L20.7292 3.75V7.125H6.97917V13.875H9.27083V9.375ZM20.7292 20.625H9.27083V17.25L4.6875 21.75L9.27083 26.25V22.875H23.0208V16.125H20.7292V20.625Z" fill="black"/> </svg>  : ''}
    {(cta == LISTING_STATISTICS) ?<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M4.875 25.125V11.125H9.5V25.125H4.875ZM12.6875 25.125V4.875H17.3125V25.125H12.6875ZM20.5 25.125V16.125H25.125V25.125H20.5Z" fill="black"/> </svg>  : ''}
    {(cta == VIEW_ON_OPENSOOQ) ? <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clipPath="url(#clip0_1576_4021)"> <path d="M15.0005 7.5C19.738 7.5 23.963 10.1625 26.0255 14.375C23.963 18.5875 19.738 21.25 15.0005 21.25C10.263 21.25 6.03796 18.5875 3.97546 14.375C6.03796 10.1625 10.263 7.5 15.0005 7.5ZM15.0005 5C8.75046 5 3.41296 8.8875 1.25046 14.375C3.41296 19.8625 8.75046 23.75 15.0005 23.75C21.2505 23.75 26.588 19.8625 28.7505 14.375C26.588 8.8875 21.2505 5 15.0005 5ZM15.0005 11.25C16.7255 11.25 18.1255 12.65 18.1255 14.375C18.1255 16.1 16.7255 17.5 15.0005 17.5C13.2755 17.5 11.8755 16.1 11.8755 14.375C11.8755 12.65 13.2755 11.25 15.0005 11.25ZM15.0005 8.75C11.9005 8.75 9.37546 11.275 9.37546 14.375C9.37546 17.475 11.9005 20 15.0005 20C18.1005 20 20.6255 17.475 20.6255 14.375C20.6255 11.275 18.1005 8.75 15.0005 8.75Z" fill="black"/> </g> <defs> <clipPath id="clip0_1576_4021"> <rect width="30" height="30" fill="white"/> </clipPath> </defs> </svg> : ''}
    {(cta == SHARE) ?<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clipPath="url(#clip0_1576_4016)"> <path d="M19.9991 6.25044L18.2241 8.02544L16.2366 6.03794L16.2366 20.0004L13.7616 20.0004L13.7616 6.03794L11.7741 8.02544L9.99911 6.25044L14.9991 1.25044L19.9991 6.25044ZM24.9991 12.5004L24.9991 26.2504C24.9991 27.6254 23.8741 28.7504 22.4991 28.7504L7.49911 28.7504C6.11161 28.7504 4.99911 27.6254 4.99911 26.2504L4.99911 12.5004C4.99911 11.1129 6.11161 10.0004 7.49911 10.0004L11.2491 10.0004L11.2491 12.5004L7.49911 12.5004L7.49911 26.2504L22.4991 26.2504L22.4991 12.5004L18.7491 12.5004L18.7491 10.0004L22.4991 10.0004C23.8741 10.0004 24.9991 11.1129 24.9991 12.5004Z" fill="black"/> </g> <defs> <clipPath id="clip0_1576_4016"> <rect width="30" height="30" fill="white"/> </clipPath> </defs> </svg> : ''}
    {(cta == COPY) ?<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clipPath="url(#clip0_1576_4028)"> <path d="M19.9995 1.25003H4.99954C3.62454 1.25003 2.49954 2.37503 2.49954 3.75003V21.25H4.99954V3.75003H19.9995V1.25003ZM23.7495 6.25003H9.99954C8.62454 6.25003 7.49954 7.37503 7.49954 8.75003V26.25C7.49954 27.625 8.62454 28.75 9.99954 28.75H23.7495C25.1245 28.75 26.2495 27.625 26.2495 26.25V8.75003C26.2495 7.37503 25.1245 6.25003 23.7495 6.25003ZM23.7495 26.25H9.99954V8.75003H23.7495V26.25Z" fill="black"/> </g> <defs> <clipPath id="clip0_1576_4028"> <rect width="30" height="30" fill="white"/> </clipPath> </defs> </svg> : ''}
    {(cta == VIEW_CHATS) ?<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clipPath="url(#clip0_1576_4010)"> <path fillRule="evenodd" clipRule="evenodd" d="M23.058 10.3214L23.0939 10.9091C23.0995 11.4661 23.0939 11.4157 23.0939 11.9318C23.0939 17.3687 18.6711 21.1364 12.5367 21.1364H10.4252C12.1003 22.7237 14.0491 23.6932 16.7595 23.6932C17.336 23.6932 17.8983 23.6427 18.4452 23.5561L22.39 25.9091V22.0418C24.5289 20.6222 25.9091 18.4186 25.9091 15.9368C25.9091 13.725 24.8112 11.7348 23.058 10.3214ZM10.8728 19.5934C11.4204 19.6793 12.664 19.7727 13.2405 19.7727C18.2939 19.7727 21.6863 16.1297 21.6863 11.8466C21.6863 7.56338 17.4472 4.09091 13.2405 4.09091C8.18008 4.09091 4.09091 7.56342 4.09091 11.8466C4.09091 14.3277 5.0671 16.4345 6.90617 17.9516V21.8181L10.8728 19.5934Z" fill="black"/> </g> <defs> <clipPath id="clip0_1576_4010"> <rect width="30" height="30" fill="white"/> </clipPath> </defs> </svg>  : ''}
    {(cta == EDIT) ? <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M24.8125 9.18751L20.8125 5.1875L22.125 3.875C22.4792 3.52084 22.9219 3.34896 23.4531 3.35938C23.9844 3.3698 24.4271 3.55209 24.7813 3.90625L26.125 5.25C26.4792 5.60417 26.6563 6.04167 26.6563 6.56251C26.6563 7.08334 26.4792 7.52084 26.125 7.87501L24.8125 9.18751ZM23.5 10.5L7.75 26.25H3.75V22.25L19.5 6.50001L23.5 10.5Z" fill="black"/> </svg>: ''}
    {(cta == DELETE) ? <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.15671 26.25C7.65671 26.25 7.21921 26.0625 6.84421 25.6875C6.46921 25.3125 6.28171 24.875 6.28171 24.375V6.5625H5.00046V4.6875H10.8755V3.75H19.1255V4.6875H25.0005V6.5625H23.7192V24.375C23.7192 24.875 23.5317 25.3125 23.1567 25.6875C22.7817 26.0625 22.3442 26.25 21.8442 26.25H8.15671ZM11.4692 21.6875H13.3442V9.21875H11.4692V21.6875ZM16.6567 21.6875H18.5317V9.21875H16.6567V21.6875Z" fill="black"/>
        </svg>
        : ''}
    {(cta == DELETE_FOR_EVER) ? <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M8.15671 26.25C7.65671 26.25 7.21921 26.0625 6.84421 25.6875C6.46921 25.3125 6.28171 24.875 6.28171 24.375V6.5625H5.00046V4.6875H10.8755V3.75H19.1255V4.6875H25.0005V6.5625H23.7192V24.375C23.7192 24.875 23.5317 25.3125 23.1567 25.6875C22.7817 26.0625 22.3442 26.25 21.8442 26.25H8.15671ZM11.4692 21.6875H13.3442V9.21875H11.4692V21.6875ZM16.6567 21.6875H18.5317V9.21875H16.6567V21.6875Z" fill="black"/> </svg>: ''}
  </>
}

export {CheckCtasIcon}