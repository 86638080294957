import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'

const SidebarLogo = () => {
  const {config} = useLayout()
  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
    ? 'minimize'
    : ''
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : ''
  const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default
  return (
    <div className='app-sidebar-logo px-6 justifyContentUnset border-bottom-0' id='kt_app_sidebar_logo ' >
      <Link to='/'>
        {config.layoutType === 'dark-sidebar' ? (
          <img
            alt='Logo'
            src={'https://opensooqui-new.s3.amazonaws.com/api/v/icons/misc/MyOpenSooq.svg'}
            className='h-40px app-sidebar-logo-default '
          />
        ) : (
          <>
             <img
              alt='Logo'
              src={'https://opensooqui-new.s3.amazonaws.com/api/v/icons/misc/MyOpenSooq.svg'}
              className=' app-sidebar-logo-default theme-light-show w-150px' 
            />
            {/* <img
              alt='Logo'
              src={process.env.REACT_APP_OPENSOOQ_LOGO}
              className='h-40px app-sidebar-logo-default theme-dark-show '
            /> */}
          </>
        )}

        <img
          alt='Logo'
          src={'https://opensooqui-new.s3.amazonaws.com/api/v/icons/misc/MyOpenSooq.svg'}
          className='h-20px app-sidebar-logo-minimize'
        />
      </Link>
    </div>
  )
}

export {SidebarLogo}
