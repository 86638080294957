import clsx from 'clsx'
import {useLayout} from '../../core'
import {Footer} from './Footer'

const FooterWrapper = () => {
  const {config} = useLayout()
  if (!config.app?.footer?.display) {
    return null
  }

  return (
    <>
    <div className="separator text-dark "></div>
    <div className='app-footer' id='kt_app_footer' style={{backgroundColor:"#f5f8fa" ,width:"100%" ,bottom:"0","height" :"auto"}}>
      {config.app.footer.containerClass ? (
        <div className={`container-xxl p-10 justify-content-between`}>
          <Footer />
        </div>
      ) : (
        <Footer />
      )}
    </div>
    </>
  )
}

export {FooterWrapper}
