import getBalanceApi from "../../../../api/getBalance.api";
import { useIntl } from "react-intl"
import { useEffect, useRef, useState } from "react";
import { BalanceInterface } from "../../../interfaces/BalanceInterface";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { RechargeModal } from "../../../layout/components/modals/RechargeModal";
import rechargeCardApi from "../../../../api/rechargeCard.api";
import { showSuccess } from "../../../helper/SystemAlert";
import { useLang } from "../../../i18n/OpenSooqi18n";
import { BundleModal } from "../../payment/BundleModal";
import accountFeaturesApi from "../../../../api/opensooq/accountFeatures.api";
import { MyAccountFeatures } from "../../../interfaces/MyAccountFeatures";
import { getCookies } from "../../../helpers/commonFunctions";
import { WalletDetailsInterface } from "../../../interfaces/WalletDetailsInterface";

type Props = {
    walletDetails : WalletDetailsInterface
}
const BalanceWidget = ({walletDetails} : Props) => {
    const memberInfo = getCookies().userInfo;
    const [showBundle,setShowBundle] = useState<boolean>(false)
    const refBundle = useRef<null | HTMLDivElement>(null); 
    const [rechargeModal, setRechargeModal] = useState(false)
    const [isDisabled,setIsDisabled] = useState(false)
    const [error,setError] = useState(false)
    const [code,setCode] = useState('')
    const Intl = useIntl();
    const lang = useLang();

    const setRechargeCode = (e: any) => {
        setCode(e.target.value);
    };

    const recharge = () => {
        setError(false)
        setIsDisabled(true);
        const rechargeCodeString = code;
        let data = {
            "VoucherRedeemForm" : {
                "code" : rechargeCodeString, 
                "posSource" :'myWallet'
            }
        };
        rechargeCardApi(data).then((response) => {
            if(response.status == 200){
                setRechargeModal(false);
                showSuccess(Intl,lang)
                setIsDisabled(false);
            }else{
                setIsDisabled(false);
                setError(true)
            }
        }).catch(err => {
            setIsDisabled(false);
            setError(true)
        });
    };

    return <><div className="card h-100 card-xxl-stretch mb-5 mb-xxl-10">
            <div className="card-header">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-gray-800">{Intl.formatMessage({id: 'myWalletText1'})}</span>
                    <span className="text-gray-900 mt-1 fw-semibold fs-6">{Intl.formatMessage({id: 'myWalletText2'})}</span>
                </h3>
            </div>
            <div className="card-body pb-0">
                <div className="d-flex flex-wrap">
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                        <div className="d-flex align-items-center fw-bold  fs-2">
                            {!walletDetails  ? <Skeleton className="w-150px"/> : <span className=""> {walletDetails.wallet.balance.toLocaleString()}</span>}
                        </div>
                        <div className="fw-semibold fs-6 text-gray-400">
                            {walletDetails && <div className=" counted">{walletDetails.wallet.currency} ({Intl.formatMessage({id : "myWalletText3"})})</div>}
                        </div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                        <div className="d-flex align-items-center fw-bold  fs-2">
                            {!walletDetails ? <Skeleton className="w-150px"/> : walletDetails.wallet.availableBundleQuantity.toLocaleString()}
                        </div>
                        <div className="fw-semibold fs-6 text-gray-400">
                            <div className=" counted">{Intl.formatMessage({id:"myWallet.text2"})}</div>
                        </div>
                    </div>
                </div>
            
            </div>
            <div className="card-footer" ref={refBundle}>
                <div className="d-flex flex-wrap" ref={refBundle}>
                    <button onClick={() => setShowBundle(true)} className='btn btn-primary me-3 align-self-end text-white'>
                        {Intl.formatMessage({id: 'myWalletText6'})}
                    </button>
                    <button className={'btn btn-primary  align-self-end'} onClick={() => {setRechargeModal(true)}}>{Intl.formatMessage({id: 'myWalletText7'})}</button>
                </div>
            </div>
        </div>
        <RechargeModal isDisabled={isDisabled} error={error} show={rechargeModal} setRechargeCode={(e : any) => {setRechargeCode(e)}} handleAction={recharge} handleClose={() => setRechargeModal(false)} />
        <BundleModal show={showBundle}  type={['Credit']} handleClose={() => {setShowBundle(false)}} id={memberInfo.id} pos={'myWallet'}/>
    </>
}

export {BalanceWidget}