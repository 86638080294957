import { useIntl } from "react-intl"

const Header = () => {
    const Intl = useIntl();
    return <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bold text-gray-800'>
            {Intl.formatMessage({id: 'myProfile.id.title'})}
        </span>
    </h3>
}

export default Header