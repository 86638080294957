/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useIntl } from "react-intl"
import { useLang } from '../../../app/i18n/OpenSooqi18n'

type Props = {
  className         :   string
  color             :   string
  totalAdsViews     :   number
  totalAccountViews :   number
  cvCountTotal      :   number
}


const HistoricalViews: React.FC<Props> = ({className, color,totalAdsViews,totalAccountViews,cvCountTotal}) => {
  const lang = useLang();
  const Intl = useIntl();   

  return (
    <div className={`card ${className}`}>
      <div className='card-body p-0'>
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-${color}`}>
          <div className='d-flex flex-stack'>
            <h3 className='m-0 text-white fw-bold fs-3'>
              {Intl.formatMessage({id: 'accountStatsText5'})}
              <br/>
              <span className='fs-7'>
              {Intl.formatMessage({id: 'accountStatsText6'})}
              </span>
            </h3>
          </div>
        </div>
        <div className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body' style={{marginTop: '-200px'}} >
          <div className='d-flex align-items-center mb-6'>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                {Intl.formatMessage({id: 'accountStatsText7'})}
                </a>
              </div>
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1'>{totalAdsViews.toLocaleString()}</div>
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center mb-6'>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                {Intl.formatMessage({id: 'accountStatsText8'})}
                </a>
              </div>
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1'>{totalAccountViews.toLocaleString()}</div>
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center mb-6'>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                {Intl.formatMessage({id: 'accountStatsText9'})}
                </a>
              </div>
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1'>{cvCountTotal ? cvCountTotal.toLocaleString() : 0}</div>
              </div>
            </div>
          </div>
            {/* <div className='d-flex align-items-center mb-6'>
              <div className='d-flex align-items-center flex-wrap w-100'>
                <div className='mb-1 pe-3 flex-grow-1'>
                  <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  {Intl.formatMessage({id: 'accountStatsText20'})}
                  </a>
                </div>
                <div className='d-flex align-items-center'>
                  <div className='fw-bold fs-5 text-gray-800 pe-1'>10</div>
                </div>
              </div>
            </div>
            <div className='d-flex align-items-center mb-6'>
              <div className='d-flex align-items-center flex-wrap w-100'>
                <div className='mb-1 pe-3 flex-grow-1'>
                  <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  {Intl.formatMessage({id: 'accountStatsText21'})}
                  </a>
                </div>
                <div className='d-flex align-items-center'>
                  <div className='fw-bold fs-5 text-gray-800 pe-1'>10</div>
                </div>
              </div>
            </div>
            <div className='d-flex align-items-center mb-6'>
              <div className='d-flex align-items-center flex-wrap w-100'>
                <div className='mb-1 pe-3 flex-grow-1'>
                  <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  Total Listings Chat
                  </a>
                </div>
                <div className='d-flex align-items-center'>
                  <div className='fw-bold fs-5 text-gray-800 pe-1'>10</div>
                </div>
              </div>
            </div>
            <div className='d-flex align-items-center mb-6'>
              <div className='d-flex align-items-center flex-wrap w-100'>
                <div className='mb-1 pe-3 flex-grow-1'>
                  <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  {Intl.formatMessage({id : "myAccount.totalListingCalls"})}
                  </a>
                </div>
                <div className='d-flex align-items-center'>
                  <div className='fw-bold fs-5 text-gray-800 pe-1'>10</div>
                </div>
              </div>
            </div>
            <div className='d-flex align-items-center mb-6'>
              <div className='d-flex align-items-center flex-wrap w-100'>
                <div className='mb-1 pe-3 flex-grow-1'>
                  <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  Total Account Calls
                  </a>
                </div>
                <div className='d-flex align-items-center'>
                  <div className='fw-bold fs-5 text-gray-800 pe-1'>10</div>
                </div>
              </div>
            </div> */}
          </div>
      </div>
    </div>
  )
}

export {HistoricalViews}
