import DynamicObject from "../../models/dynamic-object";
import { AxiosResponse} from "axios";
import Api from "../api";
import { isDeviceMobile } from "../../app/helpers/commonFunctions";

export default (filter : string, nextPage : string | '',status? : number,config?: DynamicObject): Promise<AxiosResponse> => {
    const path = `/account/listings/my-listings/v2?perPage=${isDeviceMobile() ? 8 : 30}`
    if(nextPage != ''){
        let newUrl = nextPage.replace(/%2C/g,",")
        const fullUrl = newUrl?.replace('http:',"https:")
        const apiUrl : string | '' = fullUrl
        return Api.getInstance().request.get(apiUrl, path)
    }else{
        // const url = `posts/my-post?filter=${filter}&searchTerm=&${queryParam}&supportCurrency=true&expand=category,maxViews,statsReport,images,phoneVerificationAction,dynamicAttributes,nb_comments,nb_messages,numberOfImages,ruleLabels,statusReasonDetails,shortUrl,postDetails,mediasWith360,vasExpiryMessages,jobApplicantCount,actions,custom_param_string,city_name,city,neighborhood,status,posted_days,expired_in_days,price_list,comment_enabled,custom_param_array&PostSearch[categoryId]=0&PostSearch[subCategoryId]=0&PostSearch[cityId]=0&PostSearch[neighborhood_id]=0&PostSearch[priceFrom]=0&PostSearch[priceTo]=0&PostSearch[price_currency]=0`;
        const url = `${path}&${filter}`;
        return Api.getInstance(false,false,process.env.REACT_APP_API_SUB_URL).request.get(url, path)
    }
}