import { isMobile } from 'react-device-detect'
import { useIntl } from 'react-intl'
import { social } from '../../../../helper/social'
import { toAbsoluteUrl } from '../../../../helpers'
import {SidebarMenuMain} from './SidebarMenuMain'

type Props = {
  height ?: string
  comingFrom?:string
}

const SidebarMenu = ({height,comingFrom } : Props) => {
  const Intl = useIntl();
  return (
    <div className={`app-sidebar-menu overflow-scroll flex-column-fluid ${isMobile ? 'h-425px' : 'h-450px'}`}>
      <div
        id='kt_app_sidebar_menu_wrapper'
        className='app-sidebar-wrapper hover-scroll-overlay-y '
        data-kt-scroll='true'
        data-kt-scroll-activate='true'
        data-kt-scroll-height='100%'
        data-kt-scroll-dependencies='#kt_app_sidebar_logo, #kt_app_sidebar_footer'
        data-kt-scroll-wrappers='#kt_app_sidebar_menu'
        data-kt-scroll-offset='5px'
        data-kt-scroll-save-state='true'
        style={{height: "auto !important"}}
      >
        <div
          className='menu menu-column menu-rounded menu-sub-indention px-3'
          id='#kt_app_sidebar_menu'
          data-kt-menu='true'
          data-kt-menu-expand='false'
        >
          <SidebarMenuMain comingFrom={comingFrom} />
          {isMobile && comingFrom != 'headMenu' && <><div className='separator my-2'></div>
          <div className="flex-column-fluid ps-5 pb-5 pe-5 ">
            <strong className="d-block">
              {Intl.formatMessage({id : "downloadOpenSooq"})}
            </strong>
            <div className="flex mt-4">
              <a target="_blank" href={social.sideMenu.googlePlayStore}aria-label="Download App" > 
                <img src={toAbsoluteUrl("/media/custom/googlePlay.png")}  className="w-75px  me-1" height="25" alt="Google Play" loading="lazy" /> 
              </a>
              <a target="_blank" href={social.sideMenu.appleStore} aria-label="Download App" > 
                <img src={toAbsoluteUrl("/media/custom/appStore.png")} className="w-75px  me-1" height="25" alt="App Store" loading="lazy" /> 
              </a>
              <a target="_blank" href={social.sideMenu.huaweiStore} aria-label="Download App" >
                  <img src={toAbsoluteUrl("/media/custom/appGallery.png")}  className="w-75px " height="25" alt="App Gallery" loading="lazy" /> 
              </a>
            </div>
          </div>
        </>}
        </div>
      </div>
    </div>
  )
}

export {SidebarMenu}
