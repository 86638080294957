import {useState, useRef} from 'react'
import {Modal} from 'react-bootstrap'
import { useIntl } from "react-intl"

type Props = {
  show: boolean
  handleClose: () => void
  unFollowHeaderText ?:  string
  unFollowBodyText :  string
  action ?:  (params?: any,params2 ?: any) => any
  targetId : number
}

const ActionsModal = ({show, handleClose,unFollowBodyText,unFollowHeaderText,action,targetId}: Props) => {
  const Intl = useIntl()
  return <>
        <Modal id='kt_modal_create_app' tabIndex={-1} aria-hidden='true' dialogClassName='modal-dialog modal-dialog-centered ' show={show} onHide={handleClose}  >
            {unFollowHeaderText && <div className='modal-header p-5'>
                <h2>{unFollowHeaderText}</h2>
            </div>}
            <div className='modal-body py-lg-10 px-lg-10'>
                <p>
                <i className="fa-light fa-circle-info"></i>
                </p>
                {unFollowBodyText}
            </div>
            <div className='modal-footer py-lg-10 px-lg-10 justify-content-start'>
                <button onClick={action} data-id={targetId}  className="btn btn-primary" >{Intl.formatMessage({id: 'confirm'})}</button>
                <button onClick={handleClose} className="btn btn-danger">{Intl.formatMessage({id: 'cancel'})}</button>
            </div>
        </Modal>
    </>
}

export {ActionsModal}