import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import { useLang } from '../../../../i18n/OpenSooqi18n'
import { Button } from '../../opensooq/Button'
import { generateOpenSooqRedirectUrl, getCookies } from '../../../../helpers/commonFunctions'

export function MenuInner() {
  const intl = useIntl()
  const lang = useLang()
  const openSooqUrl = generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',getCookies().ecountry,lang);
  return (
    <>
     <div className='menu-item me-lg-1'>
      <a href={`${openSooqUrl}`}  target="_blank" className={'btn btn-light text-dark unsetOnHover'}>{intl.formatMessage({id : "backTopOpenSooq"})}</a>
     </div>
    </>
  )
}
