import { Button } from '../../../app/layout/components/opensooq/Button';
import { toAbsoluteUrl } from '../../../app/helpers';
import {useNavigate} from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react';
import { ProfileHeader } from './../common/ProfileHeader';
import { useLang } from '../../i18n/OpenSooqi18n';
import AccountFeatures from './AccountFeatures';
import Products from './Products';
import sellerApi from '../../../api/opensooq/seller.api';
import { SellerInterface } from '../../interfaces/SellerInterface';
import Skeleton from 'react-loading-skeleton';
import { MyAccountFeatures } from '../../interfaces/MyAccountFeatures';
import accountFeaturesApi from '../../../api/opensooq/accountFeatures.api';
import getAccountStatsApi from '../../../api/account/getAccountStats.api';
import { MyAccountStatesInterface } from '../../interfaces/MyAccountStatesInterface';
import { BuyerInterface } from '../../interfaces/BuyerInterface';
import buyerApi from '../../../api/opensooq/buyer.api.';
import Seller from './Seller';
import Buyer from './Buyer';
import AccountStats from './AccountStats';
import UpgradeAccount from './UpgradeAccount';
import getProfileApi from '../../../api/profile/getProfile.api';
import { MemberInfoInterface } from '../../interfaces/MemberInfoInterface';
import { REGULAR_MEMBER_TYPE, SHOP_MEMBER_TYPE } from '../../helper/MemberTypes';
import accountLimitsApi from '../../../api/opensooq/accountLimits.api';
import { ShopLimitInterface } from '../../interfaces/ShopLimitInterface';

export function MyOpenSooq() {
    const [accountState,setAccountState] = useState <MyAccountStatesInterface>(); 
    const [accountFeature,setAccountFeature] = useState <MyAccountFeatures>(); 
    const [seller,setSeller] = useState <SellerInterface>(); 
    const [buyer,setBuyer] = useState <BuyerInterface>(); 
    const [profile, setProfile] = useState<MemberInfoInterface>();
    const [shopLimit, setShopLimit] = useState<ShopLimitInterface>();

    const Intl = useIntl();
    const navigate = useNavigate(); 
    const lang = useLang();

    function navigateTo(url : string) {
        navigate(url);
    }
        
    useEffect(() => {

        getProfileApi().then(response => {
            const results = response.data;
            setProfile(results);
        })
        sellerApi().then((response) => {
            setSeller(response.data);
        })
        accountFeaturesApi().then((response) => {
            setAccountFeature(response.data);
        })
        getAccountStatsApi().then((response) => {
            setAccountState(response.data)
        })
        buyerApi().then((response) => {
            setBuyer(response.data)
        })
        accountLimitsApi().then((response) => {
            setShopLimit(response.data);
        })

    },[])
    return (
        <>
            {/* <div className="card mb-5 mb-xxl-8">
                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                            <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)"
                                fill="currentColor"></rect>
                            <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)"
                                fill="currentColor"></rect>
                        </svg>
                    </span>
                    <div className="d-flex flex-stack flex-grow-1">
                        <div className="fw-semibold">
                            <h4 className="text-gray-900 fw-bold">{Intl.formatMessage({id : "shoExpiry.title"})}</h4>
                            <div className="fs-6 text-gray-700">{Intl.formatMessage({id : "shoExpiry.duration"})}</div>
                            <div className="fs-6 text-gray-700">{Intl.formatMessage({id : "shoExpiry.contact"})}</div>
                        </div>
                    </div>
                </div>
            </div> */}
            <ProfileHeader profile={profile}/>
            <div className="row g-5 g-xl-8 mt-4">
                <div className="col-xl-4 mt-3">
                    <AccountFeatures accountFeature={accountFeature} navigateTo={navigateTo}/>
                </div>
                <div className="col-xl-4 mt-3">
                    <Seller seller={seller} navigateTo={navigateTo}/>
                </div>
                <div className="col-xl-4 mt-3">
                    <Buyer buyer={buyer} navigateTo={navigateTo}/>
                </div>
                <div className="col-xl-4 mt-3">
                    <AccountStats accountState={accountState} navigateTo={navigateTo}/>
                </div>
                <div className="col-xl-4 mt-3">
                    <Products/>
                </div>
                {profile && profile.member_basic.member_type == REGULAR_MEMBER_TYPE && accountFeature && shopLimit && <div className="col-xl-4 mt-3">
                    <UpgradeAccount shopLimit = { shopLimit}/>
                </div>}
            </div>
        </>
    )
}
