export interface IUpdateEmail {
    newEmail: string
    confirmPassword: string
}

export interface IUpdatePassword {
    currentPassword: string
    newPassword: string
    passwordConfirmation: string
}

export const updateEmail: IUpdateEmail = {
    newEmail: 'example@example.com',
    confirmPassword: '',
}

export const updatePassword: IUpdatePassword = {
    currentPassword: '',
    newPassword: '',
    passwordConfirmation: '',
}

export interface CountriesInterface {
    abbreviation: string
    icon: string
    name: string
    phone_code: number
}

export interface ErrorMessages {
    firstName: string
    email: string
}

export interface DocsInterface {
    id: number
    media_type: number
    member_id: number
    mime_type: string
    uri: string
}