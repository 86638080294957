/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import { Link } from 'react-router-dom'
import { useIntl } from "react-intl"
import { useThemeMode } from '../../../partials'
import { ListingStatsInterface } from '../../../interfaces/ListingStatsInterface'
import Moment from 'moment';
import { Tooltip } from 'react-tooltip'
import { ReactTooltipContainer } from '../../../../app/modules/common/ReactTooltipContainer'

type Props = {
  adId        : string
  className   : string
  chartType   : ListingStatsInterface
  title       : string
  description : string
  buttonText  ?: string
  total       : number,
  target      : string 
  extraText   ?: string
  chartName : string
}

const Chart: React.FC<Props> = ({className,chartType,title,description,buttonText,total,target,adId,extraText,chartName}) => {
    const min = new Date(chartType.min * 1000)
    const max = new Date(chartType.max * 1000)
    
    const premium = chartType.premium;
    const free = chartType.free;
    const categoriesPremiumKeys = Object.keys(premium);
    const categoriesPremiumValues = Object.values(premium);
    const categoriesFreeKeys = Object.keys(free);
    const categoriesFreeValues = Object.values(free);

    
    for (const key in categoriesPremiumKeys) {
      categoriesPremiumKeys[key] = Moment.unix(parseInt(categoriesPremiumKeys[key])).format("YYYY-MM-DD");
    }

    for (const key in categoriesFreeKeys) {
      categoriesFreeKeys[key] = Moment.unix(parseInt(categoriesFreeKeys[key])).format("YYYY-MM-DD");
    }

    const employee : any = {
        ...chartType.free,
        ...chartType.premium
    };

    const res = Object.entries(employee).reduce((acc,[k,v])=>{
      acc[k] = (acc[k] || 0) + v;
      return acc;
    }, {...employee});

    const cat =  Object.keys(res).map(item => {
      return Moment.unix(parseInt(item)).format("YYYY-MM-DD");
    })
    
    // const categoriesFreeKeys = Object.keys(res);
    // const categoriesFreeValues = Object.values(res);

    for (const key in categoriesFreeKeys) {
      categoriesFreeKeys[key] = Moment.unix(parseInt(categoriesFreeKeys[key])).format("YYYY-MM-DD");
    }


    function toTimestamp(strDate : Date){
      var datum = Date.parse(strDate.toString());
      return datum/1000;
   }

    
    const chartRef = useRef<HTMLDivElement | null>(null)
    const {mode} = useThemeMode()
    const Intl = useIntl();

    useEffect(() => {
        const chart = refreshChart(Intl,categoriesPremiumKeys,categoriesPremiumValues)
        return () => {
            if (chart) {
              chart.destroy()
            }
        }
    }, [chartRef, mode])

    const refreshChart = (Intl : any,categoriesPremiumKeys : {},categoriesPremiumValues : {}) => {
        if (!chartRef.current) {
            return
        }

        const height = parseInt(getCSS(chartRef.current, 'height'))

        const chart = new ApexCharts(chartRef.current, getChartOptions(Intl,height,categoriesPremiumKeys,categoriesPremiumValues,categoriesFreeKeys,categoriesFreeValues,min,max,cat))
        if (chart) {
            chart.render()
        }
        return chart
    }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 '>
        <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
            <span className='text-muted fw-semibold fs-7'>{description}</span>
            {extraText && <span className='text-muted fw-semibold fs-7'>{extraText}</span>}
        </h3>
        <div className='card-toolbar'>
            <div className="d-flex flex-column mt-2">
                <div className="bd-highlight fs-3 fw-bolder">{total.toLocaleString()}</div>
                {/* <div className="bd-highlight fs-3 mt-1">
                    <span className='text-success fw-bolder'>+100% </span>{Intl.formatMessage({id: 'sinceLastWeek'})}
                </div> */}
                {buttonText && <div className="bd-highlight mt-1 fs-3">
                    <Link to={`/listings/my-listings/listing-leads?type=${target}&id=${adId}`} className='btn btn-primary btn-sm'>{buttonText}</Link>
                </div>}
            </div>
        </div>
      </div>

      <div className='card-body'>
        {total == 0 && <div className='p-15 text-center'><h1>{Intl.formatMessage({id : 'no'})} {chartName}</h1>  </div>}
        {total > 0 && <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '350px'}} />}
      </div>
    </div>

  )
}

export {Chart}


function getChartOptions(Intl : any,height: number,categoriesPremiumKeys : {},categoriesPremiumValues :any,categoriesFreeKeys : any,categoriesFreeValues : any,min : Date,max : Date,cat : string[]): ApexOptions {
  
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseColor = getCSSVariableValue('--kt-primary')
  const secondaryColor = getCSSVariableValue('--kt-gray-300')
  return {
    series: [
      {
        name: Intl.formatMessage({id : "status.promoted"}),
        data: categoriesPremiumValues,
      },
      {
        name: Intl.formatMessage({id : "status.regular"}),
        data: categoriesFreeValues,
      },
    ],

    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      type: 'datetime',
      min : min.getTime(),
      max : max.getTime(),
      categories:cat,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
      colors:['#FFA500', '#4E4E61'],

    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val : any) {
          return val
        },
      },
    },
    colors:['#FFA500', '#4E4E61'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
