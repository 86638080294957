import { MyWalletTableLoader } from "../../../components/loaders/MyWalletTableLoader"
import { ServicesListInterface } from "../../../interfaces/ServiceInterface";
import { useLang } from "../../../i18n/OpenSooqi18n";
import React, { useRef, useState } from "react";
import { useIntl } from "react-intl"
import Select from "react-select";
import Moment from 'moment';
import { UseNowModal } from "./UseNowModal";
import { toAbsoluteUrl } from "../../../helpers";
import { getCookies } from "../../../helpers/commonFunctions";

type Props = {
    Intl : any
    lang : any
    action : () => void
}

const BuyBundles = ({Intl,lang,action} : Props) => {
    return <div className="card h-100 bg-primary " data-theme="light">
        <div className="card-body d-flex flex-column pt-13 pb-0">
            <div className="m-0">
                <h1 className="fw-semibold text-white text-center lh-lg mb-5">
                    {Intl.formatMessage({id: 'myWalletText8'})}
                <br/>
                <span className="fw-bolder">{Intl.formatMessage({id: 'myWalletText9'})}</span></h1>
                <div className="d-flex flex-center ">
                    <img className="me-1" width="35" height="35" src={toAbsoluteUrl('/media/custom/featured.svg')} alt="feature"/>
                    <img className="me-1" width="35" height="35" src={toAbsoluteUrl('/media/custom/repost.svg')} alt="feature"/>
                    <img className="" width="35" height="35" src={toAbsoluteUrl('/media/custom/turbo.svg')} alt="feature"/>
                </div>
            </div>
            <div className="text-center mt-5 pb-5">
                <span onClick={() => {action()}} className="btn btn-sm bg-white btn-color-gray-800 " >{Intl.formatMessage({id: 'buyNow'})}</span>
            </div>
        </div>
    </div>
}

export default BuyBundles