import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { POST_STATUS_FEATURED, POST_STATUS_LIVE, POST_STATUS_PENDING } from "../../../helper/PostStatus";
import { SellerInterface } from "../../../interfaces/SellerInterface";

type Component = {
    changeStatus :  (params: any) => any
    seller ?: SellerInterface
}

const MyListingsOverview = (props :  Component) => {
    const Intl = useIntl();
    let navigate = useNavigate(); 
    function navigateTo(url : string) {
        navigate(url);
    }

    return <div className="card h-100">
        <div className="card-header">
            <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">{Intl.formatMessage({id :"myListings.overview.title"})}</span>
                <span className="text-gray-900 mt-1 fw-semibold fs-6">{Intl.formatMessage({id :"myListings.overview.desc"})} </span>
            </h3>
        </div>
        <div className="card-body pt-9 pb-0">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                    <div className="border border-dashed border-gray-300  grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer"  onClick={() => {props.changeStatus('promoted')}}>
                        <div className="d-flex align-items-center hoverPointer">
                            <span className="bullet bullet-vertical h-30px bg-success"></span>
                            <div className="form-check form-check-custom form-check-solid mx-5"></div>
                            <div className="flex-grow-1">
                                <a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6">{Intl.formatMessage({id : "status.promoted"})}</a>
                            </div>
                            {!props.seller && <Skeleton className="w-30px" />} {props.seller && <span className="badge badge-light-success fs-12 fw-bold text-dark">{props.seller.listing_by_status.Live_Promoted}</span>}
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                    <div className="border border-dashed border-gray-300  grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer" onClick={() => {props.changeStatus('regular')}}>
                        <div className="d-flex align-items-center  hoverPointer" >
                            <span className="bullet bullet-vertical h-30px bg-success"></span>
                            <div className="form-check form-check-custom form-check-solid mx-5"></div>
                            <div className="flex-grow-1">
                                <a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6">{Intl.formatMessage({id : "status.regular"})}</a>
                            </div>
                            {!props.seller && <Skeleton className="w-30px" />} {props.seller && <span className="badge badge-light-success fs-12 fw-bold text-dark">{props.seller.listing_by_status.Live_Regular}</span>}
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                    <div className="border border-dashed border-gray-300  grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer" onClick={() => {props.changeStatus('pending_payment')}}>
                        <div className="d-flex align-items-center  hoverPointer" >
                            <span className="bullet bullet-vertical h-30px bg-warning"></span>
                            <div className="form-check form-check-custom form-check-solid mx-5"></div>
                                <div className="flex-grow-1"><a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6">{Intl.formatMessage({id : "status.pendingPayment"})}</a>
                            </div>
                            {!props.seller && <Skeleton className="w-30px" />} {props.seller && <span className="badge badge-light-warning fs-12 fw-bold text-dark">{props.seller.listing_by_status.Pending_Payment}</span>}
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                    <div className="border border-dashed border-gray-300  grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer" onClick={() => {props.changeStatus('pending_review')}}>
                        <div className="d-flex align-items-center  hoverPointer" >
                            <span className="bullet bullet-vertical h-30px bg-warning"></span>
                            <div className="form-check form-check-custom form-check-solid mx-5"></div>
                                <div className="flex-grow-1"><a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6">{Intl.formatMessage({id : "status.pendingReview"})}</a>
                            </div>
                            {!props.seller && <Skeleton className="w-30px" />} {props.seller && <span className="badge badge-light-warning fs-12 fw-bold text-dark">{props.seller.listing_by_status.Pending_Review}</span>}
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                    <div className="border border-dashed border-gray-300  grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer" onClick={() => {props.changeStatus('rejected')}}>
                        <div className="d-flex align-items-center  hoverPointer">
                            <span className="bullet bullet-vertical h-30px bg-danger"></span>
                            <div className="form-check form-check-custom form-check-solid mx-5"></div>
                            <div className="flex-grow-1">
                                <a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6">{Intl.formatMessage({id : "status.rejected"})}</a>
                            </div>
                            {!props.seller && <Skeleton className="w-30px" />} {props.seller && <span className="badge badge-light-danger fs-12 fw-bold text-dark">{props.seller.listing_by_status.Rejected}</span>}
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                    <div className="border border-dashed border-gray-300  grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer"  onClick={() => {props.changeStatus('deleted_Expired_Sold')}}>
                        <div className="d-flex align-items-center  hoverPointer">
                            <span className="bullet bullet-vertical h-30px bg-danger"></span>
                            <div className="form-check form-check-custom form-check-solid mx-5"></div>
                            <div className="flex-grow-1">
                                <a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6">{Intl.formatMessage({id : "status.deletedOrExpired"})}</a>
                            </div>
                            {!props.seller && <Skeleton className="w-30px" />} {props.seller && <span className="badge badge-light-danger fs-12 fw-bold text-dark">{props.seller.listing_by_status.Deleted_Expired_Sold}</span>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default MyListingsOverview;