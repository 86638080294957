import {showError, showSuccess} from '../../../helper/SystemAlert'
import {ShopInterface} from '../../../interfaces/ShopInterface'
import {useLang} from '../../../../app/i18n/OpenSooqi18n'
import editShopApi from '../../../../api/listing/editShop.api'
import DynamicObject from '../../../../models/dynamic-object'
import getShopApi from '../../../../api/listing/getShop.api'
import {openHours} from '../../../helper/CallingHoursList'
import {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {CallingHours} from '../../../components/CallingHours'
import {CategoryInterface} from '../../../interfaces/CategoryInterface'
import {CityInterface} from '../../../interfaces/CityInterface'
import {Button} from '../../../../app/layout/components/opensooq/Button'
import getCategoriesApi from '../../../../api/category/getCategories.api'
import getCitiesApi from '../../../../api/listing/getCities.api'
import {UploadShopMedia} from '../../../../app/layout/components/modals/UploadShopMedia'
import {UploadCoverMedia} from '../../../../app/layout/components/modals/UploadCoverMedia'
import uploadShopMediaApi from '../../../../api/shop/uploadShopMedia.api'
import {DocsInterface} from '../../../interfaces/DocsInterface'
import { BundleModal } from '../../payment/BundleModal'
import { ProfileInterface } from '../../../interfaces/ProfileInterface'
import uploadShopCoverMediaApi from '../../../../api/shop/uploadShopCoverMedia.api'
import FsLightbox from "fslightbox-react";
import { getCookies } from '../../../helpers/commonFunctions'
import createShopApi from '../../../../api/createShop.api'

export interface TouchedInterface {
  shopName: boolean
  shopAddress: boolean
  shopUrl: boolean
  shopDescription: boolean
}

export interface IShopProps {
  isShop: boolean | undefined
  lang: string
  profile ?: ProfileInterface
}


const Shop = (props: IShopProps) => {
  const [shopOpenHours, setShopOpenHours] = useState<DynamicObject[]>(openHours)
  const [shopCallAnyTime, setShopCallAnyTime] = useState<boolean>(false)
  const [categories, setCategories] = useState<CategoryInterface[]>([])
  const [toggler, setToggler] = useState(false);
  const memberId = getCookies().userInfo.id ?? 0
  const [showUploadCover, setShowUploadCover] = useState(false)
  const [showUploadImage, setShowUploadImage] = useState(false)
  const [showUploadCoverImage, setShowUploadCoverImage] = useState(false)
  const [showUploadLogoImage, setShowUploadLogoImage] = useState(false)
  const [shopDescription, setShopDescription] = useState('')
  const [cities, setCities] = useState<CityInterface[]>([])
  const [selectedCity, setSelectedCity] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [shop, setShop] = useState<ShopInterface>()
  const [shopAddress, setShopAddress] = useState('')
  const [shopName, setShopName] = useState('')
  const [shopUrl, setShopUrl] = useState('')
  const [shopLogoImage, setShopLogoImage] = useState<DocsInterface>()
  const [shopCoverImage, setShopCoverImage] = useState<DocsInterface>()
  const cdn = process.env.REACT_APP_ARBOK_UPLOAD
  const [showBundle,setShowBundle] = useState (false);
  const [touched, setTouched] = useState<TouchedInterface>({
    shopAddress: false,
    shopDescription: false,
    shopName: false,
    shopUrl: false,
  })
  const [showImages, setShowImages] = useState(false)
  const [imagesLinks, setImagesLinks] = useState <string[]>([])
  const [isShopCreated, setIsShopCreated] = useState<boolean>(false);
  const [showCoverLoader, setShowCoverLoader] = useState<boolean>(false);
  const [showLogoLoader, setShowLogoLoader] = useState<boolean>(false);
  const Intl = useIntl()
  const lang = useLang()
  const urlRegex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')
  const [logoFile, setLogoFile] = useState<File | null>(null)
  const [coverFile, setCoverFile] = useState<File | null>(null);

  useEffect(() => {
    if(props.isShop){
      getShopApi(memberId).then((response) => {
        const results = response.data
        setIsShopCreated(true);
        setShopName(results.name)
        setShopDescription(results.description == null ? '' : results.description)
        setShopCallAnyTime(results.call_anytime)
        setShopAddress(results.address == null ? '' : results.address)
        setShopUrl(results.url)
        setShop(results)
        setShopLogoImage({
          uri: results?.avatar ? results.avatar : '',
          member_id: results.member_id,
          id:results.id,
          media_type: 0,
          mime_type: ''
        });
        setShopCoverImage({
          uri: results?.cover_photo,
          member_id: results.member_id,
          id:results.id,
          media_type: 0,
          mime_type: ''
        })
        if (results.openHours.length && results.call_anytime == 0) {
          const data: any = []
          results.openHours.map((day: any) => {
            var dayOpenAndClose = openHours
              .map((o: any) => {
                return o.dow
              })
              .indexOf(day.dow.toString())
            openHours[dayOpenAndClose].open_time = day.open_time
            openHours[dayOpenAndClose].close_time = day.close_time
            data.push(openHours[dayOpenAndClose])
          })
          setShopOpenHours(data)
        }
    })
      fetchCategories()
      getCities()
    }
  }, [props.isShop])

  const changeCallAnyTime = (e: any) => {
    if (e.target.classList.contains('checked')) {
      e.target.classList.remove('checked')
      setShopCallAnyTime(false)
    } else {
      e.target.classList.add('checked')
      setShopCallAnyTime(true)
    }
  }

  const changeShopName = (e: any) => {
    if (touched.shopName == false) {
      setTouched((old: any) => {
        return {...old, shopName: true}
      })
    }
    setShopName(e.target.value)
  }

  const changeShopDescription = (e: any) => {
    if (touched.shopDescription == false) {
      setTouched((old: any) => {
        return {...old, shopDescription: true}
      })
    }
    setShopDescription(e.target.value)
  }

  const changeShopAddress = (e: any) => {
    if (touched.shopAddress == false) {
      setTouched((old: any) => {
        return {...old, shopAddress: true}
      })
    }
    setShopAddress(e.target.value)
  }

  const changeShopUrl = (e: any) => {
    if (touched.shopUrl == false) {
      setTouched((old: any) => {
        return {...old, shopUrl: true}
      })
    }
    setShopUrl(e.target.value)
  }

  function getCities() {
    getCitiesApi().then((response) => {
      const results = response.data.items
      setCities(()=>[...results])

    })
  }

  function fetchCategories() {
    getCategoriesApi().then((response) => {
      const results = response.data.items
      setCategories(()=>[...results])
    })
  }

  const createShop = () =>{
    const shop:ShopInterface = {
      name:shopName,
      address:shopAddress,
      url: urlRegex.test(shopUrl) ? shopUrl : '',
      call_anytime: shopCallAnyTime ? 1 : 0,
      city_id: +(selectedCity === '' ? cities[0].id : selectedCity),
      category_id: +(selectedCategory === '' ?  categories[0].id : selectedCategory),
      description: shopDescription
    }
    const data = {
      Shop: shop,
      ShopHours: openHours,
    }

    createShopApi(data).then((response) => {
      showSuccess(Intl, lang);
        const results = response.data
        setIsShopCreated(true);
        setShopName(results.name)
        setShopDescription(results.description)
        setShopCallAnyTime(results.call_anytime)
        setShopAddress(results.address)
        setShopUrl(results.url)
        setShop(results)
      if(coverFile != null){
        uploadShopCover(coverFile);
      }
      if(logoFile != null )
      {
        uploadShopMedia(logoFile); 
      }
      }).catch((error) => {
      if(error.response.data){
        showError(Intl, lang, error.response.data);
      }
      else{
        showError(Intl, lang)
      }
      })
  }

  const editShop = () => {
    const newShop:ShopInterface = {
      id: shop?.id,
      name:shopName,
      address:shopAddress,
      url: urlRegex.test(shopUrl) ? shopUrl : '',
      call_anytime:shopCallAnyTime ? 1 : 0,
      description: shopDescription,
    }
    const data = {
      Shop: newShop,
      ShopHours: openHours,
    }
    editShopApi(newShop?.id ?? 0, data)
      .then((response) => {
        showSuccess(Intl, lang)
        setIsShopCreated(true);
      })
      .catch((error) => {
        if(error.response.data){
          showError(Intl, lang, error.response.data)
        }
        else{
          showError(Intl, lang)
        }
      })
  }

  const changeOpenHours = (day: string, element: any) => {
    var index = shopOpenHours
      .map(function (o: any) {
        return o.dow
      })
      .indexOf(day)
    shopOpenHours[index].open_time = element.target.value
    setShopOpenHours(()=>[...shopOpenHours])
  }

  const changeCloseHours = (day: string, element: any) => {
    var index = shopOpenHours
      .map(function (o: any) {
        return o.dow
      })
      .indexOf(day)
    shopOpenHours[index].close_time = element.target.value
    setShopOpenHours(()=>[...shopOpenHours])
  }


  const uploadShopCover = useCallback( (file: any) => {
    setShowCoverLoader(true);
    if(!isShopCreated)
    {   
      setShopCoverImage({
          uri:  URL.createObjectURL(file[0].file),
          member_id: memberId,
          id:-1,
          media_type: 0,
          mime_type: ''
      })
      setCoverFile(file);
      setShowCoverLoader(false)
      setShowUploadCoverImage(false)
    }else {
      const formData = new FormData()
      formData.append('Shop[imageFile]', file[0].file)
      formData.append(
        'Shop[is_image_review_enabled]',
        JSON.stringify({is_image_review_enabled: true})
      )
      uploadShopCoverMediaApi(shop?.id ?? 0, 'photo-cover', formData)
        .then((response) => {
          showSuccess(Intl, lang)
          setShowCoverLoader(false);
          setShowUploadCoverImage(false)
          getShopApi(memberId)
          .then((response) => {
            const results = response.data
            setShopCoverImage({
              uri: results?.cover_photo ? results.cover_photo : '',
              member_id: results.member_id,
              id:results.id,
              media_type: 0,
              mime_type: ''
            });
            }).catch((error) => {
              setShowCoverLoader(false);
              setShowUploadCoverImage(false)
              if(error.response.data){
                showError(Intl, lang, error.response.data);
              }
              else{
                showError(Intl, lang)
              }
            })
          })
        .catch((error) => {
          setShowCoverLoader(false);
          setShowUploadCoverImage(false)
          if(error.response.data){
            showError(Intl, lang, error.response.data);
          }
          else{
            showError(Intl, lang)
          }      
        })
    }
  },[isShopCreated])

  const uploadShopMedia = useCallback((file: any) => {
    setShowLogoLoader(true)
    if(!isShopCreated)
    {  
      setShopLogoImage({
        uri:  URL.createObjectURL(file[0].file),
        member_id: memberId,
        id:-1,
        media_type: 0,
        mime_type: ''
      });
      setLogoFile(file)
      setShowLogoLoader(false)
      setShowUploadLogoImage(false)
    }else{
      const formData = new FormData()
      formData.append('Shop[logoFile]', file[0].file)
      formData.append(
        'Shop[is_image_review_enabled]',
        JSON.stringify({is_image_review_enabled: true})
      )
      uploadShopMediaApi(formData)
        .then((response) => {
          showSuccess(Intl, lang)
          setShowLogoLoader(false)
          setShowUploadLogoImage(false)
          getShopApi(memberId).then((response) => {
            const results = response.data
            setShopLogoImage({
              uri: results?.avatar ? results.avatar : '',
              member_id: results.member_id,
              id:results.id,
              media_type: 0,
              mime_type: ''
            });
            }).catch((error) => {
              setShowLogoLoader(false)
              setShowUploadLogoImage(false)
              showError(Intl, lang, error.response.data);
            })
          })
        .catch((error) => {
          setShowLogoLoader(false)
          setShowUploadLogoImage(false)
          showError(Intl, lang, error.response.data);
        })
    }
  }, [isShopCreated])

  const viewFile = (src: string) => {
    setImagesLinks([src])
    setShowImages(!showImages)
  }

  const selectCategoryHandler = (e :  React.ChangeEvent<HTMLSelectElement>)=>{
    setSelectedCategory(e.target.value);
  }

  const selectCityHandler = (e :  React.ChangeEvent<HTMLSelectElement>)=>{
    setSelectedCity(e.target.value);
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold text-gray-800'>
              {shopName} {Intl.formatMessage({id: 'myProfile.shop.title'})}
            </span>
          </h3>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-filemanager-table-toolbar='base'>
              {props.isShop === undefined || props.isShop === false ? (
                <button className='btn btn btn-primary' onClick={() => {setShowBundle(true)}}>
                  <span className='svg-icon svg-icon-2'>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' > {' '} <path opacity='0.3' d='M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z' fill='currentColor' ></path>{' '} <path d='M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM16 11.6L12.7 8.29999C12.3 7.89999 11.7 7.89999 11.3 8.29999L8 11.6H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H16Z' fill='currentColor' ></path> <path opacity='0.3' d='M11 11.6V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H11Z' fill='currentColor' ></path> </svg>
                  </span>
                  {Intl.formatMessage({id: 'myProfile.shop.text1'})}
                </button>
              ) : (
                <div></div>
              )}
            </div>
            <div
              className='d-flex justify-content-end align-items-center d-none'
              data-kt-filemanager-table-toolbar='selected'
            >
              <div className='fw-bold me-5'>
                <span className='me-2' data-kt-filemanager-table-select='selected_count'></span>
                Selected
              </div>
              <button
                type='button'
                className='btn btn-danger'
                data-kt-filemanager-table-select='delete_selected'
              >
                Delete Selected
              </button>
            </div>
          </div>
        </div>
        {props.isShop && (
          <div className='card-body border-top p-9'>
            <div className='d-flex flex-column gap-7 gap-lg-10 w-100  mb-7 me-lg-10'>
              <div className='card card-flush py-4'>
                <div className='card-body pt-0'>
                  <div className='mb-10 fv-row fv-plugins-icon-container'>
                    <label className='required form-label'>
                      {Intl.formatMessage({id: 'myProfile.shop.text0'})}
                    </label>
                    <input
                      type='text'
                      className='form-control mb-2'
                      placeholder={Intl.formatMessage({id: 'myProfile.shop.text0'})}
                      value={shopName}
                      onChange={(e) => {
                        changeShopName(e)
                      }}
                    />
                    <div className='text-muted fs-7'>
                      {Intl.formatMessage({id: 'myProfile.shop.text2'})}
                    </div>
                    <div className='fv-plugins-message-container invalid-feedback'>
                      {shopName === '' && touched?.shopName
                        ? Intl.formatMessage({id: 'myProfile.shop.text3'})
                        : ''}
                    </div>
                  </div>
                  <div>
                    <label className='form-label required'>
                      {Intl.formatMessage({id: 'myProfile.shop.text4'})}
                    </label>
                    <textarea
                      id='kt_ecommerce_add_product_description'
                      className='min-h-100px mb-2 form-control '
                      value={shopDescription}
                      onChange={(e) => {
                        changeShopDescription(e)
                      }}
                    >
                      {shop?.description ?? ''}
                    </textarea>
                    <div className='text-muted fs-7'>
                      {Intl.formatMessage({id: 'myProfile.shop.text5'})}
                    </div>
                    <div className='fv-plugins-message-container invalid-feedback'>
                      {shopDescription && shopDescription.length < 10 && touched?.shopDescription
                        ? Intl.formatMessage({id: 'myProfile.shop.text24'})
                        : ''}
                    </div>
                  </div>
                  <div className='pt-10 d-flex flex-wrap gap-5'>
                    <div className='fv-row w-100 flex-md-root fv-plugins-icon-container'>
                      <label className='required form-label'>
                        {Intl.formatMessage({id: 'myProfile.shop.text6'})}
                      </label>
                      <select
                        defaultValue={shop?.category_id}
                        className='form-select mb-2 select2-hidden-accessible'
                        onChange={selectCategoryHandler}
                        disabled={isShopCreated}
                      >
                        {categories &&
                          categories.map((c, index) => {
                            return (
                              <option key={index} value={c.id}>
                                {props.lang === 'en' ? c.label_en : c.label_ar}
                              </option>
                            )
                          })}
                      </select>
                      <div className='text-muted fs-7'>
                        {Intl.formatMessage({id: 'myProfile.shop.text7'})}
                      </div>
                      <div className='fv-plugins-message-container invalid-feedback'>
                      </div>
                    </div>
                    <div className='fv-row w-100 flex-md-root'>
                      <label className='form-label required'>
                        {Intl.formatMessage({id: 'myProfile.shop.text8'})}
                      </label>
                      <select
                        defaultValue={shop?.city_id}
                        className='form-select mb-2 select2-hidden-accessible'
                        onChange={selectCityHandler}
                        disabled={isShopCreated}
                      >
                        {cities &&
                          cities.map((c, index) => {
                            return (
                              <option key={index} value={c.id}>
                                {props.lang === 'en' ? c.name_english : c.name_arabic}
                              </option>
                            )
                          })}
                      </select>
                      <div className='text-muted fs-7'>
                        {Intl.formatMessage({id: 'myProfile.shop.text9'})}
                      </div>
                    </div>
                  </div>
                  <div className='pt-10 fv-row fv-plugins-icon-container'>
                    <label className='required form-label'>
                      {Intl.formatMessage({id: 'myProfile.shop.text10'})}
                    </label>
                    <input
                      type='text'
                      name='price'
                      className='form-control mb-2'
                      placeholder={Intl.formatMessage({id: 'myProfile.shop.text10'})}
                      value={shopAddress}
                      onChange={(e) => {
                        changeShopAddress(e)
                      }}
                    />
                    <div className='text-muted fs-7'>
                      {Intl.formatMessage({id: 'myProfile.shop.text11'})}
                    </div>
                    <div className='fv-plugins-message-container invalid-feedback'>
                      {shopAddress.length < 1 && touched?.shopAddress
                        ? Intl.formatMessage({id: 'myProfile.shop.text26'})
                        : ''}
                    </div>
                  </div>
                  <div className='pt-10  fv-row fv-plugins-icon-container'>
                    <label className='form-label'>
                      {Intl.formatMessage({id: 'myProfile.shop.text12'})}
                    </label>
                    <input
                      type='text'
                      name='price'
                      className='form-control mb-2'
                      placeholder={Intl.formatMessage({id: 'myProfile.shop.text12'})}
                      value={shopUrl}
                      onChange={(e) => {
                        changeShopUrl(e)
                      }}
                    />
                    <div className='text-muted fs-7'>
                      {Intl.formatMessage({id: 'myProfile.shop.text13'})}
                    </div>
                    <div className='fv-plugins-message-container invalid-feedback'>
                      {!urlRegex.test(shopUrl) && touched?.shopUrl
                        ? Intl.formatMessage({id: 'myProfile.shop.text25'})
                        : ''}
                    </div>
                  </div>
                  <div className='pt-10  fv-row fv-plugins-icon-container'>
                    <label className='form-label required'>
                      {Intl.formatMessage({id: 'myProfile.shop.text15'})}
                    </label>
                    <div className='fv-row mb-2'>
                      <div
                        className='dropzone dz-clickable'
                        id='kt_ecommerce_add_product_media'
                        onClick={() => {
                          setShowUploadLogoImage(true)
                        }}
                      >
                        <div className='dz-message needsclick'>
                          <i className='fas fa-upload fs-1'></i>
                          <div className='ms-4'>
                            <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                              {Intl.formatMessage({id: 'myProfile.shop.text14'})}
                            </h3>
                            <span className='fs-7 fw-semibold text-gray-400'>
                              {Intl.formatMessage({id: 'myProfile.shop.text23'})}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='text-muted fs-7'>
                      {Intl.formatMessage({id: 'myProfile.shop.text16'})}
                    </div>
                    <div className='fv-plugins-message-container invalid-feedback'></div>
                    <div>
                    <div className='card'>
            {shopLogoImage && <div className='card-body d-flex flex-center flex-column p-9'>
              <div className='mb-5'>
                <img
                  src={shopLogoImage.uri}
                  className='hoverPointer w-100'
                  height={'150'}
                  width={'300'}
                  onClick={() => viewFile(shopLogoImage.uri)}
                />
              </div>
              <div className='d-flex align-items-center'>
                <Button
                  action={() => viewFile(shopLogoImage.uri)}
                  className='btn-primary btn-sm'
                  text={Intl.formatMessage({id: 'view'})}
                />
              </div>
            </div>}
          </div>
                      </div>
                  </div>

                  <div className='pt-10  fv-row fv-plugins-icon-container'>
                    <label className='form-label required'>
                      {Intl.formatMessage({id: 'myProfile.shop.text17'})}
                    </label>
                    <div className='fv-row mb-2'>
                      <div
                        className='dropzone dz-clickable'
                        id='kt_ecommerce_add_product_media'
                        onClick={() => {
                          setShowUploadCoverImage(true)
                        }}
                      >
                        <div className='dz-message needsclick'>
                          <i className='fas fa-upload fs-1'></i>
                          <div className='ms-4'>
                            <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                              {Intl.formatMessage({id: 'myProfile.shop.text14'})}
                            </h3>
                            <span className='fs-7 fw-semibold text-gray-400'>
                              {Intl.formatMessage({id: 'myProfile.shop.text22'})}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='text-muted fs-7'>
                      {Intl.formatMessage({id: 'myProfile.shop.text18'})}
                    </div>
                    <div className='fv-plugins-message-container invalid-feedback'></div>
                    {shopCoverImage && <div className='card-body d-flex flex-center flex-column p-9'>
              <div className='mb-5'>
                <img
                  src={shopCoverImage.uri}
                  className='hoverPointer w-100'
                  height={'250'}
                  width={'500'}
                  onClick={() => viewFile(shopCoverImage.uri)}
                />
              </div>
              <div className='d-flex align-items-center'>
                <Button
                  action={() => viewFile(shopCoverImage.uri)}
                  className='btn-primary btn-sm'
                  text={Intl.formatMessage({id: 'view'})}
                />
              </div>
            </div>}

                  </div>

                  <div className='pt-10  fv-row fv-plugins-icon-container'>
                    <label className='form-label'>
                      {Intl.formatMessage({id: 'myProfile.shop.text19'})}
                    </label>
                    <div className='fv-row mb-2'></div>
                    <div className='fv-row w-100 flex-md-root'>
                      <div className='fv-row w-100 flex-md-root'>
                        <div className='fv-row w-100 flex-md-root'>
                          <div className='text-muted fs-7'>
                            {Intl.formatMessage({id: 'myProfile.shop.text20'})}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row mb-6 mt-10 justify-content-between'>
                      <div className='col-2'>
                        <span className=' form-label fw-semibold '>
                          {Intl.formatMessage({id: 'myProfile.shop.text21'})}
                        </span>
                      </div>
                      <div className='col-1 text-end'>
                        <div
                          className='form-check form-switch form-check-custom form-check-solid '
                          style={{display: 'unset'}}
                        >
                          <input
                            className={`form-check-input ${shopCallAnyTime ? 'checked' : ''}`}
                            type='checkbox'
                            id='flexSwitchCheckChecked'
                            checked={shopCallAnyTime}
                            onChange={(e) => {
                              changeCallAnyTime(e)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <CallingHours
                      lang={lang}
                      openHours={shopOpenHours}
                      changeOpenHours={changeOpenHours}
                      changeClosingHours={changeCloseHours}
                      disabled={shopCallAnyTime ? true : false}
                    />
                    <div className='fv-plugins-message-container invalid-feedback'></div>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
              <div className='tab-content'>
                <div
                  className='tab-pane fade show active'
                  id='kt_ecommerce_add_product_general'
                  role='tab-panel'
                ></div>
              </div>
            </div>
            <div className='row'>
              <div className='d-flex justify-content-end'>
                <Button
                  disabled = { shopName === '' && ( touched?.shopName || !isShopCreated ) }
                  text={Intl.formatMessage({id: 'myProfile.shop.text34'})}
                  action={!isShopCreated ? createShop : editShop}
                  className='btn-primary'
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <BundleModal  show={showBundle}  type={['MemberShip']} handleClose={() => {setShowBundle(false)}} id={props?.profile?.id ?? 0} pos={'profileShop'}/>
      <UploadShopMedia loader={showLogoLoader} uploadFile={(files: any) => { uploadShopMedia(files) }} show={showUploadLogoImage} handleClose={() => { setShowUploadLogoImage(false) }} />
      <UploadCoverMedia loader={showCoverLoader} uploadFile={(files: any) => { uploadShopCover(files) }} show={showUploadCoverImage} handleClose={() => { setShowUploadCoverImage(false) }} />
      <FsLightbox toggler={showImages}
				sources={imagesLinks} type="image"
			/>
    </>
  )
}

export {Shop}
