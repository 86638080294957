import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl'
import { FacebookShareButton, TwitterShareButton} from "react-share";
import { EMAIL_SHARE_URL, TWITTER_SHARE_URL } from '../helper/ShareLinks';
import { generateOpenSooqRedirectUrl, getCookies } from '../helpers/commonFunctions';
import shortUrlApi from '../../api/site/shortUrl.api';
import DynamicObject from '../../models/dynamic-object';
export function InviteFriends() {
    
    const [shortUrl,setShortUrl] = useState<string>('')
    const [twitterShareTemplate,setTwitterShareTemplate] = useState<string>('')
    const [emailTemplate,setEmailTemplate] = useState<string>('')
    const memberId = getCookies().userInfo.id;
    const Intl = useIntl();
    useEffect(() => {
        const url = generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',getCookies().ecountry ?? '',getCookies().NEXT_LOCALE) + '/mid/' + memberId + `?utm_source=opensooq&utm_medium=share&utm_campaign=account_invitefriend_download_app_from_myopensooq`
        const data = {
            links : {
                url
            }
        }
        shorten(data);
    },[memberId]);

    const shorten = (data : DynamicObject) => {
        shortUrlApi(data).then((response) => {
            setShortUrl(response.data.links.url);
        })
    }

    useEffect(() => {
        const emailShareUrl     = EMAIL_SHARE_URL;
        const twitterShareUrl   = TWITTER_SHARE_URL;
        setTwitterShareTemplate(`${Intl.formatMessage({id : "checkMyListings"})} \n ${shortUrl} \n\n ${Intl.formatMessage({id : 'downloadApp'})} ${twitterShareUrl}`)
        setEmailTemplate(`${Intl.formatMessage({id : "checkMyListings"})} \n ${shortUrl} \n\n ${Intl.formatMessage({id : 'downloadApp'})} ${emailShareUrl}`)
    },[shortUrl]);

    return (
        <div className="card mb-5 ">
            <div className="card-body py-10 ">
                <h2 className="mb-7 text-center">{Intl.formatMessage({id: 'inviteFriend'})}</h2>
                <div className="row mb-5 justify-content-center">
                    <div className="col-xxl-6 col-xl-8 col-lg-8 col-md-8 mt-2">
                        <p className="fs-6 fw-semibold text-gray-600 pb-2 m-0">{Intl.formatMessage({id: 'inviteFriendText1'})} </p>
                        <div className="d-flex">
                            <input type="text"  className="form-control form-control-solid me-3 flex-grow-1" name="search" defaultValue={shortUrl}/>
                            <button onClick={() => {navigator.clipboard.writeText(shortUrl)}} className="btn btn-light btn-active-light-primary fw-bold flex-shrink-0">{Intl.formatMessage({id: 'inviteFriendText2'})}</button>
                        </div>
                    </div>
                </div>
                <div className="row mt-5 justify-content-center">
                    <div className="col-xxl-6 col-xl-8 col-lg-8 col-md-8 mt-2">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mt-2">
                                <a target="_blank" href={`https://mail.google.com/mail/?view=cm&fs=1&su=${Intl.formatMessage({id : 'shareEmailSubject'})}&body=${emailTemplate}}`} className="btn w-100 btn-light btn-active-light-primary fw-bold flex-shrink-0">
                                    <i className="fs-1 me-1 fa-solid fa-envelope"></i>
                                    {Intl.formatMessage({id: 'inviteFriendText5'})}
                                </a>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mt-2">
                                <FacebookShareButton  url={shortUrl} className="w-100">
                                    <span id="" className="btn w-100 btn-light btn-active-light-primary fw-bold flex-shrink-0">
                                        <i className="fab fa-facebook-square fs-1 me-1" style={{color : "#385898"}}></i>
                                        {Intl.formatMessage({id: 'inviteFriendText4'})}
                                    </span>
                                </FacebookShareButton>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mt-2">
                                <TwitterShareButton url={twitterShareTemplate} className="w-100">
                                    <span className="btn w-100 btn-light btn-active-light-primary fw-bold flex-shrink-0">
                                        <i className="fab fa-twitter-square fs-1 me-1" style={{color : "rgb(29, 155, 240)"}}></i>
                                        {Intl.formatMessage({id: 'inviteFriendText3'})}
                                    </span>
                                </TwitterShareButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}