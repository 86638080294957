/* eslint-disable react/jsx-no-target-blank */
import { useLang } from '../../../app/i18n/OpenSooqi18n';
import { IframeLoader } from '../common/IframeLoader';
import {useRef, useState} from 'react'
import { getCookies, logout } from '../../helpers/commonFunctions';
import { useIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';
interface ListingProps{
    id ?: number | 0
    type ?: string | ''
}

const ListingActionsIntegration = (props : ListingProps) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const listingId = (props.id && props.id != 0 ) ? props.id : parseInt(params.get('id') ?? '195436806');
    const listingType = (props.type != undefined && props.type != '') ? props.type : (params.get('flow')) ? params.get('flow') : 'add';

    const iframeContent = useRef<null | HTMLIFrameElement>(null);
    const [loader,setLoader] = useState <boolean>(true)
    const lang = useLang();
    const Intl= useIntl()
    window.addEventListener("message", event => {
        if(event.data === 401){
            logout()
        }else if(event.data == 200){
            setLoader(false);
            if(iframeContent.current){
                iframeContent.current.classList.remove('d-none')
            }
        }
    }, false);

    return (
        <>{loader && <IframeLoader/>}
        <iframe src={`${process.env.REACT_APP_ADD_POST_REDIRECT_URL}?v=2&source=mobile&lang=${lang}&flow=${listingType}&post_id=${listingId}&from=my-opensooq&return=${window.location.href}`} ref={iframeContent} className="d-none w-100 " style={{height : "100%",minHeight: isMobile ? '100%' : '90vh' ,overflowY:"hidden"}} ></iframe></>
    )
}

export {ListingActionsIntegration}
