import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import getMyAdsApi from "../../../../api/listing/getMyAds.api";
import { TableLoader } from "../../../../app/layout/components/opensooq/TableLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import Skeleton from "react-loading-skeleton";
import MyListingsOverview from "./../my-listings/MyListingsOverview";
import MyListingsAccountOverView from "./../my-listings/MyListingsAccountOverView";
import { SellerInterface } from "../../../interfaces/SellerInterface";
import sellerApi from "../../../../api/opensooq/seller.api";
import { MyAccountFeatures } from "../../../interfaces/MyAccountFeatures";
import accountFeaturesApi from "../../../../api/opensooq/accountFeatures.api";
import DynamicObject from "../../../../models/dynamic-object";
import { ListingList } from "./ListingList";
import Select, {GroupBase, MultiValue, MultiValueProps, SingleValue} from 'react-select'
import categoriesApi from "../../../../api/listing/categories.api";
import { useLang } from "../../../i18n/OpenSooqi18n";
import { isDeviceMobile } from "../../../helpers/commonFunctions";
import { ClassNames } from "@emotion/react";



import { components } from "react-select";

export function MyListings() {
    const statusParam : string | '0' = new URLSearchParams(window.location.search).get('status')?.toString() ?? ''
    const [accountFeature,setAccountFeature] = useState <MyAccountFeatures | undefined>(undefined); 
    const [searchData,setSearchData] = useState <DynamicObject[]>([]); 
    const [ads,setAds] = useState <DynamicObject[]>([]); 
    const [seller,setSeller] = useState <SellerInterface>(); 
    const [isCheck, setIsCheck] = useState <Number[]>([]);
    const [searchString, setSearchString] = useState("")
    const [loader,setLoading] = useState <boolean>(true)
    const [isLastPage,setIsLastPage] = useState(false)
    const [meta,setMeta] = useState <DynamicObject>(); 
    const [isShow, invokeModal] = useState(false)
    const [nextPage,setNextPage] = useState('')
    const Intl = useIntl();
    const [options,setOptions] = useState <{label:string, value:string}[]>([])
    const [categoriesFilter,setCategoriesFilter] = useState<categoryFilterI[]>([])
    const [datesFilter,setDatesFilter] = useState<{label:string, value:string}[]>([])
    const [checkedListingCounter,setCheckedListingCounter] = useState <number>(0)
    const [currentFilter,setCurrentFilter] = useState <string>('')
    const [pickedStatusObj,setPickedStatusObj] = useState <{label:string, value:string} []>()
    const [filterQueryParams,setFilterQueryParams] = useState<{[key: string]: string}>()
    const [currentPage,setCurrentPage] = useState<number>(1)
    const [pageCount,setPageCount] = useState<number>(0)
    const [totalCount, setTotalCount] = useState<number>(0);
    const [sellerObject,setSellerObject] = useState<DynamicObject>({})
    const lang = useLang()
    const [height, setHeight] = useState(4)

    interface categoryFilterI {
        label : string
        value : string
        isParent? : boolean
        options? : categoryFilterI[]
    }

    useEffect(() => {
        categoriesApi().then((response) => {
            let categoriesFilterData : any = [{value : 'section',label : Intl.formatMessage({id : "categories"}),isDisabled: true}]
            categoriesFilterData.push({value : '',label : Intl.formatMessage({id : "showAll"})})
            response.data.map((category : any,index : any) => {
                let subCats : DynamicObject = [{value : category.key,label : Intl.formatMessage({id : "allSections"}) + ' ' + category.value}]
                category.sub_categories.map((sub : any,index : any) => {
                    subCats.push({value : sub.key,label : sub.value,isParent : true})
                });
                categoriesFilterData.push({label : category.value,value : category.key,options : subCats,isParent : true})
            })
            setCategoriesFilter(categoriesFilterData);
        })
        if(statusParam !== ''){
            const elements:{label:string, value:string}[] = [];
            statusParam.split(',').forEach( (param:string) => {
                const element = options.find((option : {label:string, value:string}) => option.value === param);
                if(element)
                    elements.push(element)
            })
            setFilterQueryParams(currentFilter => {
                return {
                    ...currentFilter,
                    status : statusParam,
                    category : "",
                    sub_category : "",
                    date : "",
                }
            })
        }
        sellerApi().then((response) => {
            setSeller(response.data);
        })
        accountFeaturesApi().then((response) => {
            setAccountFeature(response.data);
        })
    },[]);
    
    const getMyAds = (filter : string,next ?: string) => {
        const nextUrl = (next == '') ? next : nextPage
        getMyAdsApi(filter,nextUrl).then(response => {
            const results = response.data.items;
            const status = response.data.filters.status;
            const dates = response.data.filters.dates;
            const meta = response.data._meta;
            setTotalCount(meta.totalCount);
            const links = response.data._links;
            setMeta(meta);
            setSearchData(results);
            setAds(results);
            if(links.next?.href == undefined){
                setNextPage('');
                setIsLastPage(true)
            }else{
                setIsLastPage(false)
                const next = (links.next) ? links.next.href : '' 
                setNextPage(next ?? '')
            }
            setSellerObject(response.data.seller);
            let statusFilter : any = []
            status.map((status : any,index : any) => {
                statusFilter.push({value : status.key,label : status.value})
            })

            let datesFilterData : any = []
            datesFilterData.push({value : '',label : Intl.formatMessage({id : "showAll"})})
            dates.map((date : any,index : any) => {
                datesFilterData.push({value : date.key,label : date.value})
            })
            setOptions(statusFilter)
            //First time loading the page
            if(pickedStatusObj === undefined){    
                let pickedStatusRedirected:{label:string, value:string}[] = []
                if(statusParam !== ''){
                    statusParam.split(',').forEach( (param:string) => {
                        const element = statusFilter.find((option : {label:string, value:string}) => option.value === param);
                        if(element)
                        pickedStatusRedirected.push(element)
                    })
                    setPickedStatusObj(pickedStatusRedirected)
                }else{
                    setPickedStatusObj(statusFilter)
                }
            }
            setDatesFilter(datesFilterData)
            setLoading(false)
        })
    } 

    function pagination(){
        const filter = new URLSearchParams(filterQueryParams).toString()
        getMyAdsApi(filter,nextPage).then(response => {
            const results = response.data.items;
            const links = response.data._links;
            
            setSearchData(prevAds=> {
                return [...prevAds, ...results];
                
            })
            setAds(prevAds=> {
                return [...prevAds, ...results];
            })

            
            if(links.next?.href == undefined){
                setNextPage('');
                setIsLastPage(true)
            }else{
                const next = (links.next) ? links.next.href : '' 
                setNextPage(next ?? '')
            }
            setLoading(false)
        }).catch(response => {
            setIsLastPage(true)
            setLoading(false)
        });
        setHeight((old) => old + 8)
        setPageCount(pageCount=>pageCount+1);
    }

    useEffect(() => {
        if(filterQueryParams){
            setLoading(true)
            setIsLastPage(true)
            setNextPage('');
            const queryParams : DynamicObject = {}
            for (const key in filterQueryParams) {
                if(filterQueryParams[key] && filterQueryParams[key] != ''){
                    queryParams[key] =filterQueryParams[key]
                }
            }
            let params = new URLSearchParams(queryParams);
            getMyAds(params.toString(),'');
        }
    },[filterQueryParams])

    
    useEffect(() => {
        setFilterQueryParams(currentFilter => {
            return {
                ...currentFilter,
                term : searchString.toLocaleLowerCase()
            }
        })
    },[searchString]);

    const setSearch = (e: any) => {
        if(e == null)
            return
        if(e.target.value && e.target.value.length > 0){
            setIsLastPage(true);
        }else{
            setIsLastPage(false);
        }
        setSearchString(e.target.value);
        setHeight(4)
        setPageCount(0)
    };
    
    function closePopup(){
        invokeModal(false)
    }

    const filterByDate = (e : any) => {
        if(!e)
            return
        setFilterQueryParams(currentFilter => {
            return {
                ...currentFilter,
                date : e.value
            }
        })
        setHeight(4)
        setPageCount(0)
    }

    const filterByCategory =  (e : SingleValue<categoryFilterI>) => {
        if(e === null || e === undefined)
            return;
        if(e.label.toLowerCase().includes('جميع') || e.label.toLowerCase().includes('all')){
            setFilterQueryParams(currentFilter => {
                return {
                    ...currentFilter,
                    category : e.value,
                    sub_category : ''
                }
            })
        }else{
            setFilterQueryParams(currentFilter => {
                return {
                    ...currentFilter,
                    sub_category : e.value,
                    category : ''
                }
            })
        }
        setHeight(4)
        setPageCount(0)
    }
    
    function filterAdsStatus(e:MultiValue<{label:string, value:string}>){
        if(e)
        {   
            let arr:{label:string, value:string}[] = [];
            let containsAll:boolean = false;
            arr = e.map(item => { 
                if(item.label.toLowerCase() === options[0].label.toLowerCase())
                    containsAll = true;
                return {value : item.value, label: item.label} 
            })
            if(containsAll && pickedStatusObj && pickedStatusObj.length === options.length && e.length < options.length)
            {
                arr = arr.filter(option=>option.label.toLowerCase() !== options[0].label.toLowerCase());
            }else if(containsAll)
            {   
                arr = options;
            }else if(pickedStatusObj && pickedStatusObj.length > 0 && pickedStatusObj[0].label.toLowerCase() === options[0].label.toLowerCase())
            {
                arr = []
            }
            if(arr.length === options.length -1 && !containsAll)
            {
                arr = options;
            }
            setPickedStatusObj(arr);
            let statusString = "";
            arr.forEach((item, index) => statusString += `${item.value}${index === arr.length-1 ? '' : ','}`)
            setFilterQueryParams(currentFilter => {
                return {
                    ...currentFilter,
                    status : statusString 
                }
            })
            setHeight(4)
            setPageCount(0)
        }
    }

    const changeStatus =  (e : string) => {
        setNextPage('');
        setIsLastPage(true)
        let element:{label: string, value:string} | undefined;
        if(options){
            if(e === 'deleted_Expired_Sold'){
                const queryParams = new URLSearchParams();
                queryParams.append("status[]",'deleted');
                queryParams.append("status[]",'expired');
                let deletedObject = options.find((option) => option.value === 'deleted');
                let expiredObject = options.find((option) => option.value === 'expired');
                if(deletedObject !== undefined && expiredObject !== undefined){
                    setPickedStatusObj([deletedObject, expiredObject]);
                    setFilterQueryParams(currentFilter => {
                        return {
                            ...currentFilter,
                            status : `${deletedObject?.value},${expiredObject?.value}`,
                            category : "",
                            sub_category : "",
                            date : "",
                        }
                    })
                }
            }else{
                element = options.find((option) => option.value === e);
                setFilterQueryParams(currentFilter => {
                    return {
                        ...currentFilter,
                        status : e,
                        category : "",
                        sub_category : "",
                        date : "",
                    }
                })
            }
            if(element !== undefined)
            {
                setPickedStatusObj([element]);
            }
        }
        setHeight(4)
        setPageCount(0)
    }

    const changeListingData = (listingId : number ,checked : boolean) => {
        if(checked){
            setCheckedListingCounter(checkedListingCounter + 1);
        }else{
            setCheckedListingCounter(checkedListingCounter - 1);
        }
        setSearchData(preAd =>{
            const foundIndex = preAd.findIndex(listing => listing.listing_id == listingId);
            preAd[foundIndex] = {...preAd[foundIndex], isChecked:  checked};
            return preAd.slice();
        })
    }

    const deleteListingFromSearchData = (id? : number)=>{
        if(!id)
            return;
        let newSerchData = searchData.filter((listing : DynamicObject) => listing.listing_id !== id)
        setTotalCount( (old : number) => old-1);
        setSearchData(newSerchData)
        sellerApi().then((response) => {
            setSeller(response.data);
        })
    }


    const CustomMultiValue = <
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>
  >(
    props: MultiValueProps<Option, IsMulti, Group>
  ) => {
    const {
      children,
      components,
      data,
      getValue,
      index,
      innerProps,
      selectProps
    } = props;

    const { Container, Label } = components;
    const additionalItems = getValue().length - 1;
        return (
      <ClassNames>
        {() => (
          <>
            {index < 1 ? (
              <Container data={data} innerProps={{className: 'badge badge-light', ...innerProps }} selectProps={selectProps}>
                <Label data={data} innerProps={{className: '' }} selectProps={selectProps}>
                  {children}
                </Label>
              </Container>
            ) : index === 1 ? (
              <Container
                data={data} innerProps={{className: 'badge badge-light', ...innerProps}} selectProps={selectProps} >
                <Label data={data} innerProps={{className: '' }} selectProps={selectProps}>
                  {`+ ${additionalItems}`}
                </Label>
              </Container>
            ) : null}
          </>
        )}
      </ClassNames>
    );
  };
    
    return (
        <>
        <div className="row">
            <div className="col-lg-6 col-12 mt-3">
                <MyListingsOverview seller={seller} changeStatus={changeStatus}/>                
            </div>
            <div className="col-lg-6 col-12 mt-3">
                <MyListingsAccountOverView accountFeature={accountFeature}/>
            </div>
        </div>
        <div className="row mt-4">
            <div className="col-12 mt-2">
                <div className="card">
                    <div className="card-header p-3" style={{display : "unset"}}>
                        <div className="row ">
                            <div className="col-lg-2 col-12">
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label fw-bold text-gray-800">{Intl.formatMessage({id : "myListings.text6"})} {!meta && <><Skeleton className="w-30px"/></>} {meta && <>({meta && totalCount})</>}</span>
                                    <span className="text-gray-900 mt-1 fw-semibold fs-6">{Intl.formatMessage({id : "myListings.text7"})}</span>
                                </h3>
                            </div>
                            <div className="col-lg-2 col-12">
                                {datesFilter.length == 0 && <Skeleton className="w-100 h-30px"/>}
                                {datesFilter  && datesFilter.length > 0 && <Select  onChange={(e) => filterByDate(e)} placeholder={Intl.formatMessage({id : "myListings.text9"})} defaultValue={datesFilter.find(dateFilter => dateFilter.label.toLowerCase() === (lang === 'en' ? 'all' : 'كل') )} className="mt-1 fw-bold" options={datesFilter} />}
                            </div>
                           <div className="col-lg-3 col-12">
                                {options.length == 0 && <Skeleton className="w-100 h-30px"/>}
                                {options && options.length > 0 && <Select 
                                components={{  MultiValue: CustomMultiValue, Option:(props)=>{
                                return <div>
                                    <components.Option {...props} className="d-flex align-center bg-white text-dark">
                                      <input type="checkbox" checked={props.isSelected} onChange={() => null}/> <label className="ms-2">{props.label}</label>
                                    </components.Option>
                                  </div>
                                }}} controlShouldRenderValue={true} hideSelectedOptions={false} value={pickedStatusObj} isMulti={true} onChange={(e) => filterAdsStatus(e) } placeholder={Intl.formatMessage({id : "myListings.text8"})} className="mt-1 fw-bold z-index-10" options={options} closeMenuOnSelect={true}/>}
                            </div>
                            <div className="col-lg-3 col-12">
                                {categoriesFilter.length == 0 && <Skeleton className="w-100 h-30px"/>}
                                {categoriesFilter && categoriesFilter.length > 0 && <Select  onChange={(e) => {filterByCategory(e)}} placeholder={Intl.formatMessage({id : "categories"})} className="mt-1 fw-bold"  defaultValue={categoriesFilter.find( (categoryFilter) => categoryFilter.label.toLowerCase() === (lang === 'en' ? 'all' : 'كل') ) } options={categoriesFilter} />}
                            </div>
                            <div className="col-lg-2 col-12">
                                {!options && <Skeleton className="w-100 h-30px"/>}
                                {options && <div className="position-relative my-1">
                                    <span className="svg-icon svg-icon-2 position-absolute top-50 translate-middle-y ms-4">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                            <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                    <input type="text" onKeyUp={(e) => setSearch(e)} className="form-control w-100 fs-7 ps-12"  placeholder={Intl.formatMessage({id : 'searchLabel'})}/>
                                </div>}
                            </div> 
                        </div>
                    </div>
                    <div className="card-body p-mobile-unset ps-4 pe-4">
                        {loader && <><TableLoader /></>}
                        {!loader && <InfiniteScroll
                            style={{width : "100%",overflow : "inherit"}}
                            dataLength={searchData.length} 
                            next={pagination}
                            hasMore={!isLastPage && searchData.length > (searchData.length * height/searchData.length) }
                            scrollThreshold={!isDeviceMobile() ? 0.8 : height/(pageCount === 0 ? searchData.length : searchData.length+2) }
                            loader={
                                <TableLoader />
                            }
                            refreshFunction={undefined}
                            endMessage={ <> 
                                {searchData.length == 0 && <>
                                    <div className="text-center mt-15">
                                        <h1>{Intl.formatMessage({id:"ads.noListings"})}</h1>
                                        <svg className="mt-5" width="177" height="140" viewBox="0 0 177 140" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 37is recursion a good idea in react jsC15.5228 37 20 32.5228 20 27C20 21.4772 15.5228 17 10 17C4.47715 17 0 21.4772 0 27C0 32.5228 4.47715 37 10 37Z" fill="#E7EBF7"/>
                                            <path d="M23.5 139C26.5376 139 29 136.538 29 133.5C29 130.462 26.5376 128 23.5 128C20.4624 128 18 130.462 18 133.5C18 136.538 20.4624 139 23.5 139Z" fill="#E7EBF7"/>
                                            <path d="M170.5 19C173.538 19 176 16.5376 176 13.5C176 10.4624 173.538 8 170.5 8C167.462 8 165 10.4624 165 13.5C165 16.5376 167.462 19 170.5 19Z" fill="#E7EBF7"/>
                                            <path d="M56.7275 25.859H41.5069C39.5737 25.859 38.0063 24.2916 38.0063 22.3584V9.36083C38.0063 4.19122 42.1973 0.000244141 47.3669 0.000244141C52.5365 0.000244141 56.7275 4.19122 56.7275 9.36083V25.859Z" fill="#F2F5FB"/>
                                            <path d="M56.7275 114.141H41.5069C39.5737 114.141 38.0063 115.708 38.0063 117.642V130.639C38.0063 135.809 42.1973 140 47.3669 140C52.5365 140 56.7275 135.809 56.7275 130.639V114.141Z" fill="#F2F5FB"/>
                                            <path d="M56.0894 9.36223V25.8582H50.2942V9.36223C50.2942 6.77469 49.2483 4.43625 47.553 2.74121C46.5216 1.7098 45.2493 0.91875 43.8296 0.458008C44.7423 0.159414 45.716 0 46.7272 0C49.3117 0 51.6532 1.04891 53.3482 2.74121C55.0433 4.43625 56.0894 6.77469 56.0894 9.36223Z" fill="#8E95A5"/>
                                            <path d="M56.7279 114.142V130.638C56.7279 133.225 55.682 135.564 53.9867 137.259C52.2916 138.951 49.9504 140 47.3656 140C46.3545 140 45.3808 139.84 44.468 139.542C45.8877 139.081 47.16 138.29 48.1914 137.259C49.8865 135.564 50.9326 133.225 50.9326 130.638V114.142H56.7279V114.142Z" fill="#8E95A5"/>
                                            <path d="M142.325 0.000244141H46.7285C51.8981 0.000244141 56.0891 4.19122 56.0891 9.36083V130.639C56.0891 135.808 51.8981 139.999 46.7285 139.999H142.325C147.494 139.999 151.684 135.809 151.684 130.64V9.35974C151.684 4.19067 147.494 0.000244141 142.325 0.000244141Z" fill="url(#paint0_linear_1276_3877)"/>
                                            <path d="M151.684 9.35922V130.641C151.684 135.81 147.494 140 142.324 140H136.529C141.698 140 145.888 135.81 145.888 130.641V9.35922C145.889 4.18988 141.698 0 136.529 0H142.325C147.494 0 151.684 4.18988 151.684 9.35922Z" fill="#F2F5FB"/>
                                            <path d="M135.617 33.733H72.1559C70.9897 33.733 70.0444 32.7877 70.0444 31.6215C70.0444 30.4553 70.9897 29.51 72.1559 29.51H135.617C136.783 29.51 137.729 30.4553 137.729 31.6215C137.729 32.7877 136.784 33.733 135.617 33.733Z" fill="#8E95A5"/>
                                            <path d="M135.617 49.0843H72.1559C70.9897 49.0843 70.0444 48.139 70.0444 46.9728C70.0444 45.8066 70.9897 44.8613 72.1559 44.8613H135.617C136.783 44.8613 137.729 45.8066 137.729 46.9728C137.729 48.139 136.784 49.0843 135.617 49.0843Z" fill="#8E95A5"/>
                                            <path d="M121.563 64.4356H72.1559C70.9897 64.4356 70.0444 63.4903 70.0444 62.3241C70.0444 61.1579 70.9897 60.2126 72.1559 60.2126H121.563C122.729 60.2126 123.675 61.1579 123.675 62.3241C123.675 63.4903 122.729 64.4356 121.563 64.4356Z" fill="#8E95A5"/>
                                            <path d="M110.701 79.7874H72.1559C70.9897 79.7874 70.0444 78.8422 70.0444 77.6759C70.0444 76.5097 70.9897 75.5645 72.1559 75.5645H110.701C111.867 75.5645 112.813 76.5097 112.813 77.6759C112.813 78.8422 111.868 79.7874 110.701 79.7874Z" fill="#8E95A5"/>
                                            <path d="M103.887 95.1387H72.1559C70.9897 95.1387 70.0444 94.1935 70.0444 93.0273C70.0444 91.861 70.9897 90.9158 72.1559 90.9158H103.887C105.053 90.9158 105.998 91.861 105.998 93.0273C105.998 94.1935 105.053 95.1387 103.887 95.1387V95.1387Z" fill="#8E95A5"/>
                                            <path d="M101.954 110.49H72.1559C70.9897 110.49 70.0444 109.545 70.0444 108.379C70.0444 107.212 70.9897 106.267 72.1559 106.267H101.954C103.12 106.267 104.066 107.212 104.066 108.379C104.066 109.545 103.121 110.49 101.954 110.49Z" fill="#8E95A5"/>
                                            <path d="M145.746 128.828C162.451 128.828 175.993 115.286 175.993 98.5808C175.993 81.876 162.451 68.334 145.746 68.334C129.041 68.334 115.499 81.876 115.499 98.5808C115.499 115.286 129.041 128.828 145.746 128.828Z" fill="#86E8D0"/>
                                            <path d="M175.993 98.5798C175.993 115.287 162.45 128.828 145.745 128.828C141.274 128.828 137.031 127.857 133.212 126.118C135.115 126.494 137.084 126.691 139.095 126.691C155.799 126.691 169.343 113.151 169.343 96.4464C169.343 84.2128 162.077 73.6746 151.628 68.9077C165.518 71.6435 175.993 83.8869 175.993 98.5798Z" fill="#5DE1C4"/>
                                            <path d="M162.015 88.2215L162.01 88.2155C160.17 86.2134 157.056 86.0816 155.054 87.9213L142.104 99.6378C141.895 99.8298 141.574 99.8298 141.365 99.6378L136.433 95.1056C134.431 93.2659 131.316 93.3977 129.477 95.3998C127.635 97.4041 127.769 100.523 129.777 102.361L136.459 108.481C139.458 111.227 144.059 111.224 147.053 108.472L161.721 95.1772C163.723 93.3381 163.855 90.2236 162.015 88.2215Z" fill="#F9F7F8"/>
                                            <path d="M161.72 95.1764L147.053 108.472C144.37 110.941 140.392 111.198 137.438 109.243C139.134 109.115 140.798 108.434 142.138 107.201L156.806 93.9052C158.81 92.0666 158.941 88.9527 157.101 86.9511L157.096 86.9443C157.058 86.9041 157.022 86.8639 156.982 86.8259C158.729 86.3099 160.695 86.7835 162.011 88.2152L162.015 88.2221C163.856 90.2242 163.724 93.3381 161.72 95.1764Z" fill="#E2E2E2"/>
                                            <defs>
                                            <linearGradient id="paint0_linear_1276_3877" x1="99.2064" y1="0.000244141" x2="99.2064" y2="139.999" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#FCFDFE"/>
                                                <stop offset="1" stopColor="#EBEFF4"/>
                                            </linearGradient>
                                            </defs>
                                        </svg>
                                    </div> 
                                </>
                                }
                            </>
                            }
                            pullDownToRefresh={false}
                            pullDownToRefreshThreshold={100}
                            pullDownToRefreshContent={null}
                            releaseToRefreshContent={null}
                            >
                            <table className="table   table-row-dashed fs-6 gy-3 dataTable no-footer" id="kt_table_widget_4_table">
                                <thead className=" fs-5 fw-semibold ">
                                    <tr className="">
                                        <th className="ps-3 customPostDetailsWidth">{Intl.formatMessage({id : "myListings.text10"})}</th>
                                        <th className="ps-3  hideOnMobile text-start">{Intl.formatMessage({id : "myListings.text11"})}</th>
                                        <th className="ps-3  hideOnMobile text-start">{Intl.formatMessage({id : "myListings.text13"})}</th>
                                        <th className="ps-3  hideOnMobile text-start">{Intl.formatMessage({id : "myListings.text14"})}</th>
                                        <th className="ps-3  hideOnMobile text-start">{Intl.formatMessage({id : "myListings.text9"})}</th>
                                        <th className="ps-3  hideOnMobile text-start">{Intl.formatMessage({id : "myListings.text18"})}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ListingList sellerObject={sellerObject} searchData={searchData} isCheck={isCheck} changeListingData={changeListingData} deleteListingFromSearchData={deleteListingFromSearchData}/>
                                </tbody>
                            </table>
                        </InfiniteScroll>  
                        }
                    </div>
                </div>
            </div>
        </div>
        <ReactTooltip clickable={true} anchorId="liveRListings"  className='w-200px'     place="bottom" content="Here Text Can Be Added" />
        <ReactTooltip clickable={true} anchorId="lPListings"  className='w-200px'     place="bottom" content="Here Text Can Be Added" />
        <ReactTooltip clickable={true} anchorId="lPendingListings"  className='w-200px'     place="bottom" content="Here Text Can Be Added" />
        <ReactTooltip clickable={true} anchorId="nlRListings"  className='w-200px'     place="bottom" content="Here Text Can Be Added" />
        <ReactTooltip clickable={true} anchorId="nlDESListings"  className='w-200px'     place="bottom" content="Here Text Can Be Added" />
        <ReactTooltip clickable={true} anchorId="avgQuality"  className='w-200px'     place="bottom" content="Here Text Can Be Added" />
        </>
    )
}
