import {useState, useRef, useEffect, useMemo, useCallback} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from "react-intl"
import Swal from 'sweetalert2'
import {NO_IMAGE} from "../../../helper/ImagePaths"
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {isDeviceMobile, logout, takeAction} from '../../../helpers/commonFunctions'
import {useLang} from '../../../i18n/OpenSooqi18n'
import getMyAdsApi from '../../../../api/listing/getMyAds.api'
import {handleError} from '../../common/HandleError'
import {MyListingInterface} from "../../../interfaces/MyListingInterface"
import DynamicObject from '../../../../models/dynamic-object'
import React from 'react'
import OpenSooqIcons from '../../common/OpenSooqIcons'
import {IMAGE_PREVIEW_SM} from '../../../helper/ImageSize'
import {Button} from '../../../../app/layout/components/opensooq/Button'
import {ServicesListInterface} from '../../../interfaces/ServiceInterface'
import {showSuccess} from "../../../helper/SystemAlert";
import purchaseBundleApi from "../../../../api/purchase-bundle.api";
import {UseBundleLoader} from "../../common/UseBundleLoader";
import InfiniteScroll from 'react-infinite-scroll-component'
import { Loader } from '../../../layout/components/opensooq/Loader'
import { BundleInterface } from '../../../interfaces/WalletDetailsInterface'
import { ListingWidgetTypes, getWidgetByType } from '../../listing/my-listings/helper'
import { EDIT, PROMOTE } from '../../../helper/Ctas'
import { useNavigate } from 'react-router-dom'


type Props = {
    show: boolean
    handleClose: () => void
    bundle: BundleInterface,
    onApplied: () => void
}

const DEFAULT_SELECTED_LISTINGS: React.SetStateAction<number[]> = []

const UseNowModal = ({show, handleClose, bundle, onApplied}: Props) => {
    const iframeContent = useRef<null | HTMLIFrameElement>(null);
    const Intl = useIntl()
    const lang = useLang()
    const [loader, setLoader] = useState<boolean>(true)
    const [listings, setListings] = useState<MyListingInterface[]>([])
    const [selectedListings, setSelectedListings] = useState<number[]>(DEFAULT_SELECTED_LISTINGS)
    const [paginatedListings, setPaginatedListings] = useState<MyListingInterface[]>([])
    const listingPerPage = 30
    const [currPage, setCurrPage] = useState<number>(0)
    const [listItemsContainerRef, setListItemsContainerRef] = useState()
    const [nextUrl,setNextUrl] = useState<string>()
    const [totalListings,setTotalListings] = useState<number>(0)

    const onlistItemsContainerRefChange = useCallback((node : any) => {
      if (node !== null) {
        setListItemsContainerRef(node);
      }
    }, []);


    const addSelectedListingHandler = (id: number) => {
        setSelectedListings((currentSelectedListings) => {
            return [
                ...currentSelectedListings,
                id
            ]
        })
    }

    const removeSelectedListingHandler = (id: number) => {
        setSelectedListings((currentSelectedListings) => {
            return [
                ...currentSelectedListings.filter((selectedListing) => {
                    return selectedListing !== id
                })
            ]
        })
    }

    const changeSelectListingHandler = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        if (!e.target.checked)
            return removeSelectedListingHandler(id)
        return addSelectedListingHandler(id)
    }


    const clickApplyHandler = useCallback(() => {
        setLoader(true)
        purchaseBundleApi({
            data: {
                id: bundle.id,
                posts: selectedListings
            }
        }).then(function () {
            setLoader(false)
            handleClose()
            onApplied()
            showSuccess(Intl, Intl.locale)
        })
    }, [selectedListings])


    const selectedListingsMapper = useMemo(() => {
        const items: DynamicObject = {}
        for (const selectedListing of selectedListings) {
            items[selectedListing] = true
        }
        return items
    }, [selectedListings])

    const reamingQty = useMemo(() => {
        return (bundle?.availableQuantity || 0) - selectedListings.length
    }, [selectedListings])


    const isAllowedToSelect = useMemo(() => {
        return selectedListings.length < (bundle?.availableQuantity as number)
    }, [selectedListings, bundle])


    useEffect(() => {
        if (show) {
            const subCategoriesIds = bundle.categoryIds?.join(",")
            getMyAdsApi(`canFilter=${bundle.postFilter}&sub_category_ids=${subCategoriesIds}`, "").then((response) => {
                if (response.data.items) {
                    setListings(response.data.items)
                    const next = (response.data._links.next) ? response.data._links.next.href : '' 
                    setNextUrl(next ?? '')
                    setTotalListings(response.data._meta.totalCount);
                    setPaginatedListings(response.data.items)
                    setCurrPage((old : number) => old + 1)
                }
                setLoader(false)
            }).catch((error) => {
                handleError(error, lang);
            });
        }
    }, [show]);

    window.addEventListener("message", event => {
        if (event.data === 401) {
            logout()
        } else if (event.data == 200) {
            setLoader(false);
            if (iframeContent.current) {
                iframeContent.current.classList.remove('d-none')
            }
        }
    }, false);


    const confirm = () => {
        const text = Intl.formatMessage({id: "cancelAddingListConfirmation"})
        Swal.fire({
            icon: "warning",
            html:
                `<div class="pe-10 ps-10 fw-bolder">${text}</div>`,
            buttonsStyling: false,
            showCancelButton: true,
            cancelButtonText: Intl.formatMessage({id: 'no'}),
            showLoaderOnConfirm: true,
            confirmButtonText: Intl.formatMessage({id: 'yes'}),
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-light-danger"
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                handleClose()
            }
        })
    }


    const redirectToPost = (e: any, url: string) => {
        if (!e.target.classList.contains('action')) {
            window.open(url, "_blank");
        }
    }

    const pagination = ()=>{
        const next = (nextUrl) ? nextUrl : ''
        console.log(next);
        getMyAdsApi("", next).then((response) => {
            if (response.data.items) {
                setListings(prevAds=> {
                    return [...prevAds, ...response.data.items];
                })
                setPaginatedListings(prevAds=> {
                    return [...prevAds, ...response.data.items];
                })
                const next = (response.data._links.next) ? response.data._links.next.href : '' 
                setNextUrl(next ?? '')
                setCurrPage((old : number) => old + 1)
            }
            setLoader(false)
        }).catch((error) => {
            handleError(error, lang);
        });{}
        // const newListings =  listings.slice(0, (currPage+1 * listingPerPage));
        // setPaginatedListings([...newListings])
        // setCurrPage((old:number) => old+1)
    }

    const getWidgetHeaderText = (type : string) => {
        let headerText = '' ;
        switch (type) {
            case ListingWidgetTypes.STATUS:
                headerText = Intl.formatMessage({id : "myListings.mobile.List.status"})
                break;
            case ListingWidgetTypes.VIEWS:
                headerText = Intl.formatMessage({id : "myListings.mobile.List.views"})
                break;
            case ListingWidgetTypes.PQS:
                headerText = Intl.formatMessage({id : "myListings.mobile.List.pqs"})
                break;
            case ListingWidgetTypes.APPLICANTS:
                headerText = Intl.formatMessage({id : "myListings.mobile.List.applicants"})
                break;
            default:
                break;
        }
        return headerText;
    }

    const hasAction = (type : string) => {
        let headerText = false ;
        switch (type) {
            case ListingWidgetTypes.STATUS:
                headerText = false;
                break;
            case ListingWidgetTypes.VIEWS:
                headerText = true;
                break;
            case ListingWidgetTypes.PQS:
                headerText = true;
                break;
            case ListingWidgetTypes.INFO:
                headerText = false;
                break;
            case ListingWidgetTypes.APPLICANTS:
                headerText = true;
                break;
            default:
                break;
        }
        return headerText;
    }

    let navigate = useNavigate();

    return <>
        <Modal id='iframe' tabIndex={-1} aria-hidden='true'
               dialogClassName='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg' show={show} onHide={handleClose}>
            {loader ? <UseBundleLoader/> : <>
                <div className='modal-header p-5'>
                    <div className="modal-title">
                        <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                                <span className="text-dark fw-bold text-hover-primary fs-3">
                                    {bundle.text}
                                </span>
                                <span
                                    className="fw-bold d-block fs-4 text-danger">{reamingQty} {Intl.formatMessage({id: "listingsLeft"})}</span>
                            </div>
                        </div>
                    </div>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg'/>
                    </div>
                </div>
                <div ref={onlistItemsContainerRefChange} className='modal-body py-lg-10 px-lg-10 align-items-center' style={{height: '500px'}}>
                    {listItemsContainerRef && <InfiniteScroll
                        style={{overflow : "clip"}}
                        next={pagination}
                        dataLength={paginatedListings.length}
                        hasMore = {totalListings > paginatedListings.length}
                        loader = {<Loader />}
                        refreshFunction={undefined}
                        pullDownToRefresh={false}
                        pullDownToRefreshThreshold={100}
                        pullDownToRefreshContent={null}
                        releaseToRefreshContent={null}
                        scrollableTarget={listItemsContainerRef}>
                    {listings.length == 0 && <div className='text-center'><h4>{Intl.formatMessage({id : "noMatchingListings"})}</h4></div>}
                    {listings.length  > 0 && <table className="table table-hover align-middle table-row-dashed fs-6 gy-3 dataTable no-footer" id="kt_table_widget_4_table">
                        <tbody className="overflow-scroll">{
                            paginatedListings.map((listing: DynamicObject, index) => {
                                const isSelected = selectedListingsMapper[listing.listing_id] || false
                                const isDisabled = !isSelected && !isAllowedToSelect
                                return <React.Fragment key={index}>
                                    <tr className={`postTr_${listing.listing_id} hoverPointer trHoverAction hoverAction border-bottom-0`} data-id={listing.listing_id}>
                                        <td className={`hideOnMobile`}>
                                            <div className="d-flex align-items-start">
                                                <div className={`sc-265a2526-1 jRUVEn postImgCont position-relative radius-8 overflowHidden marginEnd-16px ixKSmm`}>
                                                    <img src={`${listing.main_image_uri.indexOf('noImage/no-image') !== -1 ? NO_IMAGE.replace('{lang}',lang): (listing.main_image_uri.indexOf('{platform}') !== -1) ? listing.main_image_uri.replace("{platform}","android").replace("{size}","xh") : listing.main_image_uri}`} width="272" height="204"  className="width-100  customImg height-100 "  alt={listing.title} loading="eager"/>
                                                </div>
                                                <div className="d-flex justify-content-start flex-column "
                                                     style={{overflowWrap: "anywhere"}}>
                                                    <span className="customPostTitleText fw-bolder ">{listing.title}</span>
                                                    <span className="fw-semibold d-block fs-7 ">{listing.sub_title}</span>
                                                    <span className="fw-semibold d-block fs-7 ">{listing.category} {Intl.formatMessage({id : "in"})} {listing.city}</span>
                                                    <span className="fw-semibold d-block fs-7 "> {
                                                        (listing.has_price && listing.has_price == 0) ? Intl.formatMessage({id: "noPrice"}) : (listing.price) ? <span className="" dir="rtl">{listing.price.toLocaleString() + ' ' + listing.price_currency} </span> : <></>}
                                                    </span>
                                                    <div className="d-flex justify-content-start flex-column" style={{overflowWrap: "anywhere"}}>
                                                        <span className="customPostTitleText fw-bolder">{listing.listing_id}</span>
                                                        <div className="d-flex fw-semibold d-block fs-7 justify-content-start flex-column" style={{overflowWrap: "anywhere"}}>
                                                            {listing.edits_left && <>
                                                                <div className="fw-400 d-inline">{Intl.formatMessage({id: "myListings.text43"}) + ' '}
                                                                    <span className='fw-bold'> {(listing.edits_left === -1) ? Intl.formatMessage({id: "myOpenSooq.unlimited"}) : listing.edits_left} </span>
                                                                </div>
                                                            </>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-start pe-0 hideOnMobile ">
                                            <div className="d-flex justify-content-start flex-column" style={{overflowWrap: "anywhere"}}>
                                                <span className={`customPostTitleText fw-bolder ${(listing.active) ? 'text-success' : 'text-danger'} `}>
                                                    {listing.listing_status_parent}
                                                </span>
                                                <span className="fw-semibold d-block fs-7">
                                                    {listing.listing_status}
                                                </span>
                                                <div className="d-flex flex-column bd-highlight mb-3">
                                                    {listing.service_name.map((service: any, index: number) => {
                                                        return <div key={index} className="d-flex justify-content-start">
                                                            <span>
                                                                <OpenSooqIcons className="me-1 fw-semibold d-block fs-7" width={20} height={20} icon={service.icon.toLowerCase()}/>
                                                            </span>
                                                        <span>{service.label}</span>
                                                    </div>})}
                                                </div>
                                            </div>
                                        </td>
                                        <td className={`hideOnMobile`}>
                                            <div className="d-flex justify-content-center flex-column" style={{overflowWrap: "-moz-initial"}}>
                                                <div className="d-flex align-items-center">
                                                    <input type='checkbox' disabled={isDisabled} checked={isSelected} onChange={(e) => changeSelectListingHandler(e, listing.listing_id)}/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className={`postTr_${listing.listing_id} ${(index % 2 === 0) ? "even" : "odd"} hoverPointer trHoverAction hoverAction  border-bottom-0 even`} data-id={listing.listing_id}>
                                        <td colSpan={10} className="shadow1 border-top-0 d-block hideOnDesktop">
                                            <div className="card-body">
                                                <div className="row">
                                                    {listing.listing_type == 'Regular' && <div className="col-12">
                                                        <div className="d-flex align-items-start">
                                                            <div className="flex flexNoWrap  pt-4 pb-2 blackColor radius-8 bigContainer  ripple boxShadow2 relative">
                                                                <div className={`sc-265a2526-1 jRUVEn postImgCont bigContainer position-relative radius-8 overflowHidden marginEnd-16px myListingsImageContainer`}>
                                                                   <img src={`${listing.main_image_uri.indexOf('noImage/no-image') !== -1 ? NO_IMAGE.replace('{lang}',lang): (listing.main_image_uri.indexOf('{platform}') !== -1) ? listing.main_image_uri.replace("{platform}","android").replace("{size}","xh") : listing.main_image_uri}`} width="272" height="204"  className="width-100 bigContainer customImg height-100" alt={listing.title} loading="eager"/>
                                                                </div>
                                                                <div className="sc-265a2526-3 zoIKj postDet bigContainer flex-1 flex flexSpaceBetween flexDirectionColumn">
                                                                    <div className="flex flexSpaceBetween">
                                                                        <span  className={` ${isDeviceMobile() ? 'fs-4' : 'fs-2'} fw-bold  breakWord bigContainer overflowHidden `}>{listing.title}</span>
                                                                    </div>
                                                                    <div className={`${isDeviceMobile() ? 'fs-5' : 'fs-3'}  bigContainer`}>{listing.sub_title}</div>
                                                                    <div className="flex alignItems bigContainer ">
                                                                        {listing.category} {Intl.formatMessage({id : "in"})} <b>{listing.city}</b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <div className="d-flex flex-row bd-highlight mb-3">
                                                                <div className="mt-3 sc-265a2526-2 iRqeKu numOfCont flex bigContainer alignItems radius-8 whiteColor fs-13 " >
                                                                    <span className="flex alignItems radius-8 bigContainer me-1">
                                                                        <svg viewBox="0 0 12 12" width="12" height="12" className="me-2 bigContainer" data-name="iconCamera2">
                                                                            <path fill="#fff" d="M12 10.6667V1.33333C12 0.6 11.4 0 10.6667 0H1.33333C0.6 0 0 0.6 0 1.33333V10.6667C0 11.4 0.6 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667ZM3.66667 7L5.33333 9.00667L7.66667 6L10.6667 10H1.33333L3.66667 7Z"></path>
                                                                        </svg>{listing.medias_count.images}
                                                                    </span>
                                                                    <span className="flex alignItems me-1 radius-8 bigContainer">
                                                                        <OpenSooqIcons className="me-1 fw-semibold d-block fs-7 text-white" width={20} height={15} icon={'video'} /> {listing.medias_count.videos}
                                                                    </span>
                                                                    <span className="flex alignItems radius-8 bigContainer">
                                                                        <OpenSooqIcons className="me-1 fw-semibold d-block fs-7 text-white" width={20} height={15} icon={'360'} /> {listing.medias_count[360]}
                                                                    </span>
                                                                </div>
                                                                <div className="flex alignItems radius-8 bigContainer mt-3">
                                                                    {listing.vas_icons.map((service : any,index : number) => {
                                                                        return <OpenSooqIcons key={index} className="" width={30} height={30} icon={service.toLowerCase()} />
                                                                    })}
                                                                </div>
                                                            </div>
                                                            <div className="mt-3 sc-265a2526-2 fs-1 text-danger fw-bolder numOfCont flex bigContainer alignItems radius-8   ">
                                                                <b>{(listing.has_price == 0) ? Intl.formatMessage({id : "noPrice"}): (listing.has_price == 1 && listing.price) ? <span className="" dir="rtl">{listing.price.toLocaleString() + ' ' + listing.price_currency }</span> : ''}</b>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                    {listing.listing_type != 'Regular' && <div className="col-12 mb-5">
                                                        <div className="text-muted d-flex justify-content-between aligns-items-center">
                                                            <div className="d-flex aligns-items-center" style={{alignItems: "center"}}>
                                                                {listing.city_neighborhood}
                                                            </div>
                                                            <div className="flex alignItems ">
                                                                {listing.vas_icons.map((service : any,index : number) => {
                                                                    return <OpenSooqIcons key={index} className="" width={30} height={30} icon={service.toLowerCase()} />
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className="text-muted d-flex justify-content-between aligns-items-center mt-5">
                                                            <h1 className="d-flex aligns-items-center" style={{alignItems: "center"}}>
                                                                {listing.title}
                                                            </h1>
                                                        </div>
                                                        <div className="text-muted d-flex justify-content-between aligns-items-center mt-5">
                                                            <div className="d-flex aligns-items-center" style={{alignItems: "center"}}>
                                                                {listing.tags.map((tag : any,index : number) => {
                                                                    return (index <= 2 ) ? <span key={index} className="badge badge-secondary me-2">{tag}</span> : ''
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className="text-muted d-flex justify-content-between aligns-items-center mt-5">
                                                            <div className="d-flex aligns-items-center" style={{alignItems: "center"}}>
                                                                {listing.sub_title}
                                                            </div>
                                                        </div>
                                                    </div>}
                                                    {listing.widgets && listing.widgets.map((widget : DynamicObject,index : number) => {
                                                        const isLastElement = index === listing.widgets.length - 1;
                                                        const isOddIndex = index % 2 === 0;
                                                        return <div key={index} className={`${isOddIndex && isLastElement ? 'col-12' : 'col-6'} accordion-header mt-3`}>
                                                        <div className={`border py-3  ${(widget.color) ? (widget.color == 'GREEN') ? 'bg-light-success border-success' : 'bg-light-danger border-gray-300' : 'border-gray-300'} border-dashed rounded w-100 p-2`}>
                                                            <div className={`d-flex align-items-center h-50px`}>
                                                                {widget.color && <span className={`bullet bullet-vertical h-40px ${(widget.color) ? (widget.color == 'GREEN') ? 'bg-success' : 'bg-light' : ''}`}></span>}
                                                                <div className={`d-flex  justify-content-start flex-column ${(widget.type == ListingWidgetTypes.STATUS) ? 'ms-3' : ''}  w-100`}>
                                                                    <div className={`d-flex ${widget.percentage !== undefined ? 'mt-3' : ''} justify-content-between text-dark fs-5`}>
                                                                        <div className="flex-grow-1 fw-bold">
                                                                            {(!widget.title)  ? getWidgetHeaderText(widget.type) : widget.title}
                                                                        </div>
                                                                    </div>
                                                                    <span className={`fw-bolder d-block fs-6 mt-2`}>
                                                                        <span className={`customPostTitleText ${(widget.type == ListingWidgetTypes.INFO) ? 'text-danger' : 'text-dark'} fs-3`}>
                                                                            <span className=""><b>{(widget.text) ? widget.text : (widget.subTitle) ? widget.subTitle : ''}</b></span>
                                                                        </span>
                                                                        {widget.percentage !== undefined && <div className="h-5px  w-100 bg-light mb-3">
                                                                            <div className="bg-success rounded h-5px"  style={{width: `${widget.percentage}%`}}></div>
                                                                        </div>}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>})}
                                                </div>
                                            </div>
                                        </td>
                                        <td className={` hideOnDesktop`}>
                                            <div className="d-flex justify-content-center flex-column" style={{overflowWrap: "anywhere"}}>
                                                <div className="d-flex align-items-center">
                                                    <input type='checkbox' disabled={isDisabled} checked={isSelected}  onChange={(e) => changeSelectListingHandler(e, listing.listing_id)}/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            })}
                        </tbody>
                    </table>}
                </InfiniteScroll>}
                </div>
                {listings.length > 0 && <div className="modal-footer">
                    <div className='row w-100 justify-content-center'>
                        <div className='col-lg-4 col-4'>
                            <div className={`d-flex align-items-center justify-content-center`}>
                                <Button action={clickApplyHandler} className='btn-primary btn-sm w-100 mt-5 fs-2' text={Intl.formatMessage({id : "myWalletText10"})}/>
                            </div>
                        </div>
                    </div>
                </div>}
            </>}


        </Modal>
    </>
}

export {UseNowModal}