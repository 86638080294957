import Skeleton from "react-loading-skeleton";



export function UseBundleLoader() {
    return (
        <div className='text-center p-3 ' style={{height:"30vh"}} >
            <div className="row justify-content-center">
                <div className="col-11">
                    <Skeleton className="w-100  h-50px"/>

                </div>
            </div>

            <div className="row p-2 justify-content-center">
                 <div className="col-11">
                     <Skeleton className="w-100 h-150px"/>

                 </div>
             </div>
            <div className="row justify-content-center mt-4">
                <div className="col-4 p-3">
                    <Skeleton className="w-100  h-30px" style={{borderRadius:"6px"}}/>

                </div>
            </div>


                </div>
    )
}