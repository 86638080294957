import { useIntl } from "react-intl"
type Props = {
    showFileModal: () => any
}
const CommercialNotice = ({showFileModal} : Props) => {
    const Intl = useIntl();
    return <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6'>
        <span className='svg-icon svg-icon-2tx svg-icon-warning me-4'>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' > <rect opacity='0.3' x='2' y='2' width='20' height='20' rx='10' fill='currentColor' ></rect> <rect x='11' y='14' width='7' height='2' rx='1' transform='rotate(-90 11 14)' fill='currentColor' ></rect> <rect x='11' y='17' width='2' height='2' rx='1' transform='rotate(-90 11 17)' fill='currentColor' ></rect> </svg>
        </span>
        <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-semibold'>
                <h4 className='text-gray-900 fw-bold'>{Intl.formatMessage({id: 'notice'})}</h4>
                <div className='fs-6 text-gray-700'>
                    <ul>
                        <li>{Intl.formatMessage({id: 'noticeText3'})}</li>
                        <li>{Intl.formatMessage({id: 'noticeText4'})}</li>
                    </ul>
                    <span onClick={showFileModal} className='fw-bold hoverPointer'>
                        {Intl.formatMessage({id: 'noticeText2'})}
                    </span>
                </div>
            </div>
        </div>
    </div>
}

export default CommercialNotice