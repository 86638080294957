import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
// Apps
import {OpenSooqI18nProvider} from './app/i18n/OpenSooqi18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './app/assets/css/style.rtl.css'
 **/
// import './app/assets/sass/style.scss' 
// import './app/assets/sass/plugins.scss'
// import './app/assets/css/style.rtl.css'
// import './app/assets/sass/style.react.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import { ToastContainer } from 'react-toastify'

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 *
 * @see https://github.com/axios/axios#interceptors
 */
Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')
const isMobile = window.innerWidth <= 900
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <OpenSooqI18nProvider>
          <ToastContainer style={{width : isMobile ? '100%' : '1200px'}} autoClose={false} draggableDirection="y" draggablePercent={60}/>
          <AppRoutes />
      </OpenSooqI18nProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
