import { showError } from "../../helper/SystemAlert";

const handleError = (error : any, lang:string) =>{
    if(error.response.data){
      showError(Intl, lang, error.response.data);
    }
    else{
      showError(Intl, lang)
    }
  }

  export{handleError}