import ContentLoader from "react-content-loader"
import Skeleton from "react-loading-skeleton";
import { useIntl } from "react-intl";

interface loaderProps{
  counter ?: number
  className ?: string
}

const TableLoader = (props : loaderProps) => {
  const Intl = useIntl();
  return (
    <div className="table-responsive z-index-0">
        <table className="table table-hover align-middle table-row-dashed fs-6 gy-3 dataTable no-footer" id="kt_table_widget_4_table">
            <tbody className="">
                <tr>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                </tr>
                <tr>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                </tr>
                <tr>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                </tr>
                <tr>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                </tr>
                <tr>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                </tr>
                <tr>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                </tr>
                <tr>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                </tr>
                <tr>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                </tr>
                <tr>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                </tr>
                <tr>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                </tr>
                <tr>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                </tr>
                <tr>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                </tr>
                <tr>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                </tr>
                <tr>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                </tr>
                <tr>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                    <td><Skeleton   className='me-1 w-50 z-index-0'/></td>
                </tr>
            </tbody>
        </table>
    </div>
  )
}

export {TableLoader}
