
import { ListingStatsInterface } from "../../../app/interfaces/ListingStatsInterface"
import { Button } from "../../../app/layout/components/opensooq/Button"
import { Chart } from "../../../app/layout/components/opensooq/Chart"
import { toAbsoluteUrl } from "../../../app/helpers"
import getAdStatsApi from "../../../api/getAdStats.api"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { ListingInfo } from "../common/ListingInfo"
import { ListingStatistics } from "../common/ListingStatistics"
import { MyListingViewInterface } from "../../interfaces/MyListingViewInterface"
import getListingApi from "../../../api/listing/get.listing.api"
import { MEMBER_COUNTRY_COOKIE } from "../../helpers/CookiesVariables"
import { useLang } from "../../i18n/OpenSooqi18n"
import DynamicObject from "../../../models/dynamic-object"
import { EDIT, PROMOTE } from "../../helper/Ctas"
import { BundleModal } from "../payment/BundleModal"
import { useChatFunctions } from "opensooq-chat"
import { getCookies } from "../../helpers/commonFunctions"
import { LISTING_STATISTICS } from "../../helper/Ctas"

const ListingStats = () => {
    const listingId : any = new URLSearchParams(window.location.search).get('id') ?? 0;
    const [favoriteCountTotal,setFavoriteCountTotal] = useState <number>(0)
    const [favorite,setFavorite] = useState <ListingStatsInterface>()
    const [viewsCountTotal,setViewsCountTotal] = useState <number>(0)
    const [listing,setListing] = useState <MyListingViewInterface>(); 
    const [callCountTotal,setCallCountTotal] = useState <number>(0)
    const [roomCountTotal,setCvCountTotal] = useState <number>(0)
    const [calls,setCalls] = useState <ListingStatsInterface>()
    const [views,setViews] = useState <ListingStatsInterface>()
    const [chat,setChat] = useState <ListingStatsInterface>()
    const [isLoading,setISLoading] = useState <boolean>(true)
    const [showBundle,setShowBundle] = useState <boolean>(false)
    const [statsLoader, setStatsLoader] = useState(true)
    const [shares,setShares] = useState <any>({})
    const [count,setCount] = useState(0)
    const {getUnreadPostRooms} = useChatFunctions()
    const Intl = useIntl();
    const lang = useLang()
    const pathName = window.location.pathname

    useEffect(() => {
        getListingApi(listingId).then((response) => {
            setListing(response.data);
        })

        getUnreadPostRooms(listingId).then((value : number) => {
            setCount(value)
        })

        getAdStatsApi(listingId).then(response => {
            setViews(response.data.views);
            setCalls(response.data.calls);
            setChat(response.data.rooms);
            setFavorite(response.data.bookmarks);
            setFavoriteCountTotal(response.data.bookmarks.total)
            setViewsCountTotal(response.data.views.total);
            setCallCountTotal(response.data.calls.total);
            setCvCountTotal(response.data.rooms.total);
            setStatsLoader(false);
            setISLoading(false)
        });
    },[]);

    const promoteListing= () => {
        setShowBundle(true)
    }

    const refresh = () => {

    }
    
    return (
        <>
            <ListingInfo count={count} refresh={() => {refresh()}}  listing={listing}/>
            {!isLoading && <>
                {listing && Object.values(listing.ctas).filter((cta ) => {return cta.cta == PROMOTE})[0] &&<div className="card mt-4 mb-5">
                    <div className='card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0' style={{ backgroundPosition: '100% 50%', backgroundImage: `url('${toAbsoluteUrl('/media/stock/900x600/42.png')}')`, }} >
                        <div className='mb-10'>
                            <div className='fs-2 fw-bold text-gray-800 text-center mb-10'>
                                <span className='me-2'>
                                    {Intl.formatMessage({id: 'viewsAndStats.title1'})}
                                    <br />
                                    {Intl.formatMessage({id: 'viewsAndStats.title2'})}
                                </span>
                            </div>
                            <div className='text-center'>
                                <button  onClick={() => {promoteListing()}} className="btn h-100 btn-primary">{Intl.formatMessage({id : "myListingView.text38"})}</button>
                            </div>
                        </div>
                    </div>
                </div>}
                {/* <div className="row justify-content-between mt-5">
                    <div className="col-2">
                        <select className="bg-white form-select form-select-solid"  data-kt-select2="true"  data-allow-clear="true">
                            <option value="7">Last 7 days</option>
                            <option value="30">Last Month</option>
                        </select>                         
                    </div>
                </div> */}
                <div className="row">
                    {views &&  <div className="col-12">
                        <Chart className='mb-5 mt-5' total={views.total} buttonText={Intl.formatMessage({id: 'adViews.text3'})} description={Intl.formatMessage({id: 'adViews.text2'})} title={Intl.formatMessage({id: 'adViews.text1'})} chartType={views} target={'views'} adId={listingId} extraText={Intl.formatMessage({id: "listing.stats.viewsDesc"})} chartName={Intl.formatMessage({id: 'adViews.text1'})}/>
                    </div>}
                    {calls &&  <div className="col-12">
                        <Chart className='mb-5 mt-5' total={calls.total} buttonText={Intl.formatMessage({id: 'adViews.text6'})} description={Intl.formatMessage({id: 'adViews.text5'})} title={Intl.formatMessage({id: 'adViews.text4'})}  chartType={calls} target={'calls'} adId={listingId} chartName={Intl.formatMessage({id: 'adViews.text4'})}/>
                    </div>}
                    {chat &&  <div className="col-12">
                        <Chart className='mb-5 mt-5' total={chat.total} buttonText={Intl.formatMessage({id: 'adViews.text12'})} description={Intl.formatMessage({id: 'adViews.text8'})} title={Intl.formatMessage({id: 'adViews.text7'})}  chartType={chat} target={'msgs'} adId={listingId} chartName={Intl.formatMessage({id: 'adViews.text7'})}/>
                    </div>}
                    {favorite && <div className="col-12">
                        <Chart className='mb-5 mt-5' total={favorite.total} buttonText={Intl.formatMessage({id: 'adViews.text11'})} description={Intl.formatMessage({id: 'adViews.text10'})} title={Intl.formatMessage({id: 'adViews.text9'})}  chartType={favorite} target={'bookmarks'} adId={listingId} chartName={Intl.formatMessage({id: 'adViews.text9'})}/>
                    </div>}
                    {/* {chat && <div className="col-12">
                        <Chart className='mb-5 mt-5' total={0} buttonText={Intl.formatMessage({id: 'adViews.text12'})} description={Intl.formatMessage({id: 'adViews.text13'})} title={Intl.formatMessage({id: 'adViews.text13'})}  chartType={chat} target={Intl.formatMessage({id: 'adViews.text13'})} adId={listingId} chartName={Intl.formatMessage({id: 'adViews.text13'})}/>
                    </div>} */}
                </div>
                <div className="row">
                    {listing && (listing.ctas.find((cta :DynamicObject) => {return cta.cta == PROMOTE}) || listing.ctas.find((cta :DynamicObject) => {return cta.cta == LISTING_STATISTICS}) && pathName != '/listings/my-listings/listing-stats') && <ListingStatistics showStatsButton={false} showPromote={listing && listing.ctas.find((cta :DynamicObject) => {return cta.cta == PROMOTE})} stats={{views : viewsCountTotal,favorite:favoriteCountTotal,calls : callCountTotal,rooms : roomCountTotal}} loading={statsLoader}/>}     
                </div>
                <BundleModal  show={showBundle}  type={['Turbo','Premium','Bumpup']} handleClose={() => {setShowBundle(false)}} id={listingId} pos={'stats'}/>
            </>}
        </>
)}

export {ListingStats}
