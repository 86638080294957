import Skeleton from "react-loading-skeleton";

interface loaderProps{
  counter ?: number | 0
  className ?: string
}

const CellLoader = (props : loaderProps) => {
  const isMobile = window.innerWidth <= 900
  return (
    <>
        {props.counter == 1 && <div className="mb-32  position-relative customShadow bigContainer mt-3 ">
            <div  className="flex flexNoWrap ps-4 pe-4 pt-4 pb-2 blackColor radius-8 bigContainer ripple boxShadow2 relative" >
                <div className={`sc-265a2526-1 jRUVEn ixKSmm postImgCont bigContainer position-relative radius-8 overflowHidden marginEnd-16px ixKSmm`} >
                    <Skeleton style={{maxWidth : "100%",objectFit: "cover" ,borderRadius : "10px",padding:"0",height: "100%",width:"100%"}} />
                </div>
                <div className="sc-265a2526-3 zoIKj postDet bigContainer flex-1 flex flexSpaceBetween flexDirectionColumn">
                    <div className="flex flexSpaceBetween mt-1">
                        <h2 className="font-20 breakWord bigContainer overflowHidden  fs-sm-4"><Skeleton className="w-300px w-lg-700px"/></h2>
                    </div>
                    <div className="fs-16 bigContainer fs-sm-4"><Skeleton className="w-200px w-lg-600px"/></div>
                    <div className="flex alignItems bigContainer ">
                        <div className=" fs-16 category fs-sm-4"><Skeleton className="w-100px w-lg-500px"/></div>
                    </div>
                </div>
            </div>
            <div className="flex alignItems ps-4 pe-4 pt-2 pb-2">
                <div className="pe-1  bd-highlight">
                    <Skeleton className="w-30px w-lg-65px" />
                </div>
                <div className="ms-1 bd-highlight">
                    <Skeleton className="w-30px w-lg-70px" />
                </div>
                <div className="ms-1 bd-highlight">
                    <Skeleton className="w-30px w-lg-70px" />
                </div>
                <div className="ms-1 bd-highlight">
                    <Skeleton className="w-30px w-lg-50px" />
                </div>
                <div className="postPrice ms-auto bold alignSelfCenter fs-2"><Skeleton  className="w-30px w-lg-100px"/></div>
            </div>
            <div className="flex alignItems ps-4 pe-4 pt-2 pb-2" style={{paddingTop:"5px"}}>
                <div className="pe-1  bd-highlight">
                    <Skeleton className="w-30px w-lg-200px"/>
                </div>
                <div className="ms-1 bd-highlight">
                    <Skeleton className="w-30px w-lg-200px"/>
                </div>
                <div className=" bd-highlight ms-2 text-end">
                    <Skeleton className="w-30px w-lg-200px"/>
                </div>
            </div>
        </div>}
        {props.counter != 1 && <>
            <div className="mb-32  position-relative customShadow bigContainer mt-3">
                <div  className="flex flexNoWrap ps-4 pe-4 pt-4 pb-2 blackColor radius-8 bigContainer ripple boxShadow2 relative" >
                    <div className={`sc-265a2526-1 jRUVEn ixKSmm postImgCont bigContainer position-relative radius-8 overflowHidden marginEnd-16px ${(isMobile ? 'ixKSmm' : '')}`} >
                        <Skeleton style={{maxWidth : "100%",objectFit: "cover" ,borderRadius : "10px",padding:"0",height: "100%",width:"100%"}} />
                    </div>
                    <div className="sc-265a2526-3 zoIKj postDet bigContainer flex-1 flex flexSpaceBetween flexDirectionColumn">
                        <div className="flex flexSpaceBetween mt-1">
                            <h2 className="font-20 breakWord bigContainer overflowHidden  fs-sm-4"><Skeleton className="w-300px w-lg-700px"/></h2>
                        </div>
                        <div className="fs-16 bigContainer fs-sm-4"><Skeleton className="w-200px w-lg-600px"/></div>
                        <div className="flex alignItems bigContainer ">
                            <div className=" fs-16 category fs-sm-4"><Skeleton className="w-100px w-lg-500px"/></div>
                        </div>
                    </div>
                </div>
                <div className="flex alignItems ps-4 pe-4 pt-2 pb-2">
                    <div className="pe-1  bd-highlight">
                        <Skeleton className="w-30px w-lg-65px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-70px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-70px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-50px" />
                    </div>
                    <div className="postPrice ms-auto bold alignSelfCenter fs-2"><Skeleton  className="w-30px w-lg-100px"/></div>
                </div>
                <div className="flex alignItems ps-4 pe-4 pt-2 pb-2" style={{paddingTop:"5px"}}>
                    <div className="pe-1  bd-highlight">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                    <div className=" bd-highlight ms-2 text-end">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                </div>
            </div>
            <div className="mb-32  position-relative customShadow bigContainer mt-3">
                <div  className="flex flexNoWrap ps-4 pe-4 pt-4 pb-2 blackColor radius-8 bigContainer ripple boxShadow2 relative" >
                    <div className={`sc-265a2526-1 jRUVEn ixKSmm postImgCont bigContainer position-relative radius-8 overflowHidden marginEnd-16px ${(isMobile ? 'ixKSmm' : '')}`} >
                        <Skeleton style={{maxWidth : "100%",objectFit: "cover" ,borderRadius : "10px",padding:"0",height: "100%",width:"100%"}} />
                    </div>
                    <div className="sc-265a2526-3 zoIKj postDet bigContainer flex-1 flex flexSpaceBetween flexDirectionColumn">
                        <div className="flex flexSpaceBetween mt-1">
                            <h2 className="font-20 breakWord bigContainer overflowHidden  fs-sm-4"><Skeleton className="w-300px w-lg-700px"/></h2>
                        </div>
                        <div className="fs-16 bigContainer fs-sm-4"><Skeleton className="w-200px w-lg-600px"/></div>
                        <div className="flex alignItems bigContainer ">
                            <div className=" fs-16 category fs-sm-4"><Skeleton className="w-100px w-lg-500px"/></div>
                        </div>
                    </div>
                </div>
                <div className="flex alignItems ps-4 pe-4 pt-2 pb-2">
                    <div className="pe-1  bd-highlight">
                        <Skeleton className="w-30px w-lg-65px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-70px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-70px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-50px" />
                    </div>
                    <div className="postPrice ms-auto bold alignSelfCenter fs-2"><Skeleton  className="w-30px w-lg-100px"/></div>
                </div>
                <div className="flex alignItems ps-4 pe-4 pt-2 pb-2" style={{paddingTop:"5px"}}>
                    <div className="pe-1  bd-highlight">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                    <div className=" bd-highlight ms-2 text-end">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                </div>
            </div>
            <div className="mb-32  position-relative customShadow bigContainer mt-3">
                <div  className="flex flexNoWrap ps-4 pe-4 pt-4 pb-2 blackColor radius-8 bigContainer ripple boxShadow2 relative" >
                    <div className={`sc-265a2526-1 jRUVEn ixKSmm postImgCont bigContainer position-relative radius-8 overflowHidden marginEnd-16px ${(isMobile ? 'ixKSmm' : '')}`} >
                        <Skeleton style={{maxWidth : "100%",objectFit: "cover" ,borderRadius : "10px",padding:"0",height: "100%",width:"100%"}} />
                    </div>
                    <div className="sc-265a2526-3 zoIKj postDet bigContainer flex-1 flex flexSpaceBetween flexDirectionColumn">
                        <div className="flex flexSpaceBetween mt-1">
                            <h2 className="font-20 breakWord bigContainer overflowHidden  fs-sm-4"><Skeleton className="w-300px w-lg-700px"/></h2>
                        </div>
                        <div className="fs-16 bigContainer fs-sm-4"><Skeleton className="w-200px w-lg-600px"/></div>
                        <div className="flex alignItems bigContainer ">
                            <div className=" fs-16 category fs-sm-4"><Skeleton className="w-100px w-lg-500px"/></div>
                        </div>
                    </div>
                </div>
                <div className="flex alignItems ps-4 pe-4 pt-2 pb-2">
                    <div className="pe-1  bd-highlight">
                        <Skeleton className="w-30px w-lg-65px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-70px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-70px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-50px" />
                    </div>
                    <div className="postPrice ms-auto bold alignSelfCenter fs-2"><Skeleton  className="w-30px w-lg-100px"/></div>
                </div>
                <div className="flex alignItems ps-4 pe-4 pt-2 pb-2" style={{paddingTop:"5px"}}>
                    <div className="pe-1  bd-highlight">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                    <div className=" bd-highlight ms-2 text-end">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                </div>
            </div>
            <div className="mb-32  position-relative customShadow bigContainer mt-3">
                <div  className="flex flexNoWrap ps-4 pe-4 pt-4 pb-2 blackColor radius-8 bigContainer ripple boxShadow2 relative" >
                    <div className={`sc-265a2526-1 jRUVEn ixKSmm postImgCont bigContainer position-relative radius-8 overflowHidden marginEnd-16px ${(isMobile ? 'ixKSmm' : '')}`} >
                        <Skeleton style={{maxWidth : "100%",objectFit: "cover" ,borderRadius : "10px",padding:"0",height: "100%",width:"100%"}} />
                    </div>
                    <div className="sc-265a2526-3 zoIKj postDet bigContainer flex-1 flex flexSpaceBetween flexDirectionColumn">
                        <div className="flex flexSpaceBetween mt-1">
                            <h2 className="font-20 breakWord bigContainer overflowHidden  fs-sm-4"><Skeleton className="w-300px w-lg-700px"/></h2>
                        </div>
                        <div className="fs-16 bigContainer fs-sm-4"><Skeleton className="w-200px w-lg-600px"/></div>
                        <div className="flex alignItems bigContainer ">
                            <div className=" fs-16 category fs-sm-4"><Skeleton className="w-100px w-lg-500px"/></div>
                        </div>
                    </div>
                </div>
                <div className="flex alignItems ps-4 pe-4 pt-2 pb-2">
                    <div className="pe-1  bd-highlight">
                        <Skeleton className="w-30px w-lg-65px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-70px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-70px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-50px" />
                    </div>
                    <div className="postPrice ms-auto bold alignSelfCenter fs-2"><Skeleton  className="w-30px w-lg-100px"/></div>
                </div>
                <div className="flex alignItems ps-4 pe-4 pt-2 pb-2" style={{paddingTop:"5px"}}>
                    <div className="pe-1  bd-highlight">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                    <div className=" bd-highlight ms-2 text-end">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                </div>
            </div>
            <div className="mb-32  position-relative customShadow bigContainer mt-3">
                <div  className="flex flexNoWrap ps-4 pe-4 pt-4 pb-2 blackColor radius-8 bigContainer ripple boxShadow2 relative" >
                    <div className={`sc-265a2526-1 jRUVEn ixKSmm postImgCont bigContainer position-relative radius-8 overflowHidden marginEnd-16px ${(isMobile ? 'ixKSmm' : '')}`} >
                        <Skeleton style={{maxWidth : "100%",objectFit: "cover" ,borderRadius : "10px",padding:"0",height: "100%",width:"100%"}} />
                    </div>
                    <div className="sc-265a2526-3 zoIKj postDet bigContainer flex-1 flex flexSpaceBetween flexDirectionColumn">
                        <div className="flex flexSpaceBetween mt-1">
                            <h2 className="font-20 breakWord bigContainer overflowHidden  fs-sm-4"><Skeleton className="w-300px w-lg-700px"/></h2>
                        </div>
                        <div className="fs-16 bigContainer fs-sm-4"><Skeleton className="w-200px w-lg-600px"/></div>
                        <div className="flex alignItems bigContainer ">
                            <div className=" fs-16 category fs-sm-4"><Skeleton className="w-100px w-lg-500px"/></div>
                        </div>
                    </div>
                </div>
                <div className="flex alignItems ps-4 pe-4 pt-2 pb-2">
                    <div className="pe-1  bd-highlight">
                        <Skeleton className="w-30px w-lg-65px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-70px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-70px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-50px" />
                    </div>
                    <div className="postPrice ms-auto bold alignSelfCenter fs-2"><Skeleton  className="w-30px w-lg-100px"/></div>
                </div>
                <div className="flex alignItems ps-4 pe-4 pt-2 pb-2" style={{paddingTop:"5px"}}>
                    <div className="pe-1  bd-highlight">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                    <div className=" bd-highlight ms-2 text-end">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                </div>
            </div>
            <div className="mb-32  position-relative customShadow bigContainer mt-3">
                <div  className="flex flexNoWrap ps-4 pe-4 pt-4 pb-2 blackColor radius-8 bigContainer ripple boxShadow2 relative" >
                    <div className={`sc-265a2526-1 jRUVEn ixKSmm postImgCont bigContainer position-relative radius-8 overflowHidden marginEnd-16px ${(isMobile ? 'ixKSmm' : '')}`} >
                        <Skeleton style={{maxWidth : "100%",objectFit: "cover" ,borderRadius : "10px",padding:"0",height: "100%",width:"100%"}} />
                    </div>
                    <div className="sc-265a2526-3 zoIKj postDet bigContainer flex-1 flex flexSpaceBetween flexDirectionColumn">
                        <div className="flex flexSpaceBetween mt-1">
                            <h2 className="font-20 breakWord bigContainer overflowHidden  fs-sm-4"><Skeleton className="w-300px w-lg-700px"/></h2>
                        </div>
                        <div className="fs-16 bigContainer fs-sm-4"><Skeleton className="w-200px w-lg-600px"/></div>
                        <div className="flex alignItems bigContainer ">
                            <div className=" fs-16 category fs-sm-4"><Skeleton className="w-100px w-lg-500px"/></div>
                        </div>
                    </div>
                </div>
                <div className="flex alignItems ps-4 pe-4 pt-2 pb-2">
                    <div className="pe-1  bd-highlight">
                        <Skeleton className="w-30px w-lg-65px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-70px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-70px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-50px" />
                    </div>
                    <div className="postPrice ms-auto bold alignSelfCenter fs-2"><Skeleton  className="w-30px w-lg-100px"/></div>
                </div>
                <div className="flex alignItems ps-4 pe-4 pt-2 pb-2" style={{paddingTop:"5px"}}>
                    <div className="pe-1  bd-highlight">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                    <div className=" bd-highlight ms-2 text-end">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                </div>
            </div>
            <div className="mb-32  position-relative customShadow bigContainer mt-3">
                <div  className="flex flexNoWrap ps-4 pe-4 pt-4 pb-2 blackColor radius-8 bigContainer ripple boxShadow2 relative" >
                    <div className={`sc-265a2526-1 jRUVEn ixKSmm postImgCont bigContainer position-relative radius-8 overflowHidden marginEnd-16px ${(isMobile ? 'ixKSmm' : '')}`} >
                        <Skeleton style={{maxWidth : "100%",objectFit: "cover" ,borderRadius : "10px",padding:"0",height: "100%",width:"100%"}} />
                    </div>
                    <div className="sc-265a2526-3 zoIKj postDet bigContainer flex-1 flex flexSpaceBetween flexDirectionColumn">
                        <div className="flex flexSpaceBetween mt-1">
                            <h2 className="font-20 breakWord bigContainer overflowHidden  fs-sm-4"><Skeleton className="w-300px w-lg-700px"/></h2>
                        </div>
                        <div className="fs-16 bigContainer fs-sm-4"><Skeleton className="w-200px w-lg-600px"/></div>
                        <div className="flex alignItems bigContainer ">
                            <div className=" fs-16 category fs-sm-4"><Skeleton className="w-100px w-lg-500px"/></div>
                        </div>
                    </div>
                </div>
                <div className="flex alignItems ps-4 pe-4 pt-2 pb-2">
                    <div className="pe-1  bd-highlight">
                        <Skeleton className="w-30px w-lg-65px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-70px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-70px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-50px" />
                    </div>
                    <div className="postPrice ms-auto bold alignSelfCenter fs-2"><Skeleton  className="w-30px w-lg-100px"/></div>
                </div>
                <div className="flex alignItems ps-4 pe-4 pt-2 pb-2" style={{paddingTop:"5px"}}>
                    <div className="pe-1  bd-highlight">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                    <div className=" bd-highlight ms-2 text-end">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                </div>
            </div>
            <div className="mb-32  position-relative customShadow bigContainer mt-3">
                <div  className="flex flexNoWrap ps-4 pe-4 pt-4 pb-2 blackColor radius-8 bigContainer ripple boxShadow2 relative" >
                    <div className={`sc-265a2526-1 jRUVEn ixKSmm postImgCont bigContainer position-relative radius-8 overflowHidden marginEnd-16px ${(isMobile ? 'ixKSmm' : '')}`} >
                        <Skeleton style={{maxWidth : "100%",objectFit: "cover" ,borderRadius : "10px",padding:"0",height: "100%",width:"100%"}} />
                    </div>
                    <div className="sc-265a2526-3 zoIKj postDet bigContainer flex-1 flex flexSpaceBetween flexDirectionColumn">
                        <div className="flex flexSpaceBetween mt-1">
                            <h2 className="font-20 breakWord bigContainer overflowHidden  fs-sm-4"><Skeleton className="w-300px w-lg-700px"/></h2>
                        </div>
                        <div className="fs-16 bigContainer fs-sm-4"><Skeleton className="w-200px w-lg-600px"/></div>
                        <div className="flex alignItems bigContainer ">
                            <div className=" fs-16 category fs-sm-4"><Skeleton className="w-100px w-lg-500px"/></div>
                        </div>
                    </div>
                </div>
                <div className="flex alignItems ps-4 pe-4 pt-2 pb-2">
                    <div className="pe-1  bd-highlight">
                        <Skeleton className="w-30px w-lg-65px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-70px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-70px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-50px" />
                    </div>
                    <div className="postPrice ms-auto bold alignSelfCenter fs-2"><Skeleton  className="w-30px w-lg-100px"/></div>
                </div>
                <div className="flex alignItems ps-4 pe-4 pt-2 pb-2" style={{paddingTop:"5px"}}>
                    <div className="pe-1  bd-highlight">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                    <div className=" bd-highlight ms-2 text-end">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                </div>
            </div>
            <div className="mb-32  position-relative customShadow bigContainer mt-3">
                <div  className="flex flexNoWrap ps-4 pe-4 pt-4 pb-2 blackColor radius-8 bigContainer ripple boxShadow2 relative" >
                    <div className={`sc-265a2526-1 jRUVEn ixKSmm postImgCont bigContainer position-relative radius-8 overflowHidden marginEnd-16px ${(isMobile ? 'ixKSmm' : '')}`} >
                        <Skeleton style={{maxWidth : "100%",objectFit: "cover" ,borderRadius : "10px",padding:"0",height: "100%",width:"100%"}} />
                    </div>
                    <div className="sc-265a2526-3 zoIKj postDet bigContainer flex-1 flex flexSpaceBetween flexDirectionColumn">
                        <div className="flex flexSpaceBetween mt-1">
                            <h2 className="font-20 breakWord bigContainer overflowHidden  fs-sm-4"><Skeleton className="w-300px w-lg-700px"/></h2>
                        </div>
                        <div className="fs-16 bigContainer fs-sm-4"><Skeleton className="w-200px w-lg-600px"/></div>
                        <div className="flex alignItems bigContainer ">
                            <div className=" fs-16 category fs-sm-4"><Skeleton className="w-100px w-lg-500px"/></div>
                        </div>
                    </div>
                </div>
                <div className="flex alignItems ps-4 pe-4 pt-2 pb-2">
                    <div className="pe-1  bd-highlight">
                        <Skeleton className="w-30px w-lg-65px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-70px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-70px" />
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-50px" />
                    </div>
                    <div className="postPrice ms-auto bold alignSelfCenter fs-2"><Skeleton  className="w-30px w-lg-100px"/></div>
                </div>
                <div className="flex alignItems ps-4 pe-4 pt-2 pb-2" style={{paddingTop:"5px"}}>
                    <div className="pe-1  bd-highlight">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                    <div className="ms-1 bd-highlight">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                    <div className=" bd-highlight ms-2 text-end">
                        <Skeleton className="w-30px w-lg-200px"/>
                    </div>
                </div>
            </div>
        </>}
        
    </>
  );
};

export {CellLoader}