import Api from './api'
import {AxiosResponse} from "axios";
import DynamicObject from "../models/dynamic-object";

export default (config?: DynamicObject): Promise<AxiosResponse> => {

    const data = new FormData()
    data.append("BundleUser[id]", config?.data.id);

    for (const post of config?.data.posts) {
        data.append("BundleUser[postId][]", post)
    }
    const path = '/bundle-user/purchase'
    return Api.getInstance().request.post(`${path}`, data, path)
}