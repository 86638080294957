import { CountriesInterface, DocsInterface, ErrorMessages, IUpdatePassword, updatePassword } from '../../interfaces/MyProfileInterface'
import {UploadProfileImage} from '../../../app/layout/components/modals/UploadProfileImage'
import uploadProfileImageApi from '../../../api/profile/uploadProfileImage.api'
import {UploadMedia} from '../../../app/layout/components/modals/UploadMedia'
import getMemberProfileApi from '../../../api/profile/getMemberProfile.api'
import {Button} from '../../../app/layout/components/opensooq/Button'
import {ReactTooltipContainer} from '../common/ReactTooltipContainer'
import getCountriesApi from '../../../api/countries/getCountries.api'
import {ProfileDetailsLoader} from './profile/ProfileDetailsLoader'
import {ProfileInterface} from '../../interfaces/ProfileInterface'
import {showError, showSuccess} from '../../helper/SystemAlert'
import editProfileApi from '../../../api/profile/editProfile.api'
import {useLang} from '../../../app/i18n/OpenSooqi18n'
import {toAbsoluteUrl} from '../../../app/helpers'
import removeMediaApi from '../../../api/removeMedia.api'
import {ChangePhone} from '../../modales/ChangePhone'
import commercialApi from '../../../api/commercial.api'
import uploadIDApi from '../../../api/uploadID.api'
import loginApi from '../../../api/login.api'
import {useEffect, useState} from 'react'
import 'react-phone-number-input/style.css'
import {Shop} from './profile/Shop'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import Commercial from './profile/commercial/Commercial'
import FsLightbox from 'fslightbox-react'
import DynamicObject from '../../../models/dynamic-object'
import { defaultOptions, deleteCookie, setCookie } from '../../helpers/commonFunctions'
import { MEMBER_INFO_COOKIE } from '../../helpers/CookiesVariables'


const MyProfile = () => {
  const defaultProfileImage = "https://opensooqui-new.s3.amazonaws.com/api/v/icons/misc/profile_avatar.png";
  const [currentMemberPhoneCountry, setCurrentMemberPhoneCountry] = useState<CountriesInterface>()
  const [passwordUpdateData, setPasswordUpdateData] = useState<IUpdatePassword>(updatePassword)
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({firstName: '', email: ''})
  const [countriesList, setCountriesList] = useState<CountriesInterface[]>([])
  const [profileImageModal, setProfileImageModal] = useState(false)
  const [showPasswordForm, setPasswordForm] = useState<boolean>(false)
  const [openEditPhone, setOpenEditPhone] = useState<boolean>(false)
  const [profile, setProfile] = useState<ProfileInterface>()
  const [profileLoading, setProfileLoading] = useState(true)
  const cdn = process.env.REACT_APP_ARBOK_UPLOAD
  const [docs, setDocs] = useState<DocsInterface[]>([])
  const [profileImageLoader, setProfileImageLoader] = useState<boolean>(false);
  const [commercilFileLoader, setCommercialFileLoader] = useState<boolean>(false);
  const [show, setShow] = useState(false)
  const [files, setFiles] = useState([])
  const [phone, setPhone] = useState('')
  const Intl = useIntl()
  const lang = useLang()
  const [showImages, setShowImages] = useState(false)
  const [imagesLinks, setImagesLinks] = useState <string[]>([])
  const imgRegex = /\.(jpg|jpeg|png|webp|avif|gif|svg)$/;
  
  const handleError = (error : any) =>{
    if(error && error.data){
      showError(Intl, lang, error.data);
    }else{
      showError(Intl, lang)
    }
  }

  useEffect(() => {
    fetchProfile()
  }, [])

  function getCountriesList(profile: ProfileInterface) {
    getCountriesApi().then((response) => {
      const countries = response.data
      setCountriesList(countries)
      const memberCountryFlag = response.data.filter((country: CountriesInterface) => {
        return country.phone_code.toString() == profile.phone_country_code
      })
      setCurrentMemberPhoneCountry(memberCountryFlag[0])
      setProfileLoading(false)
    })
  }

  function fetchProfile(storeInCookies : boolean = false) {
    getMemberProfileApi().then((response) => {
      const results = response.data
      //setShowContact(results.hide_contact_info);
      setPhone(results.phone_number)
      setProfile(results)
      getCountriesList(results)
      getDocuments()
      if(storeInCookies){
        deleteCookie(MEMBER_INFO_COOKIE);
        setCookie("userInfo",encodeURIComponent(JSON.stringify(results)), defaultOptions);
      }
    })
  }

  const passwordFormValidationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .min(3, Intl.formatMessage({id : "myProfile.profileDetails.text37"}))
      .max(50, Intl.formatMessage({id : "myProfile.profileDetails.text36"}))
      .required(Intl.formatMessage({id : "myProfile.profileDetails.text35"})),
      newPassword: Yup.string()
      .required(Intl.formatMessage({id : "myProfile.profileDetails.text35"})),
      passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], Intl.formatMessage({id : "myProfile.profileDetails.text34"}))
      .required(Intl.formatMessage({id : "myProfile.profileDetails.text35"}))
  })

  const formik2 = useFormik<IUpdatePassword>({
    initialValues: {
      ...passwordUpdateData,
    },
    validationSchema: passwordFormValidationSchema,
    onSubmit: (values) => {
      setTimeout((values: any) => {
        setPasswordUpdateData(values)
        setPasswordForm(false)
      }, 1000)
    },
  })

  const updateFirstName = (event: any) => {
    if (event.target.value.length < 1) {
      setErrorMessages((old: any) => {
        return {...old, firstName: Intl.formatMessage({id: 'myProfile.profileDetails.text28'})}
      })
    } else {
      setErrorMessages((old: any) => {
        return {...old, firstName: ''}
      })
    }
    setProfile((old: any) => {
      return {
        ...old,
        first_name: event.target.value,
      }
    })
  }

  const updateLastName = (event: any) => {
    setProfile((old: any) => {
      return {
        ...old,
        last_name: event.target.value,
      }
    })
  }

  const updateHideContactInfo = (event: any) => {
    setProfile((old: any) => {
      return {
        ...old,
        hide_contact_info: !old.hide_contact_info,
      }
    })
  }

  const updateEmail = (event: any) => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    if (event.target.value.length > 0 && !regex.test(event.target.value)) {
      setErrorMessages((old: any) => {
        return {...old, email: Intl.formatMessage({id: 'myProfile.profileDetails.text33'})}
      })
    } else {
      setErrorMessages((old: any) => {
        return {...old, email: ''}
      })
    }
    setProfile((old: any) => {
      return {
        ...old,
        M_email: event.target.value,
        M_new_email: event.target.value,
      }
    })
  }

  const saveChanges = () => {
    if (errorMessages.firstName !== '' || errorMessages.email !== '') {
      return
    }
    Swal.fire({
      icon: 'warning',
      html: `<div className="pe-10 ps-10 fw-bolder">${Intl.formatMessage({id : "editProfileConfirmationMessage"})}</div>`,
      buttonsStyling: false,
      showCancelButton: true,
      cancelButtonText: Intl.formatMessage({id : "no"}),
      confirmButtonText: Intl.formatMessage({id : "yes"}),
      allowOutsideClick: () => !Swal.isLoading(),
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-light-danger',
      },
    }).then((result) => {
      const data = {
        Member: {
          M_fisrt_name: profile ? profile.first_name : '',
          M_last_name: profile ? profile.last_name : '',
          M_new_email: profile ? profile.M_new_email : '',
          newPassword: formik2.values.newPassword,
          M_user_name: profile ? profile.M_user_name : '',
          hide_contact_info: profile?.hide_contact_info,
        },
      }
      if (result.isConfirmed) {
       editProfileHandler(data);
      }
    })
  }

  const editProfileHandler = (data :DynamicObject) => {
    editProfileApi(data).then((response) => {
      if (response && response.status) {
        // if (response.status != 205) {
          fetchProfile()
          showSuccess(Intl, lang)
        // } else {
          // should we have this ? TODO: talk with adey
          // let data = {
          //   LoginModel: {
          //     username: profile ? profile.international_phone_number : '',
          //     password: formik2.values.newPassword,
          //   },
          // }
          // loginApi({
          //   data,
          // })
          //   .then((responseData) => {
          //     // Cookies.set(MEMBER_INFO_COOKIE, JSON.stringify(responseData.data.member)) // rename to memberInfo @TODO
          //     // Cookies.set(TGT_TICKET_COOKIE, responseData.data.ticket[1])
          //     // Cookies.set(TGT_TIMESTAMP_COOKIE, responseData.data.ticket[0])
          //   })
          //   .then(fetchProfile)
          //   .then(() => {
          //     showSuccess(Intl, lang)
          //   })
          //   .catch((error) => {
          //     handleError(error);
          //   })
        }
      // }else{
      //  handleError(response)
      // }
    }).catch((error) => {
      handleError(error.response)
    })
  }

  const discardChanges = () => {
    fetchProfile()
  }

  const getDocuments = () => {
    commercialApi()
      .then((response) => {
        setDocs(response.data.items)
      })
      .catch((error) => {
        handleError(error.response);
      })
  }

  const showCommercialFileModal = () => {
    setShow(true)
  }

  const hideCommercialFileModal = () => {
    setShow(false)
    setCommercialFileLoader(false);
    setFiles([])
  }

  const hideProfileImageModal = () => {
    setProfileImageLoader(false);
    setProfileImageModal(false)
  }

  const uploadCommercialFile = (files: any) => {
    setCommercialFileLoader(true);
    const file = files[0].file
    setFiles(files)
    const formData = new FormData()
    formData.append('MemberMedia[img_file]', file)
    uploadIDApi(formData)
      .then((response) => {
        if (response.status === 201) {
          setDocs((prev) => {
            return [...prev, response.data]
          })
          showSuccess(Intl, lang)
          hideCommercialFileModal();
        } else {
          throw new Error();
        }
      })
      .catch((error) => {
        setCommercialFileLoader(false);
        handleError(error.response);
      })
  }

  const uploadProfileImage = (files: any) => {
    setProfileImageLoader(true);
    const file = files[0].file
    setFiles(files)
    const formData = new FormData()
    formData.append('Member[avatarFile]', file)
    uploadProfileImageApi(formData)
      .then((response) => {
        fetchProfile(true)
        hideProfileImageModal()
        showSuccess(Intl, lang)
      })
      .catch((error) => {
        setProfileImageLoader(false);
        handleError(error.response);
      })
    }

  const showDeletePopup = (mediaId: number) => {
    const text = Intl.formatMessage({id : "removeDocumentMessage"})
    Swal.fire({
      icon: 'warning',
      html: `<div className="pe-10 ps-10 fw-bolder">${text}</div>`,
      buttonsStyling: false,
      showCancelButton: true,
      cancelButtonText: Intl.formatMessage({id : "no"}),
      confirmButtonText: Intl.formatMessage({id : "yes"}),
      allowOutsideClick: () => !Swal.isLoading(),
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-light-danger',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMedia(mediaId)
      }
    })
  }

  const deleteMedia = (mediaId: number) => {
    removeMediaApi(mediaId)
      .then((response) => {
        if (response.status === 200) {
          getDocuments()
          showSuccess(Intl, lang)
        } else {
          showError(Intl, lang)
        }
      })
      .catch((error) => {
        handleError(error.response)
      })
  }

  const viewFile = (src: string) => {
    if(!imgRegex.test(src))
    {
      window.open(src, '_blank');
    }else{
      setImagesLinks([
        src
      ])
      setShowImages(!showImages)
    }
  }

  const docsImagesList = () => {
    return docs.map((doc: DocsInterface, index) => {
      return (
        <div className='col-md-3 col-12 col-lg-3' key={index}>
          <div className='card'>
            <div className='card-body d-flex flex-center flex-column p-9'>
              <div className='mb-5'>
                <img src={ !imgRegex.test(doc.uri) ? toAbsoluteUrl('/media/custom/pdf.svg') :  cdn + doc.uri } className='w-100' role="button" height={'150'} width={'300'} onClick={()=>viewFile(cdn + doc.uri)}/>
              </div>
              <div className='d-flex align-items-center'>
                {
                  <Button action={() =>   viewFile(cdn + doc.uri)} className='btn-primary btn-sm' text={Intl.formatMessage({id: 'view'})} />
                }
                <Button className={'btn btn-sm btn-icon btn-light-danger  me-2 ms-2'} action={() => { showDeletePopup(doc.id) }} text={ <> <i className='fas fa-trash-alt'></i> </> } />
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  const setFilesData = (files: any) => {
    setFiles(files)
  }

  const [stepEditStep, setEditStep] = useState(1)
  const changeStep = () => {
    setEditStep(2)
  }

  const closeEditPhone = () => {
    setOpenEditPhone(false)
    setEditStep(1)
  }

  return (
    <>
      {profileLoading && <ProfileDetailsLoader />}
      {!profileLoading && (
        <div className='card mb-5 mb-xl-10' data-select2-id='select2-data-134-v21y'>
          <div className='card-header'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-gray-800'>
                {Intl.formatMessage({id: 'myProfile.profileDetails.text1'})}
              </span>
            </h3>
          </div>

          <div
            id='kt_account_settings_profile_details'
            className='collapse show'
            data-select2-id='select2-data-kt_account_settings_profile_details'
          >
            <div className='card-body border-top p-9' data-select2-id='select2-data-362-ogaw'>
              <div className='row mb-6'>
                <label className='col-lg-2 col-form-label fw-semibold fs-6'>
                  {Intl.formatMessage({id: 'myProfile.profileDetails.text2'})}
                </label>
                <div className='col-lg-10'>
                  <div
                    className='image-input image-input-outline'
                    data-kt-image-input='true'
                    style={{
                      backgroundImage: `url('${toAbsoluteUrl('/media/svg/avatars/blank.svg')}')`,
                    }}
                  >
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{backgroundImage: `url(${profile?.profile_picture_large.includes('no_profile') ? defaultProfileImage : profile?.profile_picture_large})`}}
                    >
                      <label
                        onClick={() => {
                          setProfileImageModal(true)
                        }}
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      >
                        {' '}
                        <i className='fa-solid fa-pen-to-square fs-2'></i>{' '}
                      </label>
                    </div>
                    <div className='form-text'>
                      {Intl.formatMessage({id: 'myProfile.profileDetails.text2'})}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-2 col-3 col-form-label  fw-semibold fs-6'>
                  {Intl.formatMessage({id: 'myProfile.profileDetails.text3'})}
                </label>
                <div className='col-lg-10 col-9'>
                  <div className='row'>
                    <div className='col-6 fv-row fv-plugins-icon-container'>
                      <input
                        type='text'
                        onChange={(e: any) => {
                          updateFirstName(e)
                        }}
                        className='form-control form-control-lg form-control-solid '
                        placeholder={Intl.formatMessage({id: 'myProfile.profileDetails.text8'})}
                        value={(profile && profile.first_name) || ''}
                      />
                      <div className='fv-plugins-message-container invalid-feedback'>
                        {errorMessages && errorMessages?.firstName !== ''
                          ? errorMessages.firstName
                          : ''}
                      </div>
                    </div>
                    <div className='col-6 fv-row fv-plugins-icon-container'>
                      <input
                        type='text'
                        onChange={(e: any) => {
                          updateLastName(e)
                        }}
                        className='form-control form-control-lg form-control-solid'
                        placeholder={Intl.formatMessage({id: 'myProfile.profileDetails.text9'})}
                        value={(profile && profile.last_name) || ''}
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-2 col-3 col-form-label  fw-semibold fs-6'>
                  {Intl.formatMessage({id: 'myProfile.profileDetails.text4'})}
                </label>
                <div className='col-lg-10 col-9'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <input
                        type='text'
                        onChange={(e: any) => {
                          updateEmail(e)
                        }}
                        className='form-control form-control-lg form-control-solid '
                        placeholder={Intl.formatMessage({id: 'myProfile.profileDetails.text4'})}
                        value={(profile && profile.M_email) || ''}
                      />
                      <div className='fv-plugins-message-container invalid-feedback'>
                        {errorMessages && errorMessages?.email !== '' ? errorMessages.email : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-2 col-3 col-form-label  fw-semibold fs-6'>
                  {Intl.formatMessage({id: 'myProfile.profileDetails.text5'})}{' '}
                  <i id='phone' className='fas fa-exclamation-circle ms-1 fs-7'></i>
                </label>
                <div className='col-lg-10 col-9'>
                  <div className='row'>
                    <div className='col-lg-6 col-12 fv-row fv-plugins-icon-container'>
                      <div className='input-group input-group-solid mb-5'>
                        <span className='input-group-text' id='basic-addon1'>
                          <img
                            className='w-25px '
                            src={
                              profile &&
                              countriesList &&
                              currentMemberPhoneCountry &&
                              currentMemberPhoneCountry.icon
                            }
                          />
                        </span>
                        <input
                          disabled
                          type='text'
                          className='form-control'
                          placeholder={Intl.formatMessage({id: 'myProfile.profileDetails.text5'})}
                          defaultValue={phone}
                        />
                      </div>
                    </div>
                    {currentMemberPhoneCountry && (
                      <div className='col-lg-6 col-12 fv-row fv-plugins-icon-container'>
                        <button
                          onClick={() => {
                            setOpenEditPhone(true)
                          }}
                          className='btn btn-light  btn-active-light-primary'
                        >
                          {Intl.formatMessage({id: 'myProfile.profileDetails.text10'})}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-2 col-3 col-form-label  fw-semibold fs-6'>
                  {Intl.formatMessage({id: 'myProfile.profileDetails.text6'})}
                </label>
                <div className='col-lg-10 col-9'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <div className='input-group input-group-solid mb-5'>
                        <input
                          type='text'
                          disabled
                          className='form-control'
                          placeholder={Intl.formatMessage({id: 'myProfile.profileDetails.text6'})}
                          defaultValue='************'
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <button
                        onClick={() => {
                          setPasswordForm(true)
                        }}
                        className='btn btn-light btn-active-light-primary'
                      >
                        {Intl.formatMessage({id: 'myProfile.profileDetails.text11'})}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row' data-select2-id='select2-data-415-ugv0'>
                <div className='d-flex flex-wrap align-items-center mb-10'>
                  <div
                    id='kt_signin_password_edit'
                    className={'flex-row-fluid ' + (!showPasswordForm && 'd-none')}
                  >
                    <div className='row mb-1'>
                      <div className='col-lg-6'>
                        <div className='fv-row mb-0'>
                          <label htmlFor='newpassword' className='form-label fs-6 fw-bolder mb-3'>
                            {Intl.formatMessage({id: 'myProfile.profileDetails.text14'})}
                          </label>
                          <input
                            type='password'
                            className='form-control form-control-lg form-control-solid '
                            id='newpassword'
                            {...formik2.getFieldProps('newPassword')}
                          />
                          {formik2.touched.newPassword && formik2.errors.newPassword && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik2.errors.newPassword}</div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className='fv-row mb-0'>
                          <label
                            htmlFor='confirmpassword'
                            className='form-label fs-6 fw-bolder mb-3'
                          >
                            {Intl.formatMessage({id: 'myProfile.profileDetails.text15'})}
                          </label>
                          <input
                            type='password'
                            className='form-control form-control-lg form-control-solid '
                            id='confirmpassword'
                            {...formik2.getFieldProps('passwordConfirmation')}
                          />
                          {formik2.touched.passwordConfirmation &&
                            formik2.errors.passwordConfirmation && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  {formik2.errors.passwordConfirmation}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className='d-flex'>
                      <button
                        onClick={() => {
                          setPasswordForm(false)
                          formik2.resetForm();
                        }}
                        id='kt_password_cancel'
                        type='button'
                        className='btn btn-light btn-color-gray-400 btn-active-light-primary px-6'
                      >
                        {Intl.formatMessage({id: 'cancel'})}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <div className='d-flex flex-stack'>
                  <div className='d-flex'>
                    <div className='d-flex flex-column'>
                      <a href='#' className='fs-5 text-dark text-hover-primary fw-bolder'>
                        {Intl.formatMessage({id: 'myProfile.profileDetails.text12'})}
                      </a>
                      <div className='fs-6 fw-bold text-gray-400'>
                        {Intl.formatMessage({id: 'myProfile.profileDetails.text13'})}
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <div className='form-check form-switch form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        defaultChecked={profile && profile.hide_contact_info}
                        id='flexSwitchDefault'
                        onChange={(e: any) => {
                          updateHideContactInfo(e)
                        }}
                      />
                      <label className='form-check-label' htmlFor={'flexSwitchDefault'}>
                        {' '}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <Button
                text={Intl.formatMessage({id: 'myProfile.profileDetails.text26'})}
                className={'btn-light btn-active-light-primary me-2'}
                action={discardChanges}
              />
              <Button
                text={Intl.formatMessage({id: 'myProfile.profileDetails.text25'})}
                className={'btn-primary'}
                disabled={ errorMessages.firstName !== '' || ( errorMessages.email !== '') || formik2.errors.newPassword !== undefined || formik2.errors.passwordConfirmation !== undefined }
                action={saveChanges}
              />
            </div>
          </div>
        </div>
      )}
      <Commercial showFileModal={showCommercialFileModal} docsImagesList={docsImagesList}/>
      <FsLightbox toggler={!showImages} sources={imagesLinks} type="image"/>
      <Shop isShop={profile?.isShop} lang={lang} profile={profile}/>
      <ChangePhone changeEditStep={changeStep}step={stepEditStep}handleCloseEditPhone={closeEditPhone}show={openEditPhone}countriesList={countriesList}phone={phone}currentCountry={currentMemberPhoneCountry}/>
      <UploadMedia loader={commercilFileLoader} uploadFile={uploadCommercialFile} setFilesData={setFilesData} files={files} show={show} handleClose={hideCommercialFileModal} />
      <ReactTooltipContainer />
      <UploadProfileImage loader={profileImageLoader} uploadFile={uploadProfileImage} show={profileImageModal} handleClose={hideProfileImageModal} />
    </>
  )
}

export {MyProfile}
