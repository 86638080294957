import  {FC, useEffect} from 'react'
import { useIntl } from 'react-intl'
import {Navigate, Route, Routes, useLocation} from 'react-router-dom'
import { PageLink, PageTitle } from '../../app/layout/core';
import { CarfaxReport } from '../modules/services/CarfaxReport';

const history:string[] = [];
const ServicesRoutes: FC = () => {
    const Intl = useIntl()
    const location = useLocation()

    useEffect(()=>{
      if(history.length == 2)
        history.shift();
      history.push(location.pathname)
    }, [location]);

    const services: Array<PageLink> = [
        {
          title: Intl.formatMessage({id: 'services'}),
          path: '',
          isSeparator: false,
          isActive: false,
        }
    ]
    return (
        <Routes>
            <Route path='/*' element={<Navigate to='/error/404' state= {{ 'currentPath': window.location.pathname, 'previousPath' : history.length < 1 ? '' : history[history.length - 1] }}/>} />
            <Route path='carfax-report' element={ <> <PageTitle breadcrumbs={services}>{Intl.formatMessage({id: 'carfaxReport'})}</PageTitle> <CarfaxReport /> </> } />           
        </Routes>
    )
}

export default ServicesRoutes
