import { toAbsoluteUrl } from "../../../app/helpers";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../app/layout/components/opensooq/Button";
import { useLang } from "../../../app/i18n/OpenSooqi18n";
import { useIntl } from "react-intl";
import Cookies from "js-cookie";
import { MEMBER_COUNTRY_COOKIE, MEMBER_INFO_COOKIE } from "../../helpers/CookiesVariables";
import { BundleModal } from "../payment/BundleModal";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { getCookies } from "../../helpers/commonFunctions";

export function Product() {
    let navigate = useNavigate(); 
    const lang = useLang();
    const Intl = useIntl();
    const [showBundle,setShowBundle] = useState (false);
    const cookiesData = getCookies();
    const memberInfo = cookiesData.userInfo
    const adv = (lang === 'ar') ? 'الخدمات-الاعلانية' : 'advertising-products'
    const openSooqUrl =  (process.env.REACT_APP_OPENSOOQ_BASE ?? '').replace("{country}", cookiesData.ecountry ?? '').replace("{lang}", lang ?? '');
    const serviceUrl = (lang === 'ar') ? 'كارفاكس' : 'carfax'
    const carFaxPage =  openSooqUrl + "/"+ serviceUrl;
    return (
        <div className="card ">
            <div className="card-header py-4">
                <div className="card-title align-items-start flex-column">
                    <h2>{Intl.formatMessage({id : "products.title"})}</h2>
                    <div className="d-flex flex-wrap fw-semibold  fs-5 text-gray-400">
                    {Intl.formatMessage({id : "products.description"})}
                    </div>
                </div>
            </div>
            <div className="card-body p-lg-12">
                <div className="row mb-5">
                    <div className="card border-transparent " data-theme="light" style={{backgroundColor: "#1C325E"}}>
                        <div className="card-body d-flex ps-xl-15">
                            <div className="m-0">
                                <div className="position-relative fs-2x z-index-2 fw-bold text-white mb-7">
                                    <span className="position-relative d-inline-block text-danger">
                                        <span onClick={() => { navigate(`/services/carfax-report `)}} className="hoverPointer text-danger opacity-75-hover">{Intl.formatMessage({id : "products.carFax"})}</span>
                                        <span className="position-absolute opacity-50 bottom-0 start-0 border-4 border-danger border-bottom w-100"></span>
                                    </span>
                                    <br/>
                                    <span className="me-2">{Intl.formatMessage({id : "products.carFax.section1"})}</span>
                                    <br/>
                                    {Intl.formatMessage({id : "products.carFax.section2"})}
                                </div>
                                <div className="mb-3">
                                    <a href={carFaxPage} className="btn btn-danger fw-semibold me-2" >
                                        {Intl.formatMessage({id : "common.buyNow"})}
                                    </a>
                                    <Button action={() => { navigate(`/services/carfax-report `)}} text={Intl.formatMessage({id : "common.viewPurchasedReports"})} className="btn btn-color-white bg-white bg-opacity-15 bg-hover-opacity-25 fw-semibold"/>
                                </div>
                            </div>
                            <img src={toAbsoluteUrl('/media/custom/carfax.png')} className={`position-absolute bottom-0 end-0 ${isMobile ? 'w-25' : 'w-200px'}`} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="card border-transparent " data-theme="light" style={{backgroundColor: "#1C325E"}}>
                        <div className="card-body d-flex ps-xl-15">
                            <div className="m-0">
                                <div className="position-relative fs-2x z-index-2 fw-bold text-white mb-7">
                                    <span className="position-relative d-inline-block text-danger">
                                        <span className="text-danger opacity-75-hover">{Intl.formatMessage({id : "products.openSooqBuyer"})}</span>
                                        <span  className="position-absolute opacity-50 bottom-0 start-0 border-4 border-danger border-bottom w-100"></span>
                                    </span>
                                    <br/>
                                    <span className="me-2">{Intl.formatMessage({id : "products.openSooqBuyer.section1"})}</span>
                                    <br/>{Intl.formatMessage({id : "products.openSooqBuyer.section2"})}
                                </div>
                                <div className="mb-3">
                                    <button onClick={() => {setShowBundle(true)}}className="btn btn-danger fw-semibold me-2">{Intl.formatMessage({id : "common.buyNow"})}</button>
                                </div>
                            </div>
                            <img src={toAbsoluteUrl('/media/custom/promotional.svg')}  className={`position-absolute bottom-0 end-0 ${isMobile ? 'w-25' : 'w-200px'}`} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="card border-transparent " data-theme="light" style={{backgroundColor: "#1C325E"}}>
                        <div className="card-body d-flex ps-xl-15">
                            <div className="m-0">
                                <div className="position-relative fs-2x z-index-2 fw-bold text-white mb-7">
                                    <span className="position-relative d-inline-block text-danger">
                                        <a href={`${openSooqUrl}/${adv}`}  className="text-danger opacity-75-hover">{Intl.formatMessage({id : "products.ValueService"})}</a>
                                        <span  className="position-absolute opacity-50 bottom-0 start-0 border-4 border-danger border-bottom w-100"></span>
                                    </span>
                                    <br/>
                                    <span className="me-2">{Intl.formatMessage({id : "products.ValueService.section1"})}</span>
                                    <br/>
                                    {Intl.formatMessage({id : "products.ValueService.section2"})}
                                </div>
                                <div className="mb-3">
                                    <a href={`${openSooqUrl}/${adv}`} className="btn btn-danger fw-semibold me-2">{Intl.formatMessage({id : "common.viewNow"})}</a>
                                </div>
                            </div>
                            <img src={toAbsoluteUrl('/media/custom/os-products.svg')} className={`position-absolute bottom-0 end-0 ${isMobile ? 'w-25' : 'w-200px'}`} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <BundleModal  show={showBundle}  type={['ProBuyer']} handleClose={() => {setShowBundle(false)}} id={memberInfo.id} pos={'products'}/>
        </div>
    )
}