import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import getListingApplicantApi from "../../../api/listing/getListingApplicant.api";
import { SearchComponent } from "../../assets/ts/components";
import { useLang } from "../../../app/i18n/OpenSooqi18n";
import { CellLoader } from "../../../app/layout/components/opensooq/CellLoader";
import { LazyLoader } from "../../../app/layout/components/opensooq/LazyLoader";
import { ApplicantInterface } from "../../interfaces/ApplicantInterface";
import { IframeLoader } from "../common/IframeLoader";
import { ListingInfo } from "../common/ListingInfo";
import { MyListingViewInterface } from "../../interfaces/MyListingViewInterface";
import getListingApi from "../../../api/listing/get.listing.api";


const  Applicants = () => { 
    const listingId = parseInt(new URLSearchParams(window.location.search).get('id') ?? '0');
    const [loader,setLoader] = useState<boolean>(true);
    const [listing,setListing] = useState<MyListingViewInterface>()

    const [applicants,setApplicants] = useState<ApplicantInterface[]>([])
    const [searchData,setSearchData] = useState<ApplicantInterface[]>([])

    const lang = useLang();
    const Intl = useIntl();
    
    useEffect(() => {
        getListingApi(listingId).then((response) => {
            setListing(response.data);
        })

        getListingApplicantApi(listingId).then((response) => {
            setApplicants(response.data.result.data.items)
            setSearchData(response.data.result.data.items)
            setLoader(false);
        })
    },[])

    const paginate = () => {
        // getSavedSearch();
    };

    const refresh =()=>{

    }

    const List:React.FC<{searchData:ApplicantInterface[]}> = ({searchData}) => {
        return <React.Fragment key={'favoriteListings'}>
            {searchData.map((applicant  : ApplicantInterface,index) => {
            return <div className="col-lg-4 ps-4 pe-4 text-center col-4 col-md-4  radius-8">
                <div className="card h-100 shadow-lg">
                    <div className="card-body d-flex flex-center flex-column py-9 px-5">
                        <div className="symbol symbol-65px  mb-5">
                            <img src={'https://arbok.sooqdev2.com/previews/300x300/6c/aa/6caa4a3e64ad96ae3227aadf09f7d099f8b033271dcf9a8315eccf8aac001386.webp.jpg'} alt="image"/>
                        </div>
                        <a href="#" className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">{applicant.card.name}</a>
                        <div className="fw-semibold text-gray-400 mb-6 text-center">
                            {applicant.card.location} <br/>
                            {applicant.card.currentWork}
                        </div>
                        <div className="d-flex flex-center flex-wrap mb-5">
                            <div className="border border-dashed rounded min-w-90px py-3 px-4 mx-2 mb-3">
                                <div className="fs-6 fw-bold text-gray-700">
                                 {applicant.card.tags.map((item,index) => {
                                    return  <span key={index} className="badge badge-light me-3 mt-1">{item}</span>
                                   })}
                                </div>
                            </div>
                        </div>
                        {/* <button className="btn btn-sm btn-light-primary" data-kt-follow-btn="true">
                            <span className="svg-icon following svg-icon-3"><svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3"
                                        d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z"
                                        fill="currentColor"></path>
                                    <path
                                        d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z"
                                        fill="currentColor"></path>
                                </svg>
                            </span>
                            <span className="svg-icon follow svg-icon-3 d-none"><svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                        transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                                </svg></span>
                            <span className="indicator-label">
                                Following
                                </span>
    
                            <span className="indicator-progress">
                                Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button> */}
                    </div>
                </div>
            </div>
        })}
        </React.Fragment>  
    };

    return (
        <>
            <ListingInfo refresh={() => {refresh()}} listing={listing}/>
            <div className="card mt-5">
                <div className="card-header">
                    {/* <SearchComponent action={setSearch}   placeholder={`${Intl.formatMessage({id: 'search'})} ${Intl.formatMessage({id : "menu.listing.recentSearch"})}`} /> */}
                </div>
                <div className="card-body">
                    {loader && <>
                        <CellLoader />
                    </>}
                    {!loader && searchData && <LazyLoader list={<div className="row g-5"><List searchData={searchData}/></div>} data={searchData} endMessage={<></>} loader={<CellLoader/>} paginate={paginate} isLastPage={true}/>}
                </div>
            </div>
        </>
    )
}


export {Applicants}