import {useState, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import { useIntl } from "react-intl"
import { KTSVG } from '../../../helpers'
import Select from 'react-select'
import DynamicObject from '../../../../models/dynamic-object'
import deleteListingApi from '../../../../api/listing/deleteListing.api'
import { showError, showSuccess } from '../../../helper/SystemAlert'
import { useLang } from '../../../i18n/OpenSooqi18n'
import { Loader } from '../opensooq/Loader'

type Props = {
  show: boolean
  handleClose: () => void
  id ?: number | 0
  type?:string | ''
  deleteListingFromSearchData? : (id : number)=>void
  reasons : DynamicObject
}
type SelectFieldType = {
    label : string
    key : number | undefined
    reasons : DynamicObject[]
}
type SelectFieldValueType = {
    id: number
    value : string
}
const DeactivateListModal = ({show,handleClose,id,type,reasons, deleteListingFromSearchData}: Props) => {
    const [selectFields, setSelectFields] = useState<SelectFieldType[] | []>([]);
    const [lastStep,setLastStep] = useState<boolean>(false)
    const[isOther, setIsOther] = useState<boolean>(false)
    const [note,setNote] = useState<string>('')
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const Intl = useIntl();
    const lang = useLang();
    useEffect(() => {
        setSelectFields([{key: reasons.id, label:reasons.title, reasons:reasons.reasons}]);
    },[reasons])
    const handleValueChange = (e:any, reasons:DynamicObject[], position:number)=>{
        const index = reasons.findIndex(reason => reason.id+"" == e.value);
        if(index == -1)
            return;
        setSelectFields((old:SelectFieldType[]) => {
            let newSelectedField = old.slice(0, position+1);
            if(reasons[index].child){
                if(reasons[index].child.title.toLowerCase() === "other" || reasons[index].child.title.toLowerCase() === "أخرى")
                {
                    setLastStep(true);
                    setIsOther(true);
                }else if(lastStep == true)
                {
                    setLastStep(false);
                    setIsOther(false);
                }
                newSelectedField.push({key:reasons[index].child.id, label: (reasons[index].child.title.toLowerCase() !== "other" || reasons[index].child.title.toLowerCase() === "أخرى") ?  reasons[index].child.title : reasons[index].child.description, reasons:reasons[index].child.reasons});
            }
            else {
                setLastStep(true);
                setIsOther(false);
                newSelectedField.push({key:reasons[index].id, label:reasons[index].description, reasons:[]})
            }
            return newSelectedField;
        });
    };
    const deleteListingApiAction= () => {
                setIsDeleting(true);
                const queryParams = new URLSearchParams();
                queryParams.append("PostDeleteReason[delete_reason_id]", selectFields == undefined ? '0' : selectFields[selectFields.length-1].key+'');
                queryParams.append("PostDeleteReason[user_input]",note);
                const queryString = queryParams.toString();
                deleteListingApi(id ?? 0,queryString).then((response) => {
                    showSuccess(Intl,lang);
                    if(deleteListingFromSearchData)
                        deleteListingFromSearchData(id ?? 0);
                    setTimeout(() => {
                        if(!deleteListingFromSearchData)
                            window.location.reload()
                        setIsDeleting(false);
                    }, 3000);
                handleClose();
                }).catch((error) => {
                    showError(Intl,lang);
                })}

    const closeDeleteModal = ()=>{
        setLastStep(false);
        handleClose();
    }


    useEffect(() => {
        setNote(note.trimStart())
    },[note])
return <>

        <Modal dialogClassName='modal-dialog  modal-dialog-centered' show={show} onHide={closeDeleteModal}  >
            <div className='modal-header p-5'>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={closeDeleteModal}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>
            <div className='modal-body py-lg-10 px-lg-10'>
                {
                    selectFields.map((_selectedField, _index) => {
                        if(_selectedField.label == undefined || _selectedField.key == undefined)
                            return <div key={_index}></div>;
                        let options :any[] = [];
                        if(_selectedField.reasons && _selectedField.reasons.length > 0){
                            _selectedField.reasons.map((reason:DynamicObject) => { options.push({label : reason.label, value: reason.id})});
                            return <div key={_index}>
                                <h1>{_selectedField.label}</h1>
                                <Select className="mt-3 mb-3 fw-bold" onChange={(e:any) =>{handleValueChange(e,_selectedField.reasons, _index )}} placeholder={_selectedField.label} key={_selectedField.key} options={options}></Select>
                            </div>
                        }else {
                            return <textarea key={_index} value={note} placeholder={_selectedField.label} onChange={(e : any) => {setNote(e.target.value)}} className='form-control mt-3'></textarea>
                        }
                    })
                }
                {  lastStep && (isDeleting ? Loader():<button disabled={isDeleting || ( isOther && note.length < 1)} onClick={() => {deleteListingApiAction()}} className='btn btn-primary mt-5 w-100'>{Intl.formatMessage({id : "common.delete"})}</button> )}
            </div>
        </Modal>
  </>
}
export {DeactivateListModal}