import { useIntl } from "react-intl"
import DynamicObject from "../../../../models/dynamic-object"
import { getWidgetByType, ListingWidgetTypes } from "../../listing/my-listings/helper"

type Props = {
    listing : DynamicObject
}

const Header = ({listing} : Props) => {
    const Intl = useIntl();
    const statusType = getWidgetByType(listing.widgets,ListingWidgetTypes.STATUS);
    return <><div className="card-title align-items-start flex-column">
        <div className="d-flex align-items-center ">
            <span className={`bullet bullet-vertical h-30px bg-${(statusType && statusType.color == 'GREEN') ? 'success' : 'danger'}`}></span>
            <div className="d-flex justify-content-start flex-column ms-3">
                <a href="#" className="text-dark fw-bold text-hover-primary fs-5">
                    <div className="flex-grow-1 ">
                        {<span className={`customPostTitleText fw-bolder`}>
                            <span className={`${(statusType && statusType.color == 'GREEN') ? 'text-success' : 'text-danger'}`}>{statusType.text}</span>
                        </span>}
                    </div>
                </a>
                {listing.rejection_label && <span className="text-danger fw-bold d-block fs-7">{listing.rejection_label}</span>}
            </div>
        </div>
    </div>
    <div className="card-toolbar card-title">
        {listing.listing_id && <div className=" customPostTitleText  align-items-start flex-column">
            <span className="card-label text-gray-800">{Intl.formatMessage({id : "myListingView.text1"})}: {listing.listing_id}</span>
        </div>}
    </div></>
}

export default Header