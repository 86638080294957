import DynamicObject from "../../models/dynamic-object";
import { AxiosResponse} from "axios";
import Api from "../api";

export default (url : string,config?: DynamicObject): Promise<AxiosResponse> => {
    let newUrl = url.replace(/%2C/g,",")
    const fullUrl = newUrl.replace('http:',"https:")
    const path = 'posts'
    const apiUrl : string = (url == '') ? `${path}?PostSearch%5BonlyFollowers%5D=true&PostSearch%5Bprice_currency%5D=10&page=1&per-page=30&withResultTags=&supportCurrency=true&expand=postUrl,custom_param_string,custom_param_value_string,city_name,neighborhood,imagesCount,totalComments,bookmarkStatus,searchMember,authorisedSeller,note,price_list,chat_enabled,custom_param_array` : fullUrl
    return Api.getInstance().request.get(apiUrl, path)
}
