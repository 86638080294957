import Skeleton from "react-loading-skeleton";

interface loaderProps{
  counter ?: number
  className ?: string
}

const MemberLoader = (props : loaderProps) => {
  const isMobile = window.innerWidth <= 900
  return (
    <>
        <div className="row">
            <div className="col-6 col-lg-4 mt-3">
                <div className={`card  h-100 customShadow `} >
                    <div className="card-body d-flex flex-center flex-column py-9 px-5">
                        <div className="symbol symbol-65px symbol-circle ">
                            <Skeleton width={120} height={70} />
                        </div>
                        <span className="fs-5 text-gray-800 text-hover-primary fw-bold mb-5 text-center"><Skeleton /></span>
                        <div className='d-flex justify-content-center'>
                            <Skeleton width={60} height={30} className="me-3"/>
                            <Skeleton width={50} height={30} className="ms-3"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-6 col-lg-4 mt-3">
                <div className={`card  h-100 customShadow `} >
                    <div className="card-body d-flex flex-center flex-column py-9 px-5">
                        <div className="symbol symbol-65px symbol-circle ">
                            <Skeleton width={130} height={70} />
                        </div>
                        <span className="fs-5 text-gray-800 text-hover-primary fw-bold mb-5 text-center"><Skeleton /></span>
                        <div className='d-flex justify-content-center'>
                            <Skeleton width={60} height={30} className="me-3"/>
                            <Skeleton width={60} height={30} className="ms-3"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-6 col-lg-4 mt-3">
                <div className={`card  h-100 customShadow `} >
                    <div className="card-body d-flex flex-center flex-column py-9 px-5">
                        <div className="symbol symbol-65px symbol-circle ">
                            <Skeleton width={130} height={70} />
                        </div>
                        <span className="fs-5 text-gray-800 text-hover-primary fw-bold mb-5 text-center"><Skeleton /></span>
                        <div className='d-flex justify-content-center'>
                            <Skeleton width={60} height={30} className="me-3"/>
                            <Skeleton width={60} height={30} className="ms-3"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-6 col-lg-4 mt-3">
                <div className={`card  h-100 customShadow `} >
                    <div className="card-body d-flex flex-center flex-column py-9 px-5">
                        <div className="symbol symbol-65px symbol-circle ">
                            <Skeleton width={130} height={70} />
                        </div>
                        <span className="fs-5 text-gray-800 text-hover-primary fw-bold mb-5 text-center"><Skeleton /></span>
                        <div className='d-flex justify-content-center'>
                            <Skeleton width={60} height={30} className="me-3"/>
                            <Skeleton width={60} height={30} className="ms-3"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-6 col-lg-4 mt-3">
                <div className={`card  h-100 customShadow `} >
                    <div className="card-body d-flex flex-center flex-column py-9 px-5">
                        <div className="symbol symbol-65px symbol-circle ">
                            <Skeleton width={130} height={70} />
                        </div>
                        <span className="fs-5 text-gray-800 text-hover-primary fw-bold mb-5 text-center"><Skeleton /></span>
                        <div className='d-flex justify-content-center'>
                            <Skeleton width={60} height={30} className="me-3"/>
                            <Skeleton width={60} height={30} className="ms-3"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-6 col-lg-4 mt-3">
                <div className={`card  h-100 customShadow `} >
                    <div className="card-body d-flex flex-center flex-column py-9 px-5">
                        <div className="symbol symbol-65px symbol-circle ">
                            <Skeleton width={130} height={70} />
                        </div>
                        <span className="fs-5 text-gray-800 text-hover-primary fw-bold mb-5 text-center"><Skeleton /></span>
                        <div className='d-flex justify-content-center'>
                            <Skeleton width={60} height={30} className="me-3"/>
                            <Skeleton width={60} height={30} className="ms-3"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-6 col-lg-4 mt-3">
                <div className={`card  h-100 customShadow `} >
                    <div className="card-body d-flex flex-center flex-column py-9 px-5">
                        <div className="symbol symbol-65px symbol-circle ">
                            <Skeleton width={130} height={70} />
                        </div>
                        <span className="fs-5 text-gray-800 text-hover-primary fw-bold mb-5 text-center"><Skeleton /></span>
                        <div className='d-flex justify-content-center'>
                            <Skeleton width={60} height={30} className="me-3"/>
                            <Skeleton width={60} height={30} className="ms-3"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-6 col-lg-4 mt-3">
                <div className={`card  h-100 customShadow `} >
                    <div className="card-body d-flex flex-center flex-column py-9 px-5">
                        <div className="symbol symbol-65px symbol-circle ">
                            <Skeleton width={130} height={70} />
                        </div>
                        <span className="fs-5 text-gray-800 text-hover-primary fw-bold mb-5 text-center"><Skeleton /></span>
                        <div className='d-flex justify-content-center'>
                            <Skeleton width={60} height={30} className="me-3"/>
                            <Skeleton width={60} height={30} className="ms-3"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-6 col-lg-4 mt-3">
                <div className={`card  h-100 customShadow `} >
                    <div className="card-body d-flex flex-center flex-column py-9 px-5">
                        <div className="symbol symbol-65px symbol-circle ">
                            <Skeleton width={130} height={70} />
                        </div>
                        <span className="fs-5 text-gray-800 text-hover-primary fw-bold mb-5 text-center"><Skeleton /></span>
                        <div className='d-flex justify-content-center'>
                            <Skeleton width={60} height={30} className="me-3"/>
                            <Skeleton width={60} height={30} className="ms-3"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </>
  );
};

export {MemberLoader}