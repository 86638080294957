import { useEffect, useMemo, useState } from "react";
import getCategoriesTextApi from "../../../../api/buyNow/getCategoriesText.api";

import {Modal} from 'react-bootstrap'
import { KTSVG } from "../../../helpers";
import getServicesApi from "../../../../api/buyNow/getServices.api";
import getServiceDetailsApi from "../../../../api/buyNow/getServiceDetails.api";
import createPaymentApi from "../../../../api/buyNow/createPayment.api";
import getCategoriesApi from "../../../../api/category/getCategories.api";
import { CategoryInterface } from "../../../interfaces/CategoryInterface";
import { SubCategoryInterface } from "../../../interfaces/SubCategoryInterface";
import { useLang } from "../../../i18n/OpenSooqi18n";
import { useIntl } from "react-intl";
import { BundleCategoryComponent } from "./BundleCategoryComponent";
import { BundleSubCategoryComponent } from "./BundleSubCategoryComponent";
import { BundleServiceComponent } from "./BundleServiceComponent";
import { BundleServiceDetailsComponent } from "./BundleServiceDetailsComponent";
import { ServiceDetailsInterface } from "../../../interfaces/ServiceDetailsInterface";
import { BuyNowServiceInterface } from "../../../interfaces/BuyNowServiceInterface";
import Skeleton from "react-loading-skeleton";
import { showError } from "../../../helper/SystemAlert";
import getRedirectCartUrl from "../../../../api/buyNow/getRedirectCartUrl";
import { getContactUsByCountry, getCookies } from "../../../helpers/commonFunctions";

type Props = {
  show: boolean
  handleClose: () => void
  children: any
}

type ICategoryText = {
    header : string
    searchBoxPlaceHolder : string
    subHeader : string
}

type IServiceText = {
    header? : string
    blueUnderLineText? : string
    salesTeamButton? : string
    subHeader? : string
    buttonsHeader? : string
    whatsAppButtonValue? : string
    whatsAppButtonText? : string
}

const BuyNowModal = ({show,handleClose,children}: Props) => {
    const lang = useLang()
    const Intl = useIntl()
    const [searchText, setSearchText] = useState<string>("");
    const [categories,setCategories] = useState<CategoryInterface[]>([])
    const [filteredCategories,setFilteredCategories] = useState<CategoryInterface[]>([])
    const [filteredSubCategories,setFilteredSubCategories] = useState<SubCategoryInterface[]>([])
    const [categoryPicked,setCategoryPicked] = useState<boolean>(false)
    const [categoryText, setCategoryText] = useState<ICategoryText>({header: "", searchBoxPlaceHolder: "", subHeader:""})
    const [subCategories,setSubCategories] = useState<SubCategoryInterface[]>([])
    const [subCategory, setSubCategory] = useState<SubCategoryInterface>()
    const [subCategoryPicked, setSubCategoryPicked] = useState<boolean>(false)
    const [subCategoryText, setSubCategoryText] = useState<ICategoryText>({header: "", searchBoxPlaceHolder: "", subHeader:""})
    const [service,setService] = useState<BuyNowServiceInterface>()
    const [servicePicked, setServicePicked] = useState<boolean>(false)
    const [serviceDetails, setServiceDetails]  = useState<ServiceDetailsInterface>();
    const [serviceText, setServiceText] = useState<IServiceText>({})
    const [services, setServices] = useState<BuyNowServiceInterface []>()
    const [total, setTotal] = useState<number>()
    const [currency, setCurrency] = useState<string>()
    const [isCalculating, setIsCalculating] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [productAssignId, setProductAssignId] = useState<number>()
    const [qty, setQty] = useState<number>()
    const [isRedirecting, setIsRedirecting] = useState<boolean>(false)
    const country = getCookies().ecountry;
    const contactUs = getContactUsByCountry(getCookies().ecountry || '')
    const openSooqUrl =  (process.env.REACT_APP_OPENSOOQ_BASE ?? '').replace("{country}", country ?? '').replace("{lang}", lang ?? '');

    const isServiceDetails =  serviceDetails && servicePicked && categoryPicked && subCategoryPicked && service
    const isService = !isLoading && !servicePicked && categoryPicked && subCategoryPicked && services
    const isSubCategory =  !isLoading && !servicePicked && categoryPicked && !subCategoryPicked && subCategories
    const isCategory = !isLoading && !servicePicked  && !categoryPicked && filteredCategories

    useEffect(()=>{
        if(!show) return
        setIsLoading(true)
        getCategoriesText()
        getCategories()
        getServices()
    },[show]);



    const getAdvertisementServicesDetailsUrl = () => {
        const adv = (lang === 'ar') ? 'الخدمات-الاعلانية' : 'advertising-products'
        return (process.env.REACT_APP_OPENSOOQ_BASE) ? process.env.REACT_APP_OPENSOOQ_BASE.replace("{country}", getCookies().ecountry ?? '').replace("{lang}", lang ?? '') + '/'+ adv : ''
    }

    const getCategories = ()=>{
        getCategoriesApi().then(res => {
            setCategories(res.data.items)
            setFilteredCategories(res.data.items)
            setIsLoading(false)
        }).catch(error=>{
            if(error.response && error.response.data){
                showError(Intl, lang, error.response.data)
              }
            else{
                showError(Intl, lang)
            }
        })
    }
    
    const getCategoriesText = ()=>{
        getCategoriesTextApi().then(res => {
            setCategoryText(res.data.mainCategories)
            setSubCategoryText(res.data.subCategories)
        }).catch(error=>{
            if(error.response && error.response.data){
                showError(Intl, lang, error.response.data)
              }
            else{
                showError(Intl, lang)
            }
        });
    }
    
    const getServices = ()=>{
        getServicesApi().then( (res)=>{
            setServices(res.data.services);
            setServiceText( (old : IServiceText) => { return {...old, header : res.data.header ?? "", subHeader : res.data.subHeader ?? "", blueUnderLineText: res.data.blueUnderLineText??"", buttonsHeader:res.data.buttonsHeader??"",salesTeamButton:res.data.salesTeamButton??"", whatsAppButtonText:res.data.whatsAppButtonText??"", whatsAppButtonValue:res.data.whatsAppButtonValue??""} });
        }).catch(error=>{
            if(error.response && error.response.data){
                showError(Intl, lang, error.response.data);
              }
            else{
                showError(Intl, lang)
            }
        })
    }
    
    const getServiceDetails = (serviceName:string, category: number)=>{
        getServiceDetailsApi(serviceName,category).then(res => {
            setServiceDetails(res.data);
            setTotal(res.data.defaultTotal.amount)
            const len = res.data.durations.length
            const qtyLen = res.data.qty.length
            setCurrency(res.data.defaultTotal.currency)
            setQty(res.data.qty[qtyLen - 1].value)
            setProductAssignId(res.data.durations[len-1].productAssignId)
            setServicePicked(true)
            setIsLoading(false)
        }).catch(error=>{
            if(error.response && error.response.data){
                showError(Intl, lang, error.response.data)
            }
            else{
                showError(Intl, lang)
            }
        })
    }
    
    const createPayment = ()=>{
        if(!productAssignId || !qty)
        return;
        setIsRedirecting(true)
        createPaymentApi(productAssignId.toString(), qty, "myWallet", "0").then(res=>{
            if(res && res.data){
                const sourceTransactionId = res.data.source_transaction_id
                getRedirectCartUrl(sourceTransactionId).then(res=>{
                    if(res && res.data && res.data.redirect){
                        window.location.href = res.data.redirect
                        setIsRedirecting(false)
                    }
                }).catch(error=>{
                    if(error.response && error.response.data){
                        showError(Intl, lang, error.response.data);
                    }else{
                        showError(Intl, lang)
                    }
                    setIsRedirecting(false)
                });
            }
        }).catch(error=>{
            if(error.response && error.response.data){
                showError(Intl, lang, error.response.data);
            }else{
                showError(Intl, lang)
            }
            setIsRedirecting(false)
        })
    }

    const setProductAssignIdFromChild = (productAssignIdState:number): void=>{
        setProductAssignId(productAssignIdState)
    }

    const setQtyFromChild = (qtyState : number) : void=>{
        setQty(qtyState)
    }

    const search = (value : string) => {
        if(!categoryPicked){
        setSearchText(value)
        let cats = categories
        if(value !== '' && value ){
            cats = categories?.filter((cat) => {
                 if(cat.label_ar.toLowerCase().includes(value.toLowerCase()) || cat.label_en.toLowerCase().includes(value.toLowerCase())){
                    return true
                 }
                 return false
            })
        }
        setFilteredCategories(cats);
        }else {
            setSearchText(value);
            let subCats = subCategories;
            if(value !== '' && value ){
                subCats = subCategories?.filter((cat) => {
                     if(cat.label_ar.toLowerCase().includes(value.toLowerCase()) || cat.label_en.toLowerCase().includes(value.toLowerCase())){
                        return true
                     }
                     return false
                })
            }
            setFilteredSubCategories(subCats);
        }
    }

    const pickCategory = (category : CategoryInterface) => {
        setCategoryPicked(true)
        setSearchText('')
        setSubCategories(category.subCategories)
        setFilteredSubCategories(category.subCategories)
    }

    const backActionHandler = () => {
        if(servicePicked && categoryPicked && subCategoryPicked)
        {
            setServicePicked(false)
        }
        else if(categoryPicked && subCategoryPicked)
        {
            setSubCategoryPicked(false)
            setFilteredSubCategories(subCategories)  
            setSearchText('')  
        }else if(categoryPicked && !subCategoryPicked)
        {
            setCategoryPicked(false)
            setFilteredCategories(categories)
            setSearchText('')
        }
    }

    const pickSubCat = (subCat : SubCategoryInterface) => {
        setSubCategoryPicked(true)
        setSubCategory(subCat)
        setSearchText('')
    }

    const pickService = (service : BuyNowServiceInterface) => {
        if(subCategory){
            setService(service)
            setIsLoading(true)
            getServiceDetails(service.service, subCategory.reporting_name)
        }
    }

    const closeHandler = () => {
        handleClose()
        setTimeout(()=>{
            setCategoryPicked(false)
            setSubCategoryPicked(false)
            setServicePicked(false)
            setService(undefined)
        }, 1000)
    }

    const skeletonRows = useMemo(()=>{
        let rows = []
        for (let i = 0; i < 10; i++) {
            rows.push(<Skeleton className="m-5 h-35px w-400px" key={i}/>)
        }
        return rows;
    }, []);

    console.log(service ,isLoading)

    return  <>
        <Modal id='iframe'tabIndex={-1} aria-hidden='true' dialogClassName='modal-dialog modal-dialog-centered' show={show} onHide={closeHandler}  >
            <div className='modal-header p-5'>
                {!isLoading ? <div className="h1">
                    { (isSubCategory || isService || isServiceDetails) && <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={backActionHandler}>
                         <div style={{display:"flex"}} className="align-self-center p-2">{lang === 'ar' ? <><i className="fas fs-2 fa-arrow-right"></i></> : <><i className="fas fs-2 fa-arrow-left"></i></>}</div>
                    </div>}
                    {isCategory && categoryText.header}
                    {isSubCategory && subCategoryText.header}
                    {isService && serviceText && serviceText.header}
                    {isServiceDetails && service.text}
                </div> : <span className="h1"><Skeleton className="h-30px w-300px"/></span>}
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={closeHandler}>
                    <KTSVG className='svg-icon-1 fa-5x' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>
            <div className={`modal-body py-5 overflow-scroll ${ (isServiceDetails || isService) || (isLoading && service !== undefined) ? 'h-600px' : 'h-800px'}`} >
                { !isLoading ? <div className="h4">
                    {isCategory && categoryText.subHeader}
                    {isSubCategory && subCategoryText.subHeader}
                    {isService && serviceText && serviceText.subHeader}
                </div> : <Skeleton className="h-20px w-250px"/> }
                {isService && serviceText && <a className="h4 text-primary" target="_blank" href={`${getAdvertisementServicesDetailsUrl()}`}>{serviceText.blueUnderLineText}</a>}
                <div className={`row ${ categoryPicked && subCategoryPicked ? 'pt-5' : ''}`}>
                    {(!categoryPicked || !subCategoryPicked) && <div className="col-12 py-5">
                        <div className={`d-flex align-items-center position-relative my-1 w-100 `}>
                            <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                                </svg>
                            </span>
                            { !isLoading ? <input type="text" data-kt-ecommerce-order-filter="search" value={searchText} onChange={(e) => {search(e.target.value)}} className="form-control form-control-solid ps-14" placeholder={isCategory ? categoryText.searchBoxPlaceHolder : subCategoryText.searchBoxPlaceHolder}/> : <Skeleton className="h-25px w-400px"/>}
                        </div>
                    </div>}
                    { isLoading && skeletonRows}
                    { isServiceDetails && qty && productAssignId && <BundleServiceDetailsComponent lang={lang} serviceDetails={serviceDetails} toggleLoading={()=> setIsCalculating((old:boolean) => !old)} changeCurrency={(currency:string)=>{setCurrency(currency)}} changeTotal={(total:number)=>{setTotal(total)}} setParentProductAssignId={setProductAssignIdFromChild} setParentQty={setQtyFromChild} qty={qty} productAssignId={productAssignId} isRedirecting={isRedirecting}/>}
                    { isService && <BundleServiceComponent action={pickService} lang={lang} services={services} />}
                    { isSubCategory && <BundleSubCategoryComponent lang={lang} searchData={filteredSubCategories} action={pickSubCat}/>} 
                    { isCategory && <BundleCategoryComponent lang={lang} searchData={filteredCategories} action={pickCategory}/>}
                </div>
            </div>
            {isLoading && isService &&  <div className="modal-footer">
                <div className="col-12 ">
                    <div className="d-flex align-items-center">             
                        <span className="fw-semibold h1 text-gray-800 flex-grow-1 ">
                            <span className="h1"><Skeleton className="h-20px w-100px"/></span> 
                        </span> 
                        <span className="h1"><Skeleton className="h-20px w-100px"/></span> :
                    </div>
                </div>
                <button className="btn fs-3 btn-primary w-100 btn-block" onClick={createPayment} disabled={isRedirecting}>
                    {!isRedirecting && <span>{Intl.formatMessage({id : 'buyNow'})}</span>}
                    {isRedirecting && <span>{Intl.formatMessage({id : 'pleaseWait'})}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>}
                </button>            
            </div>}
            {isServiceDetails && <div className="modal-footer">
                <div className="col-12 ">
                    <div className="d-flex align-items-center">             
                        <span className="fw-semibold h1 text-gray-800 flex-grow-1 ">
                            {serviceDetails.total}
                        </span> 
                        {isCalculating ? <span className="h1"><Skeleton className="h-20px w-100px"/></span> :
                        <span className="h1">{`${total} ${currency}`}</span>}
                    </div>
                </div>
                <button className="btn fs-3 btn-primary w-100 btn-block" onClick={createPayment} disabled={isRedirecting}>
                    {!isRedirecting && <span>{Intl.formatMessage({id : 'buyNow'})}</span>}
                    {isRedirecting && <span>{Intl.formatMessage({id : 'pleaseWait'})}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>}
                </button>            
            </div>}
            {isService  && <div className="modal-footer">
                <div className="container ">
                    <div className="row-12 mb-5">
                        <div className="col-12 h2">
                            {serviceText.buttonsHeader}
                        </div>
                    </div>
                    <div className="row-12 d-flex flex-row justify-content-between">
                        <a className="col-5 btn btn-primary" target="_blank" href={`${openSooqUrl}/sales-representatives`}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="me-2" width="15" height="15" viewBox="0 0 24 24" fill="none">
                                <path d="M11.987 0.333252C5.54698 0.333252 0.320312 5.55992 0.320312 11.9999C0.320312 18.4399 5.54698 23.6666 11.987 23.6666C18.427 23.6666 23.6536 18.4399 23.6536 11.9999C23.6536 5.55992 18.427 0.333252 11.987 0.333252ZM16.1986 7.72992C17.447 7.72992 18.4503 8.73325 18.4503 9.98159C18.4503 11.2299 17.447 12.2333 16.1986 12.2333C14.9503 12.2333 13.947 11.2299 13.947 9.98159C13.9353 8.73325 14.9503 7.72992 16.1986 7.72992ZM9.19865 5.88659C10.7153 5.88659 11.952 7.12325 11.952 8.63992C11.952 10.1566 10.7153 11.3933 9.19865 11.3933C7.68198 11.3933 6.44531 10.1566 6.44531 8.63992C6.44531 7.11159 7.67031 5.88659 9.19865 5.88659ZM9.19865 16.5383V20.9133C6.39865 20.0383 4.18198 17.8799 3.20198 15.1266C4.42698 13.8199 7.48365 13.1549 9.19865 13.1549C9.81698 13.1549 10.5986 13.2483 11.4153 13.4116C9.50198 14.4266 9.19865 15.7683 9.19865 16.5383ZM11.987 21.3333C11.672 21.3333 11.3686 21.3216 11.0653 21.2866V16.5383C11.0653 14.8816 14.4953 14.0533 16.1986 14.0533C17.447 14.0533 19.6053 14.5083 20.6786 15.3949C19.3136 18.8599 15.942 21.3333 11.987 21.3333Z" fill="#ffffff"/>
                            </svg>
                            {serviceText.salesTeamButton}
                        </a>
                        <a className="col-5 btn btn-success" target="_blank" href={`${process.env.REACT_APP_WHATSAPP_CONTACT}${contactUs.whatsapp}&text&type=phone_number&app_absent=0`}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="me-2" width="15" height="15" viewBox="0 0 24 24" fill="none"><path d="M20.224 3.72825C18.0423 1.53492 15.1373 0.333252 12.0456 0.333252C5.67562 0.333252 0.483959 5.52492 0.483959 11.8949C0.483959 13.9366 1.02063 15.9199 2.02396 17.6699L0.390625 23.6666L6.51563 22.0566C8.20729 22.9783 10.109 23.4683 12.0456 23.4683C18.4156 23.4683 23.6073 18.2766 23.6073 11.9066C23.6073 8.81492 22.4056 5.90992 20.224 3.72825ZM12.0456 21.5083C10.319 21.5083 8.62729 21.0416 7.14563 20.1666L6.79563 19.9566L3.15563 20.9133L4.12396 17.3666L3.89063 17.0049C2.93396 15.4766 2.42063 13.7033 2.42063 11.8949C2.42063 6.59825 6.73729 2.28159 12.034 2.28159C14.6006 2.28159 17.0156 3.28492 18.824 5.10492C20.644 6.92492 21.6356 9.33992 21.6356 11.9066C21.659 17.2033 17.3423 21.5083 12.0456 21.5083ZM17.319 14.3216C17.0273 14.1816 15.604 13.4816 15.3473 13.3766C15.079 13.2833 14.8923 13.2366 14.694 13.5166C14.4956 13.8083 13.9473 14.4616 13.784 14.6483C13.6206 14.8466 13.4456 14.8699 13.154 14.7183C12.8623 14.5783 11.929 14.2633 10.8323 13.2833C9.96896 12.5133 9.39729 11.5683 9.22229 11.2766C9.05896 10.9849 9.19896 10.8333 9.35063 10.6816C9.47896 10.5533 9.64229 10.3433 9.78229 10.1799C9.92229 10.0166 9.98063 9.88825 10.074 9.70159C10.1673 9.50325 10.1206 9.33992 10.0506 9.19992C9.98063 9.05992 9.39729 7.63659 9.16396 7.05325C8.93062 6.49325 8.68563 6.56325 8.51063 6.55159C8.33563 6.55159 8.14896 6.55159 7.95062 6.55159C7.75229 6.55159 7.44896 6.62159 7.18062 6.91325C6.92396 7.20492 6.17729 7.90492 6.17729 9.32825C6.17729 10.7516 7.21563 12.1283 7.35563 12.3149C7.49563 12.5133 9.39729 15.4299 12.2906 16.6783C12.979 16.9816 13.5156 17.1566 13.9356 17.2849C14.624 17.5066 15.254 17.4716 15.7556 17.4016C16.3156 17.3199 17.4706 16.7016 17.704 16.0249C17.949 15.3483 17.949 14.7766 17.8673 14.6483C17.7856 14.5199 17.6106 14.4616 17.319 14.3216Z" fill="#ffffff"/></svg>
                            {serviceText.whatsAppButtonText}
                        </a>
                    </div>
                </div>                         
            </div>}
            {(isLoading && service !== undefined) && <div className="modal-footer">
                <div className="container ">
                    <div className="row-12 mb-5">
                    <div className="row-12 mx-auto d-flex flex-row justify-content-between">
                        <span className="h1"><Skeleton className="h-20px w-150px"/></span>
                        <span className="h1"><Skeleton className="h-20px w-150px"/></span>
                    </div>
                    </div>
                    <div className="row-12 mx-auto d-flex flex-row justify-content-center">
                        <span className="h1"><Skeleton className="h-50px w-400px"/></span>
                    </div>
                </div>                         
            </div>}
        </Modal>
    </>
}

export {BuyNowModal}