/*global CryptoJS*/
import b64_safe, {safe} from "./b64_safe";

const getUtf8Bytes = str => new Uint8Array(
    [...unescape(encodeURIComponent(str))].map(c => c.charCodeAt(0))
);


export default async function hmac({key, msg}) {
    const encodedMessage = getUtf8Bytes(msg);
    const keyBytes = getUtf8Bytes(key);
    
    const cryptoKey = await window.crypto.subtle.importKey(
        'raw', keyBytes, {name: 'HMAC', hash: 'SHA-256'},
        true, ["sign", "verify"]
    );
    
    const signature = await window.crypto.subtle.sign(
        "HMAC",
        cryptoKey,
        encodedMessage
    );
    
    return b64_safe(String.fromCharCode(...new Uint8Array(signature)));
    
}