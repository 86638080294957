import { toAbsoluteUrl } from "../../../app/helpers";
import { SearchComponent } from "../../../app/layout/components/opensooq/SearchComponent";
import {useIntl} from 'react-intl'
import React, { useEffect, useState } from "react";
import getCarFaxApi from "../../../api/servicrs/get.carFax.api";
import { CarFaxInterface } from "../../interfaces/CarFaxInterface";
import { CarFaxReportView } from "../../../app/layout/components/modals/CarFaxReportView";
import { TableLoader } from "../../../app/layout/components/opensooq/TableLoader";
import { useLang } from "../../i18n/OpenSooqi18n";
export function CarfaxReport() {
    const Intl = useIntl();
    const [carFaxList,setCarFaxList] = useState<CarFaxInterface[]>([])
    const [searchData,setSearchData] = useState<CarFaxInterface[]>([])
    const [carFaxRequestedId,setCarFaxRequestedId] = useState<number>(0)
    const [showModal, setShowModal] = useState(false)
    const [showCarFaxLoader,setShowCarFaxLoader] = useState<boolean>(true)

    useEffect(() => {
        getCarFaxApi('').then (response => {
            setCarFaxList(response.data.items)
            setSearchData(response.data.items)
            setShowCarFaxLoader(false);
        })
    },[])

    const collapse = (e: any) => {
        if(e.currentTarget.classList.contains('active')){
            e.currentTarget.classList.remove('active');
        }else{
            e.currentTarget.classList.add('active');
        }
    };

    const showReport = (id : number) => {
        setCarFaxRequestedId(id);
        setShowModal(true)
    }

    const closePopup = () => {
        setShowModal(false)
    }
  
      
    const CarFaxList:React.FC<{searchData:CarFaxInterface[]}> = ({searchData}) => {
        return <table className="table table-hover align-middle table-row-dashed fs-6 gy-3 dataTable no-footer" id="kt_table_widget_4_table">
            <thead>
                <tr className="text-start text-gray-900 fw-bold fs-7 text-uppercase gs-0">
                    <th className="min-w-100px sorting_disabled" rowSpan={1} colSpan={1} >{Intl.formatMessage({id: 'carFax.post'})} </th>
                    <th className="text-start min-w-100px sorting_disabled hideOnMobile" rowSpan={1} colSpan={1} >  {Intl.formatMessage({id: 'vinNumber'})} </th>
                    <th className="text-start min-w-125px sorting_disabled hideOnMobile" rowSpan={1} colSpan={1}> {Intl.formatMessage({id: 'dateOfIssue'})}</th>
                    <th className="text-start sorting_disabled" rowSpan={1} colSpan={1} ></th>
                </tr>
            </thead>
            <tbody className="">
                {searchData.map((carFax  : CarFaxInterface,index) => {
                return <React.Fragment key={index}>
                    <tr className={(index%2 === 0) ? "even": "odd" }>
                        <td className="">
                            <div className="d-flex align-items-center">
                                <div className="symbol symbol-50px me-5">
                                    <img  width="40" height="40" src={toAbsoluteUrl('/media/custom/carfax.svg')} alt="carfax" />
                                </div>
                                <div className="d-flex justify-content-start flex-column">
                                    <a href="#" className="text-dark fw-bold text-hover-primary fs-6">{carFax.shortDescription}</a>
                                </div>
                            </div>
                        </td>
                        <td className="hideOnMobile">
                            {carFax.vin_number}
                        </td>
                        <td className="hideOnMobile">
                            {carFax.expire_at}
                        </td>
                        <td className="hideOnMobile text-end">
                            <button onClick={() => showReport(carFax.id)} className="btn  btn-lg bg-primary text-white pt-2 pb-2 ">{Intl.formatMessage({id : "carFax.viewCarFax"})}</button>
                        </td>
                        <td className="text-end hideOnDesktop">
                            <button onClick={(e) => {collapse(e)}} type="button" className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px" data-kt-table-widget-4="expand_row"  data-bs-toggle="collapse" data-bs-target={`#id-${carFax.id}`}>
                                <span className="svg-icon svg-icon-3 m-0 toggle-off">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1"
                                            transform="rotate(-90 11 18)" fill="currentColor"></rect>
                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"></rect>
                                    </svg>
                                </span>
                                <span className="svg-icon svg-icon-3 m-0 toggle-on">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"></rect>
                                    </svg>
                                </span>
                            </button>
                        </td>
                    </tr>
                    <tr id={`id-${carFax.id}`} className="hideOnDesktop collapse" data-bs-parent="#kt_accordion_tr-1"  >
                        <td colSpan={6} className="shadow1">
                            <dl className="row">
                                <dt className="col-4 text-gray-500 fw-bolder">{Intl.formatMessage({id: 'post'})}</dt>
                                <dd className="col-8 text-gray-600 fw-bold">{carFax.shortDescription}</dd>
                            </dl>
                            <dl className="row">
                                <dt className="col-4 text-gray-500 fw-bolder">{Intl.formatMessage({id: 'vinNumber'})}</dt>
                                <dd className="col-8 text-gray-600 fw-bold">{carFax.vin_number}</dd>
                            </dl>
                            <dl className="row">
                                <dt className="col-4 text-gray-500 fw-bolder">{Intl.formatMessage({id: 'date'})}</dt>
                                <dd className="col-8 text-gray-600 fw-bold">{carFax.expire_at}</dd>
                            </dl>
                            <dl className="row">
                                <dd className="col-12">
                                    <button  onClick={() => showReport(carFax.id)} className="btn  btn-lg bg-primary text-white pt-2 pb-2 ">{Intl.formatMessage({id : "carFax.viewCarFax"})}</button>
                                </dd>
                            </dl>
                        </td>
                    </tr>
                </React.Fragment>
                })}
            </tbody>
        </table>
    };
    
    const lang = useLang()
    const serviceUrl = (lang === 'ar') ? 'كارفاكس' : 'carfax'
    const openSooqUrl =  "https://" + (process.env.REACT_APP_DOMAIN_BASE ?? '') + '/' + lang + '/' + serviceUrl;
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <div className='row w-100 align-items-center justify-content-between'>
                        <div className='col-lg-4 col-md-6 col-6'>
                            <SearchComponent placeholder={`${Intl.formatMessage({id: 'searchForCarfaxReport'})}`} className={"w-100"}/>
                        </div>
                        <div className='col-xl-3 col-md-3 col-6'>
                            <div className="d-flex justify-content-end " data-kt-filemanager-table-toolbar="base">
                                <a href={openSooqUrl} target="_blank" className=" btn btn-primary" >
                                    {Intl.formatMessage({id: 'requestCarfaxReport'})}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {showCarFaxLoader && <TableLoader/>}
                    {!showCarFaxLoader && searchData.length == 0 && <div className="text-center p-10">
                        <h1>{Intl.formatMessage({id:"carFax.noCarFax"})}</h1>
                        <img  src={toAbsoluteUrl('/media/custom/noCarFax.svg')} alt="no-carFax" />
                    </div>}
                    {!showCarFaxLoader && searchData.length > 0 && <CarFaxList searchData={searchData}/>}
                </div>
            </div>
            {showModal && <CarFaxReportView  show={showModal} handleClose={() => closePopup()} id={carFaxRequestedId}/>}
        </>
    )
}