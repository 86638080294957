const social = {
    "header": {
      "googlePlayStore": "https://play.google.com/store/apps/details?id=com.opensooq.OpenSooq&referrer=utm_source%3Dopensooq%26utm_medium%3Dapp_install%26utm_campaign%menu_my_opensooq",
      "appleStore": "https://apps.apple.com/app/apple-store/id654456967?pt=28744801&menu_my_opensooq&mt=8",
      "huaweiStore": "https://url.cloud.huawei.com/ivs6Oocg9O?shareTo=qrcode"
    },
    "footer": {
      "googlePlayStore": "https://play.google.com/store/apps/details?id=com.opensooq.OpenSooq&referrer=utm_source%3Dopensooq%26utm_medium%3Dapp_install%26utm_campaign%menu_my_opensooq",
      "appleStore": "https://apps.apple.com/app/apple-store/id654456967?pt=28744801&ct=menu_my_opensooq&mt=8",
      "huaweiStore": "https://url.cloud.huawei.com/ivs5281KMM?shareTo=qrcode"
    },
    "downloadAppPage": {
      "googlePlayStore": "https://play.google.com/store/apps/details?id=com.opensooq.OpenSooq&referrer=utm_source%3Dopensooq%26utm_medium%3Dmobile%26utm_campaign%3DMobile-menu_my_opensooq",
      "appleStore": "https://apps.apple.com/app/apple-store/id654456967?pt=28744801&ct=menu_my_opensooq&mt=8",
      "huaweiStore": "https://url.cloud.huawei.com/ivs5yIUZBm?shareTo=qrcode"
    },
    "splashScreen": {
      "googlePlayStore": "https://play.google.com/store/apps/details?id=com.opensooq.OpenSooq&referrer=utm_source%3Dopensooq%26utm_medium%3Dapp_install%26utm_campaign%3Dmobile_splash",
      "appleStore": "https://apps.apple.com/app/apple-store/id654456967?pt=28744801&ct=menu_my_opensooq&mt=8",
      "huaweiStore": "https://url.cloud.huawei.com/ivs67qWLZe?shareTo=qrcode"
    },
    "sideMenu": {
      "googlePlayStore": "https://play.google.com/store/apps/details?id=com.opensooq.OpenSooq&referrer=utm_source%3Dopensooq%26utm_medium%3Dapp_install%26utm_campaign%menu_my_opensooq",
      "appleStore": "https://apps.apple.com/app/apple-store/id654456967?pt=28744801&menu_my_opensooq&mt=8",
      "huaweiStore": "https://url.cloud.huawei.com/ivs67qWLZe?shareTo=qrcode"
    },
    "headerMenu": {
      "googlePlayStore": "https://play.google.com/store/apps/details?id=com.opensooq.OpenSooq&referrer=utm_source%3Dopensooq%26utm_medium%3Dapp_install%26utm_campaign%menu_my_opensooq",
      "appleStore": "https://apps.apple.com/app/apple-store/id654456967?pt=28744801&ct=menu_my_opensooq&mt=8",
      "huaweiStore": "https://url.cloud.huawei.com/ivs67qWLZe?shareTo=qrcode"
    }
  }

  export {social};
