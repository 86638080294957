import {useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import { useIntl } from "react-intl"
import {useDropzone} from 'react-dropzone';
import ImageUploading from 'react-images-uploading';
import { Loader } from '../opensooq/Loader';


type Props = {
    show : boolean
    files : any
    loader : boolean
    handleClose: () => void
    setFilesData: (files  : any) => void 
    uploadFile : (file  : any) => void 
}

const UploadMedia = ({show,handleClose,files,setFilesData,uploadFile,loader} : Props) => {
    const [images, setImages] = useState([])
    const Intl = useIntl()
    const maxNumber = 69;
    const onChange = (imageList : any, addUpdateIndex : any) => {
        uploadFile(imageList);
    };
    
    return <>
        <Modal id='kt_modal_create_app' tabIndex={-1} aria-hidden='true' dialogClassName='modal-dialog modal-dialog-centered ' show={show} >
            <div className='modal-header'>
                <h1 className='modal-title'>{Intl.formatMessage({id: 'myProfile.id.text3'})}</h1>
                <span onClick={handleClose} className="hoverPointer">
                    <i className='fa-solid fa-xmark fs-1'></i>
                </span>
            </div>
            <div className="modal-body text-center">
                <ImageUploading value={images}  onChange={onChange}  maxNumber={1} dataURLKey="data_url" allowNonImageType={true}>
                    {({
                        imageList,
                        onImageUpload,
                        isDragging,
                        dragProps,
                    }) => (
                    <div className="upload__image-wrapper">
                        {
                        loader ? Loader() :    
                        <button style={isDragging ? { color: 'red' } : undefined} className="btn btn-primary" onClick={onImageUpload} {...dragProps} >
                            {Intl.formatMessage({id : "uploadId"})}
                        </button>
                        }
                    </div>
                    )}
                </ImageUploading>
            </div>
        </Modal>
    </>
}

export {UploadMedia}